//import { GanttComponent } from "@syncfusion/ej2-react-gantt";
import {
  GanttComponent,
  Inject,
  Selection,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-gantt";
import * as React from "react";
import Header from "./Header";
import * as ReactDOM from "react-dom";
import "../App.css";
import { appCred } from "../AppConfig.js";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import "./assests/StyleSheet/MainHeader.css";
import loadingGif from "../Components/assests/Dashboard/Cube-1s-264px.gif";
import StartDate from "../Components/assests/GanttChart/StartDate.png";
import Progress from "../Components/assests/GanttChart/Progress.png";
import Duration from "../Components/assests/GanttChart/Duration.png";
import TaskName from "../Components/assests/GanttChart/TaskName.png";

const SAMPLE_CSS = `
.material img.resource, .fabric img.resource, .bootstrap img.resource,
.tailwind img.resource, .bootstrap5 img.resource, .bootstrap4 img.resource,
.fluent img.resource{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/Resources.png");
}
.material-dark img.resource, .fabric-dark img.resource, .bootstrap-dark img.resource,
.tailwind-dark img.resource, .bootstrap5-dark img.resource, .highcontrast img.resource,
.fluent-dark img.resource{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/ResourcesDark.png");
}
.material img.taskName, .fabric img.taskName, .bootstrap img.taskName,
.tailwind img.taskName, .bootstrap5 img.taskName, .bootstrap4 img.taskName,
.fluent img.taskName{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/Task name.png");
}
.material-dark img.taskName, .fabric-dark img.taskName, .bootstrap-dark img.taskName,
.tailwind-dark img.taskName, .bootstrap5-dark img.taskName, .highcontrast img.taskName,
.fluent-dark img.taskName{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/TaskNameDark.png");
}
.material img.startDate, .fabric img.startDate, .bootstrap img.startDate,
.tailwind img.startDate, .bootstrap5 img.startDate, .bootstrap4 img.startDate,
.fluent img.startDate{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/Start date.png");
}
.material-dark img.startDate, .fabric-dark img.startDate, .bootstrap-dark img.startDate,
.tailwind-dark img.startDate, .bootstrap5-dark img.startDate, .highcontrast img.startDate,
.fluent-dark img.startDate{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/StartDateDark.png");
}
.material img.duration, .fabric img.duration, .bootstrap img.duration,
.tailwind img.duration, .bootstrap5 img.duration, .bootstrap4 img.duration,
.fluent img.duration{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/Duration.png");
}
.material-dark img.duration, .fabric-dark img.duration, .bootstrap-dark img.duration,
.tailwind-dark img.duration, .bootstrap5-dark img.duration, .highcontrast img.duration,
.fluent-dark img.duration{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/DurationDark.png");
}
.material img.progressTemplate, .fabric img.progressTemplate, .bootstrap img.progressTemplate,
.tailwind img.progressTemplate, .bootstrap5 img.progressTemplate, .bootstrap4 img.progressTemplate,
.fluent img.progressTemplate{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/Progress.png");
}
.material-dark img.progressTemplate, .fabric-dark img.progressTemplate, .bootstrap-dark img.progressTemplate,
.tailwind-dark img.progressTemplate, .bootstrap5-dark img.progressTemplate, .highcontrast img.progressTemplate,
.fluent-dark img.progressTemplate{
    content: url("https://ej2.syncfusion.com/react/demos/src/gantt/images/ProgressDark.png");
}
img.resource, img.taskName, img.startDate, img.duration, img.progressTemplate{
    margin-right: 8px;
}`;

class GanttChart extends React.Component {
  constructor() {
    super(...arguments);
    let user = window.mylsataku.get("cache2");
    this.state = {
      bannerInformation: [],
      UserRole: user.User.UserOrganizationType,
      UserID: window.mylsataku.get("cache3"),
      DashboardDetails: {},
      APIReqObj: {},
      userAccessData: {},
      activitiesDD: [],
      GanttData: [],
      selectedActivityOption: "",
      isOpen: false,
      isLoading: true,
      errorMessage: "",
      selectedActivity: "", //For applying conditions
      PSBdata: {},
      LoggedInUserName: user.User.UserName,
      LoggedInUserRole: user.User.UserOrganizationType,
      tempMenu: "",
      showHomeButton: false,
      facilityID:"",
      facilityName:"",
    };
    this.taskFields = {
      id: "TaskID",
      name: "TaskName",
      startDate: "StartDate",
      endDate: "EndDate",
      // duration: "Duration",
      progress: "Progress",
      child: "subtasks",
    };
    this.splitterSettings = {
      columnIndex: 1,
    };
    this.projectStartDate = new Date("08/01/2019");
    this.projectEndDate = new Date("12/31/2022");
    this.gridLines = "Both";
    this.toolbar = ["ExpandAll", "CollapseAll", "Indent", "Outdent"];
    this.timelineSettings = {
      topTier: {
        unit: "Year",
        format: "yyyy",
      },
      bottomTier: {
        unit: "Month",
        format: "MMM",
      },
    };
    this.funcArgs = (args) => {
      args.taskbarBorderColor = "#6c757d";
      //args.taskbarBgColor = "red";
      if (args.data.Progress < 50) {
        args.progressBarBgColor = "#d9534f";
      } else if (args.data.Progress > 50 && args.data.Progress < 80) {
        args.progressBarBgColor = "#c79500";
      } else if (args.data.Progress > 80) {
        args.progressBarBgColor = "#85ccb1";
      }
    };
  }

  removeDiv = () => document.getElementById(`js-licensing`).remove();

  componentDidMount() {

     let passedState = window.history.state;
    console.log("passed", passedState);

    //this.removeDiv();
    if (passedState && passedState.state) {
      this.state.facilityID =  passedState.state.facilityID;
      this.state.facilityName =  passedState.state.facilityName;
      this.GetGanttChartData();
    window
      .APIRequest(appCred, "", "EHRDashboardManager.CheckUserHasMTISBAccess", {
        UserID: this.state.LoggedInUserName,
      })
      .then((apiRes) => {
  
        if (apiRes.ResponseResult == true) {
          this.setState({
            showHomeButton: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else{

      
    }


    
  }

  componentDidUpdate() {
    console.log("Gantt Chart Header Updated");
  }

  rToggleNav = () => {
    window.ToggleNav();
  };
  rLogOut = () => {
    window.mylsataku.removeAll();
    //localStorage.clear();
    window.location = "/";
  };
  Capitalizing(str) {
    let string = str.replace(".", " ");
    var separateWord = string.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  }

  GetGanttChartData = () => {
    console.log('HERE');
 console.log('this.state.facilityID,');
 console.log(this.state);
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetGanttChartData",
        //this.state.APIReqObj
        {
          facilityID: this.state.facilityID,
          activityID: "MTISB",
          LoggedInRole: "COLLABORATOR",
        }
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode === 200) {
          console.log("RES", apiRes);
          let gData = apiRes.ResponseResult;
          let output = gData.map((item, pid) => {
            return {
              TaskID: pid + 1 + "",
              TaskName: item.TaskName,
              StartDate: new Date(item.StartDate),
              EndDate: item.EndDate ? new Date(item.EndDate) : "",
              Progress: item.Progress,
              Flag: item.Flag,
              subtasks: item.subtasks?.map((sItem, sid) => {
                return {
                  TaskID: pid + 1 + "." + (sid + 1),
                  TaskName: sItem.TaskName,
                  StartDate: new Date(sItem.StartDate),
                  EndDate: sItem.EndDate ? new Date(sItem.EndDate) : "",
                  Progress: sItem.Progress,
                  Flag: item.Flag,
                  // subtasks: sItem.subtasks?.map((subItem, ssid) => {
                  //   return {
                  //     TaskID: pid + 1 + "." + (sid + 1) + "." + (ssid + 1),
                  //     TaskName: subItem.TaskName,
                  //     StartDate: new Date(subItem.StartDate),
                  //     EndDate: subItem.EndDate ? new Date(subItem.EndDate) : "",
                  //     Progress: subItem.Progress,
                  //     Flag: item.Flag,
                  //   };
                  // }),
                };
              }),
            };
          });

          console.log("output", output);
          this.setState({ GanttData: output, isLoading: false });
          this.removeDiv();
        } else {
          this.setState({
            // isLoading: false,
            errorMessage: true,
          });
        }
      })
      .catch((error) => {
        this.setState(
          {
            // isLoading: false,
            errorMessage: true,
          },
          () => {
            console.log(error);
          }
        );
      });
  };

  render() {
    return (
      <>
        
        {!this.state.isLoading ? (
          <>
            <div className="control-pane">
              <style>{SAMPLE_CSS}</style>

              {/* 
              <div className="Header p-3">Status Board Gantt Chart</div> */}
              <div className="control-section" style={{ zoom: "75%" }}>
                <GanttComponent
                  id="Editing"
                  dataSource={this.state.GanttData}
                  dateFormat={"MMM dd, y"}
                  treeColumnIndex={0}
                  allowSelection={true}
                  showColumnMenu={false}
                  highlightWeekends={true}
                  allowUnscheduledTasks={true}
                  collapseAllParentTasks={true}
                  projectStartDate={this.projectStartDate}
                  projectEndDate={this.projectEndDate}
                  taskFields={this.taskFields}
                  timelineSettings={this.timelineSettings}
                  splitterSettings={this.splitterSettings}
                  //height="410px"
                  width="100%"
                  gridLines={this.gridLines}
                  toolbar={this.toolbar}
                  queryTaskbarInfo={this.funcArgs}
                >
                  <ColumnsDirective>
                    {/* <ColumnDirective
               field="TaskID"
               headerText="Task ID"
               headerTemplate={() => {
                 return (
                   <div>
                     <img
                       className="taskName"
                       //src="https://ej2.syncfusion.com/react/demos/src/gantt/images/Task name.png"
                       width="20"
                       height="20"
                     />

                     <b className="e-header ml-2">ID</b>
                   </div>
                 );
               }}
             ></ColumnDirective> */}

                    <ColumnDirective
                      field="TaskName"
                      headerText="Job Name"
                      headerTemplate={() => {
                        return (
                          <div className="mt-3">
                            <img
                              className="taskName"
                              src={TaskName} //"https://ej2.syncfusion.com/react/demos/src/gantt/images/Task name.png"
                              width="20"
                              height="20"
                            />

                            <b className="e-header ml-2">Task Name</b>
                          </div>
                        );
                      }}
                      width="250"
                    ></ColumnDirective>

                    <ColumnDirective
                      field="StartDate"
                      headerTemplate={() => {
                        return (
                          <div className="mt-3">
                            <img
                              className="startDate"
                              width="20"
                              src={StartDate} //"https://ej2.syncfusion.com/react/demos/src/gantt/images/Start date.png"
                              height="20"
                            />
                            <b className="e-header ml-2">Start Date</b>
                          </div>
                        );
                      }}
                    ></ColumnDirective>
                    <ColumnDirective
                      field="EndDate"
                      headerTemplate={() => {
                        return (
                          <div className="mt-3">
                            <img
                              className="EndDate"
                              width="20"
                              src={StartDate} //"https://ej2.syncfusion.com/react/demos/src/gantt/images/Start date.png"
                              height="20"
                            />
                            <b className="e-header ml-2">End Date</b>
                          </div>
                        );
                      }}
                    ></ColumnDirective>
                    <ColumnDirective
                      field="Duration"
                      headerTemplate={() => {
                        return (
                          <div className="mt-3">
                            <img
                              className="duration"
                              width="20"
                              src={Duration} //"https://ej2.syncfusion.com/react/demos/src/gantt/images/Duration.png"
                              height="20"
                            />
                            <b className="e-header ml-2">Duration</b>
                          </div>
                        );
                      }}
                    ></ColumnDirective>
                    <ColumnDirective
                      field="Progress"
                      headerTemplate={() => {
                        return (
                          <div className="mt-3">
                            <img
                              className="progressTemplate"
                              width="20"
                              height="20"
                              src={Progress} //"https://ej2.syncfusion.com/react/demos/src/gantt/images/Progress.png"
                            />
                            <b className="e-header ml-2">Progress</b>
                          </div>
                        );
                      }}
                    ></ColumnDirective>
                  </ColumnsDirective>
                </GanttComponent>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="loader"
              style={{ textAlign: "center", color: "#3a99c8", width: "100%" }}
            >
              <img
                src={loadingGif}
                height="100"
                width="100"
                style={{ position: "absolute", top: "40%", left: "47%" }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default GanttChart;
