import moment from "moment";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { appCred } from "../../AppConfig.js";
import IndividualActivityHeader from "../Headers/IndividualActivityHeader";
import * as Contants from "../../utils/Constants";
import CommentsNResourcesModal from "../modals/IndividualAppCommentModal";
import AddnEditFormModal from "../modals/AddNEditFormModalBody";
import RecordHistoryModal from "../modals/RecordHistoryModal";
import MomentUtils from "@date-io/moment";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as CustomFunctions from "./CustomFunctions";
import * as TableFunctions from "./CustomTableControl";
import { TransposingDataFunc } from "./TableDataTransponseFunc.js";
import { Validators } from "../../index.js";

class ApplicationDetailedView extends React.Component {
  constructor(props) {
    super(props);
    this.CommentModalElement = React.createRef();
    this.elRefs = [];
    let user = window.mylsataku.get("cache2");
    this.state = {
      UserOrgId: user.User.UserOrganizationId,
      UserOrgType: user.User.UserOrganizationType,
      FlightCarrierList: user.User.lstOrganizations,
      LoggedInUserName: user.User.UserName,
      UserID: window.mylsataku.get("cache3"),
      tempData: {
        meta: {},
        list: [],
      },
      tempDataList_master: [],
      //BM
      activityData: [],
      DashboardDetails: [],
      SubActivityDictionariesData: [],
      mainActivityProgress: {},
      resourceData: {},
      tableData: [],
      shapeColor: "",
      tableHeading: "",
      modalOpen: false,
      subActivityDescription: "",
      resourcesModalOpen: false,
      commentsModalOpen: false,
      modalHeading: "",
      resourcesData: {},
      formHeading: "",
      showWeightageModal: false,
      subActivityWeightageObject: [],
      WeightageModalHeading: "",
      singleSubActivityWeightage: {},
      SubActivityWeightage: "",
      SubActivityID: "",
      modalData: {},

      //Add Record
      addRecord: { ...Contants.intState },
      addRecorderrors: { ...Contants.intState },
      modalContent: "",
      todayDate: moment().toDate(),
      membersList: window.mylsataku.get("cache4"),
      loggedInUserData: {},
      MainActivtyDateNProgress: {},
      actParticipantstags: [], //Application Training Field
      schParticipantstags: [],
      //Add Resource
      resourceName: "",
      resourceRole: "",
      resourceLocation: "",
      resourceID: "",
      ////
      multidefault: "",
      //RecordInfo For History
      recordInfo: {},
      recordHistoryModalOpen: false,
      historyApiRes: {},
      userData: [],
    };
  }
  componentDidUpdate() {
    window.activatePopoverLoop(this.elRefs);
  }
  componentDidMount() {
    const tempUserID = this.state.UserID.replace("MT\\", "");
    let passedState = window.history.state;
    console.log("passed", passedState);

    if (passedState && passedState.state && passedState.state.item) {
      this.setState(
        {
          activityData: passedState.state.item,
          mainActivityProgress: passedState.state?.progress,
          shapeColor: passedState.state?.hexagoncolor,
          userData: passedState.state?.userAccessData?.UserData,
        },
        () => {
          this.setUserRole(tempUserID);
          this.getAllSubActivity();
        }
      );
    }
    window.CloseNav();
  }

  setUserRole = (tempUserID) => {
    console.log(
      "find",
      this.state.membersList.find((a) => a.RESOURCEUSERID === tempUserID)
    );
    if (this.state.UserOrgType === Contants.AppRoleEnum.Collaborator) {
      const UserDataForApp = this.state.membersList.find(
        (a) =>
          a.APPLICATIONID === this.state.activityData?.APPLICATIONID &&
          a.FACILITYID === this.state.activityData?.FACILITYID &&
          a.RESOURCEUSERID === tempUserID &&
          (a.RESOURCETYPE === "LEAD" ||
            a.RESOURCETYPE === "CO-LEAD" ||
            a.RESOURCETYPE === "MT-LEAD" ||
            a.RESOURCETYPE === "MT-CO-LEAD")
      );
      if (UserDataForApp) {
        this.setState(
          {
            UserOrgType: Contants.AppRoleEnum.Admin,
          },
          console.log("this.state.UserOrgType", this.state.UserOrgType)
        );
      }
    }
  };
  getAllSubActivity = () => {
    let counter = 0;
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
    };
    window
      .APIRequest(appCred, "", "EHRDashboardManager.GetSubActivity", reqData)
      .then((apiRes) => {
        console.log("", apiRes);
        console.log(reqData);
        if (apiRes.ResponseCode == 200) {
          for (let dataItem of apiRes.ResponseResult
            ? apiRes.ResponseResult
            : {}) {
            dataItem.activeClass = false;
            dataItem.subActivityCounter = counter;
            counter++;
          }
          this.setState(
            {
              DashboardDetails: apiRes.ResponseResult,
            },
            () => {
              console.log("DashboardDetails", this.state.DashboardDetails);
              this.getMainActivityTargetDate(
                this.SubActivityGridData(
                  this.state.activityData,
                  this.state.DashboardDetails[0]
                )
              );
              this.GetSubActivityWeightage();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getMainActivityTargetDate = (callbackFunc) => {
    let reqData = {
      APPLICATIONID: this.state.activityData.APPLICATIONID,
      facilityID: this.state.activityData.FACILITYID,
      activityID: this.state.activityData.ACTIVITYID,
    };

    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetActivityMainTargetDttm",
        reqData
      )
      .then((apiRes) => {
        console.log("apiRes.ResponseResult", apiRes.ResponseResult);
        if (apiRes.ResponseCode == 200) {
          this.setState(
            {
              MainActivtyDateNProgress: apiRes.ResponseResult,
            },
            () => {
              if (callbackFunc) {
                callbackFunc();
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  initcomponent = (Data, subActivityName) => {
    let subActivityData = this.state.DashboardDetails.find(
      (a) => a.objMain.SUBACTIVITYID === subActivityName
    );
    console.log("subActivityData", subActivityData);
    let reqData = {
      FacilityID: Data.FACILITYID,
      ActivityID: Data.ACTIVITYID,
      ApplicationID: Data.APPLICATIONID,
    };
    window
      .APIRequest(appCred, "", "EHRDashboardManager.GetSubActivity", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          console.log("ap", apiRes);
          for (let dataItem of apiRes.ResponseResult
            ? apiRes.ResponseResult
            : {}) {
            if (dataItem.objMain.SUBACTIVITYID === subActivityName) {
              dataItem.activeClass = true;
            } else {
              dataItem.activeClass = false;
            }
          }
          this.setState(
            {
              DashboardDetails: apiRes.ResponseResult,
            },
            () => {
              this.getMainActivityTargetDate(
                this.SubActivityGridData(Data, subActivityData)
              );
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  SubActivityGridData = (MainActivityData, SubActivityData) => {
    let reqData = {
      FacilityID: MainActivityData.FACILITYID,
      ActivityID: MainActivityData.ACTIVITYID,
      ApplicationID: MainActivityData.APPLICATIONID,
      RefTableName: SubActivityData.objMain.RefTableName,
      SubActivityID: SubActivityData.objMain.SUBACTIVITYID,
    };

    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetSubActivityDictionariesData",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          console.log(apiRes.ResponseResult);
          let tempData = this.transposeDataFromAPI(
            apiRes.ResponseResult.ObjMain,
            SubActivityData.objMain.SUBACTIVITYID
          );
          this.elRefs = Array(
            tempData.list.length * Object.keys(tempData.meta).length
          )
            .fill()
            .map((_, i) => this.elRefs[i] || React.createRef());
          console.log("tempData", tempData);
          this.setState(
            {
              SubActivityDictionariesData: apiRes.ResponseResult.ObjMain,
              tableHeading: SubActivityData.objMain.SUBACTIVITYID,
              subActivityDescription:
                SubActivityData.objMain.SubActivityDescription,
              tempData: tempData,
              tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
            },
            () => {
              if (MainActivityData.ACTIVITYID == "DNB") {
                document.getElementById("Planned_date").click();
              }
            }
          );
        } else {
          this.setState(
            {
              SubActivityDictionariesData: [],
              tableHeading: "",
              tempData: null,
            },
            () => {
              window.activatePopover();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ToggleTableDiv = (e, x, y, propIdx, rowIdx) => {
    let tempData = this.state.tempData;
    let _eleList = tempData.list;
    let _elepropList = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[0])
      : [];
    let _currentEditValue = x[y].isEdit;
    for (let _item of _eleList) {
      for (let _prop of _elepropList) {
        _item[_prop].isEdit = false;
      }
    }

    if (tempData.meta[y].allowEdit) {
      x[y].isEdit = _currentEditValue ? false : true;
      this.setState({
        tempData: this.state.tempData,
        multidefault: "",
      });
    }
  };

  handleModal = (ModalFor) => {
    let reqData = {
      ApplicationID: this.state.activityData.APPLICATIONID,
      ActivityID: this.state.activityData.ACTIVITYID,
      FacilityID: this.state.activityData.FACILITYID,
    };
    if (ModalFor === "Resources") {
      this.setState(
        {
          modalData: this.state.activityData,
          resourcesModalOpen: true,
          commentsModalOpen: this.state.commentsModalOpen
            ? false
            : this.state.commentsModalOpen,
          modalHeading:
            "[" +
            this.state.activityData.APPLICATIONID +
            "] " +
            this.state.activityData.mnemonic +
            " - " +
            this.state.activityData.Description,
        },
        () => {
          this.applicatoinResourcesAPI(reqData);
        }
      );
    } else if (ModalFor === "Comments") {
      this.setState({
        commentsModalOpen: true,
        modalOpen: this.state.modalOpen ? false : this.state.modalOpen,
        modalHeading:
          "[" +
          this.state.activityData.APPLICATIONID +
          "] " +
          this.state.activityData.mnemonic +
          " - " +
          this.state.activityData.Description,
      });
    }
  };
  transposeDataFromAPI = (list, activityName) => {
    let _list = JSON.parse(JSON.stringify(list));
    const meta_API = TransposingDataFunc(
      activityName,
      this.state.UserOrgType,
      this.state.loggedInUserData
    );
    
    for (let item of _list) {
      let keys = Object.keys(item);
      for (let key of keys) {
        if (
          key === "BASELINEDATE" ||
          key === "PLANNEDDATE" ||
          key === "ACTUALCOMPLETIONDATE" ||
          key === "DETAILSSHAREDWITHMTDT" ||
          key === "SPECSRECDFROMMTDT" ||
          key === "SPECSAPPROVEDBYAKUDT" ||
          key === "ACTDATE" ||
          key === "SCHDATE"
        ) {
          if (item[key]) {
            item["custom" + key] = {
              value: moment(item[key]).format("MMM DD, YYYY"),
              isEdit: false,
            };
          } else {
            item["custom" + key] = { value: "", isEdit: false };
          }
        }
        //else{
        item[key] = { value: item[key], isEdit: false };
        //}
      }
    }
    let tempData = {
      actionCol: {
        isVisible: true,
        buttons: [
          {
            caption: "Edit",
            callbackFunc: this.EditRow,
          },
        ],
      },
      meta: meta_API,
      list: _list,
    };
    return tempData;
  };

  getSubActivtyDicData = (e, data) => {
    e.preventDefault();
    this.state.DashboardDetails.forEach((item) => {
      item.activeClass = false;
    });
    data.activeClass = true;
    this.setState(
      {
        DashboardDetails: this.state.DashboardDetails,
      },
      () => {
        this.SubActivityGridData(this.state.activityData, data);
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      addRecorderrors: { ...Contants.intState },
    });
    if (
      this.state.UserOrgType != Contants.AppRoleEnum.Admin &&
      e.target.name === "NewWeightage" &&
      (e.target.value === "2" || e.target.value == "4")
    ) {
      ToastsStore.error("This value can only be set by admin", 5000);
    } else {
      this.state.addRecord[e.target.name] = e.target.value;
      this.setState({
        addRecord: this.state.addRecord,
      });
    }
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  setErrors = (errors) => {
    //For Validation set error msgs
    this.setState({
      addRecorderrors: errors,
    });
  };

  _handleKeyDown = (e, x, y, propIdx, rowIdx) => {
    if (e.key === "Enter") {
      let reqData;
      if (this.state.tableHeading === Contants._activityName.Dictionary) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          DICTIONARYID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          TARGETHCIS: x.TARGETHCIS.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          _STANDARDCONTENTAVAILABLE: x._STANDARDCONTENTAVAILABLE.value,
          _CMSCONTROL: x._CMSCONTROL.value,
          REFTableName: x.REFTableName.value,
          _DATAGATHERINGONEXCEL: x._DATAGATHERINGONEXCEL.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BUILDSTATUS: x.BUILDSTATUS.value,
          COMMENTS: x.COMMENTS.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          dictWeightage: x.dictWeightage.value,
          RESPONSIBLEAPPTEAM: x.RESPONSIBLEAPPTEAM.value,
          DICTIONARYNATURE: x.DICTIONARYNATURE.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Instrument) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          Comments: x.COMMENTS.value,
          INSLOCATION: x.INSLOCATION.value,
          // LABSECTION : x.LABSECTION.value,
          COMMTYPE: x.COMMTYPE.value,
          _LANTRONIXCONFIGURED: x._LANTRONIXCONFIGURED.value,
          _INSTRUMENTCONFIGURED: x._INSTRUMENTCONFIGURED.value,
          _LISCONNESTABLISHED: x._LISCONNESTABLISHED.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          // INSTRUMENTID: x.INSTRUMENTID.value
          INSTRUMENTID: x.DICTIONARYID.value,
          LASTFILEUSERID: this.state.UserID,
          // RESPONSIBLEAPPTEAM: x.RESPONSIBLEAPPTEAM.value,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Interface ||
        this.state.tableHeading === Contants._activityName.MD
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          Comments: x.COMMENTS.value,
          COMMTYPE: x.COMMTYPE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          INTERFACEID: x.DICTIONARYID.value,
          INTERFACEBELONGSTO: x.INTERFACEBELONGSTO.value,
          LASTFILEUSERID: this.state.UserID,
          _CONFIGINTOSANDBOX: x._CONFIGINTOSANDBOX.value,
          _CONFIGINTOTEST: x._CONFIGINTOTEST.value,
          _COMMFROMOV: x._COMMFROMOV.value,
          _COMMFROMMT: x._COMMFROMMT.value,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Documents ||
        this.state.tableHeading === Contants._activityName.Ndrb ||
        this.state.tableHeading === Contants._activityName.Pdrb
      ) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          DOCUMENTID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          TARGETHCIS: x.TARGETHCIS.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          _STANDARDCONTENTAVAILABLE: x._STANDARDCONTENTAVAILABLE?.value,
          _CMSCONTROL: x._CMSCONTROL?.value,
          _DATAGATHERINGONEXCEL: x._DATAGATHERINGONEXCEL?.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BUILDSTATUS: x.BUILDSTATUS.value,
          COMMENTS: x.COMMENTS.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          LASTFILEUSERID: this.state.UserID,
          _REVBYCHAMPIONS: x._REVBYCHAMPIONS.value,
          _BUILDINTOSANDBOX: x._BUILDINTOSANDBOX?.value,
          _REVBYCLINICALUSERS: x._REVBYCLINICALUSERS?.value,
          _SIGNEDOFFRECD: x._SIGNEDOFFRECD?.value,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Careplans) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          CAREPLANID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BUILDSTATUS: x.BUILDSTATUS.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          COMMENTS: x.COMMENTS.value,
          TARGETHCIS: x.TARGETHCIS.value,
          _REVBYCHAMPIONS: x._REVBYCHAMPIONS.value,
          LASTFILEUSERID: this.state.UserID,
          CAREPLANDECISION: x.CAREPLANDECISION.value,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Ordersets) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          ORDERSETID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BUILDSTATUS: x.BUILDSTATUS.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          COMMENTS: x.COMMENTS.value,
          TARGETHCIS: x.TARGETHCIS.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Gaprequirement) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          GAPID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          REQUIREMENTSOURCE: x.REQUIREMENTSOURCE.value,
          REQUIREMENTREFNO: x.REQUIREMENTREFNO.value,
          MTRESPONSE: x.MTRESPONSE.value,
          REQUIREMENTNATURE: x.REQUIREMENTNATURE.value,
          REQUIREMENTCATEGORY: x.REQUIREMENTCATEGORY.value,
          _GAPWALKTHRUDONE: x._GAPWALKTHRUDONE.value,
          GAPWALKTHRUOUTCOME: x.GAPWALKTHRUOUTCOME.value,
          DETAILSSHAREDWITHMT: x.DETAILSSHAREDWITHMT.value,
          DETAILSSHAREDWITHMTDT: x.DETAILSSHAREDWITHMTDT.value,
          SPECSRECDFROMMT: x.SPECSRECDFROMMT.value,
          SPECSRECDFROMMTDT: x.SPECSRECDFROMMTDT.value,
          SPECSAPPROVEDBYAKU: x.SPECSAPPROVEDBYAKU.value,
          SPECSAPPROVEDBYAKUDT: x.SPECSAPPROVEDBYAKUDT.value,
          PROGRESS: x.PROGRESS.value,
          _REQUIREMENTFULFILLED: x._REQUIREMENTFULFILLED.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          STATUS: x.STATUS.value,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Datamigration) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          DICTIONARYID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          TARGETHCIS: x.TARGETHCIS.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          REFTableName: x.REFTableName.value,
          _DATAGATHERINGONEXCEL: x._DATAGATHERINGONEXCEL.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          BUILDSTATUS: x.BUILDSTATUS.value,
          COMMENTS: x.COMMENTS.value,
          BUILDPROGRESS: x.BUILDPROGRESS.value,
          dictWeightage: x.dictWeightage.value,
          RESPONSIBLEAPPTEAM: x.RESPONSIBLEAPPTEAM.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Euttraining ||
        this.state.tableHeading === Contants._activityName.Sutraining ||
        this.state.tableHeading === Contants._activityName.DictTrain ||
        this.state.tableHeading === Contants._activityName.AppTr ||
        this.state.tableHeading === Contants._activityName.PhaApp ||
        this.state.tableHeading === Contants._activityName.LabApp
      ) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          DESCRIPTION: x.DESCRIPTION.value,
          _ACTIVE: x._ACTIVE.value,
          SchStartTime: x.SCHSTARTTIME.value
            ? moment(x.SCHSTARTTIME.value).format("YYYY-MM-DDTHH:mm:ss")
            : null,
          SchEndTime: x.SCHENDTIME.value
            ? moment(x.SCHENDTIME.value).format("YYYY-MM-DDTHH:mm:ss")
            : null,
          Schdate: x.SCHDATE.value,
          SchVenue: x.SCHVENUE.value,
          SchParticipants: x.SCHPARTICIPANTS.value,
          SCHPARTICIPANTNAMES: x.SCHPARTICIPANTNAMES.value,
          ActStartTime: x.ACTSTARTTIME.value
            ? moment(x.ACTSTARTTIME.value).format("YYYY-MM-DDTHH:mm:ss")
            : null,
          ActEndTime: x.ACTENDTIME.value
            ? moment(x.ACTENDTIME.value).format("YYYY-MM-DDTHH:mm:ss")
            : null,
          ACTDATE: x.ACTDATE.value,
          ActVenue: x.ACTVENUE.value,
          ActParticipants: x.ACTPARTICIPANTS.value,
          ActParticipantNames: x.ACTPARTICIPANTNAMES.value,
          TrainingInstructors: x.TRAININGINSTRUCTORS.value,
          TRAININGSESSIONID: x.TRAININGSESSIONID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          WEIGHTAGE: 1,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Testing ||
        this.state.tableHeading === Contants._activityName.IntTesting ||
        this.state.tableHeading === Contants._activityName.ParaTesting ||
        this.state.tableHeading === Contants._activityName.MGL ||
        this.state.tableHeading === Contants._activityName.MGLII ||
        this.state.tableHeading === Contants._activityName.IntTestingII
      ) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          TESTCASEID: x.TESTCASEID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          STATUS: x.STATUS.value,
          COMMENTS: x.COMMENTS.value,
          PROGRESS: x.PROGRESS.value,
          TESTCASERESULT: x.TESTCASERESULT.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Reportwriting) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          REPORTID: x.REPORTID.value,
          _ACTIVE: x._ACTIVE.value,
          REPORTTYPE: x.REPORTTYPE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          STATUS: x.STATUS.value,
          COMMENTS: x.COMMENTS.value,
          PROGRESS: x.PROGRESS.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Projectmgmnt) {
        reqData = {
          FACILITYID: x.FACILITYID.value,
          APPLICATIONID: x.APPLICATIONID.value,
          ACTIVITYID: x.ACTIVITYID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          TASKID: x.DICTIONARYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          STATUS: x.STATUS.value,
          COMMENTS: x.COMMENTS.value,
          PROGRESS: x.PROGRESS.value,
          LASTFILEUSERID: this.state.UserID,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Interbit ||
        this.state.tableHeading === Contants._activityName.SIS ||
        this.state.tableHeading === Contants._activityName.VR ||
        this.state.tableHeading === Contants._activityName.Elsevier ||
        this.state.tableHeading === Contants._activityName.WK ||
        this.state.tableHeading === Contants._activityName.IMO ||
        this.state.tableHeading === Contants._activityName.SPG ||
        this.state.tableHeading === Contants._activityName.VTH ||
        this.state.tableHeading === Contants._activityName.CPT
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          TPSID: x.TPSID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.FSP ||
        this.state.tableHeading === Contants._activityName.CSP ||
        this.state.tableHeading === Contants._activityName.TMV
        //last 1 line added by -mz
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          FSPID: x.FSPID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.EndUserDevices ||
        this.state.tableHeading === Contants._activityName.CentralHardware ||
        this.state.tableHeading === Contants._activityName.NetworkRead ||
        this.state.tableHeading === Contants._activityName.EIC
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          INFREADINESSID: x.INFREADINESSID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      if (
        this.state.tableHeading === Contants._activityName.Plans ||
        this.state.tableHeading === Contants._activityName.Strategies
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          SAPID: x.SAPID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      if (this.state.tableHeading === Contants._activityName.Gla) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          GLAID: x.GLAID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      //Pre Activites , Clinical Activities, Training
      if (
        this.state.tableHeading === Contants._activityName.PreAct ||
        this.state.tableHeading === Contants._activityName.Pdp ||
        this.state.tableHeading === Contants._activityName.US ||
        this.state.tableHeading === Contants._activityName.Kpi ||
        this.state.tableHeading === Contants._activityName.RBT ||
        this.state.tableHeading === Contants._activityName.Eup ||
        this.state.tableHeading === Contants._activityName.TP ||
        this.state.tableHeading === Contants._activityName.TM ||
        this.state.tableHeading === Contants._activityName.RA ||
        this.state.tableHeading === Contants._activityName.ITI ||
        this.state.tableHeading === Contants._activityName.ITII ||
        this.state.tableHeading === Contants._activityName.ITIII ||
        this.state.tableHeading === Contants._activityName.ITIV ||
        this.state.tableHeading === Contants._activityName.SA ||
        this.state.tableHeading === Contants._activityName.PGLO ||
        this.state.tableHeading === Contants._activityName.UAM ||
        this.state.tableHeading === Contants._activityName.RAII ||
        this.state.tableHeading === Contants._activityName.RAIII ||
        this.state.tableHeading === Contants._activityName.GLC ||
        this.state.tableHeading === Contants._activityName.CM ||
        this.state.tableHeading === Contants._activityName.SD ||
        this.state.tableHeading === Contants._activityName.PGLS ||
        this.state.tableHeading === Contants._activityName.AW ||
        this.state.tableHeading === Contants._activityName.GI ||
        this.state.tableHeading === Contants._activityName.NbiVisit ||
        this.state.tableHeading === Contants._activityName.PP ||
        this.state.tableHeading === Contants._activityName.TB
        //last 7 lines added by -mz
      ) {
        reqData = {
          FacilityID: x.FACILITYID.value,
          ActivityID: x.ACTIVITYID.value,
          ApplicationID: x.APPLICATIONID.value,
          SUBACTIVITYID: x.SUBACTIVITYID.value,
          _ACTIVE: x._ACTIVE.value,
          DESCRIPTION: x.DESCRIPTION.value,
          WEIGHTAGE: x.WEIGHTAGE.value,
          BASELINEDATE: x.BASELINEDATE.value,
          PLANNEDDATE: x.PLANNEDDATE.value,
          ACTUALCOMPLETIONDATE: x.ACTUALCOMPLETIONDATE.value,
          COMMENTS: x.COMMENTS.value,
          LASTFILEUSERID: this.state.UserID,
          PREACTIVITYID: x.PREACTIVITYID.value,
          STATUS: x.STATUS.value,
          PROGRESS: x.PROGRESS.value,
        };
      }
      console.log("REQ", reqData);
      window
        .APIRequest(
          appCred,
          "",
          "EHRDashboardManager.SaveDictionariesData",
          reqData
        )
        .then((apiRes) => {
          if (apiRes.ResponseCode == 200) {
            ToastsStore.success("Updated Successfully", 5000);
            let tempData = this.transposeDataFromAPI(
              this.state.SubActivityDictionariesData,
              this.state.tableHeading
            );
            this.setState(
              {
                SubActivityDictionariesData: apiRes.ResponseResult,
                tempData: tempData,
                tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
              },
              () => {
                this.initcomponent(
                  this.state.activityData,
                  this.state.tableHeading
                );
                this.searchInput.value = "";
                this.searchTypedKeyword({ target: { value: "" } });
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
    } else if (e.keyCode == 27) {
      this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
    }
  };
  EditRow = (item) => {
    this.setState({
      modalOpen: true,
      addRecord: CustomFunctions.editRowObject(item),
      actParticipantstags: this.state.actParticipantstags,
      schParticipantstags: this.state.schParticipantstags,
      formHeading: "Edit Record",
    });
  };
  saveNewRecord = () => {
    let reqData = {};
    let _reqData = {};
    let addRecord = this.state.addRecord;
    let addRecorderrors = this.state.addRecorderrors;
    const reqIntialData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
      SUBACTIVITYID: this.state.tableHeading,
      ACTIVE: this.state.addRecord.NewActive,
      _ACTIVE: this.state.addRecord.NewActive,
      DESCRIPTION: this.state.addRecord.NewDictionaryName
        ? this.state.addRecord.NewDictionaryName
        : "",
      WEIGHTAGE: this.state.addRecord.NewWeightage,
      BASELINEDATE: this.state.addRecord.NewBaselineDate
        ? moment(this.state.addRecord.NewBaselineDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        )
        : null,
      PLANNEDDATE: this.state.addRecord.NewPlannedDate
        ? moment(this.state.addRecord.NewPlannedDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        )
        : null,
      Comments: this.state.addRecord.NewComments,
      LASTFILEUSERID: this.state.UserID,
      DISPLAYSEQUENCE: this.state.addRecord.NewDisplaySequence
        ? parseFloat(this.state.addRecord.NewDisplaySequence)
        : 0,
    };
    if (this.state.tableHeading === Contants._activityName.Dictionary) {
      if (
        Validators.ValidateDictionaryData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          DICTIONARYID: this.state.addRecord.NewDictionaryID,
          BuildStatus: this.state.addRecord.NewBuildStatus,
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          standardcontentavailable:
            this.state.addRecord.NewStandardContentAvailable,
          _STANDARDCONTENTAVAILABLE:
            this.state.addRecord.NewStandardContentAvailable,
          cmscontrol: this.state.addRecord.NewCMSControl,
          _CMSCONTROL: this.state.addRecord.NewCMSControl,
          DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          _DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          TARGETHCIS: this.state.addRecord.NewTargetHCIS,
          RESPONSIBLEAPPTEAM: this.state.addRecord.NewResponsibleTeam,
          DICTIONARYNATURE: this.state.addRecord.NewDictionaryNature,
        };
      }
    }
    if (
      this.state.tableHeading === Contants._activityName.Interface ||
      this.state.tableHeading === Contants._activityName.MD
    ) {
      if (
        Validators.ValidateInterfaceData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          INTERFACEID: this.state.addRecord.NewDictionaryID,
          CONFIGINTOTEST: this.state.addRecord.NewConfigintoTest,
          _CONFIGINTOTEST: this.state.addRecord.NewConfigintoTest,
          InterfaceBelongsto: this.state.addRecord.NewInterfaceBelongsto,
          CommFromMT: this.state.addRecord.NewCommFromMT,
          _CommFromMT: this.state.addRecord.NewCommFromMT,
          CommFromOV: this.state.addRecord.NewCommFromOV,
          _CommFromOV: this.state.addRecord.NewCommFromOV,
          ConfigintoSandbox: this.state.addRecord.NewConfigintoSandbox,
          _ConfigintoSandbox: this.state.addRecord.NewConfigintoSandbox,
          CommType: this.state.addRecord.NewCommType,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Instrument) {
      if (
        Validators.ValidateInstrumentData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          RESPONSIBLEAPPTEAM: this.state.addRecord.NewResponsibleTeam,
          INSLOCATION: this.state.addRecord.NewInsLocation,
          LABSECTION: this.state.addRecord.NewLabSection,
          COMMTYPE: this.state.addRecord.NewCommType,
          LANTRONIXCONFIGURED: this.state.addRecord.NewLantronixConfigured,
          _LANTRONIXCONFIGURED: this.state.addRecord.NewLantronixConfigured,
          INSTRUMENTCONFIGURED: this.state.addRecord.NewInstrumentConfigured,
          _INSTRUMENTCONFIGURED: this.state.addRecord.NewInstrumentConfigured,
          LISCONNESTABLISHED: this.state.addRecord.NewLISConnectionEstablished,
          _LISCONNESTABLISHED: this.state.addRecord.NewLISConnectionEstablished,
          INSTRUMENTID: this.state.addRecord.NewDictionaryID,
        };
      }
    }
    if (
      this.state.tableHeading === Contants._activityName.Documents ||
      this.state.tableHeading === Contants._activityName.Ndrb ||
      this.state.tableHeading === Contants._activityName.Pdrb
    ) {
      if (
        Validators.ValidateDocumentData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          DOCUMENTID: this.state.addRecord.NewDictionaryID,
          BuildStatus: this.state.addRecord.NewBuildStatus,
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          standardcontentavailable:
            this.state.addRecord.NewStandardContentAvailable,
          _STANDARDCONTENTAVAILABLE:
            this.state.addRecord.NewStandardContentAvailable,
          cmscontrol: this.state.addRecord.NewCMSControl,
          _CMSCONTROL: this.state.addRecord.NewCMSControl,
          DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          _DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          TARGETHCIS: this.state.addRecord.NewTargetHCIS,
          _BUILDINTOSANDBOX: this.state.addRecord.NewBuildintoSandbox,
          _REVBYCLINICALUSERS: this.state.addRecord.NewRevByClinicalUsers,
          _SIGNEDOFFRECD: this.state.addRecord.NewSignedOffRecd,
          _REVBYCHAMPIONS: this.state.addRecord.NewRevByChampions,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Careplans) {
      if (
        Validators.ValidateCarePlanData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          CAREPLANID: this.state.addRecord.NewDictionaryID,
          BuildStatus: this.state.addRecord.NewBuildStatus,
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          TARGETHCIS: this.state.addRecord.NewTargetHCIS,
          _REVBYCHAMPIONS: this.state.addRecord.NewRevByChampions,
          CAREPLANDECISION: this.state.addRecord.NewCarePlanDecision,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Ordersets) {
      if (
        Validators.ValidateOrderSetsData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          ORDERSETID: this.state.addRecord.NewDictionaryID,
          BuildStatus: this.state.addRecord.NewBuildStatus,
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Gaprequirement) {
      if (
        Validators.ValidateGapDocumentData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          GAPID: this.state.addRecord.NewGapID,
          REQUIREMENTSOURCE: this.state.addRecord.NewRequirementSource,
          REQUIREMENTREFNO: this.state.addRecord.NewRequirementRefNo,
          MTRESPONSE: this.state.addRecord.NewMTResponse,
          REQUIREMENTNATURE: this.state.addRecord.NewRequirementNature,
          REQUIREMENTCATEGORY: this.state.addRecord.NewRequirementCatoegory,
          _GAPWALKTHRUDONE: this.state.addRecord.NewGapWalkThruDone,
          GAPWALKTHRUOUTCOME: this.state.addRecord.NewGapWalkThruOutcome,
          DETAILSSHAREDWITHMT: this.state.addRecord.NewDetailsSharedWithMT,
          DETAILSSHAREDWITHMTDT: this.state.addRecord.NewDetailsSharedWithMTDT
            ? moment(this.state.addRecord.NewDetailsSharedWithMTDT).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          SPECSRECDFROMMT: this.state.addRecord.NewSpecsRecdFromMT,
          SPECSRECDFROMMTDT: this.state.addRecord.NewSpecsRecdFromMTDT
            ? moment(this.state.addRecord.NewSpecsRecdFromMTDT).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          SPECSAPPROVEDBYAKU: this.state.addRecord.NewSpecsApprovedByAKU,
          SPECSAPPROVEDBYAKUDT: this.state.addRecord.NewSpecsApprovedByAKUDT
            ? moment(this.state.addRecord.NewSpecsApprovedByAKUDT).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          PROGRESS: this.state.addRecord.NewGapProgress,
          _REQUIREMENTFULFILLED: this.state.addRecord.NewRequirementFulfilled,
          STATUS: this.state.addRecord.NewGapStatus,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Datamigration) {
      if (
        Validators.ValidateMigrationData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          DICTIONARYID: this.state.addRecord.NewDictionaryID,
          BUILDPROGRESS: this.state.addRecord.NewBuildProgress,
          DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          _DATAGATHERINGONEXCEL: this.state.addRecord.NewDataGatheringOnExcel,
          TARGETHCIS: this.state.addRecord.NewTargetHCIS,
          RESPONSIBLEAPPTEAM: this.state.addRecord.NewResponsibleTeam,
        };
      }
    }
    if (
      this.state.tableHeading === Contants._activityName.Euttraining ||
      this.state.tableHeading === Contants._activityName.Sutraining ||
      this.state.tableHeading === Contants._activityName.DictTrain ||
      this.state.tableHeading === Contants._activityName.AppTr ||
      this.state.tableHeading === Contants._activityName.PhaApp ||
      this.state.tableHeading === Contants._activityName.LabApp
    ) {
      if (
        Validators.ValidateTrainingData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          PROGRESS: this.state.addRecord.NewGapProgress,
          STATUS: this.state.addRecord.NewGapStatus,
          SCHDATE: this.state.addRecord.NewschDate
            ? moment(this.state.addRecord.NewschDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          ACTDATE: this.state.addRecord.NewactDate
            ? moment(this.state.addRecord.NewactDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          SCHSTARTTIME: this.state.addRecord.NewSchStartTime
            ? moment(this.state.addRecord.NewSchStartTime).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          SCHENDTIME: this.state.addRecord.NewSchEndTime
            ? moment(this.state.addRecord.NewSchEndTime).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          ACTSTARTTIME: this.state.addRecord.NewActStartTime
            ? moment(this.state.addRecord.NewActStartTime).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null,
          ACTENDTIME: this.state.addRecord.NewActEndTime
            ? moment(this.state.addRecord.NewActEndTime).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
            : null, //this.state.addRecord.NewSchEndTime,
          SCHVENUE: this.state.addRecord.NewSchVenue,
          SCHPARTICIPANTS: this.state.addRecord.NewSchParticipants
            ? parseInt(this.state.addRecord.NewSchParticipants)
            : null,
          SCHPARTICIPANTNAMES: this.state.schParticipantstags
            ? JSON.stringify(this.state.schParticipantstags)
            : "[]",
          ACTVENUE: this.state.addRecord.NewActVenue,
          ACTPARTICIPANTS: this.state.addRecord.NewActParticipants
            ? parseInt(this.state.addRecord.NewActParticipants)
            : null,
          ACTPARTICIPANTNAMES: this.state.actParticipantstags
            ? JSON.stringify(this.state.actParticipantstags)
            : "[]",
          TRAININGINSTRUCTORS: this.state.addRecord.NewTrainingInstructors,
          TRAININGSESSIONID: this.state.addRecord.NewTrainingSessionID,
          WEIGHTAGE: 1, // as shahab said, 1 for every new record
        };
      }
    }
    if (
      this.state.tableHeading === Contants._activityName.Testing ||
      this.state.tableHeading === Contants._activityName.IntTesting ||
      this.state.tableHeading === Contants._activityName.ParaTesting ||
      this.state.tableHeading === Contants._activityName.MGL ||
      this.state.tableHeading === Contants._activityName.MGLII ||
      this.state.tableHeading === Contants._activityName.IntTestingII
    ) {
      if (
        Validators.ValidateTestingData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          TESTCASEID: this.state.addRecord.NewTestCaseID,
          TESTCASERESULT: this.state.addRecord.NewTestCaseResult,
          BuildStatus: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }
    if (this.state.tableHeading === Contants._activityName.Reportwriting) {
      if (
        Validators.ValidateReportWritinData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          REPORTID: this.state.addRecord.NewReportID,
          REPORTTYPE: this.state.addRecord.NewReportType,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewBuildProgress,
          RESPONSIBLEAPPTEAM: this.state.addRecord.NewResponsibleTeam,
        };
      }
    }

    if (this.state.tableHeading === Contants._activityName.Projectmgmnt) {
      if (
        Validators.ValidateProjectMangmntData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          TASKID: this.state.addRecord.NewProjectID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    if (
      this.state.tableHeading === Contants._activityName.Interbit ||
      this.state.tableHeading === Contants._activityName.SIS ||
      this.state.tableHeading === Contants._activityName.VR ||
      this.state.tableHeading === Contants._activityName.Elsevier ||
      this.state.tableHeading === Contants._activityName.WK ||
      this.state.tableHeading === Contants._activityName.IMO ||
      this.state.tableHeading === Contants._activityName.SPG ||
      this.state.tableHeading === Contants._activityName.VTH ||
      this.state.tableHeading === Contants._activityName.CPT
    ) {
      if (
        Validators.ValidateThirdPartySoftwareData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          TPSID: this.state.addRecord.NewTPSID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    if (
      this.state.tableHeading === Contants._activityName.FSP ||
      this.state.tableHeading === Contants._activityName.CSP ||
      this.state.tableHeading === Contants._activityName.TMV
      //last 1 line added by -mz
    ) {
      if (
        Validators.ValidateFutureStateProcessData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          FSPID: this.state.addRecord.NewFSPID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    if (
      this.state.tableHeading === Contants._activityName.EndUserDevices ||
      this.state.tableHeading === Contants._activityName.CentralHardware ||
      this.state.tableHeading === Contants._activityName.NetworkRead ||
      this.state.tableHeading === Contants._activityName.EIC
      //last line added -mz
    ) {
      if (
        Validators.ValidateInfrastructureReadinessData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          INFREADINESSID: this.state.addRecord.NewINFREADINESSID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    if (
      this.state.tableHeading === Contants._activityName.Plans ||
      this.state.tableHeading === Contants._activityName.Strategies
    ) {
      if (
        Validators.ValidateStrategiesAndPlansData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          SAPID: this.state.addRecord.NewSAPID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    if (this.state.tableHeading === Contants._activityName.Gla) {
      if (
        Validators.ValidateGoLiveExecutionData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          GLAID: this.state.addRecord.NewGLAID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }
    //Pre Activites , Clinical Activities, Training, UAM
    if (
      this.state.tableHeading === Contants._activityName.PreAct ||
      this.state.tableHeading === Contants._activityName.Pdp ||
      this.state.tableHeading === Contants._activityName.US ||
      this.state.tableHeading === Contants._activityName.Kpi ||
      this.state.tableHeading === Contants._activityName.RBT ||
      this.state.tableHeading === Contants._activityName.Eup ||
      this.state.tableHeading === Contants._activityName.TP ||
      this.state.tableHeading === Contants._activityName.TM ||
      this.state.tableHeading === Contants._activityName.RA ||
      this.state.tableHeading === Contants._activityName.ITI ||
      this.state.tableHeading === Contants._activityName.ITII ||
      this.state.tableHeading === Contants._activityName.ITIII ||
      this.state.tableHeading === Contants._activityName.ITIV ||
      this.state.tableHeading === Contants._activityName.SA ||
      this.state.tableHeading === Contants._activityName.PGLO ||
      this.state.tableHeading === Contants._activityName.UAM ||
      this.state.tableHeading === Contants._activityName.RAII ||
      this.state.tableHeading === Contants._activityName.RAIII ||
      this.state.tableHeading === Contants._activityName.GLC ||
      this.state.tableHeading === Contants._activityName.CM ||
      this.state.tableHeading === Contants._activityName.SD ||
      this.state.tableHeading === Contants._activityName.PGLS ||
      this.state.tableHeading === Contants._activityName.AW ||
      this.state.tableHeading === Contants._activityName.GI ||
      this.state.tableHeading === Contants._activityName.NbiVisit ||
      this.state.tableHeading === Contants._activityName.PP ||
      this.state.tableHeading === Contants._activityName.TB
      //last 7 line added by -mz
    ) {
      if (
        Validators.ValidatePreActivitiesData(
          addRecorderrors,
          addRecord,
          this.setErrors
        )
      ) {
        _reqData = {
          PREACTIVITYID: this.state.addRecord.NewPREACTIVITYID,
          STATUS: this.state.addRecord.NewBuildStatus,
          PROGRESS: this.state.addRecord.NewGapProgress,
        };
      }
    }

    console.log("REQ DATA", _reqData);
    reqData = Object.assign(reqIntialData, _reqData);
    console.log("REQ DATA", reqData);
    console.log(
      "Test obj",
      Object.keys(reqData).length >= Object.keys(reqIntialData).length
    );
    console.log("test obj 1", JSON.stringify(reqData) != JSON.stringify({}));
    if (
      Object.keys(reqData).length >= Object.keys(reqIntialData).length &&
      JSON.stringify(_reqData) != JSON.stringify({})
    ) {
      console.log("IN the function");
      window
        .APIRequest(
          appCred,
          "",
          "EHRDashboardManager.SaveDictionariesData",
          reqData
        )
        .then((apiRes) => {
          if (apiRes.ResponseCode == 200) {
            this.setState(
              {
                modalOpen: false,
                addRecord: { ...Contants.intState },
                actParticipantstags: [],
                schParticipantstags: [],
              },
              () => {
                this.initcomponent(
                  this.state.activityData,
                  this.state.tableHeading
                );
                ToastsStore.success("Record Saved Successfully", 3000);
                this.searchInput.value = "";
                this.searchTypedKeyword({ target: { value: "" } });
                this.setState({
                  multidefault: "",
                });
              }
            );
          } else {
            ToastsStore.error("Error", 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ToastsStore.error("Fill all fields");
    }
  };

  // Input Handlers
  handleDateTimeChange = (name, DateTime) => {
    this.state.addRecord[name] = DateTime;
    this.setState({
      addRecorderrors: { ...Contants.intState },
      name: this.state.addRecord,
    });
  };
  handleDeletetags = (i) => {
    const _actParticipantstags = this.state.actParticipantstags;
    this.setState({
      actParticipantstags: _actParticipantstags.filter((index) => index !== i),
    });
  };
  handleAdditiontags = (tag) => {
    if (!this.state.actParticipantstags) {
      this.setState({
        actParticipantstags: [],
      });
    }
    this.setState((state) => ({
      actParticipantstags: [...state.actParticipantstags, tag],
    }));
  };
  handleDragtags = (tag, currPos, newPos) => {
    const tags = [...this.state.actParticipantstags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ actParticipantstags: newTags });
  };
  handleDeleteschtags = (i) => {
    const _schParticipantstags = this.state.schParticipantstags;
    this.setState({
      schParticipantstags: _schParticipantstags.filter(
        (schParticipantstags, index) => index !== i
      ),
    });
  };
  handleAdditionschtags = (tag) => {
    if (!this.state.schParticipantstags) {
      this.setState({
        schParticipantstags: [],
      });
    }
    this.setState((state) => ({
      schParticipantstags: [...state.schParticipantstags, tag],
    }));
  };
  handleDragschtags = (tag, currPos, newPos) => {
    const tags = [...this.state.schParticipantstags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ schParticipantstags: newTags });
  };
  handleOnChangeMulti = (e) => {
    const NewData = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    if (this.state.addRecord.NewTestCaseResult) {
      this.state.addRecord.NewTestCaseResult =
        this.state.addRecord.NewTestCaseResult.concat(
          "|",
          e.target.value,
          " ",
          NewData
        );
    } else {
      this.state.addRecord.NewTestCaseResult = e.target.value + " " + NewData;
    }
    this.setState({
      addRecord: this.state.addRecord,
      multidefault: e.target.value,
    });
  };
  handleChangeForEvent = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  //handlers end-------

  sortTable = (item) => {
    const _tempData = TableFunctions.sortTableFunc(item, this.state.tempData);
    this.setState({
      tempData: _tempData,
    });
  };
  searchTypedKeyword = (e) => {
    let allVisibleProps = Object.entries(this.state.tempData.meta)
      .filter((a) => a[1].isVisible)
      .map((m) => m[0]);
    let newList = this.state.tempDataList_master.filter((a) => {
      for (let prop of allVisibleProps) {
        if (
          a[prop].value &&
          a[prop].value
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return a;
        }
      }
    });
    this.state.tempData.list = newList;
    this.setState({
      tempData: this.state.tempData,
    });
  };
  CloseCommentNResourecsModal = () => {
    this.setState({
      resourcesModalOpen: false,
      commentsModalOpen: false,
      resourcesData: {},
      modalHeading: "",
    });
  };
  closeAddNEditFormModal = () => {
    this.setState({
      modalOpen: false,
      addRecord: { ...Contants.intState },
      addRecorderrors: { ...Contants.intState },
      actParticipantstags: [],
      schParticipantstags: [],
      multidefault: "",
    });
  };
  GetSubActivityWeightage = () => {
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetAppSubActivityWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.setState({
            subActivityWeightageObject: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleWeightageChangeModal = (data) => {
    let singleSubActivityWeightage = this.state.subActivityWeightageObject.find(
      (a) => a.SubActivityID === data.objMain.SUBACTIVITYID
    );
    this.setState({
      WeightageModalHeading: data.objMain.SubActivityDescription,
      showWeightageModal: true,
      SubActivityID: data.objMain.SUBACTIVITYID,
      SubActivityWeightage: singleSubActivityWeightage
        ? singleSubActivityWeightage.WHEIGHTAGE
        : 0,
    });
  };
  saveSubactivityWeightage = () => {
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
      Weightage: parseInt(this.state.SubActivityWeightage),
      SUBACTIVITYID: this.state.SubActivityID,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveAppSubActivityWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          this.setState(
            {
              showWeightageModal: false,
              WeightageModalHeading: "",
              SubActivityWeightage: "",
              SubActivityID: "",
            },
            this.getMainActivityTargetDate(),
            this.GetSubActivityWeightage(),
            this.getAllSubActivity(),
            () => {
              ToastsStore.success("Saved Successfully");
            }
          );
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  applicatoinResourcesAPI = (reqData) => {
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetApplicationResources",
        reqData
      )
      .then((apiRes) => {
        console.log("RES", apiRes);
        if (apiRes.ResponseCode == 200) {
          this.setState(
            {
              resourcesData: apiRes.ResponseResult,
            },
            () => {
              console.log("resourcesData", this.state.resourcesData);
            }
          );
        } else {
          this.setState({
            resourcesData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add New Resource -------
  addNewResource = () => {

    const cModalState = this.CommentModalElement.current;
    let reqResourceData = {
      ApplicationID: this.state.activityData.APPLICATIONID,
      ActivityID: this.state.activityData.ACTIVITYID,
      FACILITYID: this.state.activityData.FACILITYID,
    };
    let reqData = {
      FACILITYID: this.state.activityData.FACILITYID,
      APPLICATIONID: this.state.activityData.APPLICATIONID,
      ACTIVITYID: this.state.activityData.ACTIVITYID,
      RESOURCENAME: this.state.resourceName,
      RESOURCEREGION: this.state.resourceLocation,
      _ACTIVE: "Y",
      RESOURCEUSERID: this.state.resourceID,
      RESOURCETYPE: this.state.resourceRole,
      LASTFILEUSERID: this.state.UserID,
    };
    console.log("reqData", reqData);
    window
      .APIRequest(appCred, "", "EHRDashboardManager.SaveResources", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          console.log("RES", apiRes);
          this.setState(
            {
              resourceName: "",
              resourceLocation: "",
              resourceID: "",
              resourceRole: "",
            },
            () => this.applicatoinResourcesAPI(reqResourceData),
            cModalState.changeAddResource(false)
          );
          ToastsStore.success("Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End ------
  clearResourceInput = () => {
    this.setState({
      resourceName: "",
      resourceLocation: "",
      resourceID: "",
      resourceRole: "",
    });
  };
  copyFromSchToAct = (e) => {
    e.preventDefault();
    const GetDataFromFunction = CustomFunctions.copyFromSchToAct(
      this.state.addRecord
    );
    this.setState({
      addRecord: GetDataFromFunction,
      actParticipantstags: this.state.schParticipantstags,
    });
  };
  openRecordHistory = (x) => {
    let reqData = CustomFunctions.recordHistoryObj(x);
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetSubActivityDictionariesDataLog",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.setState({
            historyApiRes: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      recordInfo: x,
      recordHistoryModalOpen: true,
    });
  };
  recordHistoryModalClose = () => {
    this.setState({
      recordInfo: {},
      recordHistoryModalOpen: false,
      historyApiRes: {},
    });
  };
  DoNothing = () => { };
  render() {
    let tempData = this.state.tempData;
    console.log("this.state?.DashboardDetails", tempData);
    let SubActivtyData = this.state?.DashboardDetails;
    let tempDataKeys = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[0])
      : [];
    let tempDataHeadings = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[1].heading)
      : [];
    let tempDataisEditable = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].allowEdit)
        .map((m) => m[1].heading)
      : [];
    let tempDataSortType = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => {
          return { key: m[0], value: m[1].sortType };
        })
      : [];
    tempDataSortType = tempDataSortType.map((a) => a.value);
    // console.log("tempData.list", tempData.list);
    // console.log("tempData.ey", tempDataKeys);
    return (
      <div style={{ overflowX: "hidden" }}>
        <div
          className="row alert alert-primary ml-0 mb-1 mt-0 mr-0"
          onClick={this.toggle}
          style={{
            padding: "3px 13px 3px 0px",
            color: "rgb(56 53 53)",
            backgroundColor: "rgb(219 219 219)",
            borderColor: "rgb(187 189 192)",
          }}
        >
          <div className="col" style={{ lineHeight: "22px" }}>
            <span style={{ fontSize: "12px" }}>
              <b></b>
              <IndividualActivityHeader
                ActivityData={this.state.activityData}
                TDateNProgress={this.state.MainActivtyDateNProgress}
                Description={
                  this.state?.userData.find(
                    (a) => a.ActivityID === this.state.activityData.ACTIVITYID
                  )?.description
                }
                handleModal={this.handleModal}
              />
            </span>
          </div>
        </div>
        <div className={SubActivtyData.length > 3 ? "row-style" : ""}>
          {/* <div className={(SubActivtyData.length>2 ? "d-flex justify-content-center " : "") + ("row ml-4 mt-3 mb-3 ")}> */}
          <div className="row ml-4 mt-3 mb-3 ">
            {SubActivtyData ? (
              SubActivtyData.map((item, index) => {
                let roundedProgressPercentageValue = item.objMain
                  .progressPercentage
                  ? Math.round(item.objMain.progressPercentage)
                  : 0;
                let formattedItemDate = moment(item.objMain.Targetdate).format(
                  "MM/DD/YYYY"
                );
                let dateDiff = moment()
                  .startOf("day")
                  .diff(formattedItemDate, "days");
                CustomFunctions.setTileStyle(
                  dateDiff,
                  roundedProgressPercentageValue
                );

                //On Click box style
                let defaultTileActive = SubActivtyData.find(
                  (a) => a.activeClass === true
                );
                CustomFunctions.selectedTileStyle(
                  defaultTileActive,
                  index,
                  item?.activeClass,
                  SubActivtyData.length
                );

                return (
                  <div
                    className={CustomFunctions.TileProperties.cardBoxClass}
                    style={CustomFunctions.TileBackgroundStyle}
                    key={index}
                    onClick={(e) => {
                      this.getSubActivtyDicData(e, item);
                    }}
                  >
                    {/* <div className="col-12 ml-1 pr-0 pl-1" style={{ paddingRight: "0px" }}> */}
                    <div className="row">
                      <div className="col-9 pr-0">
                        <div className="row ml-2">
                          <h3
                            className="text-dark"
                            style={{
                              marginTop: "-3px",
                              marginBottom: 0,
                              textAlign: "left",
                              fontSize: "14px",
                              //paddingLeft: 10,
                              paddingTop: 10,
                            }}
                          >
                            {item.objMain.SubActivityDescription}
                            {/* [MT-01] HIM */}
                          </h3>
                        </div>

                        <div
                          className="row"
                          style={{
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <div className="ml-3 mt-2">
                            <h5
                              className="label-color-individual"
                              style={{
                                marginTop: "0px",
                                marginBottom: 0,
                                textAlign: "left",
                                fontSize: "10px",
                                paddingLeft: 10,
                              }}
                            >
                              <i></i>
                              Target Date
                              <br />
                              <span
                                className=" text-left text-dark"
                                style={{ fontSize: "12px" }}
                              >
                                {item.objMain.Targetdate
                                  ? moment(item.objMain.Targetdate).format(
                                    "MMM DD, YYYY"
                                  )
                                  : ""}
                              </span>
                            </h5>
                          </div>
                          &nbsp;&nbsp;
                          {this.state.activityData.ACTIVITYID != "UT" ? (
                            <div
                              style={Contants.IndividualWeightageIcon}
                              className="col-2 mt-2"
                              onClick={(e) => {
                                e.preventDefault();
                                this.state.UserOrgType ==
                                  Contants.AppRoleEnum.Admin
                                  ? this.handleWeightageChangeModal(item)
                                  : this.DoNothing();
                              }}
                            >
                              &nbsp;
                              <span
                                style={{
                                  fontSize: "15px",
                                  position: "absolute",
                                  bottom: "-4px",
                                  color: "#c2c4c6",
                                }}
                              >
                                {item.objMain.weightage}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-3 pt-2 pr-0 pl-3">
                        <div
                          className={
                            CustomFunctions.TileProperties.individualHexagon
                          }
                        >
                          <span
                            className="text-dark"
                            style={{ fontSize: "16px", fontWeight: 500 }}
                          >
                            {item.objMain.progressPercentage
                              ? Math.round(item.objMain.progressPercentage)
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: 0,
                        height: 1,
                        marginBottom: "5px",
                        marginTop: "6px",
                        backgroundImage:
                          "linear-gradient(to right, rgba(0, 0, 0, 0), rgb(17 16 16), rgba(0, 0, 0, 0))",
                      }}
                    />
                    <div
                      className="row mb-2"
                      style={{ lineHeight: "1", height: "40px" }}
                    >
                      <div className="col-3">
                        <div className="text-center">
                          <span
                            className="label-color-individual mb-1"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            <i
                              className="text-secondary text-sm"
                              style={{ paddingRight: "5px" }}
                            >
                              {" "}
                            </i>
                            Total
                          </span>
                          <br />
                          <label className="text-center ml-1 custom-background-square-blue">
                            {item.objMain.TotalCount[0].TotalCount
                              ? item.objMain.TotalCount[0].TotalCount
                              : 0}
                          </label>
                        </div>
                      </div>
                      <div className="col-3 pl-0 pr-0">
                        <div className="text-center">
                          <span
                            className="label-color-individual mb-1 text-center"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            <i
                              className="text-center text-sm"
                              style={{ paddingRight: "5px" }}
                            >
                              {" "}
                            </i>
                            Completed
                          </span>
                          <br />
                          <label className="text-center ml-1 custom-background-square-green">
                            {item.objMain.TotalCount[0].Completed
                              ? item.objMain.TotalCount[0].Completed
                              : 0}
                          </label>
                        </div>
                      </div>
                      <div className="col-3 pl-0 pr-0">
                        <div className="text-center">
                          <span
                            className="label-color-individual mb-1"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            <i
                              className="text-secondary text-sm"
                              style={{ paddingRight: "4px" }}
                            >
                              {" "}
                            </i>
                            In-Progress
                          </span>
                          <br />
                          <label className="text-center custom-background-square-yellow">
                            {item.objMain.TotalCount[0].PROGRESS
                              ? item.objMain.TotalCount[0].PROGRESS
                              : 0}
                          </label>
                        </div>
                      </div>
                      <div className="col-3 pl-0 pr-0">
                        <div className="text-center">
                          <span
                            className="label-color-individual mb-1"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            <i
                              className="text-secondary text-sm"
                              style={{ paddingRight: "5px" }}
                            >
                              {" "}
                            </i>
                            Outstanding
                          </span>
                          <br />
                          <label className="text-center custom-background-square-red">
                            {item.objMain.TotalCount[0].Outstanding
                              ? item.objMain.TotalCount[0].Outstanding
                              : 0}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className="col-12 mt-1"
          style={{ backgroundColor: "white", borderRadius: "15px" }}
        >
          <div className="row">
            <div className="col-6">
              <h6 className="ml-3">
                [{this.state.activityData.APPLICATIONID}]&nbsp;
                {this.state.activityData.Description}&nbsp;-&nbsp;
                {this.state.subActivityDescription}
              </h6>
            </div>

            <div
              className={
                this.state.UserOrgType === Contants.AppRoleEnum.Admin
                  ? "col-4"
                  : "col-5"
              }
            >
              {this.state.SubActivityDictionariesData.length != 0 ? (
                <div
                  className=" search-wrapper"
                  style={{ position: "absolute", right: "0" }}
                >
                  <form>
                    <input
                      ref={(input) => {
                        this.searchInput = input;
                      }}
                      autoComplete="off"
                      type="text"
                      name="focus"
                      className="search-box"
                      placeholder="Search..."
                      onChange={this.searchTypedKeyword}
                    />
                    <label
                      className="close-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        this.searchInput.value = "";
                        this.searchTypedKeyword({ target: { value: "" } });
                      }}
                    ></label>
                  </form>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={
                this.state.UserOrgType === Contants.AppRoleEnum.Admin
                  ? "col-2 pl-0"
                  : "col-1 pl-0"
              }
            >
              <Link
                to={{
                  pathname: "/ApplicationsMain",
                  state: {
                    facilityID: this.state.activityData.FACILITYID,
                    activityID: this.state.activityData.ACTIVITYID,
                    thruPSB: true,
                  },
                }}
              >
                <button
                  className="btn btn-info btn-sm pull-right mb-2"
                  style={{ color: "white", width: "80px" }}
                >
                  Return
                </button>
              </Link>
              {this.state.SubActivityDictionariesData.length > 0 ? (
                this.state.UserOrgType === Contants.AppRoleEnum.Admin ? (
                  <button
                    //  onClick={()=>{this.handleAddRow}}
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                        formHeading: "Add Record",
                      });
                    }}
                    className="btn btn-primary btn-sm pull-right mb-3 mr-1"
                  >
                    Add Record
                  </button>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          </div>

          {tempData && tempData.meta ? (
            <div
              className="col-12"
              style={{ height: "308px", overflowY: "scroll", marginTop: "4px" }}
            >
              <table className="table table-bordered table-striped">
                {tempData.meta != null && tempDataHeadings.length > 0 ? (
                  <thead>
                    {this.state.activityData.ACTIVITYID === "UT" &&
                      this.state.activityData.Description !=
                      "Training Planning" && this.state.activityData.Description !=
                      "Expanse System Demonstration" ? (
                      <tr>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                        ></th>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                          className="text-center"
                        >
                          Scheduled
                        </th>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                          className="text-center"
                        >
                          Actual
                        </th>
                        <th
                          colSpan="3"
                          style={Contants.Trainingtheadstickyclass}
                        ></th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th
                        className="hideextra"
                        style={Contants.theadstickyclass}
                      >
                        S. No
                      </th>
                      {tempDataHeadings.map((item, i) => (
                        <th
                          scope="col"
                          style={Contants.theadstickyclass}
                          className={TableFunctions.tableCellSize(item)}
                          key={i}
                          onClick={(e) => {
                            e.preventDefault();
                            this.sortTable(item);
                          }}
                          id={
                            item === "Planned Date"
                              ? "Planned_date"
                              : "column" + i
                          }
                        >
                          {tempDataSortType[i] ? (
                            tempDataSortType[i] === "asc" ? (
                              <i
                                className="fa fa-long-arrow-down"
                                aria-hidden="true"
                              >
                                &nbsp;
                              </i>
                            ) : (
                              <i
                                className="fa fa-long-arrow-up"
                                aria-hidden="true"
                              >
                                &nbsp;
                              </i>
                            )
                          ) : (
                            <></>
                          )}
                          {item}{" "}
                          {tempDataisEditable.indexOf(item) > -1 ? (
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          ) : (
                            <></>
                          )}
                        </th>
                      ))}
                      {this.state.UserOrgType === Contants.AppRoleEnum.Admin ? (
                        tempData.actionCol && tempData.actionCol.isVisible ? (
                          <th style={Contants.theadstickyclassAction}>
                            Action
                          </th>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </tr>
                  </thead>
                ) : (
                  <div>No Data Found</div>
                )}
                {tempData.list && tempData.list.length > 0 ? (
                  <tbody>
                    {tempData.list.map((x, rowIdx) => (
                      <tr key={rowIdx}>
                        <td>{rowIdx + 1}</td>
                        {tempDataKeys.map(
                          (y, propIdx) =>
                            x[y].isEdit ? (
                              <td
                                key={propIdx}
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              >
                                {(() => {
                                  switch (tempData.meta[y].type) {
                                    case "text":
                                      return (
                                        <input
                                          autoFocus
                                          type="text"
                                          value={x[y].value}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        />
                                      );
                                    case "comment":
                                      return (
                                        <textarea
                                          autoFocus
                                          type="text"
                                          value={x[y].value}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                          cols={33}
                                          rows={2}
                                        />
                                      );
                                    case "radio":
                                      return tempData.meta[y].options.map(
                                        (ropt, z) => (
                                          <>
                                            <input
                                              autoFocus
                                              name={y + rowIdx}
                                              type="radio"
                                              key={z}
                                              onKeyDown={(e) => {
                                                this._handleKeyDown(
                                                  e,
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }}
                                              checked={x[y].value == ropt}
                                              value={ropt}
                                              onChange={(e) => {
                                                x[y].value = ropt;
                                                this.setState({
                                                  tempData: this.state.tempData,
                                                });
                                              }}
                                            />
                                            <label htmlFor={ropt}>{ropt}</label>
                                          </>
                                        )
                                      );
                                    case "checkbox":
                                      return tempData.meta[y].options.map(
                                        (ropt, z) => (
                                          <>
                                            <input
                                              autoFocus
                                              name={y + rowIdx}
                                              key={z}
                                              onKeyDown={(e) => {
                                                this._handleKeyDown(
                                                  e,
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }}
                                              checked={
                                                x[y].value
                                                  .split(",")
                                                  .includes(ropt)
                                                  ? true
                                                  : false
                                              }
                                              type="checkbox"
                                              value={ropt}
                                              onChange={(e) => {
                                                let tmpArr =
                                                  x[y].value.split(",");
                                                if (e.target.checked == true) {
                                                  tmpArr.push(ropt);
                                                } else {
                                                  tmpArr = tmpArr.filter(
                                                    (a) => !(a == ropt)
                                                  );
                                                }
                                                console.log(
                                                  "checked",
                                                  e.target.checked
                                                );
                                                x[y].value = tmpArr.toString();
                                                this.setState({
                                                  tempData: this.state.tempData,
                                                });
                                              }}
                                            />
                                            <label htmlFor={ropt}>{ropt}</label>
                                          </>
                                        )
                                      );
                                    case "multiselect":
                                      return (
                                        <select
                                          autoFocus
                                          defaultValue=""
                                          value={this.state.multidefault} //{x[y].value.substring(x[y].value.lastIndexOf('|')+1)}
                                          style={{ maxWidth: "215px" }}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            console.log(x[y].value);
                                            if (x[y].value) {
                                              x[y].value = x[y].value.concat(
                                                "|",
                                                e.target.value
                                              );
                                            } else {
                                              x[y].value = e.target.value;
                                            }

                                            console.log("change", x[y].value);
                                            this.setState({
                                              multidefault: x[
                                                y
                                              ].value.substring(
                                                x[y].value.lastIndexOf("|") + 1
                                              ),
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        >
                                          {tempData.meta[y].options.map(
                                            (ropt, z) => (
                                              <option key={z} value={ropt}>
                                                {tempData.meta[y].displays[z]}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      );
                                    case "select":
                                      return (
                                        <select
                                          autoFocus
                                          value={x[y].value}
                                          style={{ maxWidth: "215px" }}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        >
                                          {tempData.meta[y].options.map(
                                            (ropt, z) => (
                                              <option key={z} value={ropt}>
                                                {tempData.meta[y].displays[z]}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      );
                                    case "date":
                                      return (
                                        <DatePicker
                                          style={{ zIndex: "100" }}
                                          selected={
                                            x[y].value
                                              ? moment(
                                                x[y].value,
                                                "MMM DD, YYYY"
                                              ).toDate()
                                              : new Date()
                                          }
                                          value={x[y].value}
                                          popperPlacement="bottom-end"
                                          popperProps={{
                                            positionFixed: true,
                                          }}
                                          onChange={(e) => {
                                            x[y].value =
                                              moment(e).format("MMM DD, YYYY");
                                            x[y].isEdit = false;
                                            x[
                                              y.toString().replace("custom", "")
                                            ].value = moment(e).format(
                                              "YYYY-MM-DDTHH:mm:ss"
                                            );
                                            this.setState(
                                              {
                                                tempData: this.state.tempData,
                                              },
                                              () => {
                                                this._handleKeyDown(
                                                  { key: "Enter" },
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }
                                            );
                                          }}
                                        />
                                      );
                                    case "time":
                                      return (
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardTimePicker
                                            value={x[y].value}
                                            onChange={(value) => {
                                              x[y].value = value;
                                              this.setState({
                                                tempData: this.state.tempData,
                                              });
                                            }}
                                            onKeyDown={(e) => {
                                              this._handleKeyDown(
                                                e,
                                                x,
                                                y,
                                                propIdx,
                                                rowIdx
                                              );
                                            }}
                                            style={{ width: 126 }}
                                            autoComplete="off"
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      );
                                    default:
                                      return <></>;
                                  }
                                })()}
                              </td>
                            ) : tempData.meta[y].isCustomRender ? ( //1st con start
                              <td
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              >
                                {tempData.meta[y].customRenderFunc(
                                  x[y],
                                  x.PLANNEDDATE ? x.PLANNEDDATE.value : "",
                                  x.SCHDATE ? x.SCHDATE.value : "",
                                  x.WEIGHTAGE ? x.WEIGHTAGE.value : "",
                                  x.BUILDPROGRESS ? x.BUILDPROGRESS.value : "",
                                  x.PROGRESS ? x.PROGRESS.value : ""
                                )}
                              </td>
                            ) : x[y].value ? ( //2nd in 1st start
                              x[y].value.toString().length < 35 ? ( //3rd in 2nd start
                                <td
                                  onDoubleClick={(e) => {
                                    this.ToggleTableDiv(
                                      e,
                                      x,
                                      y,
                                      propIdx,
                                      rowIdx
                                    );
                                  }}
                                >
                                  <label
                                    className={
                                      tempData.meta[y].style === "blink"
                                        ? x.CalculatedPlannedDateDiff
                                          ? x.CalculatedPlannedDateDiff.value
                                            ? x.CalculatedPlannedDateDiff
                                              .value === "Y"
                                              ? "customPlannedDatedStyle"
                                              : "demo"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    {x[y].value}
                                  </label>
                                </td>
                              ) : (
                                <td
                                  ref={
                                    this.elRefs[(rowIdx + 1) * (propIdx + 1)]
                                  }
                                  onDoubleClick={(e) => {
                                    window.deactivatePopover(e.target);
                                    this.ToggleTableDiv(
                                      e,
                                      x,
                                      y,
                                      propIdx,
                                      rowIdx
                                    );
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  data-html="true"
                                  title=""
                                  data-original-title={CustomFunctions.fullInformation(
                                    x[y].value
                                  )}
                                >
                                  {x[y].value.toString().substring(0, 34) +
                                    "..."}
                                </td>
                              ) //3rd end
                            ) : (
                              <td
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              ></td>
                            ) //1st and 2nd end
                        )}
                        {this.state.UserOrgType ===
                          Contants.AppRoleEnum.Admin ? (
                          tempData.actionCol && tempData.actionCol.isVisible ? (
                            <td>
                              {tempData.actionCol.buttons.map((btn, btnIdx) => (
                                <>
                                  <button
                                    title="Edit Record"
                                    key={btnIdx}
                                    className="btn btn-sm btn-info"
                                    onClick={(e) => {
                                      btn.callbackFunc(x);
                                    }}
                                  >
                                    {
                                      // btn.caption
                                    }
                                    <i className="fa fa-pencil-square-o"></i>
                                  </button>
                                  <button
                                    //key={btnIdx}
                                    title="Record History"
                                    style={{
                                      width: "32px",
                                      background: "lightsalmon",
                                      borderColor: "lightsalmon",
                                    }}
                                    className="btn btn-sm btn-secondary ml-1"
                                    onClick={(e) => {
                                      this.openRecordHistory(x);
                                    }}
                                  >
                                    {
                                      // btn.caption
                                    }
                                    <i className="fa fa-history"></i>
                                  </button>
                                </>
                              ))}
                            </td>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
            </div>
          ) : (
            "No Data Found"
          )}
        </div>

        <div className="text-right col-6 p-1 m-0 "></div>
        <Modal isOpen={this.state.showWeightageModal} size="md" centered={true}>
          <div className="maindashboard-custom-modal-header">
            {this.state.WeightageModalHeading}
            <br />
            <i
              className="fa fa-times text-warning fa-2x"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
              onClick={() => {
                this.setState({
                  showWeightageModal: false,
                  WeightageModalHeading: "",
                  SubActivityWeightage: "",
                  SubActivityID: "",
                });
              }}
            ></i>{" "}
          </div>
          <ModalBody>
            <div>
              <div className="col-12">
                <div className="form-group row">
                  <label
                    className="col-sm-5 text-right pt-0 col-form-label  custom-leftAlign-Label"
                    style={{ marginTop: "3px", fontSize: "14px" }}
                  >
                    Weightage:{" "}
                  </label>
                  <div className="col-sm-7">
                    <select
                      name="SubActivityWeightage"
                      value={this.state.SubActivityWeightage}
                      className="form-control custom-individual-dropdown fontsize"
                      onChange={(e) => {
                        this.handleChangeForEvent(e);
                      }}
                    >
                      <option value=""> Select </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "72px" }}
              color="success"
              onClick={() => {
                this.saveSubactivityWeightage();
              }}
            >
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showWeightageModal: false,
                  WeightageModalHeading: "",
                  SubActivityWeightage: "",
                  SubActivityID: "",
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <AddnEditFormModal
          ParentData={this.state}
          saveNewRecord={this.saveNewRecord}
          handleDateTimeChange={this.handleDateTimeChange}
          handleOnChange={this.handleOnChange}
          handleEnter={this.handleEnter}
          handleDeletetags={this.handleDeletetags}
          handleDeleteschtags={this.handleDeleteschtags}
          handleAdditiontags={this.handleAdditiontags}
          handleAdditionschtags={this.handleAdditionschtags}
          handleDragtags={this.handleDragtags}
          handleDragschtags={this.handleDragschtags}
          closeAddNEditFormModal={this.closeAddNEditFormModal}
          handleOnChangeMulti={this.handleOnChangeMulti}
          copyFromSchToAct={this.copyFromSchToAct}
        />

        <CommentsNResourcesModal
          ref={this.CommentModalElement}
          ParentData={this.state}
          handleOnChange={this.handleChangeForEvent}
          addNewResource={this.addNewResource}
          clearResourceInput={this.clearResourceInput}
          CloseCommentNResourecsModal={this.CloseCommentNResourecsModal}
        />

        <RecordHistoryModal
          ParentData={this.state.recordInfo}
          showModal={this.state.recordHistoryModalOpen}
          recordHistoryModalClose={this.recordHistoryModalClose}
          historyApiRes={this.state.historyApiRes}
          tableHeading={this.state.tableHeading}
        />
      </div>
    );
  }
}

export default ApplicationDetailedView;
