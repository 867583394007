import React from "react";
import {
    Row,
    Col,
    Collapse,
    Button,
    CardBody,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";


  class InterventionModal extends React.Component{
    constructor(props) {
        super(props);
      }
    componentDidMount (){
    }
    
      render(){
          return(
            <Modal  
            isOpen={this.props.parentData.InterventionModalOpen}
            centered={true}
          >
            <div className="maindashboard-custom-modal-header">
              {this.props.parentData.modalHeading}
              <br /> Select Intervention{" "}
              <i
                className="fa fa-times text-warning fa-2x"
                style={{ cursor: "pointer" }}
                aria-hidden="true"
                onClick={() => {
                  this.props.InterventionModalClose()
                }}
              ></i>{" "}
               
            </div>
            <div style={{width:"500px",height:"100px"}} className="pt-2">
              <div>
                <div className="row text-center custom-modal-marginright ">
                  <div
                    className=" col-lg-3 text-center" style={{paddingLeft:"17px"}}
                    onClick={(e) => {
                      this.props.setIntervention("B");
                    }}
                  >
                    <div className={this.props.parentData.tempActivityData.ACTFLAG== "B" ?  "active_simple_square_ColorSelection-Blue ":"simple_square_ColorSelection-Blue"} >
                      <i className="fa fa-square Simple-Blue-Color"></i>
                      <div className="mt-2">
                      <span style={{marginLeft:"-3px"}}>Blue</span>
                      </div>
                      
                    </div>
                  </div>
                  <div
                    className=" col-lg-3" style={{paddingLeft:"20px"}}
                    onClick={(e) => {
                      this.props.setIntervention("G");
                    }}
                  >
                    <div className={this.props.parentData.tempActivityData.ACTFLAG== "G" ?  "active_simple_square_ColorSelection-Green":"simple_square_ColorSelection-Green"} >
                        <i className="fa fa-square Simple-Green-Color"></i>
                        <div className="mt-2">
                        <span style={{marginLeft:"-9px"}}>Green</span>
                        </div>
                        
                    </div>
                  </div>
                  <div
                    className=" col-lg-3" style={{paddingLeft:"25px"}}
                    onClick={(e) => {
                      this.props.setIntervention("R");
                    }}
                  >
                    <div className={this.props.parentData.tempActivityData.ACTFLAG== "R" ?  "active_simple_square_ColorSelection-Red":"simple_square_ColorSelection-Red"}>
                        <i className="fa fa-square Simple-Red-Color"></i>
                        <div className="mt-2">
                        <span>Red</span>
                        </div>
                        
                    </div>
                  </div>
                  <div
                    className=" col-lg-3"
                    onClick={(e) => {
                      this.props.setIntervention("N");
                    }}
                  >
                    <div className="simple_square_ColorSelection-Neutral" style={{marginLeft:"13px"}}>
                        <i className="fa fa-square text-secondary"></i>
                        
                    </div>
                    <div className="mt-2" style={{marginRight:"45px"}}>
                        <span>Neutral</span>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          
          )
      }
  }
  export default InterventionModal;