import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import {
  AppRoleEnum,
  BigInfoIcon,
  BigRedIcon,
  infoIcon,
  redIcon,
} from "../../utils/Constants";
import moment from "moment";
import { ToastsStore } from "react-toasts";
import { UserAccessContext } from "./../UserAccessContext";
import { appCred } from "./../../AppConfig";

const PSBCommentModal = ({ modalOpen, data, closeModal, modalFor }) => {
  const userData = useContext(UserAccessContext);
  const [inputComment, setInputComment] = useState();
  const [commentFlag, setCommentFlag] = useState();

  useEffect(() => {
    setCommentFlag(data?.CommentFlag);
  }, [data?.CommentFlag]);

  useEffect(() => {
    setInputComment(data?.Comments);
  }, [data?.Comments]);

  const handleChange = (e) => {
    setInputComment(e.target.value);
  };

  const saveComment = () => {
    const reqObj = {
      FACILITYID: data.FACILITYID,//"AKUHN", //Hardcoded for now because of dev value against facility id
      ACTIVITYID: data.ACTIVITYID,
      COMMENTS: inputComment,
      optionalString: "PSB",
      CommentEnteredBy: userData.user?.UserID,
      CommentFlag: commentFlag,
    };
    console.log(reqObj);
    window
      .APIRequest(appCred, "", "EHRDashboardManager.SaveComments", reqObj)
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          closeModal();
          ToastsStore.success("Comments Saved Successfully");
          userData.refreshData();
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <Modal
      size='md'
      isOpen={modalFor === "comments" ? modalOpen : false}
      centered={true}
    >
      <div className='maindashboard-custom-modal-header'>
        {data?.DESCRIPTION}
        <br /> Comments
        <i
          className='fa fa-times text-warning fa-2x'
          style={{ cursor: "pointer" }}
          aria-hidden='true'
          onClick={() => {
            closeModal();
          }}
        ></i>{" "}
      </div>
      <ModalBody>
        <div>
          {(() => {
            if (data) {
              if (userData.user?.UserOrganizationType === AppRoleEnum.Admin) {
                return (
                  <div>
                    <textarea
                      type='text'
                      value={inputComment}
                      style={{
                        width: "100%",
                        height: "100px",
                        fontSize: "14px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {inputComment}
                    </textarea>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <h5
                          className='label-color'
                          style={{
                            textAlign: "left",
                            fontSize: "10px",
                            marginTop: "5px",
                            marginBottom: "-28px",
                          }}
                        >
                          Commented By
                        </h5>
                        <br />
                        <span
                          className='text-dark'
                          style={{ fontSize: "11px" }}
                        >
                          {data.CommentEnteredBy
                            ? data.CommentEnteredBy
                            : "N/A"}
                        </span>
                      </div>
                      <div className='col-lg-6 text-right'>
                        <h5
                          className='label-color'
                          style={{
                            marginTop: "5px",
                            textAlign: "right",
                            fontSize: "10px",
                            marginBottom: "-28px",
                          }}
                        >
                          Last Updated Date/Time
                        </h5>
                        <br />
                        <span
                          className='text-dark'
                          style={{ fontSize: "11px" }}
                        >
                          {data.CommentEnteredDTTM
                            ? moment(data.CommentEnteredDTTM).format(
                                "MMM DD,YYYY hh:mm:ss"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ width: "500px", height: "39px" }}
                      className='pt-2'
                    >
                      <div className='row'>
                        <div
                          className='col-2 ml-3'
                          style={{ marginTop: "-6px" }}
                        >
                          <div
                            className={
                              commentFlag === "B"
                                ? "active_simple_bigsquare-comment-blue "
                                : "simple_bigsquare-comment"
                            }
                            style={infoIcon}
                            onClick={() => {
                              setCommentFlag("B");
                            }}
                          ></div>
                        </div>
                        <div className='col-2' style={{ marginTop: "-6px" }}>
                          <div
                            className={
                              commentFlag === "R"
                                ? "active_simple_bigsquare-comment-red "
                                : "simple_bigsquare-comment"
                            }
                            onClick={() => {
                              setCommentFlag("R");
                            }}
                            style={redIcon}
                          ></div>
                        </div>
                        <Button
                          onClick={() => {
                            closeModal();
                          }}
                          className='customModal-savebutton btn-danger'
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={(e) => {
                            commentFlag
                              ? saveComment()
                              : ToastsStore.error("Select message type");
                          }}
                          className='customModal-save2button btn-success'
                        >
                          Save
                        </Button>
                      </div>
                      {/* {inputComment && !commentFlag ? (
                        ToastsStore.error("Select message type")
                      ) : (
                        <></>
                      )} */}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div
                      className={(() => {
                        if (commentFlag === "B") return "bigbluebox row";
                        else if (commentFlag === "R") return "bigredbox row";
                        else return "";
                      })()}
                    >
                      <div
                        className='col-lg-3'
                        style={commentFlag === "B" ? BigInfoIcon : BigRedIcon}
                      ></div>
                      <div className='col-lg-8' style={{ fontSize: "14px" }}>
                        {data.Comments}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <h5
                          className='label-color'
                          style={{
                            textAlign: "left",
                            fontSize: "10px",
                            marginTop: "5px",
                            marginBottom: "-28px",
                          }}
                        >
                          Commented By
                        </h5>
                        <br />
                        <span
                          className='text-dark'
                          style={{ fontSize: "11px" }}
                        >
                          {data.CommentEnteredBy
                            ? data.CommentEnteredBy
                            : "N/A"}
                        </span>
                      </div>
                      <div className='col-lg-6 text-right'>
                        <h5
                          className='label-color'
                          style={{
                            marginTop: "5px",
                            textAlign: "right",
                            fontSize: "10px",
                            marginBottom: "-28px",
                          }}
                        >
                          Last Updated Date/Time
                        </h5>
                        <br />
                        <span
                          className='text-dark'
                          style={{ fontSize: "11px" }}
                        >
                          {data.CommentEnteredDTTM
                            ? moment(data.CommentEnteredDTTM).format(
                                "MMM DD,YYYY hh:mm:ss"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            }
          })()}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PSBCommentModal;
