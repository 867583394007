import React from 'react';
const CommentTextarea=({name,value,onChange})=>{
    return ( 
        <React.Fragment>
            <div className="form-group row col-lg-11">
                <label className="col-sm-1 text-left pt-2 pl-0 col-form-label">
                Comments:{" "}
                </label>
                <div className="col-sm-9" style={{ marginLeft: "80px" }}>
                <textarea
                    style={{ width: "125%", minHeight : "50px" }}
                    type="text"
                    name={name}
                    value={value}
                    className="form-control fontsize"
                    onChange={onChange}
                />
                </div>
            </div>
        </React.Fragment>
     );
}

export default CommentTextarea;