import React from "react";
import Input from "../Controls/Input";
import Select from "../Controls/Select";
import Datepicker from "../Controls/DatePicker";
import CommentTextarea from "../Controls/CommentTextarea";
import TimePicker from "./TimePicker";

//------Reusable Controls Function---------

export function inputDictionaryName(props, desc) {
  return (
    <Input
      description={desc}
      name='NewDictionaryName'
      value={props.ParentData.addRecord.NewDictionaryName}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField='required-field'
      error={props.ParentData.addRecorderrors?.NewDictionaryName}
      type='text'
    />
  );
}
export function inputDictionaryID(props, desc) {
  return (
    <Input
      description={desc}
      name='NewDictionaryID'
      value={props.ParentData.addRecord.NewDictionaryID}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField='required-field'
      error={props.ParentData.addRecorderrors?.NewDictionaryID}
      type='text'
    />
  );
}
export function selectActive(props) {
  return (
    <Select
      description='Active'
      name='NewActive'
      value={props.ParentData.addRecord.NewActive}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField='required-field'
      error={props.ParentData.addRecorderrors?.NewActive}
    />
  );
}
export function selectWeightage(props, className) {
  return (
    <Select
      description='Weightage'
      name='NewWeightage'
      value={props.ParentData.addRecord.NewWeightage}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField={className}
      error={props.ParentData.addRecorderrors?.NewWeightage}
    />
  );
}
export function inputBuildProgress(props) {
  return (
    <Input
      description='Build Progress'
      name='NewBuildProgress'
      value={props.ParentData.addRecord.NewBuildProgress.toString()}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField='required-field'
      error={props.ParentData.addRecorderrors?.NewBuildProgress}
      type='number'
    />
  );
}
export function inputTargetHCIS(props) {
  return (
    <Input
      description='Target HCIS'
      name='NewTargetHCIS'
      value={props.ParentData.addRecord.NewTargetHCIS}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      requiredField='required-field'
      error={props.ParentData.addRecorderrors?.NewTargetHCIS}
      type='text'
    />
  );
}
export function datePicker(props, name, desc, reqField) {
  return (
    <Datepicker
      description={desc}
      name={name}
      selected={props.ParentData.addRecord[name]}
      onChange={(date) => {
        props.handleDateTimeChange(name, date);
      }}
      handleEnter={props.handleEnter}
      requiredField={reqField}
      error={props.ParentData.addRecorderrors[name]}
    />
  );
}

export function displaySequence(props) {
  return (
    <Input
      description='Display Sequence'
      name='NewDisplaySequence'
      value={props.ParentData.addRecord.NewDisplaySequence}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
      handleEnter={props.handleEnter}
      type='number'
    />
  );
}
export function commentTextArea(props) {
  return (
    <CommentTextarea
      name='NewComments'
      value={props.ParentData.addRecord.NewComments}
      onChange={(e) => {
        props.handleOnChange(e);
      }}
    />
  );
}
export function timePicker(props, name, desc, value, reqField) {
  return (
    <TimePicker
      description={desc}
      onChange={(value) => {
        props.handleDateTimeChange(value, name);
      }}
      name={name}
      value={value}
      requiredField={reqField}
    />
  );
}

//------------------End--------------------
