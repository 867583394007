import infoicon from "./../assests/Dashboard/Info.png"
import moment from "moment";
const cardStyle=(color)=>{
    return {
            percentageColor: "text-"+color,
            progressBar: "progressborder_"+color,
            progressBarBorder : color+"-bar-border",
            progressColor:   "progress_"+color, //"bg-"+type,
            cardDouble: "double-"+color,
            circleBorderColor : "bubble_"+color,
            sideLine : "border-"+color
     }
             
 }
export const setCardStyle =(data)=> {
    const formattedTargetDate = moment(data.ACTTARGETDATE).format("MM/DD/YYYY");
    const roundedProgressPercentageValue= Math.round(data.PROGRESS)
    const dateDiff = moment().startOf('day').diff(formattedTargetDate, "days") * -1 // -1 for sign toggling
    if(data.ActivityFlag && data.ActivityFlag !=='N'){
        if(data.ActivityFlag ==='B') return cardStyle("blue") 
        else if(data.ActivityFlag ==='G') return cardStyle("green") 
        else if(data.ActivityFlag ==='R') return cardStyle("red") 
        else if(data.ActivityFlag ==='A') return cardStyle("amber") 
    }

    else if(roundedProgressPercentageValue >=90) return cardStyle("green","success") //color and Type
    else if(roundedProgressPercentageValue >=66 && roundedProgressPercentageValue <=89) return cardStyle("blue","primary") 
    else if(dateDiff < 0) return cardStyle("red","danger")
    //Target date <= 60 days
    else if(dateDiff <= 60){
        if(roundedProgressPercentageValue < 40) return cardStyle("red","danger") //color and Type
        else if(roundedProgressPercentageValue >=40 && roundedProgressPercentageValue < 66) return cardStyle("amber","danger") 
    }
    
    else if(dateDiff > 60){
        if(roundedProgressPercentageValue < 40) return cardStyle("amber","danger")
        else if(roundedProgressPercentageValue >=40 && roundedProgressPercentageValue < 66) return cardStyle("blue","primary")
    }

    
    // Old Rule----------------
    // else if(dateDiff >= 0){
    //     if(roundedProgressPercentageValue >=90 ) return cardStyle("green","success") //color and Type
    //     else if(roundedProgressPercentageValue < 90) return cardStyle("red","danger") //color and Type
    //   }
    // else if(dateDiff <=-60){
    //     if(roundedProgressPercentageValue >=75) return cardStyle("green","success") //color and Type
    //     else if(roundedProgressPercentageValue >=20 && 
    //             roundedProgressPercentageValue < 75) return cardStyle("blue","primary") //color and Type
    //     else if(roundedProgressPercentageValue < 20) return cardStyle("red","danger") //color and Type
    //   }
    // else if(dateDiff >-60){
    //     if(roundedProgressPercentageValue >=90) return cardStyle("green","success") //color and Type
    //     else if(roundedProgressPercentageValue >=30 &&
    //             roundedProgressPercentageValue <90) return cardStyle("blue","primary") //color and Type
    //     else if(roundedProgressPercentageValue < 30)return cardStyle("red","danger") //color and Type      
    //   }
    //End--------------------------
     
 }
        
export const setCommentBtn =(flag) =>{
     if(flag ==="B"){
         return {
             commImage :{backgroundImage:`url('${infoicon}')` ,
                         backgroundSize: "20px 20px",
                         height:"20px",
                         width:"20px",
                         backgroundRepeat: "no-repeat"} ,
             commClass : ""
         }
     }
     else if(flag === "R"){
         return {
             commImage : '',
             commClass : "fa fa-flag text-danger mr-1 "
         }
     }
     else{
         return {
             commImage : '',
             commClass : 'fa fa-info text-secondary mr-1 '
         }
     }
 }

export const btnInterventionColor = (flag)=>{
    if(flag ==="G") return {color: "#28a745" ,height:'24px'}
    if(flag ==="R") return {color:"#d9534f", height:'24px'}
    if(flag ==="B") return {color:"#85a2f3", height:'24px' }
    if(flag ==="A") return {color:"#c79500", height:'24px' }

}

export const getMonthsRange = (startDate,endDate) => {
    var start      = startDate.split('/');
    var end        = endDate.split('/');
    var startYear  = parseInt(start[2]);
    var endYear    = parseInt(end[2]);
    var dates      = [];
    const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

    for(var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
        var startMon = i === startYear ? parseInt(start[0])-1 : 0;
       
        for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? + month : month;
        // dates.push([i, displayMonth, '01'].join('-')); "2023-11-01"
        dates.push(monthNames[displayMonth]  + " " + i );
        }
    }
   
    return dates;
}

export const getDateMonthsDifference = (startDate, endDate) => {
    const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
    const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
    let result = moment(formattedEndDate).diff(formattedStartDate,'months');
    return result;
 }
 
 export const getDateDaysDifference = (startDate, endDate) => {
    if(endDate != null)
    {
        // make it to 30 days
        endDate = endDate.replace ("/31", "/30"); 
        endDate = endDate.replace ("02/28", "02/30");
    }
    
    const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
    const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
    let result = moment(formattedEndDate).diff(formattedStartDate,'days');
    return result;
 }

 export const getMinDate = (inputArray) => {
    let moments = inputArray.map(d => moment(d));
    let result = moment.min(moments).toDate() ;
    let formtattedResult = moment(result).format("MM/DD/YYYY");
    return formtattedResult;
 }

 export const getMaxDate = (inputArray) => {
    let moments = inputArray.map(d => moment(d));
    let result = moment.max(moments) ;
    let formtattedResult = moment(result).format("MM/DD/YYYY");
    return formtattedResult;
 }

 export const getTodayDate = () => {
    let result = moment().format('MM/DD/YYYY');
    return result;
 }
 
 export const getTodayDateFormatted = (inputDate) => {
    let result = moment(inputDate).format('MM/DD/YYYY');
    return result;
 }


 export const getTodayDateForDisplay = () => {
    let result = moment().format('MMM DD,YYYY');
    return result;
 }
 export const isCurrentMonth = (inputDate) => {
    let todayDate = getTodayDate();
    let result = true;
    //return result;

    // Create a new Date object for the current date
    const currentDate = new Date();

    // Define the month to compare with (Jan 2023 in this case)
    const targetMonth = new Date(inputDate);

    // Extract the month and year from the current date
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Extract the month and year from the target date
    const targetMonthValue = targetMonth.getMonth();
    const targetYearValue = targetMonth.getFullYear();

    // Check if the target month and year match the current month and year
    if (currentMonth === targetMonthValue && currentYear === targetYearValue) {
       // console.log("The given month 'Jan 2023' is the current month.");
        return true;
  
    } else {
       // console.log("The given month 'Jan 2023' is not the current month.");
        return false;

    }

 }



