import * as React from "react";
import "../projectganttchart.css";
import { appCred } from "../AppConfig.js";
import loadingGif from "../Components/assests/Dashboard/Cube-1s-264px.gif";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  setCardStyle,
  getDateDaysDifference,
  getMinDate,
  getMaxDate,
  getMonthsRange,
  getDateMonthsDifference,
  getTodayDate,
  isCurrentMonth,
  getTodayDateForDisplay,getTodayDateFormatted
} from "../Components/MainStream/CustomFunctions";

class ProjectGanttChart extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      GanttData: [],
      GanttTimelineDateArray: [],
      GanttTimelineDateCount: 0,
      GanttMonthsTotalWidth: 0,
      GoLiveMTISBLineIndicator:0,
      AsOnDate: "",
      FacilityName: "",
      isLoading: true,
      GoLiveDrawLine: 0,
    };
  }

  //Get Data
  GetProjectGanttChartData = () => {
    window
      .APIRequest(appCred, "", "EHRDashboardManager.GetGanttChartData", {
        facilityID: this.state.facilityID,
        activityID: "MTISB",
        LoggedInRole: "COLLABORATOR",
      })
      .then((apiRes) => {
        if (apiRes.ResponseCode === 200) {
          let ganttChartDateArray = [];
          let minStartDate;
          let maxEndDate;
          let goLiveDate ;
          let goLiveMTISBPlannedWidth;
          let gData = apiRes.ResponseResult;
        
          gData.map((item, pid) => {
            if(item.ACTIVITYID == "MTISB")
            {
              minStartDate = item.StartDate;
              maxEndDate = item.EndDate;
              goLiveDate = item.maxEndDate;
              var index = 3; 
              minStartDate = minStartDate.substring(0, index) + '01' + minStartDate.substring(index + 2);
              maxEndDate = maxEndDate.substring(0,index) + '30' + maxEndDate.substring(index + 2);      
            }
          });
          let output = gData.map((item, pid) => {

            item.ACTTARGETDATE = item.EndDate;

            let tempR = [];
            item.ResponsibilityList.map((responsibility) => {
              let responsibilityColor = "";
              if (responsibility.startsWith("AKU-")) {
                responsibilityColor = "green";
              } else if (responsibility.startsWith("MT-")) {
                responsibilityColor = "goldenyellow";
              } else {
                responsibilityColor = "blue";
              }
              tempR.push({
                Responsibility: responsibility,
                ResponsibilityColor:
                  " pg-entitytag pg-entitytag-" + responsibilityColor,
              });
            });
            item.ResponsibilityList = tempR;

            const card = setCardStyle(item);
            return {
              TaskID: pid + 1 + "",
              TaskName: item.TaskName,
              StartDate: new Date(item.StartDate),
              EndDate: item.EndDate ? new Date(item.EndDate) : "",
              Progress: item.Progress,
              Flag: item.Flag,
              Flag: item.ActivityFlag,
              FormattedStartDate: item.StartDate,
              FormattedEndDate: item.EndDate,
              ActivityID: item.ACTIVITYID,
              FacilityID: item.FACILITYID,
              Responsibility: item.Responsibility,
              ResponsibilityList: item.ResponsibilityList,
              ProgressColor: card.progressColor,
              ProgressIDColor: "pg-progress pgc-" + card.progressColor,
              ProgressBarColor:
                item.Progress == "100"
                  ? "progresscompleted"
                  : "stripes_" + card.progressColor,
              NinetyProgressBarColor:
                "ninetyprogress ninetyprogress_" + card.progressColor,
              ActivityParentColor: "",
              ActivityMinStartDate: minStartDate,
              ActivityMaxEndDate: maxEndDate,
              ActualStartDate: item.ActualStartDate,
              ActualEndDate: item.ActualEndDate,
              ActualNinetyProgressDate: item.ActualNinetyProgressDate,
              PlannedActivityMargin: getDateDaysDifference(
                minStartDate,
                item.StartDate
              ),
              PlannedActivityWidth: getDateDaysDifference(
                item.StartDate,
                item.EndDate
              ),
              ProgressActivityMargin: getDateDaysDifference(
                minStartDate,
                item.ActualStartDate
              ),
              GoLiveDateDrawMargin : 0,
              MainActivityStyle:
                item.ACTIVITYID == "MTISB" ? "gantt__row-bars mainFirstActivity" : "gantt__row-bars",
              MainActivityHeaderStyle:
                item.ACTIVITYID == "MTISB"? "gantt__row-first mainFirstActivity" : "gantt__row-first",
              MTISBHeaderStyle:  
                 item.ACTIVITYID == "MTISB" ? "gantt__row whole--row mtisbheader" : "gantt__row whole--row",
            };
          });

          output.map((aItem,aItemID) => {

            // MM - PLANNED ACTIVITY ROW START 
            let BoxWidthPixels = 3;
            let adjustedDatePixels = 0; // if the min date is not starting from 1st (AKUHN)
            let adjustedDatePixelsWidth = 0;

            aItem.PlannedActivityMargin = aItem.PlannedActivityMargin * BoxWidthPixels  ;//+ 21;
            let adjustedMargin =  ((aItem.PlannedActivityMargin / 90) + 1);
            aItem.PlannedActivityMargin = aItem.PlannedActivityMargin-adjustedMargin;

            aItem.PlannedActivityWidth = aItem.PlannedActivityWidth * BoxWidthPixels;
            let adjustedWidth = 0;// ((aItem.PlannedActivityWidth / 90) + 1); // MM - need to check if enable or not
            aItem.PlannedActivityWidth = (aItem.PlannedActivityWidth - adjustedWidth);//- 30;

             // handle exceptions
             if (aItem.PlannedActivityWidth < 1) {
                aItem.PlannedActivityWidth = 0;
                aItem.PlannedActivityMargin = 0;
            }
            // MM - PLANNED ACTIVITY ROW END 

            // MM - PROGRESS ACTIVITY ROW START 
            aItem.ProgressActivityMargin = aItem.ProgressActivityMargin * BoxWidthPixels  ;
            let adjustedProgressMargin =  ((aItem.ProgressActivityMargin / 90) + 1);
            aItem.ProgressActivityMargin = aItem.ProgressActivityMargin - adjustedProgressMargin;
           
            // check if complete date is present
            let progresswidth = 0;
            aItem.ProgressActivityWidth = 0;
            aItem.ProgressActivityExtendedWidth = 0;

            // calculate width if date is present 
            if (aItem.ActualEndDate != "") {
              progresswidth = getDateDaysDifference(
                aItem.ActualStartDate,
                aItem.ActualEndDate
              );
            }
            // if actual completed date is absent, check for 90% date
            if (aItem.ActualEndDate == null) {
              // current date should not be exceeding max date 

              let todayDate = getTodayDate();
              let extendedActivityMaxDate = todayDate;
              let extendedActivityMaxWidth = 0;

              // if todays date is crossing max date, set max date as extended date
              let dateArry = [];
              dateArry.push(todayDate);
              dateArry.push(maxEndDate);
              let minDateMTI= getMinDate(dateArry);
              extendedActivityMaxDate = minDateMTI;
              // TODO - need to remove
            //  // if (todayDate > maxEndDate) {
            //   if (todayDate > maxEndDateObject) {
            //     extendedActivityMaxDate = maxEndDate;
            //     console.log(extendedActivityMaxDate); 
            //   }

              if (aItem.ActualNinetyProgressDate != "" && aItem.ActualNinetyProgressDate  != null) {

                // check for extended progress activity based on ninetypercent date
                progresswidth = getDateDaysDifference(
                  aItem.ActualStartDate,
                  aItem.ActualNinetyProgressDate
                );

                extendedActivityMaxWidth = getDateDaysDifference(
                  aItem.ActualNinetyProgressDate,
                  extendedActivityMaxDate
                );

                aItem.ProgressActivityExtendedWidth = extendedActivityMaxWidth * BoxWidthPixels;
              }
              // MM - on 03092023 
              // if no actual date and no ninety date but activity has started then render till current date
              else 
              {
                progresswidth = getDateDaysDifference(
                  aItem.ActualStartDate,
                  extendedActivityMaxDate
                );
              }
            }
            if (progresswidth > 0) {
              aItem.ProgressActivityWidth = progresswidth * BoxWidthPixels ;//- 30;
              let adjustedProgressWidth =  ((aItem.ProgressActivityWidth / 90) + 1);
              aItem.ProgressActivityWidth = aItem.ProgressActivityWidth - adjustedProgressWidth;

            }

            // rest margin and width to avoid rendering 

            if (aItem.PlannedActivityMargin < 0) {
              aItem.PlannedActivityMargin = 0;
            }
            if (aItem.ProgressActivityMargin < 0) {
              aItem.ProgressActivityMargin = 0;
            }
            if (aItem.ProgressActivityWidth < 1) {
              aItem.ProgressActivityWidth = 0;
              aItem.ProgressActivityMargin = 0;
            }
            if (aItem.ProgressActivityExtendedWidth <= 0) {
              aItem.NinetyProgressBarColor = " hidden";
            }
            if (aItem.PlannedActivityWidth <= 0) {
              aItem.ActivityParentColor = "hidden";
              //return; // why return? 
            }
            // MM - PROGRESS ACTIVITY ROW END

            // MM - GOIVE DATE
            if(aItem.ActivityID == "MTISB")
            {
              goLiveMTISBPlannedWidth = aItem.PlannedActivityWidth;
              //console.log("goLiveMTISBPlannedWidth:" + goLiveMTISBPlannedWidth);
            }

          });
         

          //Get months range between two dates for months bar
          console.log(output);
          ganttChartDateArray = getMonthsRange(minStartDate, maxEndDate);

          // Total Timeline Duration
          let timelineCount = ganttChartDateArray.length;
          let timelineMonthsTotalWidth =  ganttChartDateArray.length * 90 + 280;

          // GOLIVE DATE GREEN LINE 
          let goLiveMTISBLineIndicator = timelineMonthsTotalWidth - 280 - goLiveMTISBPlannedWidth;
          
          let ganttChartTimelineMonths = [];

          ganttChartDateArray.map((item, index) => {
            let isInputMonthCurrentMonth = isCurrentMonth(item);
            let currentMonthStyleTitle = "";
            let currentMonthStyleBar = "";
            if (isInputMonthCurrentMonth == true) {
              currentMonthStyleTitle = "currentMonthStyleTitle ";
              currentMonthStyleBar = "currentMonthStyleBar ";
            }
            ganttChartTimelineMonths.push({
              MonthName: item,
              IsCurrentMonth: isInputMonthCurrentMonth,
              CurrentMonthStyleTitle: currentMonthStyleTitle,
              CurrentMonthStyleBar: currentMonthStyleBar,
            });
          });

          //Get Date to display
          let todayDateForDisplay = getTodayDateForDisplay();
          let facilityName = this.state.facilityName + " Gantt Chart";

          // Set State
          this.setState({
            GanttData: output,
            isLoading: false,
            GanttTimelineDateArray: ganttChartTimelineMonths,
            GanttTimelineDateCount: timelineCount,
            GanttMonthsTotalWidth: timelineMonthsTotalWidth,
            GoLiveMTISBLineIndicator: goLiveMTISBLineIndicator+ "px",
            AsOnDate: todayDateForDisplay,
            FacilityName: facilityName,
          });

          // console.log(this.state);
        } else {
          this.setState({
            // isLoading: false,
            errorMessage: true,
          });
        }
      })
      .catch((error) => {
        this.setState(
          {
            // isLoading: false,
            errorMessage: true,
          },
          () => {
            console.log(error);
          }
        );
      });
  };

  redirectHomePage = () => {
    window.history.go(-1);
    var hasHeader = document.querySelector("body header") !== null;
    if (hasHeader) {
      var akuMainHeader = document.querySelector(".custom-header-height");
      akuMainHeader.style.display = "block";
    }
  };

  changeTheme = () => {
    var themeType = document.getElementById("theme_type");
    const mainHeader = document.getElementById("pgc-mainheader");
    const mainHeaderLabel = mainHeader.querySelector(".mti-gantt");
    const ganttRowsMonth = document.getElementById("gantt__row--months");
    const rowMonthRightLine = document.getElementById("gantt__row--lines");
    const rowMonthRightLabel = rowMonthRightLine.querySelector("label");
    const sidebarHeader = document.querySelector(".gantt__row-first");
    const sidebarHeaderLabel = sidebarHeader.querySelectorAll("label");
    const asOn = document.querySelector(".as-on");
    const asOnDate = document.querySelector(".as-on-date");
    const sidebar = document.querySelectorAll(".gantt__row-first");
    const rowbars = document.querySelectorAll(".greylinebars");

    if (themeType.checked) {
      document.body.style.background =
        "linear-gradient(rgb(216 212 212) 0%, rgb(212 207 207) 30%, rgb(194 193 193) 75%, rgb(217 213 213) 100%)";
      sidebar.forEach((div) => {
        div.style.backgroundColor = "#d5d4d4";
        const inside_text = div.querySelector("label");
        inside_text.style.color = "black";
      });
      rowbars.forEach((li) => {
        li.style.backgroundColor = "#7f7b7b";
      });

      mainHeader.style.background = "#d5d4d4";
      mainHeaderLabel.style.color = "black";
      mainHeader.style.borderBottom =
        "6px solid rgb(127, 123, 123 / 64%) !important";
      mainHeader.classList.add("borderMainheader");
      sidebarHeader.style.borderRight = "";
      sidebarHeader.style.borderRight = "2px solid rgb(114 110 110)";
      asOn.style.color = "black";
      asOnDate.style.color = "black";
      ganttRowsMonth.style.backgroundColor = "#d5d4d4";
      ganttRowsMonth.style.borderBottom = "2px solid rgb(19 18 18 / 61%) ";
      rowMonthRightLabel.style.borderRight = "2px solid rgb(19 18 18 / 61%)";
      sidebarHeaderLabel.forEach((label) => {
        label.style.color = "black";
      });

      const divElement = document.querySelector("#gantt__row--months");
      const spanElements = divElement.querySelectorAll("span");
      spanElements.forEach((span) => {
        span.style.color = "black"; // Example style change
      });
    } else {
      document.body.style.background =
        "linear-gradient(rgb(0 0 0) 0%, rgb(68 68 68) 30%, rgb(35 35 35) 75%, rgb(0 0 0) 100%)";
      sidebar.forEach((div) => {
        div.style.backgroundColor = "black";
        const inside_text = div.querySelector("label");
        inside_text.style.color = "white";
      });
      rowbars.forEach((li) => {
        li.style.backgroundColor = "#bbbbbb";
      });

      mainHeader.style.background = "black";
      mainHeader.classList.remove("borderMainheader");
      mainHeaderLabel.style.color = "white";
      mainHeader.style.borderBottom =
        "6px solid rgb(255 255 255 / 64%) !important";
      asOn.style.color = "white";
      asOnDate.style.color = "white";
      sidebarHeader.style.borderRight = "";
      sidebarHeader.style.borderRight = "2px solid rgba(255 242 242 /61%)";
      ganttRowsMonth.style.backgroundColor = "black";
      ganttRowsMonth.style.borderBottom = "2px solid rgb(255 242 242 / 61%)";
      rowMonthRightLabel.style.borderRight = "2px solid rgb(255 242 242 / 61%)";
      sidebarHeaderLabel.forEach((label) => {
        label.style.color = "white"; // Example style change
      });
      const divElement = document.querySelector("#gantt__row--months");
      const spanElements = divElement.querySelectorAll("span");
      spanElements.forEach((span) => {
        span.style.color = "white"; // Example style change
      });
    }
  };

  legendGanttChart = () => {
    var dropdownContent = document.querySelector(".dropdown-content");
    if (dropdownContent.style.display == "block") {
      dropdownContent.style.display = "none";
      document.getElementById("myDropdown").classList.toggle("hide");
    } else {
      dropdownContent.style.display = "block";
      document.getElementById("myDropdown").classList.toggle("show");
    }
  };
  zoomOut = () => {
     const ganttChart = document.getElementById('gantt');
     const zoomOutButton = document.getElementById('zoomoutButton');
     const zoomInButton = document.getElementById('zoominButton'); 
     const chartWidth = ganttChart.scrollWidth;
      const chartHeight = ganttChart.scrollHeight;

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

     
      if(chartWidth >= 3100 && chartWidth < 3800 ){
        ganttChart.style.zoom = '0.495';
        ganttChart.style.marginTop = '25px';
        zoomOutButton.style.display = 'none';
        zoomInButton.style.display = 'inline-block';
      }
     else if(chartWidth >= 2600 && chartWidth < 3100){
        ganttChart.style.zoom = '0.52';
        ganttChart.style.marginTop = '25px';
        zoomOutButton.style.display = 'none';
        zoomInButton.style.display = 'inline-block';
      }
      else if(chartWidth >= 3800){
        ganttChart.style.zoom = '0.4';
        ganttChart.style.marginTop = '25px';
        zoomOutButton.style.display = 'none';
        zoomInButton.style.display = 'inline-block';
      } 
};

zoomIn = () => {
  const ganttChart = document.getElementById('gantt');
  const zoomOutButton = document.getElementById('zoomoutButton');
  const zoomInButton = document.getElementById('zoominButton'); 
  const chartWidth = ganttChart.scrollWidth;
   const chartHeight = ganttChart.scrollHeight;

     ganttChart.style.zoom = '';
     ganttChart.style.marginTop = '';
     zoomOutButton.style.display = 'inline-block';
     zoomInButton.style.display = 'none';
  
};
  closeButton = () => {
    var dropdownContent = document.querySelector(".dropdown-content");
    dropdownContent.style.display = "none";
  };

  downloadGanttChart = () => {
    function toggleTheme() {
      var themeType = document.getElementById("theme_type");
      const mainHeader = document.getElementById("pgc-mainheader");
      const mainHeaderLabel = mainHeader.querySelector(".mti-gantt");
      const ganttRowsMonth = document.getElementById("gantt__row--months");
      const rowMonthRightLine = document.getElementById("gantt__row--lines");
      const rowMonthRightLabel = rowMonthRightLine.querySelector("label");
      const sidebarHeader = document.querySelector(".gantt__row-first");
      const sidebarHeaderLabel = sidebarHeader.querySelectorAll("label");
      const asOn = document.querySelector(".as-on");
      const asOnDate = document.querySelector(".as-on-date");
      const sidebar = document.querySelectorAll(".gantt__row-first");
      const rowbars = document.querySelectorAll(".greylinebars");

      if (themeType.checked) {
        document.body.style.background =
          "linear-gradient(rgb(216 212 212) 0%, rgb(212 207 207) 30%, rgb(194 193 193) 75%, rgb(217 213 213) 100%)";
        sidebar.forEach((div) => {
          div.style.backgroundColor = "#d5d4d4";
          const inside_text = div.querySelector("label");
          inside_text.style.color = "black";
        });
        rowbars.forEach((li) => {
          li.style.backgroundColor = "#7f7b7b";
        });

        mainHeader.style.background = "#d5d4d4";
        mainHeaderLabel.style.color = "black";
        asOn.style.color = "black";
        asOnDate.style.color = "black";
        ganttRowsMonth.style.backgroundColor = "#d5d4d4";
        ganttRowsMonth.style.borderBottom = "2px solid rgb(19 18 18 / 61%)";
        rowMonthRightLabel.style.borderRight = "2px solid rgb(19 18 18 / 61%)";
        sidebarHeader.style.borderRight = "";
        sidebarHeader.style.borderRight = "2px solid rgb(114 110 110)";
        sidebarHeaderLabel.forEach((label) => {
          label.style.color = "black";
        });

        const divElement = document.querySelector("#gantt__row--months");
        const spanElements = divElement.querySelectorAll("span");

        spanElements.forEach((span) => {
          span.style.color = "black"; // Example style change
        });
      } else {
        document.body.style.background =
          "linear-gradient(rgb(0 0 0) 0%, rgb(68 68 68) 30%, rgb(35 35 35) 75%, rgb(0 0 0) 100%)";
        sidebar.forEach((div) => {
          div.style.backgroundColor = "black";
          const inside_text = div.querySelector("label");
          inside_text.style.color = "white";
        });
        rowbars.forEach((li) => {
          li.style.backgroundColor = "#bbbbbb";
        });

        mainHeader.style.background = "black";
        mainHeaderLabel.style.color = "white";
        asOn.style.color = "white";
        asOnDate.style.color = "white";
        sidebarHeader.style.borderRight = "";
        sidebarHeader.style.borderRight = "2px solid rgba(255 242 242 /61%)";
        ganttRowsMonth.style.backgroundColor = "black";
        ganttRowsMonth.style.borderBottom = "2px solid rgb(255 242 242 / 61%)";
        rowMonthRightLabel.style.borderRight = "2px solid rgb(255 242 242 / 61%)";

        sidebarHeaderLabel.forEach((label) => {
          label.style.color = "white"; // Example style change
        });
        const divElement = document.querySelector("#gantt__row--months");
        const spanElements = divElement.querySelectorAll("span");
        spanElements.forEach((span) => {
          span.style.color = "white"; // Example style change
        });
      }
    }
    const body = document.body;
    const downloadButton = document.getElementById("downloadButton");
    var downloadButtonParent = downloadButton.parentNode;
    var downloadButtonIndex = Array.prototype.indexOf.call(
      downloadButtonParent.children,
      downloadButton
    );
    const backButton = document.getElementById("backButton");
    var backButtonParent = backButton.parentNode;
    var backButtonIndex = Array.prototype.indexOf.call(
      backButtonParent.children,
      backButton
    );
    const dataIdValue = downloadButton.getAttribute("data-id");
    document.body.style.width = dataIdValue;
    downloadButton.parentNode.removeChild(downloadButton);
    backButton.parentNode.removeChild(backButton);
    var themeLabel = document.getElementById("theme_label");
    var themeLabelClone = themeLabel.cloneNode(true);
    var themeLabelParent = themeLabel.parentNode;
    var themeLabelIndex = Array.prototype.indexOf.call(
      themeLabelParent.children,
      themeLabel
    );
    themeLabel.remove();
    const legendButton = document.getElementById("legendButton");
    var legendButtonParent = legendButton.parentNode;
    var legendButtonIndex = Array.prototype.indexOf.call(
      legendButtonParent.children,
      legendButton
    );
    legendButton.parentNode.removeChild(legendButton);

    html2canvas(body).then(function (canvas) {
      const imgData = canvas.toDataURL("image/png");
      downloadButton.style.display = "";
      backButton.style.display = "";
      downloadButtonParent.insertBefore(
        downloadButton,
        downloadButtonParent.children[downloadButtonIndex]
      );
      backButtonParent.insertBefore(
        backButton,
        backButtonParent.children[backButtonIndex]
      );
      legendButtonParent.insertBefore(
        legendButton,
        legendButtonParent.children[legendButtonIndex]
      );
      themeLabelParent.insertBefore(
        themeLabelClone,
        themeLabelParent.children[themeLabelIndex]
      );
      themeLabelParent.addEventListener("click", toggleTheme);
      document.getElementById("legendButton").classList.toggle("show");
      const downloadLink = document.createElement("a");
      downloadLink.href = imgData;
      downloadLink.download = "mtiganttchart.png";
      downloadLink.click();
    });
  };

  hideDropdown = () => {
    var dropdown = document.getElementById("myDropdown");
    var legendButton = document.getElementById("legendButton");
    var downloadButton = document.getElementById("downloadButton");
    function handleClick(event) {
      if (
        dropdown &&
        legendButton &&
        !dropdown.contains(event.target) &&
        !legendButton.contains(event.target)
      ) {
        dropdown.style.display = "none";
       
      } else {
        dropdown.style.display = "show";
      }
    }
    document.addEventListener("click", handleClick);
  };

  AnimateGanttChart = () => {
	const rows = document.querySelectorAll(".whole--row");
	const activityLabels = document.querySelectorAll(".pg-entitytag-task");
	const progressLabels = document.querySelectorAll(".pg-progress");
	const responsibilityButtons = document.querySelectorAll(".gantt__row .pg-entitytag");
	
	// Setting initial visibility and opacity of rows and labels
	rows.forEach((row) => {
	  row.style.visibility = "hidden";
	  row.style.opacity = "0";
	});
  
	activityLabels.forEach((label) => {
	  label.style.opacity = "1";
	});
  
	progressLabels.forEach((label) => {
	  label.style.opacity = "1";
	});
  
	responsibilityButtons.forEach((button) => {
	  button.style.opacity = "0";
	  button.style.transform = "translateX(100%)";
	});
  
	// Animating rows and labels with slow motion effect
	rows.forEach((row, i) => {
	  setTimeout(() => {
		row.style.visibility = "visible";
		row.style.transform = "translateX(100)";
		row.style.transition = "transform 1s ease-out, opacity 1s ease-out";
		setTimeout(() => {
		  row.style.opacity = "1";
		  activityLabels.forEach((label, labelIndex) => {
			if (labelIndex === i) {
			  setTimeout(() => {
				label.style.opacity = "1";
			  }, 250);
			}
		  });
		  progressLabels.forEach((label, labelIndex) => {
			if (labelIndex === i) {
			  setTimeout(() => {
				label.style.opacity = "1";
			  }, 250);
			}
		  });
		}, 250);
	  }, i * 250);
	});
  
	// Animation for the responsibility button
	responsibilityButtons.forEach((button, index) => {
	  setTimeout(() => {
		button.style.transform = "translateX(0)";
		button.style.transition = "transform 1s ease-out, opacity 1s ease-out";
		button.style.opacity = "1";
	  }, index * 110);
	});
  };

	checkForClass = () => {
		const intervalId = setInterval(() => {
		  const targetElement = document.querySelector('#loadingGif');
	
		  if (!targetElement) {
			clearInterval(intervalId); // Stop checking when class is not found
			this.AnimateGanttChart();
		  }
		}, 50); // Check every 1 second (adjust as needed)
	  };

  componentDidMount() {
		this.checkForClass();
    var currentURL = window.location.pathname;
    var routeName = currentURL.substring(1);
    var hasHeader = document.querySelector("body header") !== null;
    var ganttHeader = document.getElementById("pgc-mainheader");
    if (routeName == "ProjectGanttChart") {
      document.body.style.background =
        "linear-gradient(rgb(0 0 0) 0%, rgb(68 68 68) 30%, rgb(35 35 35) 75%, rgb(0 0 0) 100%)";
      if (hasHeader) {
        var akuMainHeader = document.querySelector(".custom-header-height");
        akuMainHeader.style.display = "none";
        akuMainHeader.classList.remove(".custom-header-height");
      }
    } else {
      document.body.style.background = "white";
    }
	
//mz start
let scrollSpeed = 7; // Adjust the initial scroll speed as needed

function animateScroll(direction, scrollProp) {
let scrollVelocity = scrollSpeed;

const startScroll = document.documentElement[scrollProp];
const targetScroll = (direction === 'up' || direction === 'left') ? startScroll - scrollSpeed : startScroll + scrollSpeed;

function scrollStep(timestamp) {
  const currentScroll = document.documentElement[scrollProp];
  const distance = targetScroll - currentScroll;

  if (Math.abs(distance) <= Math.abs(scrollVelocity)) {
  document.documentElement[scrollProp] = targetScroll;
  return;
  }

  const newScroll = currentScroll + scrollVelocity;
  document.documentElement[scrollProp] = newScroll;

  // Gradually reduce the scroll speed
  scrollVelocity *= 0.2;

  requestAnimationFrame(scrollStep);
}

requestAnimationFrame(scrollStep);
}

let keyDownTimestamp = 0;
let animationFrameId = null;

document.addEventListener('keydown', function (event) {
if (animationFrameId !== null) {
  cancelAnimationFrame(animationFrameId);
}

keyDownTimestamp = performance.now();

switch (event.key) {
  case 'ArrowUp':
  event.preventDefault(); // Prevent default scrolling behavior
  animateScroll('up', 'scrollTop');
  break;
  case 'ArrowDown':
  event.preventDefault(); // Prevent default scrolling behavior
  animateScroll('down', 'scrollTop');
  break;
  case 'ArrowLeft':
  event.preventDefault(); // Prevent default scrolling behavior
  animateScroll('left', 'scrollLeft');
  break;
  case 'ArrowRight':
  event.preventDefault(); // Prevent default scrolling behavior
  animateScroll('right', 'scrollLeft');
  break;
}
});

document.addEventListener('keyup', function () {
const elapsedTime = performance.now() - keyDownTimestamp;

function animateStop() {
  let scrollVelocity = scrollSpeed; // Declare scrollVelocity as a local variable
  if (scrollVelocity > 0) {
  scrollVelocity -= scrollSpeed * (elapsedTime / 1000); // Reduce the scroll velocity based on elapsed time
  requestAnimationFrame(animateStop);
  }
}

animationFrameId = requestAnimationFrame(animateStop);
});
//mz end
    // BYPASS TESTING START
    // this.state.facilityID =  "AKUHN";//passedState.state.facilityID;
    // this.state.facilityName =  "MTISB";//passedState.state.facilityName;
    // this.GetProjectGanttChartData();
    // BYPASS TESTING END

    let passedState = window.history.state;

    if (passedState && passedState.state) {
      this.state.facilityID = passedState.state.facilityID;
      this.state.facilityName = passedState.state.facilityName;
      this.GetProjectGanttChartData();
      window
        .APIRequest(
          appCred,
          "",
          "EHRDashboardManager.CheckUserHasMTISBAccess",
          {
            UserID: this.state.LoggedInUserName,
          }
        )
        .then((apiRes) => {
          if (apiRes.ResponseResult == true) {
            this.setState({
              showHomeButton: apiRes.ResponseResult,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
	
  }

  render() {
    return (
      <>
        {!this.state.isLoading ? (
          <>
            <div onClick={this.hideDropdown}>
              <div className="pgc-mainheader" id="pgc-mainheader">
                <label className="as-on"> As On:</label>
                <label className="as-on-date">{this.state.AsOnDate}</label>
                <label className="mainheader mti-gantt">
                  {" "}
                  {this.state.FacilityName}
                </label>
                <label className="switch theme" id="theme_label">
                  <input
                    type="checkbox"
                    id="theme_type"
                    onClick={this.changeTheme}
                  />
                  <span className="slider round"></span>
                </label>
                <button
                  className="btn-sm mainheader-buttons download"
                  data-id={this.state.GanttMonthsTotalWidth + "px"}
                  id="downloadButton"
                  onClick={this.downloadGanttChart}
                  type="button"
                >
                  Download
                </button>
                <button
                  className="btn-sm mainheader-buttons legends"
                  id="legendButton"
                  onClick={this.legendGanttChart}
                  type="button"
                >
                  Legends
                </button>
                <button
                  className="btn-sm mainheader-buttons zoominbtn"
                  id="zoomoutButton"
                  onClick={this.zoomOut}
                  type="button"
                >
                  Single Page
                </button>
                <button
                  className="btn-sm mainheader-buttons zoominbtn"
                  id="zoominButton"
                  onClick={this.zoomIn}
                  type="button"  style={{ display: 'none' }} 
                >
                  Zoom In
                </button>
               
                <div id="myDropdown" className="dropdown-content">
                  <div className="input-color">
                    <span className="close" onClick={this.closeButton}>
                      &times;
                    </span>
                    <label className="ml-4 mr-1 labelChange">Plan Date</label>
                    <div
                      className="color-box2"
                      style={{ backgroundColor: "#bbbbbb" }}
                    ></div>
                  </div>
                  <div className="input-color ">
                    <label className="ml-4 mr-1 labelChange">
                      Completed On Time
                    </label>
                    <div
                      className="color-box2"
                      style={{ backgroundColor: "#1b9d10" }}
                    ></div>
                  </div>
                  <div className="input-color">
                    <label className="ml-4 mr-1 labelChange">In Progress</label>
                    <div
                      className="color-box2"
                      style={{ backgroundColor: "#06a1d7" }}
                    ></div>
                  </div>
                  <div className="input-color">
                    <label className="ml-4 mr-1 labelChange">In Progress</label>
                    <div
                      className="color-box2"
                      style={{ backgroundColor: "#c79500" }}
                    ></div>
                  </div>
                  <div className="input-color">
                    <label className="ml-4 mr-1 labelChange">
                      Completion Delayed
                    </label>
                    <div
                      className="color-box2"
                      style={{ backgroundColor: "#de140e" }}
                    ></div>
                  </div>
                </div>

                <button
                  className="btn-sm mainheader-buttons back"
                  id="backButton"
                  type="button"
                  onClick={this.redirectHomePage}
                >
                  Back 
                </button>
                {/* <button className="btn-sm downloading" id="downloadingButton" type="button"  >Downloading ...</button> */}
              </div>

              <div className="pgc-bg pgc-bg-color pgc-text-color">
                <div className="gantt-upper-border"> </div>
                {/* <div className="gantt"  style={{width: this.state.GanttMonthsTotalWidth }}> */}
                <div
                  className="gantt" id="gantt"
                  style={{ width: this.state.GanttMonthsTotalWidth }}
                >
                  <div
                    id="gantt__row--months"
                    className="gantt__row gantt__row--months"
                  >
                    <div
                      className="gantt__row-first"
                      style={{
                        borderRight: "2px solid rgb(255 242 242 / 61%)",
                      }}
                    >
                      <label
                        className="pgc-header-activity"
                        style={{ width: "220px", fontSize: "12px" }}
                      >
                        Activity
                      </label>
                      <label
                        className="pgc-header-activity"
                        style={{ width: "40px", fontSize: "12px" }}
                      >
                        Progress
                      </label>
                    </div>
                    {this.state.GanttTimelineDateArray.map((item) => (
                      <span
                        style={{ borderRight: "1px dotted #5d5d5d" }}
                        className={item.CurrentMonthStyleTitle}
                      >
                        {item.MonthName}
                      </span>
                    ))}
                 
                  </div>
                  <div
                    className="gantt__row gantt__row--lines"
                    id="gantt__row--lines"
                  >
                    {/* style={{ "gridTemplateColumns" : "280px repeat(" + this.state.GanttTimelineDateCount + ", 1fr)" }}> */}
                    <label style={{ width: "280px" }}></label>
                    {this.state.GanttTimelineDateArray.map((item, index) => (
                      <span className={item.CurrentMonthStyleBar}></span>
                    ))}
                  </div>
                  <div className="goLiveDiv">
                      <span className="goLiveSpan c" 
                          style={{ marginRight: this.state.GoLiveMTISBLineIndicator }}>
                      </span>
                    </div>
                  {this.state.GanttData.map((item, index) => (
                    <div className={item.MTISBHeaderStyle}>
                      <div
                        className={item.MainActivityHeaderStyle}
                        title={item.ActivityDateCaption}
                      >
                         <label className="pg-entitytag-task">
                          {item.TaskName}
                        </label>
                        {/* Enable to check dates */}
                        {/* <label className="font-sm-custom">
                          {item.FormattedStartDate} - {item.FormattedEndDate}
                        </label>
                        <label className="font-sm-custom">
                          {item.ActualStartDate} - {item.ActualEndDate}
                        </label> */}
                        <label className={item.ProgressIDColor}>{item.Progress}%
                        </label>
                        {item.ResponsibilityList.map((responsibility) => (
                          <label className={responsibility.ResponsibilityColor}>
                            {responsibility.Responsibility}
                          </label>
                        ))}
                      </div>
                      <ul
                        className={item.MainActivityStyle}
                        style={{ visibility: item.ActivityParentColor }}
                      >
                        <li
                          className="greylinebars"
                          style={{
                            width: item.PlannedActivityWidth,
                            marginLeft: item.PlannedActivityMargin,
                          }}
                        ></li>
                        <li
                          style={{
                            width: item.ProgressActivityWidth,
                            marginLeft: item.ProgressActivityMargin,
                          }}
                          className={item.ProgressBarColor}
                        ></li>
                        <li
                          style={{ width: item.ProgressActivityExtendedWidth }}
                          className={item.NinetyProgressBarColor}
                        ></li>
                      </ul>

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="loadingGif"
              style={{ textAlign: "center", color: "#3a99c8", width: "100%" }}
            >
              <img
                src={loadingGif}
                height="100"
                width="100"
                style={{ position: "absolute", top: "40%", left: "47%" }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default ProjectGanttChart;
