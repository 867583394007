import React from "react";
const Input = ({description,name,value,onChange,handleEnter,requiredField, error,type})=> {
    return ( 
        <React.Fragment>
            <div className="col-lg-6">
                <div className="form-group row">
                    <label className={"col-sm-5 text-left col-form-label "+ requiredField}>
                        {description}:{" "}
                    </label>
                    <div className="col-sm-7">
                        <input
                        type={type}
                        name={name}
                        value={value}
                        className="form-control fontsize"
                        onChange={onChange}
                        onKeyDown={handleEnter}
                        autoComplete = "off"
                        />
                        {error ? (
                        <label style={{ fontSize: 10, color: "RED" }}>
                        {error}
                        </label>) : (
                            <></>
                        )}
                    </div>
                </div>    
            </div>
            </React.Fragment>
        
     );
}

export default Input;