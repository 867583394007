import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

const Datepicker = ({
  description,
  name,
  selected,
  onChange,
  handleEnter,
  requiredField,
  error,
}) => {
  const todayDate = moment().toDate();
  return (
    <React.Fragment>
      <div className='col-lg-6'>
        <div className='form-group row'>
          <label
            className={
              "col-sm-5 text-left pt-1 col-form-label " + requiredField
            }
          >
            {description}
          </label>
          <div className='col-sm-7'>
            <DatePicker
              className='pl-2'
              name={name}
              selected={selected}
              onChange={onChange}
              onKeyDown={handleEnter}
              // minDate={todayDate}
              autoComplete='off'
            />
            {error ? (
              <label style={{ fontSize: 10, color: "RED" }}>{error}</label>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Datepicker;
