import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import "../../targetdatemodals.css";
import {
  AppRoleEnum,
  BigInfoIcon,
  BigRedIcon,
  infoIcon,
  redIcon,
} from "../../utils/Constants";
import moment from "moment";
import { ToastsStore } from "react-toasts";
import { UserAccessContext } from "./../UserAccessContext";
import { appCred } from "./../../AppConfig";
import DatePicker from "react-datepicker";

const UpdateTargetDateMoal = ({ modalOpen, data, closeModal, modalFor }) => {
  const userData = useContext(UserAccessContext);
  const [targetDate, setTargetDate] = useState();
  useEffect(() => {
    setTargetDate(moment(data?.ACTTARGETDATE).toDate());
  }, [data?.ACTTARGETDATE]);
//mz adding this
const [baselineDate, setBaselineDate] = useState(null); // Initialize with null

useEffect(() => {
  if (data?.ACTBASELINEDATE) {
    // If ACTBASELINEDATE is not null, convert it to Date
    setBaselineDate(moment(data.ACTBASELINEDATE).toDate());
  } else {
    // If ACTBASELINEDATE is null, set baselineDate to null
    setBaselineDate(null);
  }
}, [data?.ACTBASELINEDATE]);
const [startDate, setStartDate] = useState();
useEffect(() => {
  setStartDate(moment(data?.ACTSTARTDATE).toDate());
}, [data?.ACTSTARTDATE]);
const [endDate, setEndDate] = useState(null); // Initialize with null

useEffect(() => {
  if (data?.ACTCOMPLETIONDATE) {
    // If ACTENDDATE is not null, convert it to Date
    setEndDate(moment(data.ACTCOMPLETIONDATE).toDate());
  } else {
    // If ACTENDDATE is null, set endDate to null
    setEndDate(null);
  }
}, [data?.ACTCOMPLETIONDATE]);
const [ninetyProgressDate, setNinetyProgressDate] = useState(null); // Initialize with null

useEffect(() => {
  if (data?.ACTNINETYPROGDATE) {
    // If ACTNINETYPROGRDATE is not null, convert it to Date
    setNinetyProgressDate(moment(data?.ACTNINETYPROGDATE).toDate());
  } else {
    // If ACTNINETYPROGRESSDATE is null, set ninetyProgressDate to null
    setNinetyProgressDate(null);
  }
}, [data?.ACTNINETYPROGDATE]);


const optionalHandleChangeBaselineDate = (date) => {
  setBaselineDate(date);
};
const optionalHandleChangeStartDate = (date) => {
  setStartDate(date);
};
const optionalHandleChangeEndDate = (date) => {
  setEndDate(date);
};
const optionalHandleChangeNinetyProgressDate = (date) => {

  setNinetyProgressDate(date);
};
//mz stopped adding

  const optionalHandleChange = (date) => {
    setTargetDate(date);
  };

  const saveTargetDate = () => {

    const reqObj = {
      FACILITYID: data.FACILITYID, //Hardcoded for now because of dev value against facility id
      ACTIVITYID: data.ACTIVITYID,
      ACTIVITYTARGETDATE: targetDate == null ? null : moment(targetDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTIVITYBASELINEDATE:baselineDate == null ? null : moment(baselineDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTIVITYSTARTDATE: startDate == null ? null :moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTIVITYENDDATE: endDate == null ? null :moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTIVITYNINETYPROGRESSDATE:ninetyProgressDate == null ? null :moment(ninetyProgressDate).format("YYYY-MM-DDTHH:mm:ss"),
      ActivityFor: "MAINSTREAM",
    };
    console.log(reqObj);
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveFacilityApplicationTargetDate",
        reqObj
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          closeModal();
          ToastsStore.success("TargetDate Saved Successfully");
          userData.refreshData();
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <Modal className="target-modal"
      size='md'
      isOpen={modalFor === "TargetDate" ? modalOpen : false}
      centered={true}
    >
      <div className='maindashboard-custom-modal-header'>
        {/* " + data?.ACTIVITYID + "- */}
        {"[" + data?.DESCRIPTION + "]"}
        <br /> Set Date
        <i
          className='fa fa-times text-warning fa-2x crossbtn'
          style={{ cursor: "pointer" }}
          aria-hidden='true'
          onClick={() => {
            closeModal();
          }}
        ></i>{" "}
      </div>
      <ModalBody className='target-date-modal'>
        <div style={{ width: "500px", height: "39px" }} className='pt-2'>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label">Planned Start Date </label>
    </div>
  </div>
  <div className='col-md-10'>
    <div class="date" style={{ width: "9rem" }}>
      <DatePicker
        description='Date'
        name='Baseline'
        selected={baselineDate == "Invalid Date" ? "" : baselineDate}
       onChange={(date) => {
         optionalHandleChangeBaselineDate(date);
       }}
      />
    </div>
  </div>
            
          </div>
          
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div style={{ width: "9rem" }}>
      <label className="modal-label">Planned End Date </label>
    </div>
  </div>
  <div className='col-md-10'>
    <div class="date" style={{ width: "9rem" }}>
      <DatePicker
        description='Date'
        name='Target'
        selected={targetDate == "Invalid Date" ? "" : targetDate}
        onChange={(date) => {
          optionalHandleChange(date);
        }}
      />
    </div>
  </div>
            
          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div style={{ width: "9rem" }}>
      <label className="modal-label">Actual Start Date </label>
    </div>
  </div>
  <div className='col-md-10'>
    <div class="date" style={{ width: "9rem" }}>
      <DatePicker
        description='Date'
        name='Start'
        selected={startDate == "Invalid Date" ? "" : startDate}
        onChange={(date) => {
          optionalHandleChangeStartDate(date);
        }}
      />
    </div>
  </div>
            
          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div style={{ width: "9rem" }}>
      <label className="modal-label">Actual End Date </label>
    </div>
  </div>
  <div className='col-md-10'>
    <div class="date" style={{ width: "9rem" }}>
      <DatePicker
        description='Date'
        name='End'
        selected={endDate == "Invalid Date" ? "" : endDate}
        onChange={(date) => {
          optionalHandleChangeEndDate(date);
        }}
      />
    </div>
  </div>
            
          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div style={{ width: "9rem" }}>
      <label className="modal-label">Actual 90% Progress Date</label>
    </div>
  </div>
  <div className='col-md-10'>
    <div class="date" style={{ width: "9rem" }}>
      <DatePicker
        description='Date'
        name='NinetyProgress'
        selected={ninetyProgressDate == "Invalid Date" ? "" : ninetyProgressDate}
        onChange={(date) => {
          optionalHandleChangeNinetyProgressDate(date);
        }}
      />
    </div>
  </div>
            
          </div>
          <br></br>
          <Button
              onClick={() => {
                closeModal();
              }}
              className='customModal-savebutton btn-danger' id='closebtn'
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                saveTargetDate();
              }}
              className='customModal-save2button btn-success ' id='savebtn'
            >
              Save
            </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateTargetDateMoal;
