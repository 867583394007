import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
  } from "reactstrap";
import { GenericSelect } from '../Controls/Select';

const WeightageModal = ({modalOpen, data, closeModal, modalFor, saveApplicationWeightage}) => {
    // const intWeightage = data?.WEIGHTAGE
    // console.log("intWeightage",intWeightage)
    const [weightage,setWeightage] =useState("")
    const handleWeightageChange=(e)=>{
        setWeightage(e.target.value)
    }
    useEffect(()=>{
        if(modalOpen && modalFor=== 'weightage') setWeightage(data?.WEIGHTAGE)
    },[data?.WEIGHTAGE])

    return ( 
        <Modal size="md" isOpen={modalFor=== 'weightage' ? modalOpen : false} centered={true}>
           <div className="maindashboard-custom-modal-header">
              {data?.DESCRIPTION}
              <br /> Set Weightage
              <i
                className="fa fa-times text-warning fa-2x"
                style={{ cursor: "pointer" }}
                aria-hidden="true"
                onClick={() => {
                 closeModal()
                }}
              ></i>{" "}
            </div>
            <ModalBody >
              <div>
                <div className="col-lg-12">
                  <div className="form-group row">
                    <label className="col-sm-5 text-right pt-0 col-form-label  custom-leftAlign-Label" style={{marginTop:"3px",fontSize:"14px"}}>
                      Application Weightage:{" "}
                    </label>
                    <div className="col-sm-7">
                        {/* {GenericSelect("NewWeightage",weightage,handleWeightageChange)} */}
                        <select
                        name="weightage"
                        value={weightage}
                        className="form-control custom-individual-dropdown fontsize"
                        onChange={(e) => {
                            handleWeightageChange(e);
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{width:"72px"}}
                color="success"
                onClick={()=>{
                    saveApplicationWeightage(weightage,data.ACTIVITYID,data.FACILITYID)
                }}
              >
                Save
              </Button>
              <Button 
                color="danger"
                onClick={() => {
                    closeModal()
                   }}
              >
                Cancel
              </Button>
          </ModalFooter>
        </Modal>
 
     );
}
 
export default WeightageModal;



       