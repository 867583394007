import React from 'react';
import Input from "../Controls/Input"
import Select from "../Controls/Select"
import Datepicker from "../Controls/DatePicker";
import { inputDictionaryName, 
        inputDictionaryID, 
        selectActive, 
        selectWeightage, 
        inputBuildProgress,
        inputTargetHCIS,
        datePicker,
        displaySequence,
        commentTextArea,
        timePicker  } from "../Controls/ReusableControlsFunction";
import MultiSelect from "../Controls/MultiSelect";      

function InterfaceData({props, pData, pDataRecord, pDataRecorderrors}) {
    return (
    <div>
        <form>
          <div className="row mb-2">
            {inputDictionaryID(props,"Interface ID")}
            {inputDictionaryName(props,"Interface Name")}
          </div>
          <div className="row mb-2">
            {selectActive(props,"custom-leftAlign-Label required-field")}    {/*Active Repeated Function */}
            <Input description ="Interface Belongs To"
                    name="NewInterfaceBelongsto"
                    value= {pDataRecord.NewInterfaceBelongsto}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField = "custom-leftAlign-Label required-field"
                    error = {pDataRecorderrors?.NewInterfaceBelongsto}
                    type="text"
                      />
          </div>
          <div className="row mb-2">
            {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
            {selectWeightage(props,"required-field custom-leftAlign-Label")} {/*Weightage Repeated Function */}  
          </div>
          <div className="row mb-2">
            <Select description="Comm From MT"
                    name="NewCommFromMT"
                    value={pDataRecord.NewCommFromMT}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField=" required-field custom-leftAlign-Label"
                    />
            <Select description="Comm From OV"
                    name="NewCommFromOV"
                    value={pDataRecord.NewCommFromOV}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField="required-field custom-leftAlign-Label"
                    />
          </div>
          <div className="row mb-2 my-auto">
            <Select description="Config into Sandbox"
                    name="NewConfigintoSandbox"
                    value={pDataRecord.NewConfigintoSandbox}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField="required-field custom-leftAlign-Label"
                    />
            <Select description="Config into Test"
                    name="NewConfigintoTest"
                    value={pDataRecord.NewConfigintoTest}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField="required-field custom-leftAlign-Label"
                    />
          </div>
          <div className="row mb-2">
            {datePicker(props,"NewBaselineDate","Baseline Date","required-field custom-leftAlign-Label")} {/*{Repeated Date Control} */}
            {datePicker(props,"NewPlannedDate","Planned Date", "custom-leftAlign-Label")} {/*{Repeated Date Control} */}
          </div>
          <div className="row mb-2">
            {(() =>{
                if(pData.formHeading ==="Edit Record"){
                  return datePicker(props,"NewCompletionDate","Completion Date")
                }
            })()}
            <Input description ="Comm Type"
                  name="NewCommType"
                  value= {pDataRecord.NewCommType}
                  onChange ={(e)=>{props.handleOnChange(e)}}
                  handleEnter ={props.handleEnter}
                  requiredField = "required-field custom-leftAlign-Label"
                  error ={pDataRecorderrors?.NewCommType}
                  type="text"
                  />
          </div>
          <div className="row mb-2 my-auto">
            {displaySequence(props)}
          </div>
          {commentTextArea(props)}
        </form>
      </div>
     );
}

export default InterfaceData;