import React from "react";
import { Button, Modal, ModalBody, Collapse } from "reactstrap";
import {
  AppRoleEnum,
  BigInfoIcon,
  BigRedIcon,
  infoIcon,
  redIcon,
} from "../../utils/Constants";
import moment from "moment";
import { ToastsStore } from "react-toasts";

class CommentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addResoucresIsOpen: false,
    };
  }
  componentDidMount() {
    // window.activatePop()
  }
  componentDidUpdate() {}
  Addresoucres = () => {
    return (
      <div>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='col-sm-5 text-left pt-2 pl-0 col-form-label custom-addresoucrelabel'>
              ID:
            </label>
            <div className='form-group row'>
              <div className='col-sm-11'>
                <input
                  type='text'
                  name='resourceID'
                  className='form-control fontsize'
                  autoComplete='off'
                  value={this.props.parentData.resourceID}
                  onChange={(e) => this.props.handleOnChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='col-sm-5 text-left pt-2 pl-0 col-form-label custom-addresoucrelabel'>
              Name:
            </label>
            <div className='form-group row'>
              <div className='col-sm-11'>
                <input
                  type='text'
                  name='resourceName'
                  className='form-control fontsize'
                  autoComplete='off'
                  value={this.props.parentData.resourceName}
                  onChange={(e) => this.props.handleOnChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='col-sm-5 text-left pl-0 pt-2 col-form-label custom-addresoucrelabel'>
              Role:
            </label>
            <div className='form-group row'>
              <div className='col-sm-11'>
                <select
                  name='resourceRole'
                  value={this.props.parentData.resourceRole}
                  className='form-control custom-individual-dropdown fontsize'
                  onChange={(e) => this.props.handleOnChange(e)}
                >
                  <option value=''> Select</option>
                  <option value='LEAD'>LEAD</option>
                  <option value='CO-LEAD'>CO-LEAD</option>
                  <option value='TECHNO-FUNC'>TECHNO-FUNC</option>
                  <option value='MT-LEAD'>MT-LEAD</option>
                  <option value='MT-CO-LEAD'>MT-CO-LEAD</option>
                </select>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='col-sm-5 pl-0 text-left col-form-label custom-addresoucrelabel'>
              Location:
            </label>
            <div className='form-group row'>
              <div className='col-sm-11'>
                <input
                  type='text'
                  name='resourceLocation'
                  className='form-control fontsize'
                  onChange={(e) => this.props.handleOnChange(e)}
                  value={this.props.parentData.resourceLocation}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <button
            className='btn btn-success mb-2 custom-addresourcebtn custom-addresoucesavebtnmar'
            onClick={() => {
              this.props.addNewResource();
            }}
          >
            Save
          </button>
          <button
            className='btn btn-danger mb-2 custom-addresourcebtn  custom-addresourcecanbtn ml-2 '
            onClick={() => {
              this.setState({ addResoucresIsOpen: false }, () => {
                this.props.clearResourceInput();
              });
            }}
          >
            Cancel
          </button>
        </div>
        {/* <Button className="btn-danger mb-2 custom-addresourcebtnmargin ml-2 float-right" onClick={()=>{this.setState({addResoucresIsOpen : false},()=>{this.props.clearResourceInput()})}}>Cancel</Button>
      <Button className="btn-success mb-2 float-right" onClick={()=>{this.props.addNewResource()}}>Save</Button> */}
      </div>
    );
  };
  Addresoucresbtn = (isOpened) => {
    if (!isOpened) {
      return (
        <i
          className='fa fa-plus text-info fa-2x'
          title='Add Resource'
          style={{ cursor: "pointer", position: "absolute", right: "10%" }}
          onClick={() => {
            this.setState({ addResoucresIsOpen: true });
          }}
        ></i>
      );
    } else {
      return (
        <i
          className='fa fa-minus text-info fa-2x'
          style={{ cursor: "pointer", position: "absolute", right: "10%" }}
          onClick={() => {
            this.setState({ addResoucresIsOpen: false });
          }}
        ></i>
      );
    }
  };
  changeAddResource = (value) => {
    this.setState({
      addResoucresIsOpen: value,
    });
  };
  handleTextChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  render() {
    let resourcesData = this.props.parentData.resourcesData;
    return (
      <Modal
        size='md'
        isOpen={
          this.props.parentData.modalOpen ||
          this.props.parentData.commentsModalOpen
        }
        centered={true}
      >
        <div className='maindashboard-custom-modal-header'>
          {this.props.parentData.modalHeading}
          <br />{" "}
          {this.props.parentData.modalOpen ? "Application Resources" : ""}
          {this.props.parentData.UserOrgType == AppRoleEnum.Admin &&
          this.props.parentData.modalOpen ? (
            this.Addresoucresbtn(this.state.addResoucresIsOpen)
          ) : (
            <></>
          )}
          {this.props.parentData.loggedInUserData &&
          this.props.parentData.UserOrgType == AppRoleEnum.Collaborator ? (
            this.props.parentData.loggedInUserData.APPLICATIONID ===
            this.props.parentData.modalData.APPLICATIONID ? (
              this.Addresoucresbtn(this.state.addResoucresIsOpen)
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <i
            className='fa fa-times text-warning fa-2x'
            style={{ cursor: "pointer" }}
            aria-hidden='true'
            onClick={() => {
              this.props.CommentModalClose();
            }}
          ></i>{" "}
        </div>
        <ModalBody
          style={
            this.props.parentData.modalOpen
              ? { maxHeight: "400px", overflowY: "scroll" }
              : {}
          }
        >
          {}
          {this.props.parentData.modalOpen ? (
            <div>
              <Collapse
                style={{ marginTop: "" }}
                isOpen={this.state.addResoucresIsOpen}
                className='col-lg-12'
              >
                {this.Addresoucres()}
              </Collapse>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='p-1'>Name</th>
                    <th className='p-1'>Role</th>
                    <th className='p-1'>Location</th>
                  </tr>
                </thead>
                {resourcesData && resourcesData.length > 0 ? (
                  <tbody>
                    {resourcesData.map((resourceItem, index) => (
                      <tr key={index}>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCENAME}
                        </td>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCETYPE}
                        </td>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCEREGION}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  "No Data"
                )}
              </table>
            </div>
          ) : (
            <div>
              {(() => {
                if (
                  this.props.parentData.UserOrgType == AppRoleEnum.Admin ||
                  (this.props.parentData.UserOrgType == AppRoleEnum.PMO &&
                    this.props.parentData.modalData.ACTIVITYID == "PMT")
                ) {
                  return (
                    <div>
                      <textarea
                        initialValue
                        type='text'
                        value={this.props.parentData.inputComment}
                        name='inputComment'
                        style={{
                          width: "100%",
                          height: "100px",
                          fontSize: "14px",
                        }}
                        onChange={(e) => {
                          this.props.handleOnChange(e);
                        }}
                      >
                        {this.props.parentData.modalData.COMMENTS}
                      </textarea>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <h5
                            className='label-color'
                            style={{
                              textAlign: "left",
                              fontSize: "10px",
                              marginTop: "5px",
                              marginBottom: "-28px",
                            }}
                          >
                            Commented By
                          </h5>
                          <br />
                          <span
                            className='text-dark'
                            style={{ fontSize: "11px" }}
                          >
                            {this.props.parentData.modalData.COMMENTENTEREDBY
                              ? this.props.parentData.modalData.COMMENTENTEREDBY
                              : "N/A"}
                          </span>
                        </div>
                        <div className='col-lg-6 text-right'>
                          <h5
                            className='label-color'
                            style={{
                              marginTop: "5px",
                              textAlign: "right",
                              fontSize: "10px",
                              marginBottom: "-28px",
                            }}
                          >
                            Last Updated Date/Time
                          </h5>
                          <br />
                          <span
                            className='text-dark'
                            style={{ fontSize: "11px" }}
                          >
                            {this.props.parentData.modalData.COMMENTENTEREDDTTM
                              ? moment(
                                  this.props.parentData.modalData
                                    .COMMENTENTEREDDTTM
                                ).format("MMM DD,YYYY hh:mm:ss")
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ width: "500px", height: "39px" }}
                        className='pt-2'
                      >
                        <div className='row'>
                          <div
                            className='col-lg-2 ml-3'
                            style={{ marginTop: "-6px" }}
                          >
                            <div
                              className={
                                this.props.parentData.commentFlag === "B"
                                  ? "active_simple_bigsquare-comment-blue "
                                  : "simple_bigsquare-comment"
                              }
                              style={infoIcon}
                              onClick={(e) => {
                                this.props.SaveCommentFlag("B");
                              }}
                            ></div>
                          </div>
                          <div
                            className='col-lg-2'
                            style={{ marginTop: "-6px" }}
                          >
                            <div
                              className={
                                this.props.parentData.commentFlag === "R"
                                  ? "active_simple_bigsquare-comment-red "
                                  : "simple_bigsquare-comment"
                              }
                              onClick={(e) => {
                                this.props.SaveCommentFlag("R");
                              }}
                              style={redIcon}
                            ></div>
                          </div>
                          <Button
                            onClick={() => {
                              this.props.CommentModalClose();
                            }}
                            className='customModal-savebutton btn-danger'
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={(e) => {
                              this.props.parentData.inputComment
                                ? this.props.parentData.commentFlag
                                  ? this.props.saveComment()
                                  : this.props.setNullCommentMessage()
                                : this.props.saveComment();
                            }}
                            className='customModal-save2button btn-success'
                          >
                            Save
                          </Button>
                        </div>
                        {this.props.parentData.showNullmessage &&
                        !this.props.parentData.commentFlag ? (
                          ToastsStore.error("Select message type")
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                } else if (
                  this.props.parentData.loggedInUserData &&
                  this.props.parentData.UserOrgType == AppRoleEnum.Collaborator
                ) {
                  if (
                    this.props.parentData.loggedInUserData.APPLICATIONID ===
                    this.props.parentData.modalData.APPLICATIONID
                  ) {
                    return (
                      <div>
                        <textarea
                          type='text'
                          value={this.props.parentData.inputComment}
                          name='inputComment'
                          style={{
                            width: "100%",
                            height: "100px",
                            fontSize: "14px",
                          }}
                          onChange={(e) => {
                            this.props.handleOnChange(e);
                          }}
                        ></textarea>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <h5
                              className='label-color'
                              style={{
                                textAlign: "left",
                                fontSize: "10px",
                                marginTop: "5px",
                                marginBottom: "-28px",
                              }}
                            >
                              Commented By
                            </h5>
                            <br />
                            <span
                              className='text-dark'
                              style={{ fontSize: "11px" }}
                            >
                              {this.props.parentData.modalData.COMMENTENTEREDBY
                                ? this.props.parentData.modalData
                                    .COMMENTENTEREDBY
                                : "N/A"}
                            </span>
                          </div>
                          <div className='col-lg-6 text-right'>
                            <h5
                              className='label-color'
                              style={{
                                marginTop: "5px",
                                textAlign: "right",
                                fontSize: "10px",
                                marginBottom: "-28px",
                              }}
                            >
                              Last Updated Date/Time
                            </h5>
                            <br />
                            <span
                              className='text-dark'
                              style={{ fontSize: "11px" }}
                            >
                              {this.props.parentData.modalData
                                .COMMENTENTEREDDTTM
                                ? moment(
                                    this.props.parentData.modalData
                                      .COMMENTENTEREDDTTM
                                  ).format("MMM DD,YYYY hh:mm:ss")
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{ width: "500px", height: "39px" }}
                          className='pt-2'
                        >
                          <div className='row'>
                            <div
                              className='col-lg-2 ml-3'
                              style={{ marginTop: "-6px" }}
                            >
                              <div
                                className={
                                  this.props.parentData.commentFlag === "B"
                                    ? "active_simple_bigsquare-comment-blue "
                                    : "simple_bigsquare-comment"
                                }
                                style={infoIcon}
                                onClick={(e) => {
                                  this.props.SaveCommentFlag("B");
                                }}
                              ></div>
                            </div>
                            <div
                              className='col-lg-2'
                              style={{ marginTop: "-6px" }}
                            >
                              <div
                                className={
                                  this.props.parentData.commentFlag === "R"
                                    ? "active_simple_bigsquare-comment-red "
                                    : "simple_bigsquare-comment"
                                }
                                onClick={(e) => {
                                  this.props.SaveCommentFlag("R");
                                }}
                                style={redIcon}
                              ></div>
                            </div>

                            <Button
                              onClick={() => {
                                {
                                  this.props.CommentModalClose();
                                }
                              }}
                              className='customModal-savebutton btn-danger'
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={(e) => {
                                this.props.parentData.inputComment
                                  ? this.props.parentData.commentFlag
                                    ? this.props.saveComment()
                                    : this.props.setNullCommentMessage()
                                  : this.props.saveComment();
                              }}
                              className='customModal-save2button btn-success'
                            >
                              Save
                            </Button>
                          </div>
                          {this.props.parentData.showNullmessage &&
                          !this.props.parentData.commentFlag ? (
                            ToastsStore.error("Select message type")
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div>
                      <div
                        className={
                          this.props.parentData.modalData.COMMENTFLAG === "B"
                            ? "bigbluebox row"
                            : "bigredbox row"
                        }
                      >
                        <div
                          className='col-lg-3'
                          style={
                            this.props.parentData.modalData.COMMENTFLAG === "B"
                              ? BigInfoIcon
                              : BigRedIcon
                          }
                        ></div>
                        <div className='col-lg-8' style={{ fontSize: "14px" }}>
                          {this.props.parentData.modalData.COMMENTS}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <h5
                            className='label-color'
                            style={{
                              textAlign: "left",
                              fontSize: "10px",
                              marginTop: "5px",
                              marginBottom: "-28px",
                            }}
                          >
                            Commented By
                          </h5>
                          <br />
                          <span
                            className='text-dark'
                            style={{ fontSize: "11px" }}
                          >
                            {this.props.parentData.modalData.COMMENTENTEREDBY
                              ? this.props.parentData.modalData.COMMENTENTEREDBY
                              : "N/A"}
                          </span>
                        </div>
                        <div className='col-lg-6 text-right'>
                          <h5
                            className='label-color'
                            style={{
                              marginTop: "5px",
                              textAlign: "right",
                              fontSize: "10px",
                              marginBottom: "-28px",
                            }}
                          >
                            Last Updated Date/Time
                          </h5>
                          <br />
                          <span
                            className='text-dark'
                            style={{ fontSize: "11px" }}
                          >
                            {this.props.parentData.modalData.COMMENTENTEREDDTTM
                              ? moment(
                                  this.props.parentData.modalData
                                    .COMMENTENTEREDDTTM
                                ).format("MMM DD,YYYY hh:mm:ss")
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default CommentsModal;
