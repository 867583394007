import React from 'react';
const ValidateFutureStateProcessData = (addRecorderrors, addRecord,pSetErrors) => {
    let errors = addRecorderrors;
    let addRecordData = addRecord;
    let value = true;
    if (!addRecordData.NewGapProgress ||
        addRecordData.NewGapProgress  < 0 ||
         addRecordData.NewGapProgress > 100 
       ) {
         errors.NewGapProgress = "Progress must be in between 0 to 100";
         value = false;
       }
       if (!addRecordData.NewWeightage) {
         errors.NewWeightage = "value cannot be null";
         value = false;
       }
       if (!addRecordData.NewBaselineDate) {
         errors.NewBaselineDate = "date cannot be empty";
         value = false;
       }
       if (!addRecordData.NewFSPID) {
         errors.NewFSPID = "value cannot be null";
         value = false;
       }
       if (!addRecordData.NewDictionaryName) {
         errors.NewDictionaryName = "value cannot be null";
         value = false;
       }
       if (!addRecordData.NewActive || addRecordData.NewActive==="Select") {
         errors.NewActive = "value cannot be null";
         value = false;
       }
    pSetErrors(errors)
            return value;
}
 
export default ValidateFutureStateProcessData;