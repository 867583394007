import React, { useContext } from "react";
import { appCred } from "../AppConfig.js";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import * as Contants from "../utils/Constants";
import InterventionModal from "./modals/InterventionModal";
import CommentModal from "./modals/CommentsModal";
import infoicon from "../Components/assests/Dashboard/Info.png";
import { ToastsStore } from "react-toasts";
import loadingGif from "../Components/assests/Dashboard/Cube-1s-264px.gif";
import { UserAccessContext } from "./UserAccessContext";
import TargetDateModal from "./modals/TargetDateModal";

class EhrApplications extends React.Component {
  static contextType = UserAccessContext;
  constructor(props) {
    super(props);
    this.CommentModalElement = React.createRef();
    let user = window.mylsataku.get("cache2");
    this.state = {
      isOpen: false,
      resourcesData: {},
      modalOpen: false,
      modalData: {},
      modalHeading: "",
      InterventionModalOpen: false,
      TargetDateModalOpen: false,
      commentsModalOpen: false,
      tempActivityData: {},
      UserOrgId: user.User.UserOrganizationId,
      UserOrgType: user.User.UserOrganizationType,
      UserID: window.mylsataku.get("cache3"),
      LoggedInUserName: user.User.UserName,
      inputComment: "",
      commentFlag: "",
      membersList: window.mylsataku.get("cache4"),
      loggedInUserData: {},
      showNullmessage: false,
      mainApplicationWeight: "",
      showWeightageModal: false,
      //Add Resource
      resourceName: "",
      resourceRole: "",
      resourceLocation: "",
      resourceID: "",

      //API request Object
    };
  }
  // componentDidUpdate(){
  //   this.interval = setInterval(
  //     () => {if(window.location.pathname ==="/ApplicationsMain") window.history.pushState({},null,"/")},
  //     3000
  //   ); //3 sec
  // }
  handleModal = (data, ModalFor, LoggedInUserData) => {
    console.log(ModalFor);
     console.log(data);
    let reqData = {
      FacilityID: data.FACILITYID,
      ApplicationID: data.APPLICATIONID,
      ActivityID: data.ACTIVITYID,
    };
    
    if (ModalFor === "Resources") {
      this.setState(
        {
          loggedInUserData: LoggedInUserData,
          modalData: data,
          modalOpen: true,
          commentsModalOpen: this.state.commentsModalOpen
            ? false
            : this.state.commentsModalOpen,
          tempActivityData: data,
          modalHeading:
            "[" +
            data.APPLICATIONID +
            "] " +
            data.mnemonic +
            " - " +
            data.Description,
        },
        () => {
          this.applicatoinResourcesAPI(reqData);
        }
      );
    } else if (ModalFor === "Comments") {
      this.setState({
        loggedInUserData: LoggedInUserData,
        modalData: data,
        inputComment: data.COMMENTS,
        commentsModalOpen: true,
        modalOpen: this.state.modalOpen ? false : this.state.modalOpen,

        modalHeading:
          "[" +
          data.APPLICATIONID +
          "] " +
          data.mnemonic +
          " - " +
          data.Description,
      });
    }
  };
  setIntervention = (color) => {
    let reqData = {
      ACTIVITYFLAG: color,
      FACILITYID: this.state.tempActivityData.FACILITYID,
      APPLICATIONID: this.state.tempActivityData.APPLICATIONID,
      ACTIVITYID: this.state.tempActivityData.ACTIVITYID,
      LASTFILEUSERID: this.state.UserID,
    };
 console.log("reqData");
    console.log(reqData);
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveInterventionColor",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult == true) {
          this.setState(
            {
              InterventionModalOpen: false,
            },
            () => {
              this.props.getfunc();
            }
          );
          ToastsStore.success("Intervention Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setTargetDate = (targetDate,baselineDate,startDate,endDate,ninetyProgressdate) => {
    let reqData = {
      
      TARGETDATE: targetDate == '' ? this.state.tempActivityData.targetDate: moment(targetDate).format("YYYY-MM-DDTHH:mm:ss"),
      BASELINEDATE: baselineDate == '' ? this.state.tempActivityData.baselineDate : moment(baselineDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTUALSTARTDATE: startDate == null ? '' : moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTUALENDDATE: endDate == null ? ''  : moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
      ACTUALNINETYPROGRESSDATE: ninetyProgressdate == null ? ''  : moment(ninetyProgressdate).format("YYYY-MM-DDTHH:mm:ss"),
      // TARGETDATE: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      FACILITYID: this.state.tempActivityData.FACILITYID,
      APPLICATIONID: this.state.tempActivityData.APPLICATIONID,
      ACTIVITYID: this.state.tempActivityData.ACTIVITYID,
      LASTFILEUSERID: this.state.UserID,
      ActivityFor: "MAINSUB",
    };
    console.log("set Req Data MS", reqData);
   
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveFacilityApplicationTargetDate",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult == true) {
          this.setState(
            {
              TargetDateModalOpen: false,
            },
            () => {
              this.props.getfunc();
            }
          );
          ToastsStore.success("TargetDate Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInterventionModal = (e, activityData) => {
    console.log("HERE in handleInterventionModal")
    e.preventDefault();
    this.setState({
      InterventionModalOpen: true,
      modalHeading:
        "[" +
        activityData.APPLICATIONID +
        "] " +
        activityData.mnemonic +
        " - " +
        activityData.Description,
      tempActivityData: activityData,
    });
  };

  handleTargetDateModal = (e, activityData) => {
    e.preventDefault();
    this.setState({
      TargetDateModalOpen: true,
      modalHeading:
        "[" +
        activityData.APPLICATIONID +
        "] " +
        activityData.mnemonic +
        " - " +
        activityData.Description,
      tempActivityData: activityData,
    });
  };

  saveComment = () => {
    let reqData = {
      FACILITYID: this.state.modalData.FACILITYID,
      APPLICATIONID: this.state.modalData.APPLICATIONID,
      ACTIVITYID: this.state.modalData.ACTIVITYID,
      COMMENTS: this.state.inputComment,
      CommentEnteredBy: this.state.LoggedInUserName,
      CommentFlag: this.state.inputComment != "" ? this.state.commentFlag : "",
    };
    window
      .APIRequest(appCred, "", "EHRDashboardManager.SaveComments", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseResult == true) {
          this.setState(
            {
              modalOpen: false,
              commentsModalOpen: false,
              showNullmessage: false,
              commentFlag: "",
            },
            () => {
              this.props.getfunc();
            }
          );
          ToastsStore.success("Comments Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  setNullCommentMessage = () => {
    this.setState({ showNullmessage: true });
  };
  InterventionModalClose = () => {
    this.setState({
      InterventionModalOpen: false,
      resourcesData: {},
      modalHeading: "",
    });
  };

  TargetDateModalClose = () => {
    this.setState({
      TargetDateModalOpen: false,
      resourcesData: {},
      modalHeading: "",
    });
  };
  handleWeightModal = (activityData, e) => {
    e.preventDefault();
    let reqData = {
      FACILITYID: activityData.FACILITYID,
      APPLICATIONID: activityData.APPLICATIONID,
      ACTIVITYID: activityData.ACTIVITYID,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetApplicationWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult) {
          this.setState({
            tempActivityData: activityData,
            mainApplicationWeight: apiRes.ResponseResult[0].Weightage,
            modalHeading:
              "[" +
              activityData.APPLICATIONID +
              "] " +
              activityData.mnemonic +
              " - " +
              activityData.Description,
            showWeightageModal: true,
          });
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  saveApplicationWeightage = () => {
    let reqData = {
      FACILITYID: this.state.tempActivityData.FACILITYID,
      APPLICATIONID: this.state.tempActivityData.APPLICATIONID,
      ACTIVITYID: this.state.tempActivityData.ACTIVITYID,
      WEIGHTAGE: this.state.mainApplicationWeight,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveApplicationWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          this.setState(
            {
              showWeightageModal: false,
              modalHeading: "",
              mainApplicationWeight: "",
              tempActivityData: {},
            },
            this.props.getfunc(),
            () => {
              ToastsStore.success("Saved Successfully");
            }
          );
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  CommentModalClose = () => {
    const cModalState = this.CommentModalElement.current;
    this.setState(
      {
        modalOpen: false,
        commentsModalOpen: false,
        resourcesData: {},
        modalHeading: "",
        showNullmessage: false,
        commentFlag: "",
        tempActivityData: {},
      },
      () => cModalState.changeAddResource(false)
    );
  };
  SaveCommentFlag = (flag) => {
    this.setState({
      commentFlag: flag,
    });
  };
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  applicatoinResourcesAPI = (reqData) => {
    debugger;
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetApplicationResources",
        reqData
      )
      .then((apiRes) => {
        debugger;
        console.log("RES", apiRes);
        if (apiRes.ResponseCode == 200) {
          this.setState(
            {
              resourcesData: apiRes.ResponseResult,
            },
            () => {
              console.log("resourcesData", this.state.resourcesData);
            }
          );
        } else {
          this.setState({
            resourcesData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add New Resource -------
  addNewResource = () => {
    debugger;
    console.log("b");
    const cModalState = this.CommentModalElement.current;
    let reqResourceData = {
      ApplicationID: this.state.tempActivityData.APPLICATIONID,
      ActivityID: this.state.tempActivityData.ACTIVITYID,
      FACILITYID: this.state.tempActivityData.FACILITYID
    };
    let reqData = {
      FACILITYID: this.state.tempActivityData.FACILITYID,
      APPLICATIONID: this.state.tempActivityData.APPLICATIONID,
      ACTIVITYID: this.state.tempActivityData.ACTIVITYID,
      RESOURCENAME: this.state.resourceName,
      RESOURCEREGION: this.state.resourceLocation,
      _ACTIVE: "Y",
      RESOURCEUSERID: this.state.resourceID,
      RESOURCETYPE: this.state.resourceRole,
      LASTFILEUSERID: this.state.UserID,
    };
    console.log("reqData", reqData);
    window
      .APIRequest(appCred, "", "EHRDashboardManager.SaveResources", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          debugger;
          console.log("RES", apiRes);
          this.setState(
            {
              resourceName: "",
              resourceLocation: "",
              resourceID: "",
              resourceRole: "",
            },
            () => this.applicatoinResourcesAPI(reqResourceData),
            cModalState.changeAddResource(false)
          );
          ToastsStore.success("Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  clearResourceInput = () => {
    this.setState({
      resourceName: "",
      resourceLocation: "",
      resourceID: "",
      resourceRole: "",
    });
  };
  // End ------
  doNothing = () => {
    console.log("NOTHING");
  };
  commentButton = (IsUserLeadData, item, smallInfoIcon, commentIconColour) => {
    const _html = (
      <div
        onClick={() => {
          this.handleModal(item, "Comments", IsUserLeadData);
        }}
        className='simple_square mt-1 text-center'
        style={{ paddingLeft: "0px" }}
      >
        <div style={smallInfoIcon}>
          <i className={commentIconColour}></i>
        </div>
      </div>
    );
    if (this.state.UserOrgType == Contants.AppRoleEnum.Collaborator) {
      if (IsUserLeadData) {
        if (IsUserLeadData.APPLICATIONID === item.APPLICATIONID) {
          return _html;
        }
      } else if (item.COMMENTS) {
        return _html;
      }
    } else if (this.state.UserOrgType == Contants.AppRoleEnum.Admin) {
      return _html;
    } else {
      if (item.COMMENTS) {
        return _html;
      }
    }
  };

  render() {
    let DashboardDetails = this.props.data[0]?.DashboardActivities;
    let ApplicationPercentage = this.props.data[1]?.PercentageObject;
    let commentIconColour;
    const hexagonStyleObj = {
      R: {
        hexagoncolor: "redhexagon text-center text-dark ml-2",
        rightColMargin: { marginTop: "54px" },
        TileBackgroundStyle: Contants.RedTileBackgroundStyle,
        Interventioncolor: { color: "#d9534f" },
      },
      G: {
        hexagoncolor: "greenhexagon text-center text-dark ml-2",
        TileBackgroundStyle: Contants.GreenTileBackgroundStyle,
        Interventioncolor: { color: "#85ccb1" },
        rightColMargin: { marginTop: "51px" },
      },
      B: {
        hexagoncolor: "bluehexagon text-center text-dark ml-2",
        TileBackgroundStyle: Contants.BlueTileBackgroundStyle,
        rightColMargin: { marginTop: "51px" },
        Interventioncolor: { color: "#5fbdc6" },
      },
    };
    let intTileStyleObj = {
      hexagoncolor: "",
      TileBackgroundStyle: "",
      rightColMargin: { marginTop: "51px" },
      Interventioncolor: { color: "gray" },
    };
    const tempInterventionColor = intTileStyleObj.Interventioncolor;
    function setTileStyle(item, roundedProgressPercentageValue) {
      const formattedItemDate = moment(item.Targetdate).format("MM/DD/YYYY");
      const dateDiff = moment().startOf("day").diff(formattedItemDate, "days");

      function setStyleWhenActivityFlagNull(val) {
        item.ACTFLAG = val;
        intTileStyleObj = hexagonStyleObj[val];
        intTileStyleObj.Interventioncolor = tempInterventionColor;
      }

      if (item.ACTIVITYFLAG && item.ACTIVITYFLAG != "N") {
        //For those Items where activityflag is not null
        item.ACTFLAG = item.ACTIVITYFLAG;
        intTileStyleObj = hexagonStyleObj[item.ACTIVITYFLAG];
      } else if (dateDiff >= 0) {
        if (roundedProgressPercentageValue >= 90) {
          setStyleWhenActivityFlagNull("G");
        } else if (roundedProgressPercentageValue < 90) {
          setStyleWhenActivityFlagNull("R");
        }
      } else if (dateDiff <= -60) {
        if (roundedProgressPercentageValue >= 75) {
          setStyleWhenActivityFlagNull("G");
        } else if (
          roundedProgressPercentageValue >= 20 &&
          roundedProgressPercentageValue < 75
        ) {
          setStyleWhenActivityFlagNull("B");
        } else if (roundedProgressPercentageValue < 20) {
          setStyleWhenActivityFlagNull("R");
        }
      } else if (dateDiff > -60) {
        if (roundedProgressPercentageValue >= 90) {
          setStyleWhenActivityFlagNull("G");
        } else if (
          roundedProgressPercentageValue >= 30 &&
          roundedProgressPercentageValue < 90
        ) {
          setStyleWhenActivityFlagNull("B");
        } else if (roundedProgressPercentageValue < 30) {
          setStyleWhenActivityFlagNull("R");
        }
      }
      if (item.COMMENTFLAG === "R") {
        commentIconColour = "fa fa-flag text-danger";
        smallInfoIcon = {};
      } else if (item.COMMENTFLAG === "B") {
        commentIconColour = "";
        smallInfoIcon = {
          backgroundImage: `url('${infoicon}')`,
          backgroundSize: "24px 24px",
          height: "25px",
          width: "24px",
          backgroundRepeat: "no-repeat",
        };
      } else {
        commentIconColour = "fa fa-info text-secondary";
        smallInfoIcon = {};
      }
    }
    let smallInfoIcon;
    return (
      <div style={{ overflowX: "hidden" }}>
        <div className='row mb-1 ml-4 mt-2'>
          {DashboardDetails ? (
            DashboardDetails.map((item, index) => {
              const tempUserID = this.state.UserID.replace("MT\\", "");
              let IsUserLeadData = this.state.membersList.find(
                (a) =>
                  a.RESOURCEUSERID === tempUserID &&
                  a.APPLICATIONID === item.APPLICATIONID &&
                  (a.RESOURCETYPE === "LEAD" ||
                    a.RESOURCETYPE === "CO-LEAD" ||
                    a.RESOURCETYPE === "MT-LEAD" ||
                    a.RESOURCETYPE === "MT-COLEAD")
              );
              let itemProgressPercentage = ApplicationPercentage
                ? ApplicationPercentage.find(
                    (a) => a.ApplicationID == item.APPLICATIONID
                  )
                : 0;
              let roundedProgressPercentageValue = itemProgressPercentage
                ? Math.round(itemProgressPercentage.progressPercentage)
                : 0;

              setTileStyle(item, roundedProgressPercentageValue); //Tile Styling Function
              return (
                <div
                  className='col-2 card custom-Shadow mr-4 mb-3 custom-border TileImage'
                  style={intTileStyleObj.TileBackgroundStyle}
                  key={index}
                >
                  <div className='row'>
                    <div className='col-10 p-0'>
                      <Link
                        to={{
                          pathname: "/ApplicationDetailedView",
                          state: {
                            resourceType: IsUserLeadData,
                            userAccessData: this.context.userData,
                            item: item,
                            progress: itemProgressPercentage,
                            hexagoncolor: intTileStyleObj.hexagoncolor,
                          },
                        }}
                        key={index}
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        <div className='row'>
                          <div className='col-lg-10'>
                            <h3
                              className='tracking-in-expand'
                              style={{
                                color: "#6c757d",
                                marginTop: "-3px",
                                marginBottom: 0,
                                textAlign: "left",
                                fontSize: "11px",
                                paddingLeft: 10,
                                paddingTop: 12,
                              }}
                            >
                              [{item.APPLICATIONID}] {item.mnemonic}
                              {/* [MT-01] HIM */}
                            </h3>
                          </div>
                          {itemProgressPercentage ? (
                            <div className='col-lg-2'>
                              <span
                                className={
                                  itemProgressPercentage.CalculatedPlannedDateDiff
                                    ? itemProgressPercentage.CalculatedPlannedDateDiff ===
                                      "B"
                                      ? "circle red ml-3"
                                      : "dot ml-3 mt-2"
                                    : ""
                                }
                              ></span>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div
                            className='col-lg-12'
                            style={{ paddingRight: "5px" }}
                          >
                            <h3
                              className='text-dark tracking-in-expand'
                              style={{
                                //color: "gray",
                                marginTop: "0px",
                                marginBottom: 0,
                                textAlign: "left",
                                fontSize: "13px",
                                paddingLeft: 10,
                                paddingTop: 3,
                                height: "36px",
                              }}
                            >
                              {item.Description}
                              {/* Health Information Management */}
                            </h3>
                          </div>
                          <div className='mt-1 col-lg-12 row'>
                            {window.mylsataku.get("cache5")
                              .selectedDashboardActivityValue != "UT" ? (
                              <div
                                style={Contants.WeightageIcon}
                                className='ml-4 col-lg-2'
                                onClick={(e) => {
                                  this.state.UserOrgType ==
                                  Contants.AppRoleEnum.Admin
                                    ? this.handleWeightModal(item, e)
                                    : this.doNothing();
                                }}
                              >
                                &nbsp;
                                <span
                                  style={{
                                    fontSize: "15px",
                                    position: "absolute",
                                    bottom: "-4px",
                                    color: "#c2c4c6",
                                  }}
                                >
                                  {item.Weightage}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className='center'>
                              <div className={intTileStyleObj.hexagoncolor}>
                                <span style={{ fontSize: "20px" }}>
                                  <b>
                                    {itemProgressPercentage
                                      ? Math.round(
                                          itemProgressPercentage.progressPercentage
                                        ) + "%"
                                      : "0%"}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          style={{
                            border: 0,
                            height: 1,
                            marginBottom: "5px",
                            marginTop: "6px",
                            backgroundImage:
                              "linear-gradient(to right, rgba(0, 0, 0, 0), rgb(17 16 16), rgba(0, 0, 0, 0))",
                          }}
                        />
                        <div
                          className=' row m-0 p-0'
                          style={{ lineHeight: "1", height: "25px" }}
                        >
                          {(() => {
                            if (
                              this.state.UserOrgType !=
                              Contants.AppRoleEnum.Admin
                              //   &&
                              // item.ACTIVITYFLAG &&
                              // item.ACTIVITYFLAG != "N"
                            ) {
                              return (
                                <div
                                  className='col-lg-6 mt-0'
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <h5
                                    className='label-color tracking-in-expand'
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: 0,
                                      textAlign: "left",
                                      fontSize: "10px",
                                      position: "absolute",
                                      bottom: "0",
                                    }}
                                  >
                                    <i className='mr-1'></i>
                                    Target Date
                                    <br />
                                    <span
                                      className='ml-1 text-dark '
                                      style={{ fontSize: "11px" }}
                                    >
                                      {item.Targetdate
                                        ? moment(item.Targetdate).format(
                                            "MMM DD, YYYY"
                                          )
                                        : "N/A"}
                                    </span>
                                  </h5>
                                </div>
                              );
                            } else if (
                              this.state.UserOrgType ===
                              Contants.AppRoleEnum.Admin
                            ) {
                              return (
                                <div
                                  className='col-lg-6 mt-0'
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <h5
                                    onClick={(e) => {
                                      {
                                        this.state.UserOrgType ===
                                        Contants.AppRoleEnum.Admin
                                          ? this.handleTargetDateModal(e, item)
                                          : this.doNothing();
                                      }
                                    }}
                                    className='label-color tracking-in-expand'
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: 0,
                                      textAlign: "left",
                                      fontSize: "10px",
                                      position: "absolute",
                                      bottom: "0",
                                    }}
                                  >
                                    <i className='mr-1'></i>
                                    Target Date
                                    <br />
                                    <span
                                      className='ml-1 text-dark '
                                      style={{ fontSize: "11px" }}
                                    >
                                      {item.Targetdate
                                        ? moment(item.Targetdate).format(
                                            "MMM DD, YYYY"
                                          )
                                        : "N/A"}
                                    </span>
                                  </h5>
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          })()}

                          <div
                            className='col-lg-6 mt-0'
                            style={{ paddingLeft: "8px" }}
                          >
                            <h5
                              className='label-color tracking-in-expand'
                              style={{
                                marginTop: "0px",
                                marginBottom: 0,
                                textAlign: "right",
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "0",
                                right: "0",
                              }}
                            >
                              <i className='mr-1'></i>
                              Last Modified
                              <br />
                              <span
                                className='ml-1 text-dark '
                                style={{ fontSize: "11px" }}
                              >
                                {item.LastModifiedDttm
                                  ? moment(item.LastModifiedDttm).fromNow()
                                  : "No Information"}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className='col-2 icon-col-pad'>
                      <div style={intTileStyleObj.rightColMargin}>
                        {/* Resources Box */}
                        <div
                          title='Application Team'
                          className='simple_square'
                          onClick={(e) => {
                            this.handleModal(item, "Resources", IsUserLeadData);
                          }}
                        >
                          <a>
                            <i className='fa fa-users text-secondary'></i>
                          </a>
                        </div>
                      </div>
                      {/* Comment Box */}
                      {this.commentButton(
                        IsUserLeadData,
                        item,
                        smallInfoIcon,
                        commentIconColour
                      )}

                      {/* Intervention Box */}
                      {(() => {
                        if (
                          this.state.UserOrgType !=
                            Contants.AppRoleEnum.Admin &&
                          item.ACTIVITYFLAG &&
                          item.ACTIVITYFLAG != "N"
                        ) {
                          return (
                            <div
                              className='simple_square mt-1 text-center'
                              style={{ paddingLeft: "0px" }}
                            >
                              <i
                                className='fa fa-pencil-square-o'
                                style={intTileStyleObj.Interventioncolor}
                                aria-hidden='true'
                              ></i>
                            </div>
                          );
                        } else if (
                          this.state.UserOrgType === Contants.AppRoleEnum.Admin
                        ) {
                          return (
                            <div
                              className='simple_square mt-1 text-center'
                              onClick={(e) => {
                                {
                                  this.state.UserOrgType ===
                                  Contants.AppRoleEnum.Admin
                                    ? this.handleInterventionModal(e, item)
                                    : this.doNothing();
                                }
                              }}
                              style={{ paddingLeft: "0px" }}
                            >
                              <i
                                className='fa fa-pencil-square-o'
                                style={intTileStyleObj.Interventioncolor}
                                aria-hidden='true'
                              ></i>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className='loader mt-4'
              style={{ textAlign: "center", color: "#3a99c8", width: "100%" }}
            >
              <img
                src={loadingGif}
                height='100'
                width='100'
                style={{ position: "absolute", top: "50%", left: "47%" }}
              />
            </div>
          )}
        </div>
        <Modal // Application Weightage
          isOpen={this.state.showWeightageModal}
          size='md'
          centered={true}
        >
          <div className='maindashboard-custom-modal-header'>
            {this.state.modalHeading}
            <br />
            <i
              className='fa fa-times text-warning fa-2x'
              style={{ cursor: "pointer" }}
              aria-hidden='true'
              onClick={() => {
                this.setState({ showWeightageModal: false, modalHeading: "" });
              }}
            ></i>{" "}
          </div>
          <ModalBody>
            <div>
              <div className='col-lg-12'>
                <div className='form-group row'>
                  <label
                    className='col-sm-5 text-right pt-0 col-form-label  custom-leftAlign-Label'
                    style={{ marginTop: "3px", fontSize: "14px" }}
                  >
                    Application Weightage:{" "}
                  </label>
                  <div className='col-sm-7'>
                    <select
                      name='mainApplicationWeight'
                      value={this.state.mainApplicationWeight}
                      className='form-control custom-individual-dropdown fontsize'
                      onChange={(e) => {
                        this.handleOnChange(e);
                      }}
                      onKeyDown={this.handleEnter}
                    >
                      <option value=''> Select </option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "72px" }}
              color='success'
              onClick={() => {
                this.saveApplicationWeightage();
              }}
            >
              Save
            </Button>
            <Button
              color='danger'
              onClick={() => {
                this.setState({ showWeightageModal: false, modalHeading: "" });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <CommentModal //Comment and Resources Modal
          ref={this.CommentModalElement}
          parentData={this.state}
          handleOnChange={this.handleOnChange}
          addNewResource={this.addNewResource}
          clearResourceInput={this.clearResourceInput}
          CommentModalClose={this.CommentModalClose}
          handleCommentModalTextOnChange={this.handleCommentModalTextOnChange}
          SaveCommentFlag={this.SaveCommentFlag}
          saveComment={this.saveComment}
          setNullCommentMessage={this.setNullCommentMessage}
        />
        <InterventionModal
          parentData={this.state}
          InterventionModalClose={this.InterventionModalClose}
          setIntervention={this.setIntervention}
        />
        <TargetDateModal
          parentData={this.state}
          TargetDateModalClose={this.TargetDateModalClose}
          setTargetDate={this.setTargetDate}
        />
      </div>
    );
  }
}
export default EhrApplications;
