import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  Collapse,
} from "reactstrap";
import moment from "moment";
import { _activityName } from "../../utils/Constants";
class RecordHistoryModal extends React.Component{
    constructor(props) {
        super(props);
      }
      componentDidUpdate(){
        window.activatePopover() 
      }
      renderBuildState=(status)=>{
        if(status === "Not Required"){
            return(
              <h6><span className="badge badge-warning p-1 ml-3">{status}</span></h6>
            )
          }
        else if(status === "Completed" ){
            return(
              <h6><span className="badge badge-success ml-3">{status}</span></h6>
            )
          }
        else if(status === "Outstanding"){
        return(
            <h6><span className="badge badge-danger ml-3">{status}</span></h6>
        )
        }
        else if(status === "In-Progress"){
            return(
                <h6><span className="badge badge-primary ml-3">{status}</span></h6>
            )
            }
      }
      renderTestCase=(value)=>{
        let itemarr 
        if(value){
          itemarr = value.split('|')
          let item =[]
          for(let i=0 ; i<itemarr.length; i++){
              if(itemarr[i] =="Passed"){
                item.push(<span className="badge badge-success mr-2 mb-1 pt-1" style={{fontSize:"12px",width: "53px"}}>
                          {itemarr[i]}</span>)
              }
              else if(itemarr[i] =="Failed"){
                item.push(<span className="badge badge-danger mr-2 mb-1 pt-1" style={{fontSize:"12px",width: "53px"}}>
                          {itemarr[i]}</span> )
              }
            }
            return(<div style={{width:"260px"}}>{item}</div>) 
          }    
      }
      renderParticipantName = (item)=>{
        let arr = item
        if(arr){
          return <span className="custom-participantsname">{JSON.parse(arr).map(({ text }) => text).join(', ')}</span>
        }
        else{
          return <></>
        }
      }
    render(){
        let data = this.props.ParentData
        let history = this.props.historyApiRes.ObjMain
        let subActivity = this.props.tableHeading
        let colStyle= {position: "sticky",
                        top: "0px",
                        background: "#dce8ff",
                        zIndex:1,
                        minWidth: "250px"}
        // let activityData = this.props.activityData
        let theadstickyclass={position: "sticky",
        top: "0px",
        background: "#dce8ff",
        zIndex:1,
        };
        return(
            <Modal size="lg" isOpen={this.props.showModal} centered={true}>
                <div>
                    <div className="row mt-2 mr-2 ml-2">
                        <div className="col-lg-11">
                            <>Record Change Log</>
                        </div>
                        <div className="col-lg-1">
                                <i
                                className="fa fa-times text-dark ml-4"
                                style={{ cursor: "pointer" }}
                                aria-hidden="true"
                                onClick={() => {
                                this.props.recordHistoryModalClose()
                                }}
                                ></i>
                        </div>
                    </div>
                    
                    <div className="row ml-2">
                        <div className="col-lg-10">
                            <h6>
                                {(()=>{
                                    if(subActivity===_activityName.Reportwriting){
                                        return(
                                            <>{data.REPORTID ? data.REPORTID.value : ""} - </>   
                                        )   
                                    }
                                    else if(subActivity ===_activityName.Testing || 
                                            subActivity ===_activityName.IntTesting ||
                                            subActivity ===_activityName.ParaTesting ){
                                        return(
                                            <>{data.TESTCASEID ? data.TESTCASEID.value :""} - </>
                                        )
                                    }
                                    else{
                                        return(
                                            <>{data.DICTIONARYID ? data.DICTIONARYID.value : ""} - </>
                                        )
                                    }
                                })()}
                                {data.DESCRIPTION ? data.DESCRIPTION.value.length >= 75 ?
                                    (data.DESCRIPTION.value).toString().substring(0,75)+"..." : 
                                        data.DESCRIPTION.value :""}
                            </h6>
                        </div>
                    </div>
                    <div className="row ml-4">
                        {(()=>{
                            if(subActivity===_activityName.Testing || 
                                subActivity===_activityName.Reportwriting ||
                                subActivity===_activityName.Gaprequirement ||  
                                subActivity===_activityName.Projectmgmnt ||
                                subActivity ===_activityName.IntTesting ||
                                subActivity ===_activityName.ParaTesting ){
                                return(
                                    <label style={{fontSize: "12px", fontWeight:"200"}}>
                                        {data.STATUS ? data.STATUS.value : <></>} &nbsp;&nbsp;
                                        {data.PROGRESS ? data.PROGRESS.value +"%" : <></>}
                                    </label>
                                )
                            }
                            else{
                                return(
                                    <label style={{fontSize: "12px", fontWeight:"200"}}>
                                        {data.BUILDSTATUS ? data.BUILDSTATUS.value : <></>} &nbsp;&nbsp;
                                        {data.BUILDPROGRESS ? data.BUILDPROGRESS.value +"%" : <></>}
                                    </label>
                                )
                            }
                        })()}
                    </div>
                        <div
                        className="col-lg-12"
                        style={{ overflow: "scroll",maxHeight:"420px" }}
                        >
                    {(()=>{
                        if(subActivity===_activityName.Dictionary || subActivity===_activityName.Instrument ||
                            subActivity===_activityName.Documents || subActivity===_activityName.Careplans  ||
                            subActivity===_activityName.Ordersets || subActivity===_activityName.Interface ||
                            subActivity===_activityName.Gaprequirement || subActivity===_activityName.Testing || 
                            subActivity===_activityName.Projectmgmnt || subActivity===_activityName.Reportwriting ||
                            subActivity===_activityName.Datamigration || subActivity===_activityName.Sutraining ||
                            subActivity===_activityName.Euttraining || subActivity ===_activityName.IntTesting || 
                            subActivity ===_activityName.ParaTesting || subActivity===_activityName.DictTrain){
                            return(
                                <table className="table table-bordered table-striped" >
                                    <thead>
                                        <tr>
                                            <th style={theadstickyclass} className='custom-history-Sno'>
                                                Txn #
                                            </th>
                                            {(()=>{
                                                if(subActivity !=_activityName.Sutraining && subActivity!=_activityName.Euttraining &&
                                                    subActivity !=_activityName.DictTrain){
                                                    return(
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Planned Date
                                                        </th>
                                                    )
                                                }
                                            })()}
                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                Progress
                                            </th>
                                            <th style={colStyle} className="custom-history-hdr">
                                                Comment
                                            </th>
                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                Status
                                            </th>
                                            {(()=>{
                                                if(subActivity !=_activityName.Sutraining && subActivity!=_activityName.Euttraining
                                                    && subActivity !=_activityName.DictTrain){
                                                    return(
                                                        <th style={theadstickyclass} className="custom-history-hdr">
                                                            Baseline Date
                                                        </th>
                                                    )
                                                }
                                            })()}
                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                Weightage
                                            </th>
                                            {(()=>{
                                                if(subActivity===_activityName.Dictionary){
                                                    return(
                                                        <>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Standard Content
                                                        </th>
                                                        <th style={theadstickyclass} className="custom-history-hdr">
                                                            Global/Local
                                                        </th>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Documents){
                                                    return(
                                                        <>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            REV By Champions
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            REV By Clinical Users
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Build Into Standards   
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            SIGNOFF Record  
                                                        </th>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Instrument){
                                                    return(
                                                    <>
                                                    <th style={colStyle} className="custom-history-hdr">
                                                        INS Location
                                                    </th>
                                                    <th style={colStyle} className="custom-history-hdr">
                                                        Inst Configure
                                                    </th>
                                                    <th style={theadstickyclass} className="custom-history-hdr">
                                                        Lab Section
                                                    </th>
                                                    <th style={colStyle} className="custom-history-hdr">
                                                        Lantronix Configured
                                                    </th>
                                                    <th style={colStyle} className="custom-history-hdr">
                                                        LIS CONN Established
                                                    </th>
                                                    </>)
                                                }
                                                else if(subActivity===_activityName.CarePlan){
                                                    return(
                                                        <>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                        CAREPLAN Decision
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                        REV By Champions
                                                        </th>
                                                        </>
                                                    )

                                                }
                                                else if(subActivity===_activityName.Interface){
                                                    return(
                                                        <>
                                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                                COMMFROMMT
                                                            </th>
                                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                                COMMFROMOV
                                                            </th>
                                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                                COMMTYPE
                                                            </th>
                                                            <th style={colStyle} className="custom-history-hdr">
                                                                CONFIGINTOSANDBOX
                                                            </th>
                                                            <th style={colStyle} className="custom-history-hdr">
                                                                CONFIGINTOTEST
                                                            </th>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Gaprequirement){
                                                    return(
                                                        <>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Dtls. Shared with MT
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Dtls. Shared Date
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            WalkThru Done
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            WalkThru OutCome
                                                        </th>
                                                        <th style={theadstickyclass} className="custom-history-hdr">
                                                            MT Response
                                                        </th>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Testing || 
                                                    subActivity ===_activityName.IntTesting ||
                                                    subActivity ===_activityName.ParaTesting){
                                                    return(
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Testcase Result
                                                        </th>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Sutraining || subActivity===_activityName.Euttraining ||
                                                        subActivity ===_activityName.DictTrain){
                                                    return(
                                                        <>
                                                        <th  style={theadstickyclass} className="custom-history-hdr">
                                                            Actual Date
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Act Start Time
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Act End Time
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Act Participants
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Act Participants Name
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Act Venue
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Scheduled Date
                                                        </th>
                                                        <th  style={theadstickyclass} className="custom-history-hdr">
                                                            Sch Start Time
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Sch End Time
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Sch Participants
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Sch Participants Name
                                                        </th>
                                                        <th  style={colStyle} className="custom-history-hdr">
                                                            Sch Venue
                                                        </th>
                                                        <th style={colStyle} className="custom-history-hdr">
                                                            Training Instructors
                                                        </th>
                                                        </>
                                                    )
                                                }
                                                
                                            })()}
                                            <th style={theadstickyclass} className="custom-history-hdr">
                                                Changed By
                                            </th>
                                            <th style={colStyle} className="custom-history-hdr">
                                                Changed DateTime
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {history ? history.map((item,index)=>(
                                        <tr key={index}>
                                            <td>
                                            {history.length-index}
                                            </td>
                                            {(()=>{
                                                if(subActivity !=_activityName.Sutraining && subActivity!=_activityName.Euttraining &&
                                                    subActivity !=_activityName.DictTrain){
                                                    return(
                                                        <td>
                                                        {item.PLANNEDDATE
                                                        ? moment(item.PLANNEDDATE).format(
                                                            "MMM DD, YYYY"
                                                        )
                                                        : ""}
                                                    </td>
                                                    )
                                                }
                                            })()}
                                            <td>
                                                {item.BUILDPROGRESS ? parseInt(item.BUILDPROGRESS) + "%": item.PROGRESS ? parseInt(item.PROGRESS)+ "%": "0%"}
                                            </td>
                                            <td
                                            data-toggle={item.COMMENTS && item.COMMENTS.length >34 ? "tooltip":""} 
                                            data-placement="left" 
                                            data-html="true"
                                            title=""  data-original-title={item.COMMENTS}
                                            >
                                                {item.COMMENTS ? 
                                                    item.COMMENTS.length >34 ?
                                                        item.COMMENTS.toString().substring(0,34)+"..." : 
                                                        item.COMMENTS
                                                    :""}
                                            </td>
                                            <td>
                                                {item.BUILDSTATUS ? item.BUILDSTATUS : item.STATUS}
                                            </td>
                                            {(()=>{
                                                if(subActivity !=_activityName.Sutraining && 
                                                    subActivity!=_activityName.Euttraining &&
                                                    subActivity !=_activityName.DictTrain){
                                                    return(
                                                        <td>
                                                            {item.BASELINEDATE
                                                            ? moment(item.BASELINEDATE).format(
                                                                "MMM DD, YYYY"
                                                            )
                                                            : ""}
                                                        </td>
                                                    )
                                                }
                                            })()}
                                            <td>
                                                {item.WEIGHTAGE}
                                            </td>
                                            {(()=>{
                                                if(subActivity===_activityName.Dictionary){
                                                    return(<>
                                                    <td>
                                                        {item.STANDARDCONTENTAVAILABLE}
                                                    </td>
                                                    <td>
                                                        {item.DICTIONARYNATURE}
                                                    </td>
                                                    </>)
                                                }
                                                else if(subActivity==_activityName.Documents){
                                                    return(
                                                        <>
                                                            <td>
                                                                {item.REVBYCHAMPIONS}
                                                            </td>
                                                            <td>
                                                                {item.REVBYCLINICALUSERS}                                                                
                                                            </td>
                                                            <td>
                                                                {item.BUILDINTOSANDBOX}
                                                            </td>
                                                            <td>
                                                                {item.SIGNEDOFFRECD}
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.CarePlan){
                                                    return(
                                                        <>
                                                        <td>
                                                            {item.CAREPLANDECISION}
                                                        </td>
                                                        <td>
                                                            {item.REVBYCHAMPIONS}
                                                        </td>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Instrument){
                                                    return(
                                                    <>
                                                        <td>
                                                            {item.INSLOCATION}
                                                        </td>
                                                        <td>
                                                            {item.INSTRUMENTCONFIGURED}    
                                                        </td>
                                                        <td>
                                                            {item.LABSECTION}    
                                                        </td>
                                                        <td>
                                                            {item.LANTRONIXCONFIGURED}    
                                                        </td>
                                                        <td>
                                                            {item.LISCONNESTABLISHED}    
                                                        </td>
                                                    </>)
                                                }
                                                else if(subActivity===_activityName.Interface){
                                                    return(
                                                        <>
                                                        <td>
                                                            {item.COMMFROMMT}
                                                        </td>
                                                        <td>
                                                            {item.COMMFROMOV}
                                                        </td>
                                                        <td>
                                                            {item.COMMTYPE}
                                                        </td>
                                                        <td>
                                                            {item.CONFIGINTOSANDBOX}
                                                        </td>
                                                        <td>
                                                            {item.CONFIGINTOTEST}
                                                        </td>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Gaprequirement){
                                                    return(
                                                        <>
                                                        <td>
                                                            {item.DETAILSSHAREDWITHMT}
                                                        </td>
                                                        <td>
                                                        {item.DETAILSSHAREDWITHMTDT
                                                        ? moment(item.DETAILSSHAREDWITHMTDT).format(
                                                            "MMM DD, YYYY"
                                                        )
                                                        : ""}
                                                        </td>
                                                        <td>
                                                            {item.GAPWALKTHRUDONE}
                                                        </td>
                                                        <td>
                                                            {item.GAPWALKTHRUOUTCOME}
                                                        </td>
                                                        <td>
                                                            {item.MTRESPONSE}
                                                        </td>
                                                        </>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Testing ||
                                                    subActivity ===_activityName.IntTesting ||
                                                    subActivity ===_activityName.ParaTesting){
                                                    return(
                                                        <td>
                                                            {this.renderTestCase(item.TESTCASERESULT)}
                                                        </td>
                                                    )
                                                }
                                                else if(subActivity===_activityName.Sutraining || subActivity===_activityName.Euttraining ||
                                                        subActivity ===_activityName.DictTrain){
                                                    return(
                                                        <>
                                                        <td>
                                                            {item.ACTDATE
                                                            ? moment(item.ACTDATE).format(
                                                            "MMM DD, YYYY"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.ACTSTARTTIME
                                                            ? moment(item.ACTSTARTTIME).format(
                                                                "hh:mm A"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.ACTENDTIME
                                                            ? moment(item.ACTENDTIME).format(
                                                                "hh:mm A"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.ACTPARTICIPANTS}
                                                        </td>
                                                        <td>
                                                            {this.renderParticipantName(item.ACTPARTICIPANTNAMES)}
                                                        </td>
                                                        <td>
                                                            {item.ACTVENUE}
                                                        </td>
                                                        <td>
                                                            {item.SCHDATE
                                                            ? moment(item.SCHDATE).format(
                                                            "MMM DD, YYYY"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.SCHDATE
                                                            ? moment(item.SCHSTARTTIME).format(
                                                                "hh:mm A"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.SCHENDTIME
                                                            ? moment(item.SCHENDTIME).format(
                                                                "hh:mm A"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            {item.SCHPARTICIPANTS
                                                            ? moment(item.SCHPARTICIPANTS).format(
                                                                "hh:mm A"
                                                            )
                                                            : ""}
                                                        </td>
                                                        <td>
                                                        {this.renderParticipantName(item.SCHPARTICIPANTNAMES)}
                                                        </td>
                                                        <td>
                                                            {item.SCHVENUE}
                                                        </td>
                                                        <td>
                                                            {item.TRAININGINSTRUCTORS}  
                                                        </td>
                                                        </>
                                                    )
                                                }
                                            })()}
                                            <td>
                                                {item.LASTFILEUSERID}
                                            </td>
                                            <td>
                                                {item.LASTFILEDTTM
                                                ? moment(item.LASTFILEDTTM).format(
                                                "MMM DD, YYYY hh:mm:ss"
                                                )
                                                : ""}
                                            </td>
                                        </tr>
                                    ))
                                    :<></>}
                                    </tbody> 
                                </table>
                            )
                        }
                        else{
                            return(
                                <></>
                            )
                        }
                        })()}
                        
                    </div>
            
                </div>
                </Modal>
        )
    }
       
}

export default RecordHistoryModal;