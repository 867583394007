import { localeData } from 'moment';
import React from 'react';
import {
    Modal,
    ModalBody,
  } from "reactstrap";
const ResourcesModal = ({modalOpen, data, closeModal, modalFor}) => {
    return ( 
        <Modal size="md" isOpen={modalFor=== 'resources' ? modalOpen : false} centered={true}>
          <div className="row justify-content-between mt-2" style={{height: "24px"}}>
            <div className="col-6 ml-3">
            <p><strong> {data?.DESCRIPTION}</strong></p>
            </div>
            <div className="col-2 mr-2 text-right">
            <i
                className="fa fa-times text-warning fa-2x"
                style={{ cursor: "pointer" }}
                aria-hidden="true"
                onClick={() => {
                 closeModal()
                }}
              ></i>{" "}
            </div>
          </div>
          <span className="ml-3 fontsize">Responsible Persons: </span>
            <ModalBody >
              <div>
                <h6>{data?.ACTLEADNAME}</h6>
                <h6>{data?.ACTCOLEADNAME}</h6>
              </div>
           </ModalBody>
        </Modal>
     );
}
 
export default ResourcesModal;