import { badgeStyle } from "./Functions";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AppRoleEnum } from "../../utils/Constants";
import { UserAccessContext } from "../UserAccessContext";
import BannerInterventionModal from "../ProjectStatusBoardModals/BannerInterventionModal";

const IndividualActivityHeader = ({
  ActivityData,
  TDateNProgress,
  Description,
  handleModal,
}) => {
  const getUserContext = useContext(UserAccessContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalFor, setModalFor] = useState("");
  const [indvdlCard, setindvdlCard] = useState();
 

  const openModal = (e, item, modalType) => {
    
    e.preventDefault();
    e.stopPropagation();
    setModalFor(modalType);
    setindvdlCard(item);

    if (modalType === "intervention") setModalOpen(true);
    else setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalFor("");
  };
  let userData = window.mylsataku.get("cache2");
  const adminRole = userData.User.UserOrganizationType;
  
  return (
    <div className="row pl-1 pb-1 ">
      <div className="col-lg-10 pl-0">
        <div>
          {" "}
          &nbsp;&nbsp;&nbsp; Application: &nbsp;{" "}
          <b>
            [{ActivityData?.APPLICATIONID}]&nbsp;
            {ActivityData?.Description}
          </b>
          &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Dashboard Activity: &nbsp;{" "}
          <b>{Description}</b> &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Target
          Date: &nbsp;{" "}
          <b>
            {TDateNProgress
              ? moment(TDateNProgress.TargetDate).format("MMMM DD, YYYY")
              : ""}
          </b>{" "}
          &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp; Progress: &nbsp;{" "}
          <b>
            <span
              onClick={(e) => {
                if (adminRole === AppRoleEnum.Admin) {
                  openModal(e, TDateNProgress, "intervention");
                }
              }}
            >
              {TDateNProgress.DashboardBannerPercentage ? (
                //bageStyle function import from MainDashboardHeader.js
                <span
                  className={badgeStyle(
                    TDateNProgress.TargetDate,
                    TDateNProgress.DashboardBannerPercentage
                  )}
                  style={{
                    fontSize: "12px",
                    marginTop: "2px",
                    paddingBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  {Math.round(TDateNProgress.DashboardBannerPercentage) + "%"}
                </span>
              ) : (
                "0%"
              )}
            </span>
          </b>{" "}
        </div>
      </div>

      <div className="row" style={{ position: "absolute", right: 20 }}>
        <div
          className="simple_circle_individualapp custom-shadow-individual text-center mr-3 ml-4"
          style={{ paddingLeft: "0px", marginLeft: "0px" }}
          onClick={() => {
            handleModal("Comments");
          }}
        >
          <i
            className={
              ActivityData.COMMENTFLAG
                ? ActivityData.COMMENTFLAG === "R"
                  ? "fa fa-flag text-danger"
                  : "fa fa-info text-primary"
                : "fa fa-info text-secondary"
            }
          ></i>
        </div>
        <div
          className="simple_circle_individualapp custom-shadow-individual mr-4"
          onClick={(e) => {
            handleModal("Resources");
          }}
        >
          <i className="fa fa-users text-secondary"></i>
        </div>
      </div>
      <BannerInterventionModal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
      />
    </div>
  );
};

export default IndividualActivityHeader;
