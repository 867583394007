import React from 'react';
const ValidateGapDocumentData = (addRecorderrors, addRecord,pSetErrors) => {
    let errors = addRecorderrors;
    let addRecordData = addRecord;
    let value = true;
    if (
        !addRecordData.NewGapProgress ||
        addRecordData.NewGapProgress < 0 ||
        addRecordData.NewGapProgress > 100
      ) {
        errors.NewGapProgress = "Progress must be in between 0 to 100";
        value = false;
      }
      if (!addRecordData.NewWeightage) {
        errors.NewWeightage = "Please select value for Weightage";
        value = false;
      }
      if (!addRecordData.NewPlannedDate) {
        errors.NewPlannedDate = "date cannot be empty";
      }
      if (!addRecordData.NewGapID) {
        errors.NewGapID = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewDictionaryName) {
        errors.NewDictionaryName = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewRequirementSource) {
        errors.NewRequirementSource = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewRequirementNature) {
        errors.NewRequirementNature = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewRequirementCatoegory) {
        errors.NewRequirementCatoegory = "value cannot be null";
        value = false;
      } 
      if (!addRecordData.NewRequirementRefNo) {
        errors.NewRequirementRefNo = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewGapWalkThruDone) {
        errors.NewGapWalkThruDone = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewActive || addRecordData.NewActive==="Select") {
        errors.NewActive = "value cannot be null";
        value = false;
      }
    pSetErrors(errors)
            return value;
}
 
export default ValidateGapDocumentData;