import React from 'react';
import { inputDictionaryName, 
        selectActive, 
        selectWeightage, 
        datePicker,
        displaySequence,
        commentTextArea,} from "../Controls/ReusableControlsFunction";

function PlansAndStrategiesData({props, pData, pDataRecord, pDataRecorderrors}) {
    return ( 
        <div>
        <form>
          <div className="row mb-2">
          <div className="col-lg-6">
            <div className="form-group row">
              <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                SAP ID:{" "}
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  name="NewSAPID"
                  value={pDataRecord.NewSAPID}
                  className="form-control fontsize"
                  onChange={(e) => {
                    props.handleOnChange(e);
                  }}
                  onKeyDown={props.handleEnter}
                  autoComplete = "off"
                />
                {pDataRecorderrors.NewSAPID ? (
                  <label style={{ fontSize: 10, color: "RED" }}>
                    {pDataRecorderrors.NewSAPID}
                  </label>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {inputDictionaryName(props,"Name")}
          </div>
          <div className="row mb-2">
            {selectActive(props,"custom-leftAlign-Label required-field")}    {/*Active Repeated Function */}
          </div>
          <div className="row mb-2">
          <div className="col-lg-6">
            <div className="form-group row">
              <label className="col-sm-5 text-left col-form-label required-field">
                Progress:{" "}
              </label>
              <div className="input-group col-sm-7">
                <input
                  type="number"
                  name="NewGapProgress"
                  value={pDataRecord.NewGapProgress.toString()}
                  className="form-control fontsize border-right-0 rounded-left"
                  onChange={(e) => {
                    props.handleOnChange(e);
                  }}
                  onKeyDown={props.handleEnter}
                />
                 <div className="input-group-append" >
                    <span className="input-group-text border-left-0" 
                          style={{height:"28px",border:"1px solid"}}>%</span>
                  </div>
                {pDataRecorderrors.NewGapProgress ? (
                  <label style={{ fontSize: 10, color: "RED" }}>
                    {pDataRecorderrors.NewGapProgress}
                  </label>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        
           {/* {inputBuildProgress(props)} Build Progress Repeated Function */}
            {selectWeightage(props,"required-field custom-leftAlign-Label")} {/*Weightage Repeated Function */}  
          </div>
          <div className="row mb-2">
            {datePicker(props,"NewBaselineDate","Baseline Date","required-field custom-leftAlign-Label")} {/*{Repeated Date Control} */}
            {datePicker(props,"NewPlannedDate","Planned Date", "custom-leftAlign-Label")} {/*{Repeated Date Control} */}
          </div>
          <div className="row mb-2">
            {(() =>{
                if(pData.formHeading ==="Edit Record"){
                  return datePicker(props,"NewCompletionDate","Completion Date")
                }
            })()}
            {displaySequence(props)}
          </div>
          {commentTextArea(props)}
        </form>
      </div>
     );
}

export default PlansAndStrategiesData;