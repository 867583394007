export const NewActive=[{val:"Y",desc :"Yes"},{val:"N",desc :"No"}]

export const NewCMSControl=[...NewActive]

export const NewStandardContentAvailable=[...NewActive]

export const NewInstrumentConfigured=[...NewActive]

export const NewLISConnectionEstablished=[...NewActive]

export const NewLantronixConfigured=[...NewActive]

export const NewCommType=[...NewActive]

export const NewCommFromMT=[...NewActive]

export const NewCommFromOV=[...NewActive]

export const NewConfigintoSandbox=[...NewActive]

export const NewConfigintoTest=[...NewActive]

export const NewSignedOffRecd=[...NewActive]

export const NewRevByChampions=[...NewActive]

export const NewRevByClinicalUsers=[...NewActive]

export const NewRequirementFulfilled=[...NewActive]

export const NewGapWalkThruDone=[...NewActive]

export const NewDetailsSharedWithMT=[...NewActive]

export const NewSpecsRecdFromMT=[...NewActive]

export const NewSpecsApprovedByAKU=[...NewActive]

export const NewDictionaryNature=[{val:"Global",desc :"Global"},
                                  {val:"Local",desc :"Local"},
                                  {val:"Both",desc :"Both"}]

export const NewWeightage =[{val:"0",desc :"0"},
                            {val:"1",desc :"1"},
                            {val:"2",desc :"2"},
                            {val:"3",desc :"3"},
                            {val:"4",desc :"4"},
                            {val:"5",desc :"5"}]

export const NewDataGatheringOnExcel= [{val:"Y",desc :"Yes"},
                                      {val:"N",desc :"No"},
                                      {val:"Not Required", desc:"Not Required"}]    
                                      
export const NewRequirementSource=[{val:"Traceability Matrix",desc :"Traceability Matrix"},
                                    {val:"Weekly Calls",desc :"Weekly Calls"},
                                    {val:"Workshops",desc :"Workshops"},
                                    {val:"CFO List",desc :"CFO List"}]

export const NewRequirementNature=[{val:"Critical",desc:"Critical"},
                                    {val:"Medium",desc:"Medium"},
                                    {val:"Low",desc:"Low"}]

export const NewRequirementCatoegory=[{val:"Administrative", desc:"Administrative"},
                                    {val:"Service Imprv", desc:"Service Improvement"},
                                    {val:"Patient Safety", desc:"Patient Safety"},
                                    {val:"Regulatory", desc:"Regulatory"},
                                    {val:"Financial Impct", desc:"Financial Impact"}]


export const NewGapWalkThruOutcome=[{val:"A",desc : "Requirement is Fulfilled"},
                                    {val:"B",desc : "Requirement is Fulfilled via Available Workaround"},
                                    {val:"C",desc : "Development is Required"},
                                    {val:"D",desc : "3rd Party Solution is Required"},
                                    {val:"E",desc : "Requirement is Being Dropped"}]

export const NewTestCaseResult=[{val:"Passed", desc: "Passed"},{val:"Failed", desc:"Failed"}]