import React from 'react';
import {appCred} from '../AppConfig.js';

class ChangePassword extends React.Component {
	constructor(){
		super();
		this.FormChangePassword = React.createRef()
		this.state = {
		  username : '',
		 // oldpassword : '',
		  newpassword : '',
		  verifypassword : '',
		  isLoading : false,
		  customAlert : {
			isDisplay : false,
			AlertText : '',
			AlertType : ''
		  }
		}
		
	  }

	  rChangePassword = () =>{
		//console.log('here')

		window.ValidateChangePassword();
		window.ChangePassword(this,appCred);
	  }


	  KeyPressHook = (event) =>{
		this.setState({
		  customAlert : {
			isDisplay : false,
			AlertText : '',
			AlertType : ''
		  }
		})
		if (event.which === 13) {
		  event.preventDefault();
		  console.log("USER",this.state)
		  if (this.state.newpassword) {
			  if (this.state.verifypassword) {
				  this.rChangePassword();
			  }
			  else {
				  this.verifypasswordInput.focus();
			  }
		  }
		  else {
			  this.newpasswordInput.focus();
		  }
	  }
	  }
	
	  KeyUpHook = (event) =>{
		if (event.getModifierState && event.getModifierState('CapsLock')) {
			this.setState({
			  customAlert : {
				isDisplay : true,
				AlertText : 'Caps lock is ON.',
				AlertType : 'alert alert-warning'
			  }
			})
		} else {
			this.setState({
			  customAlert : {
				isDisplay : false,
				AlertText : '',
				AlertType : ''
			  }
			})
		}
	  }
	
	  handleUserInput (e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({[name]: value});

		
		window.ValidateChangePassword();
	  }
	componentDidMount () {
		this.FormChangePassword.current.addEventListener("keyup", this.KeyUpHook);
		this.FormChangePassword.current.addEventListener("keypress", this.KeyPressHook); 
	}
	
  render() {
    return (
		<div className="row m-2">
		<div className = "p-2 w-30">
		{/* <div className = "p-2 w-30"> */}

			
      <form id="frmChangePassword" className="form-signin" ref={this.FormChangePassword}>
		  			<div className="row">
						<div className="col-lg-1" style={{marginTop:"3px"}}>
							  <i class="fa fa-info-circle"></i>&nbsp; 
						</div>
					  	<div className="col-lg-10">
						  <span style={{fontSize:"10px"}}>The password should be alpha numeric and atleast 8 characters long including special character.
						  </span>
						</div>
					  </div>
					  <div className="form-label-group" style={{marginBottom:'5px',marginTop:"10px"}}>
					  <span style={{marginLeft:"5px"}}>New Password</span>
                        <input value={this.state.newpassword} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="newpassword" 
                               id="NewPassword" 
                               className="form-control" 
							   placeholder="New Password"
							   style={{height:"35px"}} 
                               required 
                               ref={(input) => { this.newpasswordInput = input; }}
                               />
                      </div>
					  <div className="form-label-group" style={{marginBottom:'5px',marginTop:"10px"}}>
					  <span style={{marginLeft:"5px"}}>Confirm Password</span>
                        <input value={this.state.verifypassword} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="verifypassword" 
                               id="ConfirmPassword" 
                               className="form-control" 
							   placeholder="Confirm Password" 
							   style={{height:"35px"}} 
                               required 
							   ref={(input) => { this.verifypasswordInput = input; }}
							   onKeyDown ={this.KeyPressHook}
                               />
                      </div>

						<div className="text-danger p-2" id="msgCP"> </div>
                      <input type="submit" style={{display: 'none'}} />
                    </form>
					<div style={{textAlign: 'right'}}>
                        <button id="btnChangePassword" 
                                className="btn btn-info btn-width60 " 
                                type="button" 
                                onClick={this.rChangePassword}
                                disabled={this.state.isLoading}
                                >
                          Update 
                          {(this.state.isLoading)?<i className="fa fa-spinner fa-spin fa-1x fa-fw" />:<i/>}
                        </button>
                      </div>       
		</div> 
		    
    </div>
		);
	}
}

export default ChangePassword;
