import React from "react";
import {
  Row,
  Col,
  Collapse,
  Button,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import "../../targetdatemodals.css";
import DatePicker from "react-datepicker";

class TargetDateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Baselinedate: "",
      Targetdate: "",
      Startdate: "",
      Endtdate: "",
      NinetyProgressdate: ""
    };
  
  }
  
  componentDidMount() {
  
  }
  optionalBaselineHandleChange = (e) => {
    const Baselinedate = e;
    this.setState(
      {
        Baselinedate: Baselinedate,
      },
      () => {
        // console.log("this.state.Targetdate set state", this.state.Targetdate);
      }
    );
  };
  optionalHandleChange = (e) => {
    const Targetdate = e;
    
    this.setState(
      {
        Targetdate: Targetdate,
      },
      () => {
        // console.log("this.state.Targetdate set state", this.state.Targetdate);
        
      }
    );
  };
  optionalStartHandleChange = (e) => {
    const Startdate = e;
    this.setState(
      {
        Startdate: Startdate,
      },
      () => {
        // console.log("this.state.Targetdate set state", this.state.Targetdate);
      }
    );
  };
  optionalEndHandleChange = (e) => {
    const Enddate = e;
    this.setState(
      {
        Enddate: Enddate,
      },
      () => {
        // console.log("this.state.Targetdate set state", this.state.Targetdate);
      }
    );
  };
  optionalNinetyProgressHandleChange = (e) => {
    const NinetyProgressdate = e;
    this.setState(
      {
        NinetyProgressdate: NinetyProgressdate,
      },
      () => {
        // console.log("this.state.Targetdate set state", this.state.Targetdate);
      }
    );
  };
  render() {
const Targetdate = this.state.Targetdate;
let propsdate = moment(this.props.parentData?.tempActivityData?.Targetdate);
let propsbaselinedate = moment(this.props.parentData?.tempActivityData?.BaselineDate);
const Startdate = this.state.Startdate;
let propsstartdate = moment(this.props.parentData?.tempActivityData?.ActualStartDate);
const Enddate = this.state.Enddate;
let propsenddate = moment(this.props.parentData?.tempActivityData?.ActualEndDate);
const NinetyProgressdate = this.state.NinetyProgressdate;
let propsninetydate = moment(this.props.parentData?.tempActivityData?.ActualNinetyProgressDate);

// Check if the dates are valid and not null or undefined
propsdate = propsdate.isValid() ? propsdate.format("MM-DD-yyyy") : null;
propsbaselinedate = propsbaselinedate.isValid() ? propsbaselinedate.format("MM-DD-yyyy") : null;
propsstartdate = propsstartdate.isValid() ? propsstartdate.format("MM-DD-yyyy") : null;
propsenddate = propsenddate.isValid() ? propsenddate.format("MM-DD-yyyy") : null;
propsninetydate = propsninetydate.isValid() ? propsninetydate.format("MM-DD-yyyy") : null;
    return (
      <Modal className="target-modal" isOpen={this.props.parentData.TargetDateModalOpen} centered={true}>
        <div className='maindashboard-custom-modal-header'>
          {this.props.parentData.modalHeading}
          <br /> Set Date{" "}
          <i
            className='fa fa-times text-warning fa-2x'
            style={{ cursor: "pointer" }}
            aria-hidden='true'
            onClick={() => {
              this.props.TargetDateModalClose();
            }}
          ></i>{" "}
        </div>
        <div style={{ width: "500px", height: "300px" }} className='pt-2'>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label modal-label-sub">Planned Start Date </label>
    </div>
  </div>
            <div className='col-2 date' style={{ marginTop: "-6px" }}>
              <div
                className='mt-1'
                style={{ width: "9rem", marginLeft: "1rem" }}
              >
                <DatePicker
                  dateFormat='MM-dd-yyyy'
                  description='Date'
                  name='Target'
                  selected={this.state.Baselinedate} /*{this.state.Baselinedate}*/
                  value={this.state.Baselinedate  ? this.state.Baselinedate : propsbaselinedate}
                  onChange={(e) => {
                    this.optionalBaselineHandleChange(e);
                  }}
                />
                {
                  
                }
              </div>
            </div>

          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label modal-label-sub">Planned End Date </label>
    </div>
  </div>
            <div className='col-2 date' style={{ marginTop: "-6px" }}>
              <div
                className='mt-1'
                style={{ width: "9rem", marginLeft: "1rem" }}
              >
                <DatePicker
                  dateFormat='MM-dd-yyyy'
                  description='Date'
                  name='Target'
                  selected={this.state.Targetdate} /*{this.state.Targetdate}*/
                  value={this.state.Targetdate  ? this.state.Targetdate : propsdate}
                  onChange={(e) => {
                    this.optionalHandleChange(e);
                  }}
                />
              </div>
              
            </div>

          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label modal-label-sub">Actual Start Date </label>
    </div>
  </div>
            <div className='col-2 date' style={{ marginTop: "-6px" }}>
              <div
                className='mt-1'
                style={{ width: "9rem", marginLeft: "1rem" }}
              >
                <DatePicker
                  dateFormat='MM-dd-yyyy'
                  description='Date'
                  name='Target'
                  selected={this.state.Startdate} /*{this.state.Startdate}*/
                  value={this.state.Startdate  ? this.state.Startdate : propsstartdate}
                  
                  onChange={(e) => {
                    this.optionalStartHandleChange(e);
                  }}
                />
              </div>
            </div>

          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label modal-label-sub">Actual End Date </label>
    </div>
  </div>
            <div className='col-2 date' style={{ marginTop: "-6px" }}>
              <div
                className='mt-1'
                style={{ width: "9rem", marginLeft: "1rem" }}
              >
                <DatePicker
                  dateFormat='MM-dd-yyyy'
                  description='Date'
                  name='Target'
                  selected={this.state.Enddate} /*{this.state.Enddate}*/
                  value={this.state.Enddate  ? this.state.Enddate : propsenddate}
                  onChange={(e) => this.optionalEndHandleChange(e)}
                />
              </div>
            </div>

          </div>
          <br></br>
          <div className='row'>
          <div className='col-md-2' style={{ marginTop: "-6px" }}>
    <div  style={{ width: "9rem" }}>
      <label className="modal-label modal-label-sub">Actual 90% Progress Date </label>
    </div>
  </div>
            <div className='col-2 date' style={{ marginTop: "-6px" }}>
              <div
                className='mt-1'
                style={{ width: "9rem", marginLeft: "1rem" }}
              >
                <DatePicker
                  dateFormat='MM-dd-yyyy'
                  description='Date'
                  name='Target'
                  selected={this.state.NinetyProgressdate} /*{this.state.Targetdate}*/
                 value={this.state.NinetyProgressdate  ? this.state.NinetyProgressdate : propsninetydate}
                  onChange={(e) => {
                    this.optionalNinetyProgressHandleChange(e);
                  }}
                />
              </div>
            </div>

          </div>
          <br></br>
          <Button
              onClick={() => {
                this.props.TargetDateModalClose();
              }}
              className='customModal-savebutton btn-danger' id='closebtn'
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                this.props.setTargetDate(this.state.Targetdate  ? this.state.Targetdate : propsdate,
                  this.state.BaselineDate  ? this.state.BaselineDate : propsbaselinedate,
                  this.state.Startdate  ? this.state.Startdate : propsstartdate,
                  this.state.Enddate  ? this.state.Enddate : propsenddate,
                  this.state.NinetyProgressdate  ? this.state.NinetyProgressdate : propsninetydate);
              }}
              className='customModal-save2button btn-success' id='savebtn'
            >
              Save
            </Button>
        </div>
        
      </Modal>
    );
  }
}
export default TargetDateModal;
