import React from "react";
import Input from "./../Controls/Input";
import Select from "./../Controls/Select";
import {
  inputDictionaryName,
  inputDictionaryID,
  selectActive,
  selectWeightage,
  inputBuildProgress,
  datePicker,
  displaySequence,
  commentTextArea,
} from "./../Controls/ReusableControlsFunction";

function InstrumentData({ props, pData, pDataRecord, pDataRecorderrors }) {
  return (
    <div>
      <form>
        <div className='row mb-2'>
          {inputDictionaryID(props, "Instrument ID")}
          {inputDictionaryName(props, "Instrument Name")}
        </div>
        <div className='row mb-2'>
          {selectActive(props)} {/*Active Repeated Function */}
          {selectWeightage(props, "required-field")}{" "}
          {/*Weightage Repeated Function */}
        </div>
        <div className='row mb-2'>
          <Input
            description='Lab Section'
            name='NewLabSection'
            value={pDataRecord.NewLabSection}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='required-field'
            error={pDataRecorderrors?.NewLabSection}
            type='text'
          />
          {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
        </div>
        <div className='row mb-2 my-auto'>
          <Select
            description='Instrument Configured'
            name='NewInstrumentConfigured'
            value={pDataRecord.NewInstrumentConfigured}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='required-field'
            error={pDataRecorderrors.NewInstrumentConfigured}
          />
          <Select
            description='LIS Connection Established'
            name='NewLISConnectionEstablished'
            value={pDataRecord.NewLISConnectionEstablished}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='large-required-field'
            error={pDataRecorderrors.NewLISConnectionEstablished}
          />
        </div>
        <div className='row mb-2 mt-0'>
          {datePicker(
            props,
            "NewBaselineDate",
            "Baseline Date",
            "required-field"
          )}
          {datePicker(props, "NewPlannedDate", "Planned Date")}
        </div>
        <div className='row mb-2'>
          <Select
            description='Lantronix Configured'
            name='NewLantronixConfigured'
            value={pDataRecord.NewLantronixConfigured}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='required-field'
            error={pDataRecorderrors.NewLantronixConfigured}
          />
          {/* <Select description="Communication Type"
                  name="NewCommType"
                  value={pDataRecord.NewCommType}
                  onChange ={(e)=>{props.handleOnChange(e)}}
                  handleEnter ={props.handleEnter}
                  requiredField= "required-field"
                  error={pDataRecorderrors.NewCommType}
                  /> */}
          <Input
            description='Communication Type'
            name='NewCommType'
            value={pDataRecord.NewCommType}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='required-field'
            error={pDataRecorderrors?.NewCommType}
            type='text'
          />
        </div>
        <div className='row mb-2'>
          {(() => {
            if (pData.formHeading === "Edit Record") {
              return datePicker(props, "NewCompletionDate", "Completion Date");
            }
          })()}
          <Input
            description='Instrument Location'
            name='NewInsLocation'
            value={pDataRecord.NewInsLocation}
            onChange={(e) => {
              props.handleOnChange(e);
            }}
            handleEnter={props.handleEnter}
            requiredField='required-field'
            error={pDataRecorderrors?.NewInsLocation}
            type='text'
          />
        </div>
        <div className='row mb-2'>{displaySequence(props)}</div>
        {commentTextArea(props)}
      </form>
    </div>
  );
}

export default InstrumentData;
