import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  Collapse,
} from "reactstrap";
  import {BigInfoIcon, BigRedIcon, AppRoleEnum} from '../../utils/Constants'
import moment from "moment";
class CommentsNResourcesModal extends React.Component{
    constructor(props) {
        super(props);
        this.state={
          addResoucresIsOpen : false,
        }
      }
      changeAddResource=(value)=>{
        this.setState({
          addResoucresIsOpen : value
        })
      }
      handleTextChange(e){
        this.setState({
          [e.target.name] : e.target.value
        })
      }
      Addresoucres=()=>{
        return <div>
        <div className="row">
        <div className="col-lg-6">
          <label className="col-sm-5 text-left pt-2 pl-0 col-form-label custom-addresoucrelabel">
              ID:
            </label>
            <div className="form-group row">
            <div className="col-sm-11">
              <input
                type="text"
                name="resourceID"
                className="form-control fontsize"
                autoComplete = "off"
                value={this.props.ParentData.resourceID}
                onChange = {(e)=>this.props.handleOnChange(e)}
              />
            </div>
          </div>
      </div>
          <div className="col-lg-6">
          <label className="col-sm-5 text-left pt-2 pl-0 col-form-label custom-addresoucrelabel">
              Name:
          </label>
            <div className="form-group row">
            <div className="col-sm-11">
              <input
                type="text"
                name="resourceName"
                className="form-control fontsize"
                autoComplete = "off"
                value={this.props.ParentData.resourceName}
                onChange = {(e)=>this.props.handleOnChange(e)}
              />
            </div>
          </div>
      </div>
          </div> 
        <div className="row">
          <div className="col-lg-6">
          <label className="col-sm-5 text-left pl-0 pt-2 col-form-label custom-addresoucrelabel">
              Role:
            </label>
            <div className="form-group row">
            <div className="col-sm-11">
            <select
                              name="resourceRole"
                              value={this.props.ParentData.resourceRole}
                              className="form-control custom-individual-dropdown fontsize"
                              onChange = {(e)=>this.props.handleOnChange(e)}>
                              <option value=""> Select</option>
                              <option value="LEAD">LEAD</option>
                              <option value="CO-LEAD">CO-LEAD</option>
                              <option value="TECHNO-FUNC">TECHNO-FUNC</option>
                              <option value="MT-LEAD">MT-LEAD</option>
                              <option value="MT-CO-LEAD">MT-CO-LEAD</option>
                            </select>
            </div>
          </div>
      </div>
          <div className="col-lg-6">
              <label className="col-sm-5 pl-0 text-left col-form-label custom-addresoucrelabel">
                  Location:
                </label>
              <div className="form-group row">
                <div className="col-sm-11">
                  <input
                    type="text"
                    name="resourceLocation"
                    className="form-control fontsize"
                    onChange = {(e)=>this.props.handleOnChange(e)}
                    value={this.props.ParentData.resourceLocation}
                  />
                  </div>
                </div>
          </div>
        </div> 
        <div className="row mt-2">
          <button className="btn btn-success mb-2 custom-addresourcebtn custom-addresoucesavebtnmar" onClick={()=>{this.props.addNewResource()}}>Save</button>
          <button className="btn btn-danger mb-2 custom-addresourcebtn  custom-addresourcecanbtn ml-2 " onClick={()=>{this.setState({addResoucresIsOpen : false},()=>{this.props.clearResourceInput()})}}>Cancel</button>
         
        </div>
          
        
        </div>
      }
      Addresoucresbtn=(isOpened)=>{
        if(!isOpened){
          return  <i className="fa fa-plus text-info fa-2x"
                  title="Add Resource"
                  style={{ cursor: "pointer", position:"absolute",right:"10%" }}
                  onClick={()=>{this.setState({addResoucresIsOpen : true})}}
                  ></i>
        }
        else{
          return <i className="fa fa-minus text-info fa-2x"
                style={{ cursor: "pointer", position:"absolute",right:"10%" }}
                onClick={()=>{this.setState({addResoucresIsOpen : false})}}
                ></i>
        }
    }
    render(){
        return(
            <Modal size="md" isOpen={this.props.ParentData.resourcesModalOpen || this.props.ParentData.commentsModalOpen} centered={true}>
            <div className="maindashboard-custom-modal-header">
              {this.props.ParentData.modalHeading}
              <br /> {this.props.ParentData.resourcesModalOpen ? "Application Resources" : ""}
              
                { 
                  (this.props.ParentData.resourcesModalOpen 
                  && this.props.ParentData.UserOrgType==AppRoleEnum.Admin)  ?
                 this.Addresoucresbtn(this.state.addResoucresIsOpen)
               : <></>
              }
              {this.props.ParentData.loggedInUserData && this.props.ParentData.UserOrgType!=AppRoleEnum.Viewer ?
                this.props.ParentData.loggedInUserData.APPLICATIONID===this.props.ParentData.modalData.APPLICATIONID ? 
                this.Addresoucresbtn(this.state.addResoucresIsOpen)
                : <></> : <></>}  
              <i
                className="fa fa-times text-warning fa-2x"
                style={{ cursor: "pointer" }}
                aria-hidden="true"
                onClick={
                  this.props.CloseCommentNResourecsModal
                }
              ></i>{" "}
            </div>
            <ModalBody style={this.props.ParentData.resourcesModalOpen ? {maxHeight:'400px', overflowY:'scroll'} :{}}>
              {this.props.ParentData.resourcesModalOpen ? 
              <div>
          <Collapse
            style={{ marginTop: "" }}
            isOpen={this.state.addResoucresIsOpen}
            className="col-lg-12"
          >
            {this.Addresoucres()}
          </Collapse>
              <table className="table">
                <thead>
                  <tr>
                    <th className="p-1">Name</th>
                    <th className="p-1">Role</th>
                    <th className="p-1">Location</th>
                  </tr>
                </thead>
                {this.props.ParentData.resourcesData && this.props.ParentData.resourcesData.length > 0 ? (
                  <tbody>
                    {this.props.ParentData.resourcesData.map((resourceItem, index) => (
                      <tr key={index}>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCENAME}
                        </td>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCETYPE}
                        </td>
                        <td style={{ padding: "6px" }}>
                          {resourceItem.RESOURCEREGION}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  "No Data"
                )}
              </table>
            </div>
              : <div>
                   <div className={this.props.ParentData.activityData.COMMENTFLAG ? this.props.ParentData.activityData.COMMENTFLAG==="B" ? "bigbluebox row" : "bigredbox row" :""} >
                      <div className="col-lg-3 "style={this.props.ParentData.activityData.COMMENTFLAG ==="B" ? BigInfoIcon : BigRedIcon}></div>
                      <div className="col-lg-9" style={{fontSize:"14px"}}>{this.props.ParentData.activityData.COMMENTS}</div>
                      </div>
                      <div className="row">
                      <div className="col-lg-6">
                      <h5
                                    className="label-color"
                                    style={{
                                      textAlign: "left",
                                      fontSize: "10px",
                                      marginTop:"5px",
                                      marginBottom: "-28px"
                                    }}
                                  >Commented By</h5><br/>
                                  <span className="text-dark" style={{fontSize:"11px"}}>
                                  {this.props.ParentData.activityData.COMMENTENTEREDBY ?
                                   this.props.ParentData.activityData.COMMENTENTEREDBY.replace("."," ") : "N/A"}
                                   </span></div>
                      <div className="col-lg-6 text-right">
                      <h5
                                    className="label-color"
                                    style={{
                                      marginTop:"5px",
                                      textAlign: "right",
                                      fontSize: "10px",
                                      marginBottom: "-28px"
                                    }}>
                                      Last Updated Date/Time
                                      </h5><br/>
                                      <span className="text-dark" style={{fontSize:"11px"}}>
                                      {this.props.ParentData.activityData.COMMENTENTEREDDTTM ? moment(this.props.ParentData.activityData.COMMENTENTEREDDTTM).format("MMM DD,YYYY hh:mm:ss") : "N/A"}</span></div>
                      </div>
                </div>}
              </ModalBody>
          </Modal>
       
    )}
}

export default CommentsNResourcesModal;