import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
// import Sidebar from './Sidebar';
import ApplicationDetailedView from "./ApplicationDetailedView/ApplicationDetailedView";
import EhrApplications from "./EhrApplications";
import ChangePassword from "./ChangePassword";
import Login from "./Login";
import MainStream from "./MainStream/MainStream";
import ApplicationsMain from "./ApplicationsMain";
import GanttChart from "./GanttChart";
import moment from "moment";
// MM - added Project GanttCHart
import ProjectGanttChart from "./ProjectGanttChart";
import OverallProgressDetailView from "./ApplicationDetailedView/OverallProgressDetailView";


class Main extends React.Component {
  constructor() {
    super();

    let user = window.mylsataku.get("cache2"); //JSON.parse(window.localStorage.getItem("User"));

    this.state = {
      isUserLoggedIn: window.mylsataku.get("cache1"), //localStorage.getItem('isUserLoggedIn'),
      User: window.mylsataku.get("cache2"), //JSON.parse(localStorage.getItem('User')),
      srcOrgLogoUrl: user.User.OrgLogoUrl,
      tempUserMenu: window.mylsataku.get("cache6"),
      //UserAssignedMenus : JSON.parse(localStorage.getItem('UserAssignedMenus'))
    };
  }

  componentDidMount() {
    //console.log(this.state.User)
    
  }
  componentDidUpdate() {}

  render() {
    let user = window.mylsataku.get("cache2"); //JSON.parse(window.localStorage.getItem("User"));
    let isForceChangePassword =
      user.User.ForceChangePassword == "Y" ? true : false;
    let userRole = user.User.UserOrganizationType;

    //console.log(user.User.ForceChangePassword);
    //console.log('HERE AMQ ' + isForceChangePassword);
    const NormalRoutes = () => (
      <Switch>
        <Route exact path="/" component={() => <ApplicationsMain />} />
        <Route
          exact
          path="/ApplicationsMain"
          component={() => <ApplicationsMain key={moment()} />}
        />
        <Route exact path="/MainStream" component={() => <MainStream />} />
        {/* <Route exact path='/home' component={()=><Home User={this.state.User} />} /> */}
        <Route
          exact
          path="/ApplicationDetailedView"
          component={ApplicationDetailedView}
        />
        <Route exact path="/EhrApplications" component={EhrApplications} />
        <Route exact path="/ChangePassword" component={ChangePassword} />
        <Route
          exact path="/OverallProgressDetailView" component={OverallProgressDetailView}
        />
        {/* <Route exact path="/GanttChart" component={() => <GanttChart />} /> */}
		 <Route exact path="/ProjectGanttChart" component={() => <ProjectGanttChart />} />
      </Switch>
    );

    const ForceAuthRoutes = () => (
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Login userType={"External"} />}
        />
        <Route exact path="/ChangePassword" component={ChangePassword} />
        <Route render={() => <Redirect to="/ChangePassword" />} />
        {/* <Route exact path='/EhrApplications' component={()=> <EhrApplications SetModalContent = {this.SetModalContent} />} />
        <Route exact path='/CheckIndividualApplication' component={()=> <CheckIndividualApplication SetModalContent = {this.SetModalContent} />} /> */}
      </Switch>
    );
    return (
      <Router>
        <Helmet>
          <title>EHR Applications </title>
          <link href="/Theme/Custom/animationStyles.css" rel="stylesheet" />
          <link href="/Theme/Custom/Custom.css" rel="stylesheet" />
        </Helmet>
        <div>
          <div style={{ padding: 0 }}>
            {isForceChangePassword ? <></> : <Header />}
            {/* <Sidebar User={this.state.User}/> */}
            <div className="custom-before-header">
              <div style={{ userSelect: "none" }}>
                <div>
                  {isForceChangePassword ? (
                    <ForceAuthRoutes />
                  ) : (
                    <NormalRoutes />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer ServerName={appCred.appServer} /> */}
          <ToastsContainer store={ToastsStore} />
        </div>
      </Router>
    );
  }
}

export default Main;
