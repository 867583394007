import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { UserAccessContext } from "../UserAccessContext";
import CommentModal from "./../ProjectStatusBoardModals/CommentModal";
import {
  btnInterventionColor,
  setCardStyle,
  setCommentBtn,
} from "./CustomFunctions";
import ResourcesModal from "../ProjectStatusBoardModals/ResourcesModal";
import InterventionModal from "../ProjectStatusBoardModals/InterventionModal";
import { AppRoleEnum, MSWeightageIcon } from "../../utils/Constants";
import { ToastsStore } from "react-toasts";
import WeightageModal from "../ProjectStatusBoardModals/WeightageModal";
import { appCred } from "../../AppConfig";
import UpdateTargetDateMoal from "./../ProjectStatusBoardModals/UpdateTargetDateModal";
import * as Constants from "../../utils/Constants";

const _MainStream = ({ mainCardsData, lastModifiedData }) => {
 
  const [indvdlCard, setindvdlCard] = useState();
  const [userAccess, setUserAccess] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFor, setModalFor] = useState("");
 const getUserContext = useContext(UserAccessContext);
  
  useEffect(() => {
    //For User Access
    setUserAccess(getUserContext);
  }, [getUserContext]);

  const userData = userAccess?.userData.UserData;
  const userRole = userAccess?.user.UserOrganizationType;


  const openModal = (e, item, modalType) => {
    e.preventDefault();
    e.stopPropagation();
    setModalFor(modalType);

    setindvdlCard(item);
    if (modalType === "resources") {
      if (item.ACTLEADNAME || item.ACTCOLEADNAME) setModalOpen(true);
      else ToastsStore.error("Responsible Person name is not set");
    }
    if (modalType === "weightage") setModalOpen(true);
    else setModalOpen(true);
    if (modalType === "TargetDate") setModalOpen(true);
    else setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setindvdlCard();
    setModalFor("");
  };

  const saveApplicationWeightage = (Weightage, Activity, Facility) => {
    if (Weightage) {
      const reqObj = {
        Weightage: Weightage,
        ACTIVITYID: Activity,
        FACILITYID: Facility,
      };
      window
        .APIRequest(
          appCred,
          "",
          "EHRDashboardManager.SaveFacilityApplicationWeightage",
          reqObj
        )
        .done((apiRes) => {
          if (apiRes.ResponseCode === 200) {
            closeModal();
            getUserContext.refreshData();
            ToastsStore.success("Saved Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };



  const content = (item, lastModifiedDTTM, card, commentBtn) => {
    return (
      <div className='card-body pt-2 pl-2 pr-0'>
        <div className='row'>
          <div className='col-12 pr-0 pb-1 heading-height'>
            <span className='card-title tracking-in-expand custom-card-head box-header'>
              <i className=' fa fa-snowflake-o pr-1' aria-hidden='true'></i>
              {item.DESCRIPTION}
            </span>
          </div>
        </div>
        <div className='d-flex ' style={{ paddingBottom: "12px" }}>
          <div className='col-10 pl-5 justify-content-center'>
            <div
              style={MSWeightageIcon}
              className='ml-4 col-lg-2'
              onClick={(e) => {
                if (userRole === AppRoleEnum.Admin)
                  openModal(e, item, "weightage");
              }}
            >
              &nbsp;
              <span className='weight-class'>{item.WEIGHTAGE}</span>
            </div>
            <div
              className={
                "pl-1 hover_color_bubble center " + card?.circleBorderColor
              }
            >
              <h4
                className={"tracking-in-expand text-centre text-dark"}
                style={{ padding: "15px 0px 10px 0px", fontSize: "20px" }}
              >
                {item.PROGRESS + "%"}
              </h4>
            </div>
          </div>
          <div className='col-2 pl-0 pr-0 button-pos'>
            <div className={"text-center top-right side-vl " + card?.sideLine}>
              <div>
                {/* Resources Box */}
                <div
                  onClick={(e) => {
                    openModal(e, item, "resources");
                  }}
                  title={
                    (item.ACTLEADNAME ? item.ACTLEADNAME : "") +
                    (item.ACTCOLEADNAME ? ", " + item.ACTCOLEADNAME : "")
                  }
                  className='simple_square custom-grey-box'
                >
                  <i className='fa fa-users text-secondary mr-1'></i>
                </div>
              </div>
              <div
                className='simple_square mt-1 text-center custom-grey-box'
                onClick={(e) => {
                  openModal(e, item, "comments");
                }}
                style={{ height: "24px" }}
              >
                <div style={commentBtn.commImage ? commentBtn.commImage : null}>
                  <i className={commentBtn.commClass}></i>
                </div>
              </div>
              <div
                onClick={(e) => {
                  userRole === AppRoleEnum.Admin
                    ? openModal(e, item, "intervention")
                    : e.preventDefault();
                }}
                className='simple_square mt-1 text-center custom-grey-box'
                style={btnInterventionColor(item?.ActivityFlag)}
              >
                <i className='fa fa-pencil-square-o ' aria-hidden='true'></i>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"mt-1 progress " + card?.progressBarBorder}
          style={{ height: "5px" }}
        >
          <div
            className={
              "progress-bar-striped progress-bar-animated " +
              card?.progressColor
            }
            role='progressbar'
            style={{ width: item.PROGRESS + "%" }}
            aria-valuenow='10'
            aria-valuemin='0'
            aria-valuemax='100'
          ></div>
        </div>
        <div className='row'>
          {(() => {
            if (userRole != "ADMIN") {
              return (
                <div className='col-5'>
                  <h5
                    className='mt-1 tracking-in-expand text-grey1 text-size65 mb-0'
                    onClick={(e) => {
                      // openModal(e, item, "TargetDate");
                    }}
                  >
                    Target Date
                    <br />
                    <span className='text-dark text-size11'>
                      {item.ACTTARGETDATE
                        ? moment(item.ACTTARGETDATE).format("MMM DD, YYYY")
                        : "N/A"}
                    </span>
                  </h5>
                </div>
              );
            } else if (userRole === AppRoleEnum.Admin) {
              return (
                <div className='col-5'>
                  <h5
                    className='mt-1 tracking-in-expand text-grey1 text-size65 mb-0'
                    onClick={(e) => {
                      openModal(e, item, "TargetDate");
                    }}
                  >
                    Target Date
                    <br />
                    <span className='text-dark text-size11'>
                      {item.ACTTARGETDATE
                        ? moment(item.ACTTARGETDATE).format("MMM DD, YYYY")
                        : "N/A"}
                    </span>
                  </h5>
                </div>
              );
            } else {
              return <></>;
            }
          })()}

          <div className='col-7'>
            <h5 className='mt-1 tracking-in-expand text-grey1 text-right text-size65 mb-0'>
              Last Modified <br />
              <span className='text-dark text-size11'>
                {lastModifiedDTTM
                  ? moment(lastModifiedDTTM).fromNow()
                  : "No Information"}
              </span>
            </h5>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className='row mt-3 mb-3 ml-4 mr-0'>
        {mainCardsData ? (
          mainCardsData.map((item, index) => {
            const card = setCardStyle(item);
            const commentBtn = setCommentBtn(item.CommentFlag);
            const lastModifiedDTTM = lastModifiedData
            //"AKUHN"
              ? lastModifiedData.find(
                  (a) =>
                    a.ACTIVITYID === item.ACTIVITYID && a.FACILITYID === item.FACILITYID 
                )?.LastModifiedDttm
              : null; //Facility hardcoded for now
            return (
              <div
                className='col-2 card mr-4 mb-4 custom-Shadow cardbox_custom card-background '
                key={index}
                style={{ float: "left", padding: "2px 16px 5px 5px" }}
              >
                <div className=''>
                  {(() => {
                    const temp = userData.find(
                      (a) => a.ActivityID === item.ACTIVITYID
                    );
                    if (temp != null && temp)
                      return (
                        <Link
                          to={{
                            //Need to add Facilityid too but userData has dev value against facility id in every object
                            pathname: "/ApplicationsMain",
                            state: {
                              facilityID: item.FACILITYID,
                              activityID: item.ACTIVITYID,
                              thruPSB: true,
                            },
                          }}
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                        >
                          {content(item, lastModifiedDTTM, card, commentBtn)}
                        </Link>
                      );
                    else
                      return content(item, lastModifiedDTTM, card, commentBtn);
                  })()}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <UpdateTargetDateMoal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
      />
      <CommentModal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
      />
      <ResourcesModal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
      />
      <InterventionModal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
      />
      <WeightageModal
        modalOpen={modalOpen}
        data={indvdlCard}
        closeModal={closeModal}
        modalFor={modalFor}
        saveApplicationWeightage={saveApplicationWeightage}
      />
    </div>
  );
};

export default _MainStream;
