import React from "react"
import * as constants from "./SelectControlConstants"


const options =function(_item){
    const item = constants[_item]
    console.log("item",item)
    const opts=[<option value=""> Select </option>]
        for(let i= 0; i < item.length; i++){
            opts.push(<option value={item[i]?.val}> {item[i]?.desc} </option>)
        }
    return opts    
}
const Select =({description,name,value,onChange,handleEnter,requiredField, error}) => {
    return ( 
        <React.Fragment>
            <div className="col-lg-6">
                <div className="form-group row">
                        <label className={"col-sm-5 text-left pt-2 col-form-label "+requiredField}>
                            {description}:{" "}
                        </label>
                        <div className="col-sm-7">
                            <select
                            name={name}
                            value={value}
                            className="form-control custom-individual-dropdown fontsize"
                            onChange={onChange}
                            onKeyDown={handleEnter}
                            >
                                {options(name)}
                            </select>
                            {error ? (
                            <label style={{ fontSize: 10, color: "RED" }}>
                            {error}
                            </label>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
            </div>
              </React.Fragment>
     );
}

export default Select;