import React from 'react';
const ValidateOrderSetsData = (addRecorderrors, addRecord,pSetErrors) => {
    let errors = addRecorderrors;
    let addRecordData = addRecord;
    let value = true;
    if (
        !addRecordData.NewBuildProgress ||
        addRecordData.NewBuildProgress < 0 ||
        addRecordData.NewBuildProgress > 100
      ) {
        errors.NewBuildProgress = "Build Progress must be in between 0 to 100";
        value = false;
      }
      if (!addRecordData.NewWeightage) {
        errors.NewWeightage = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewPlannedDate) {
        errors.NewPlannedDate = "date cannot be empty";
      }
      if (!addRecordData.NewBaselineDate) {
        errors.NewBaselineDate = "date cannot be empty";
        value = false;
      }
      if (!addRecordData.NewDictionaryID) {
        errors.NewDictionaryID = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewDictionaryName) {
        errors.NewDictionaryName = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewTargetHCIS) {
        errors.NewTargetHCIS = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewActive || addRecordData.NewActive==="Select") {
        errors.NewActive = "value cannot be null";
        value = false;
      }
        pSetErrors(errors)
        return value;
}
 
export default ValidateOrderSetsData;