import React from 'react';
import {Helmet} from "react-helmet";
import moment from 'moment';
import {appCred} from '../AppConfig.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ChangePassword from './ChangePassword';

class Login extends React.Component {
  constructor(props){
    super(props);
    this.FormLogin = React.createRef()
    this.state = {
      username : '',
      password : '',
      isLoading : false,
      externalUserModal : (this.props.userType === "External") ? true : false,
      customAlert : {
        isDisplay : false,
        AlertText : '',
        AlertType : ''
      }
    }
    
  }

  rLogin = (e) =>{
   // console.log('here',this)
    window.Login(this,appCred);
  }
  rLogOut = () =>{
    window.mylsataku.removeAll();
    //localStorage.clear();
    window.location = '/';
  }
  
  KeyPressHook = (event) =>{
    this.setState({
      customAlert : {
        isDisplay : false,
        AlertText : '',
        AlertType : ''
      }
    })
    if (event.which === 13) {
      event.preventDefault();
      if (this.state.username) {
          if (this.state.password) {
              this.rLogin();
          }
          else {
              this.passwordInput.focus();
          }
      }
      else {
          this.usernameInput.focus();
      }
  }
  }

  KeyUpHook = (event) =>{
    if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.setState({
          customAlert : {
            isDisplay : true,
            AlertText : 'Caps lock is ON.',
            AlertType : 'alert alert-warning'
          }
        })
    } else {
        this.setState({
          customAlert : {
            isDisplay : false,
            AlertText : '',
            AlertType : ''
          }
        })
    }
  }

  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  componentDidMount(){
    //MMChanges - commenetd the code
   // console.log("PROPS",this.props)
    this.FormLogin.current.addEventListener("keyup", this.KeyUpHook);
    this.FormLogin.current.addEventListener("keypress", this.KeyPressHook);
  }

  componentWillUnmount() {
    this.FormLogin.current.removeEventListener("keyup", this.KeyUpHook);
    this.FormLogin.current.removeEventListener("keypress", this.KeyPressHook);
  }

  render() {
    return (
	    <div className="bgsolidcolor" style={{height:'100vh'}}>  
        <Helmet>
            <title>EHR Applications : Login</title>
            {/* <link href="/Theme/Custom/Custom.css" rel="stylesheet"/> */}
            <link href="/Theme/Custom/CustomLogin.css" rel="stylesheet"/>
            
        </Helmet>
        <div>
          
          <div id="main" className="container">
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto" style={{marginTop:"85px"}}>
                <div className="card card-signin my-5 cardStyle mr-5 ml-5 " style={{backgroundColor: '#ffffff7a'}}>
                  <div className="card-body" style={{paddingTop:'5px',paddingBottom:'5px'}}>
                    {/* <div style={{backgroundImage:'url(/airplane.png)', 'backgroundRepeat': 'no-repeat', backgroundSize:'contain'}}> */}
                    <div>
                      <div style={{opacity:'1', textAlign:'center'}}>
                        <br/>
                        <div><span style={{fontWeight:"bold"}} className="lfont-size">AKU One-Health Implementation</span></div>
                        <div><span className="lfont-size">Aga Khan University</span></div>
                        <div><span style={{color:"#2061b8",fontWeight:"bold"}} className="lfont-size">Status Board</span></div>

                        {/* <img src={'/tclogo.png'} style={{textAlign: 'center', display:'block', margin:'auto', width:'75%'}} ></img> */}
                        
                        <br />                      
                      </div>                      
                    </div>
                    
                    <form id="frmLogin" className="form-signin" ref={this.FormLogin}>
                      <div className="form-label-group">
                        <input value={this.state.username} 
                              onChange={(event) => this.handleUserInput(event)} 
                              type="text" 
                              id="username" 
                              name="username" 
                              className="form-control" 
                              placeholder="Username" required 
                              ref={(input) => { this.usernameInput = input; }}
                              autoFocus
                              />
                        <label htmlFor="username">Username</label>
                      </div>
                      <div className="form-label-group">
                        <input value={this.state.password} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="password" 
                               id="password" 
                               className="form-control" 
                               placeholder="Password" 
                               required 
                               ref={(input) => { this.passwordInput = input; }}
                               onKeyDown ={this.KeyPressHook}
                               />
                        <label htmlFor="password">Password</label>
                      </div>
                      
                      {/*
                      <p className="text-right text-white font-sm">
                        <Link to="/ChangePassword">Forget Password?</Link>
      
                        </p>
                      
                      
                      <div className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Remember password</label>
                      </div> */}
                      <div style={{textAlign: 'center'}}>
                        <button id="btnLogIn" 
                                className="btn btn-lg btn-signin btn-width60 text-uppercase" 
                                type="button" 
                                onClick={this.rLogin}
                                disabled={this.state.isLoading}
                                style={{color:"black"}}
                                >
                          Sign in
                          {(this.state.isLoading)?
                          <i className="fa fa-spinner fa-spin fa-1x fa-fw" />:<i/>}
                        </button>
                      </div>
                      <input type="submit" style={{display: 'none'}} />
                    </form>
                    <br />
                    {
                      (this.state.customAlert.isDisplay)?
                      <div className={this.state.customAlert.AlertType} role="alert" style={{textAlign: 'center', fontSize: '13px'}}>
                        <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{paddingRight: '6px'}} />
                        <span><b>{this.state.customAlert.AlertText}</b></span>
                      </div>
                      :
                      <div></div>
                    }
                    {/* <div style={{textAlign: 'center', paddingBottom:'10px'}}>
                      <h6>Aga Khan University Hospitals</h6>
                      <p style={{fontSize: '10px'}}>Stadium Road, Karachi</p>
                    </div> */}
                    
                    <div style={{textAlign: 'center'}}>
                      <p style={{fontSize: '10px'}}>
                        Information Technology, Aga Khan University <br/>
                        {"© Intellectual property rights " + moment().format('YYYY') + " "}
                         All rights reserved.
                      </p>
                    </div>
                 
                  </div>
                </div>
                </div>  
              
            </div>
          </div>
        </div>
      <Modal isOpen={this.state.externalUserModal} style={{width:370}} centered={true}>
        <ModalHeader>Change Password</ModalHeader>
        <ModalBody>
          <ChangePassword/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary mr-3" style={{width:"135px"}}onClick={()=>{this.setState({externalUserModal : false},this.rLogOut)}}>Cancel</Button>
        </ModalFooter>
      </Modal>
      </div>
		);
	}
}

export default Login;
