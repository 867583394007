import React from 'react';
const ValidateTrainingData = (addRecorderrors, addRecord,pSetErrors) => {
    let errors = addRecorderrors;
    let addRecordData = addRecord;
    console.log("IN validate",addRecordData)
    let value = true;
    if (!addRecordData.NewGapProgress ||
        addRecordData.NewGapProgress  < 0 ||
         addRecordData.NewGapProgress > 100 
       ) {
         errors.NewGapProgress = "Progress must be in between 0 to 100";
         value = false;
       }
       if(!addRecordData.NewschDate){
         errors.NewschDate = "date cannot be empty"
         value = false;
       }
       if(!addRecordData.NewSchStartTime){
         errors.NewSchStartTime = "Invalid or empty Time"
         value = false;
       }
       if(!addRecordData.NewSchEndTime){
         errors.NewSchEndTime = "Invalid or empty Time"
         value = false;
       }
       if(!addRecordData.NewSchVenue){
         errors.NewSchVenue = "value cannot be null"
         value = false;
       }
       if(!addRecordData.NewTrainingSessionID){
         errors.NewTrainingSessionID = "value cannot be null"
         value = false;
       }
       if (!addRecordData.NewActive || addRecordData.NewActive==="Select") {
         errors.NewActive = "value cannot be null";
         value = false;
       }
       if (!addRecordData.NewDictionaryName || addRecordData.NewDictionaryName==="Select") {
         errors.NewDictionaryName = "value cannot be null";
         value = false;
       }
    pSetErrors(errors)
            return value;
}
 
export default ValidateTrainingData;