import React, { useState, useEffect } from "react";
import './../assests/StyleSheet/MainStream.css'
import _MainStream from "./_MainStream";



const MainStream = ({MainData}) => {
    const [data, setData] = useState();
    const [lastModifiedData, setLastModifiedData] =useState()

    useEffect(()=>{ //For Dashboard Activites
        setData(MainData[0]?.DashboardActivities)
    },[MainData[0]?.DashboardActivities])

    useEffect(()=>{ //For LastModifiedData Activites
        setLastModifiedData(MainData[0]?.LastModifiedDTTM)
    },[MainData[0]?.LastModifiedDTTM])
  
    return ( 
    <div style={{ overflowX: "hidden"}}>
        <_MainStream mainCardsData={data} lastModifiedData={lastModifiedData}/>
    </div> );
}
 
export default MainStream;