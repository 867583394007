import {dateCellHeading,_activityName,largetablecellnames} from '../../utils/Constants'


export function sortTableFunc (item,_tempData){
    let tempData = _tempData
    let prop = Object.entries(tempData.meta).find(a=> a[1].heading == item)[0]
    prop = prop.replace("custom","");
    
    let Arr = Object.entries(tempData.meta)
    let _itemHeading = Object.keys(dateCellHeading).find(key => dateCellHeading[key] === item)
    console.log("(obj[1].heading ",_itemHeading)
    for(let obj of Arr){
     
      if(obj[1].heading == item){
        
      }
      else{
        if(tempData.meta["custom"+obj[0]] && tempData.meta["custom"+obj[0]].heading == item){

        }
        else{
          tempData.meta[obj[0]].sortType = "";  
        }
      }
    }
    //if(_itemHeading){
      if((tempData.meta[prop].sortType && tempData.meta[prop].sortType == "asc")){
        tempData.meta[prop].sortType = "desc"
        if(tempData.meta["custom"+prop]){
          tempData.meta["custom"+prop].sortType = "desc";
        }
        tempData.list.sort((a,b)=>{
          if(!a[prop].value){
            return 1;
          }
          if(!b[prop].value){
            return -1
          }
          if ( a[prop].value < b[prop].value ){
            return  -1;
          }
          if ( a[prop].value > b[prop].value ){
            return 1;
          }
          return 0;
        })
      }
      else{
        tempData.meta[prop].sortType = "asc"
        if(tempData.meta["custom"+prop]){
          tempData.meta["custom"+prop].sortType = "asc";
        }
        tempData.list.sort((a,b)=>{
          if(!a[prop].value){
            return 1;
          }
          if(!b[prop].value){
            return -1
          }
          if ( a[prop].value < b[prop].value ){
            return  1;
          }
          if ( a[prop].value > b[prop].value ){
            return -1;
          }
          return 0;
        })
      }
    // this.setState({
    //   tempData : this.state.tempData
    // })

    return tempData
    
}

export function tableCellSize(item){
  let _item = Object.keys(largetablecellnames).find(key => largetablecellnames[key] === item)
  if(_item){
    return "extrawidthcolumn"
  }
  else{
    return "hideextra"
  }
}