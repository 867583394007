import Input from "../Controls/Input"
import Select from "../Controls/Select"
import React from 'react';
import { inputDictionaryName, 
        inputDictionaryID, 
        selectActive, 
        selectWeightage, 
        inputBuildProgress,
        inputTargetHCIS,
        datePicker,
        displaySequence,
        commentTextArea} from "../Controls/ReusableControlsFunction";

function DictionaryData({props, pData, pDataRecord, pDataRecorderrors}) {
    return (  
    <div>
        <form>
            <div className="row mb-2">
                {inputDictionaryID(props,"Dictionary ID")}
                {inputDictionaryName(props,"Dictionary Name")}
            </div>
            <div className="row mb-2">
                {selectActive(props)} {/*Active Repeated Function */}
                {selectWeightage(props,"required-field")} {/*Weightage Repeated Function */}
            
            </div>
            <div className="row mb-2">
                <Select description="Std Content Available"
                    name="NewStandardContentAvailable"
                    value={pDataRecord.NewStandardContentAvailable}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField="required-field"
                    error ={pDataRecorderrors?.NewStandardContentAvailable}/>
                <Select description="Global/Local"
                    name="NewDictionaryNature"
                    value={pDataRecord.NewDictionaryNature}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    requiredField="required-field"
                    error ={pDataRecorderrors?.NewDictionaryNature}/>
            </div>
            <div className="row mb-2">
                {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
                {inputTargetHCIS(props)}
            </div>    
            <div className="row mb-2">
                {datePicker(props,"NewBaselineDate","Baseline Date","required-field")}
                {datePicker(props,"NewPlannedDate","Planned Date")}
            </div>
            <div className="row mb-2">
                {(() =>{
                    if(pData.formHeading ==="Edit Record"){
                    return datePicker(props,"NewCompletionDate","Completion Date")
                    }
                })()}
                <Select description="Data On Excel"
                    name="NewDataGatheringOnExcel"
                    value={pDataRecord.NewDataGatheringOnExcel}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    />
            </div>
            <div className="row mb-2">
                <Select description="CMS Controlled"
                    name="NewCMSControl"
                    value={pDataRecord.NewCMSControl}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    />
                <Input description ="Responsible Team"
                    name="NewResponsibleTeam"
                    value= {pDataRecord.NewResponsibleTeam}
                    onChange ={(e)=>{props.handleOnChange(e)}}
                    handleEnter ={props.handleEnter}
                    type="text"
                    />
            </div>
            <div className="row mb-2">
                {displaySequence(props)}
            </div>
                {commentTextArea(props)}
        </form>
    </div>
   );
}

export default DictionaryData