import moment from "moment";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { appCred } from "../../AppConfig.js";
import IndividualActivityHeader from "../Headers/IndividualActivityHeader";
import * as Contants from "../../utils/Constants";
import CommentsNResourcesModal from "../modals/IndividualAppCommentModal";
import AddnEditFormModal from "../modals/AddNEditFormModalBody";
import RecordHistoryModal from "../modals/RecordHistoryModal";
import MomentUtils from "@date-io/moment";
import * as CustomFunctions from "./CustomFunctions";
import * as TableFunctions from "./CustomTableControl";
import { TransposingDataFunc } from "./TableDataTransponseFunc.js";
import { Validators } from "../../index.js";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Slider from '@material-ui/core/Slider';
import { makeStyles, Slider } from '@material-ui/core';
import { Typography, withStyles } from '@material-ui/core';
import { toBeRequired } from "@testing-library/jest-dom";

const PrettoSlider = withStyles({
  root: {
    color: '#17a2b8',
    height: 8,
    width: '70%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    width: 'auto', 
    whiteSpace: 'nowrap',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    transform: 'translateY(4px)',
      textAlign: 'center', 
  },
})(Slider);

// Your existing styles
const styles = theme => ({
  slider: {
    width: '70%', // Adjust the width here
    margin: '0 auto',
  },
  sliderContainer: {
    right: '1100px',
    top: '35px',
    
    padding: '20px 0',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    whiteSpace: 'nowrap',
  },
});
const staticDashboardDetails = [
  {
    objMain: {
      SUBACTIVITYID: 'SAP',
      SubActivityDescription: 'Missed Tasks',
      progressPercentage: 100,
      TotalCount: [{ TotalCount: 10}],

    }

  },
  {
    objMain: {
      SUBACTIVITYID: 'SAP',
      SubActivityDescription: 'Completed Tasks',
      progressPercentage: 500,
      TotalCount: [{ TotalCount: 20}],

    }

  },
 
  {
    objMain: {
      SUBACTIVITYID: 'SAP',
      SubActivityDescription: 'Due Tasks',
      progressPercentage: 322,
      TotalCount: [{ TotalCount: 30}],

    }

  },
  // Add more static dashboard items as needed
];

const markValues = [0, 15, 30, 182, 365];
    const totalMarks = markValues.length;
    const equalStep = 100 / (totalMarks - 1);  // Divide the slider into equal steps
    
    const marks = markValues.map((value, index) => ({
      value: index * equalStep,
      label: value === 0 ? 'Today' : value === 15 ? '15 Days' : value === 30 ? '1 month' : value === 182 ? '6 months' : '1 year'
    }));
    
    const valueMapping = (value) => {
      const stepIndex = Math.round(value / equalStep);
      return markValues[stepIndex];
    };
    
    const reverseValueMapping = (value) => {
      const index = markValues.indexOf(value);
      return index * equalStep;
    };


    
const staticUserData = {
  User: {
    UserName: 'John Doe',
    UserOrganizationId: 'ORG123',
    UserOrganizationType: 'Admin',
    lstOrganizations: ['Org1', 'Org2'],
  }
};
const dummyActivityData = {
  ACTFLAG: "R",
  ACTIVE: null,
  ACTIVITYBASELINEDATE: null,
  ACTIVITYCOLEAD: null,
  ACTIVITYENDDATE: null,
  ACTIVITYFLAG: null,
  ACTIVITYID: "SAP",
  ACTIVITYLEAD: null,
  ACTIVITYNINETYPROGRESSDATE: null,
  ACTIVITYSTARTDATE: null,
  ACTIVITYTARGETDATE: null,
  APPLICATIONID: "MT-STR",
  ActivityFor: null,
  COLEAD: null,
  COMMENTENTEREDBY: null,
  COMMENTENTEREDDTTM: "2024-02-15T17:14:08",
  COMMENTFLAG: null,
  COMMENTS: null,
  CalculatedPlannedDateDiff: null,
  Description: "Strategies",
  EndDate: null,
  FACILITYID: "AKUHK",
  FacilityName: "Aga Khan University Hospital, Karachi",
  Flag: null,
  LASTFILEDTTM: null,
  LASTFILETERMINALID: null,
  LASTFILEUSERID: null,
  LEAD: null,
  LastModifiedBy: "SYSTEM",
  LastModifiedDttm: "2024-02-15T17:14:08",
  LoggedInRole: null,
  MTCOLEAD: null,
  MTLEAD: null,
  Progress: null,
  RESOURCENAME: null,
  RESOURCEREGION: null,
  RESOURCETYPE: null,
  Responsibility: null,
  ResponsibilityList: null,
  SUBACTIVITYID: null,
  StartDate: "08/19/2019",
  Targetdate: "2024-12-31T00:00:00",
  TaskName: null,
  Weightage: 5,
  commentsModalOpen: false,
  mnemonic: "STR",
  reftablename: null,
  resourcesModalOpen: false,
  subActData: null,
  subtasks: null,
  new_entry: "",
  Description: "Sub Activities",
  TDateNProgress: { TargetDate: "Dec 31, 2024" },
  TargetDate: "Dec 31, 2024"
};




// This function can be moved inside your component or kept outside as a helper function

class OverallProgressDetailView extends React.Component {

  constructor(props) {
    
    
    super(props);
    this.CommentModalElement = React.createRef();
    this.elRefs = [];
    let user = window.mylsataku.get("cache2");
    this.state = {
      selectedSubActivityDescription: '',
      Missed: "",
      Completed: "",
      Due: "",
      transposedData: null,
      sliderValue: 0,
      isSliderDisabled: false,
      UserOrgId: user.User.UserOrganizationId,
      UserOrgType: user.User.UserOrganizationType,
      FlightCarrierList: user.User.lstOrganizations,
      LoggedInUserName: user.User.UserName,
      UserID: window.mylsataku.get("cache3"),
      tempData: {
        meta: {},
        list: [],
      },
      tempDataList_master: [],
      //BM
      tempDataHeadings: [],
      activityData: [],
      DashboardDetails: staticDashboardDetails,
      SubActivityDictionariesData: [],
      mainActivityProgress: {},
      resourceData: {},
      tableData: [],
      shapeColor: "",
      tableHeading: "",
      modalOpen: false,
      subActivityDescription: "",
      resourcesModalOpen: false,
      commentsModalOpen: false,
      modalHeading: "",
      resourcesData: {},
      formHeading: "",
      showWeightageModal: false,
      subActivityWeightageObject: [],
      WeightageModalHeading: "",
      singleSubActivityWeightage: {},
      SubActivityWeightage: "",
      SubActivityID: "",
      modalData: {},
      

      //Add Record
      addRecord: { ...Contants.intState },
      addRecorderrors: { ...Contants.intState },
      modalContent: "",
      todayDate: moment().toDate(),
      membersList: window.mylsataku.get("cache4"),
      loggedInUserData: {},
      MainActivtyDateNProgress: {},
      actParticipantstags: [], //Application Training Field
      schParticipantstags: [],
      //Add Resource
      resourceName: "",
      resourceRole: "",
      resourceLocation: "",
      resourceID: "",
      ////
      multidefault: "",
      //RecordInfo For History
      recordInfo: {},
      recordHistoryModalOpen: false,
      historyApiRes: {},
      userData: [],

    };
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  componentDidUpdate() {
    window.activatePopoverLoop(this.elRefs);
  }
  componentDidMount() {
    const tempUserID = this.state.UserID.replace("MT\\", "");
    let passedState = window.history.state;
    console.log("passed", passedState);
    this.MissedSubActivityData(this.state.activityData, this.state.DashboardDetails[0]);
    this.CompletedSubActivityData(this.state.activityData, this.state.DashboardDetails[0]);
    this.DueSubActivityData(this.state.activityData, this.state.DashboardDetails[0]);

    if (passedState && passedState.state && passedState.state.item) {
      this.setState(
        {
          activityData: passedState.state.item,
          mainActivityProgress: passedState.state?.progress,
          shapeColor: passedState.state?.hexagoncolor,
          userData: passedState.state?.userAccessData?.UserData,
        },
        () => {
          this.setUserRole(tempUserID);
          this.getAllSubActivity();
        }
      );
    }
    window.CloseNav();
  }

  setUserRole = (tempUserID) => {
    console.log(
      "find",
      this.state.membersList.find((a) => a.RESOURCEUSERID === tempUserID)
    );
    if (this.state.UserOrgType === Contants.AppRoleEnum.Collaborator) {
      const UserDataForApp = this.state.membersList.find(
        (a) =>
          a.APPLICATIONID === this.state.activityData?.APPLICATIONID &&
          a.FACILITYID === this.state.activityData?.FACILITYID &&
          a.RESOURCEUSERID === tempUserID &&
          (a.RESOURCETYPE === "LEAD" ||
            a.RESOURCETYPE === "CO-LEAD" ||
            a.RESOURCETYPE === "MT-LEAD" ||
            a.RESOURCETYPE === "MT-CO-LEAD")
      );
      if (UserDataForApp) {
        this.setState(
          {
            UserOrgType: Contants.AppRoleEnum.Admin,
          },
          console.log("this.state.UserOrgType", this.state.UserOrgType)
        );
      }
    }
  };
  getAllSubActivity = () => {
    let counter = 0;
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
    };
    window
      .APIRequest(appCred, "", "EHRDashboardManager.GetSubActivity", reqData)
      .then((apiRes) => {
        console.log("", apiRes);
        console.log(reqData);
        if (apiRes.ResponseCode == 200) {
          for (let dataItem of apiRes.ResponseResult
            ? apiRes.ResponseResult
            : {}) {
            dataItem.activeClass = false;
            dataItem.subActivityCounter = counter;
            counter++;
          }
          this.setState(
            {
              DashboardDetails: apiRes.ResponseResult,
            },
            () => {
              console.log("DashboardDetails", this.state.DashboardDetails);
              this.getMainActivityTargetDate(
                this.SubActivityGridData(
                  this.state.activityData,
                  this.state.DashboardDetails[0]
                )
              );
              this.GetSubActivityWeightage();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getMainActivityTargetDate = (callbackFunc) => {
    let reqData = {
      APPLICATIONID: this.state.activityData.APPLICATIONID,
      facilityID: this.state.activityData.FACILITYID,
      activityID: this.state.activityData.ACTIVITYID,
    };

    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetActivityMainTargetDttm",
        reqData
      )
      .then((apiRes) => {
        console.log("apiRes.ResponseResult", apiRes.ResponseResult);
        if (apiRes.ResponseCode == 200) {
          this.setState(
            {
              MainActivtyDateNProgress: apiRes.ResponseResult,
            },
            () => {
              if (callbackFunc) {
                callbackFunc();
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  initcomponent = (Data, subActivityName) => {
    let subActivityData = this.state.DashboardDetails.find(
      (a) => a.objMain.SUBACTIVITYID === subActivityName
    );
    console.log("subActivityData", subActivityData);
    let reqData = {
      FacilityID: Data.FACILITYID,
      ActivityID: Data.ACTIVITYID,
      ApplicationID: Data.APPLICATIONID,
    };
    window
      .APIRequest(appCred, "", "EHRDashboardManager.GetSubActivity", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          console.log("ap", apiRes);
          for (let dataItem of apiRes.ResponseResult
            ? apiRes.ResponseResult
            : {}) {
            if (dataItem.objMain.SUBACTIVITYID === subActivityName) {
              dataItem.activeClass = true;
            } else {
              dataItem.activeClass = false;
            }
          }
          this.setState(
            {
              DashboardDetails: apiRes.ResponseResult,
            },
            () => {
              this.getMainActivityTargetDate(
                this.SubActivityGridData(Data, subActivityData)
              );
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  CompletedSubActivityData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetCompletedSubActivityCount",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.state.Completed = apiRes.ResponseResult[0].Completed;
          this.forceUpdate();
          let tempData = this.transposeDataFromAPI(
            apiRes.ResponseResult.ObjMain,
            SubActivityData.objMain.SUBACTIVITYID,
            apiRes.ResponseResult[0].Completed,
          );
          this.setState({
            SubActivityDictionariesData: apiRes.ResponseResult.ObjMain,
            tempData: tempData,
            tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
            Completed: apiRes.ResponseResult[0].Completed

          });

        } else {
          this.setState({
            SubActivityDictionariesData: [],
            tempData: null,
          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };


  CompletedSubActivityGridData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetCompletedSubActivity",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
         debugger;
          let extractedData = apiRes.ResponseResult.map(item => ({
            application: item.Application || '',
            activity: item.Activity || '',
            subActivity: item.Subactivity || '',
            description: item.Description || '',
            weightage: parseInt(item.WEIGHTAGE) || 0,
            status: item.Status || '',
            progress: item.Progress || '',
            baselinedate: item.BaselineDate ? moment(item.BaselineDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '', // Parsing date
            planneddate: item.PlannedDate ? moment(item.PlannedDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '', // Parsing date
            completiondate: item.CompletionDate ? moment(item.CompletionDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '' // Parsing date

          }));
          this.setState({ data: extractedData }, this.transposeData);
          console.log("Data", extractedData);
         
        } else {
          this.setState({

          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };


  MissedSubActivityGridData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetMissedSubActivity",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          debugger;
          let extractedData = apiRes.ResponseResult.map(item => ({
            application: item.Application || '',
            activity: item.Activity || '',
            subActivity: item.Subactivity || '',
            description: item.Description || '',
            weightage: parseInt(item.WEIGHTAGE) || 0,
            status: item.Status || '',
            progress: item.Progress || '',
            baselinedate: item.BaselineDate ? moment(item.BaselineDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '', // Parsing date
            planneddate: item.PlannedDate ? moment(item.PlannedDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '', // Parsing date
            completiondate: ''

          }));
          this.setState({ data: extractedData }, this.transposeData);
          console.log("Data", extractedData);
         
        } else {
          this.setState({

          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };



   DueSubActivityGridData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetDueSubActivity",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
       let extractedData = apiRes.ResponseResult.map(item => ({
            application: item.Application || '',
            activity: item.Activity || '',
            subActivity: item.Subactivity || '',
            description: item.Description || '',
            weightage: parseInt(item.WEIGHTAGE) || 0,
            status: item.Status || '',
            progress: item.Progress || '',
            planneddate: item.PlannedDate ? moment(item.PlannedDate, "DD/MM/YYYY hh:mm:ss A").format('MMM DD, YYYY') : '', // Parsing date
            completiondate: ''
          }));
          this.setState({ data: extractedData }, this.transposeData);
          console.log("Data", extractedData);
         
        } else {
          this.setState({

          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };



  DueSubActivityData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetDueSubActivityCount",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.state.Due = apiRes.ResponseResult[0].Due;
          this.forceUpdate();

          let tempData = this.transposeDataFromAPI(
            apiRes.ResponseResult.ObjMain,
            SubActivityData.objMain.SUBACTIVITYID,
            apiRes.ResponseResult[0].Due,
          );
          this.setState({
            SubActivityDictionariesData: apiRes.ResponseResult.ObjMain,
            tempData: tempData,
            tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
            Due: apiRes.ResponseResult[0].Due

          });

        } else {
          this.setState({
            SubActivityDictionariesData: [],
            tempData: null,
          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };


  MissedSubActivityData = (MainActivityData, SubActivityData) => {
    const plannedDate = this.state.sliderValue;

    let reqData = {
      PlannedDate: plannedDate
    };
    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetMissedSubActivityCount",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.state.Missed = apiRes.ResponseResult[0].Missed;
          this.forceUpdate();
          console.log(apiRes.ResponseResult[0].Missed);

          let tempData = this.transposeDataFromAPI(
            apiRes.ResponseResult.ObjMain,
            SubActivityData.objMain.SUBACTIVITYID,
            apiRes.ResponseResult[0].Missed,
          );
          this.setState({
            SubActivityDictionariesData: apiRes.ResponseResult.ObjMain,
            tempData: tempData,
            tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
            Missed: apiRes.ResponseResult[0].Missed
          }, () => {
            console.log("Updated Missed state:", this.state.Missed);

          });

        } else {
          this.setState({
            SubActivityDictionariesData: [],
            tempData: null,
          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };




  SubActivityGridData = (MainActivityData, SubActivityData) => {
    let reqData = {
      FacilityID: MainActivityData.FACILITYID,
      ActivityID: MainActivityData.ACTIVITYID,
      ApplicationID: MainActivityData.APPLICATIONID,
      RefTableName: SubActivityData.objMain.RefTableName,
      SubActivityID: SubActivityData.objMain.SUBACTIVITYID,
    };

    window.APIRequest(
      appCred, "",
      "EHRDashboardManager.GetSubActivityDictionariesData",
      reqData
    )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          console.log(apiRes.ResponseResult);
          let tempData = this.transposeDataFromAPI(
            apiRes.ResponseResult.ObjMain,
            SubActivityData.objMain.SUBACTIVITYID
          );
          this.elRefs = Array(
            tempData.list.length * Object.keys(tempData.meta).length
          )
            .fill()
            .map((_, i) => this.elRefs[i] || React.createRef());
          console.log("tempData", tempData);
          this.setState(
            {
              SubActivityDictionariesData: apiRes.ResponseResult.ObjMain,
              tableHeading: SubActivityData.objMain.SUBACTIVITYID,
              subActivityDescription:
              SubActivityData.objMain.SubActivityDescription,
              tempData: tempData,
              tempDataList_master: JSON.parse(JSON.stringify(tempData.list)),
            },
            () => {
              if (MainActivityData.ACTIVITYID == "DNB") {
                document.getElementById("Planned_date").click();
              }
            }
          );
        } else {
          this.setState(
            {
              SubActivityDictionariesData: [],
              tableHeading: "",
              tempData: null,
            },
            () => {
              window.activatePopover();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };





  ToggleTableDiv = (e, x, y, propIdx, rowIdx) => {
    let tempData = this.state.tempData;
    let _eleList = tempData.list;
    let _elepropList = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[0])
      : [];
    let _currentEditValue = x[y].isEdit;
    for (let _item of _eleList) {
      for (let _prop of _elepropList) {
        _item[_prop].isEdit = false;
      }
    }

    if (tempData.meta[y].allowEdit) {
      x[y].isEdit = _currentEditValue ? false : true;
      this.setState({
        tempData: this.state.tempData,
        multidefault: "",
      });
    }
  };

  handleModal = (ModalFor) => {
    let reqData = {
      ApplicationID: this.state.activityData.APPLICATIONID,
      ActivityID: this.state.activityData.ACTIVITYID,
      FacilityID: this.state.activityData.FACILITYID,
    };
    if (ModalFor === "Resources") {
      this.setState(
        {
          modalData: this.state.activityData,
          resourcesModalOpen: true,
          commentsModalOpen: this.state.commentsModalOpen
            ? false
            : this.state.commentsModalOpen,
          modalHeading:
            "[" +
            this.state.activityData.APPLICATIONID +
            "] " +
            this.state.activityData.mnemonic +
            " - " +
            this.state.activityData.Description,
        },
        () => {
          this.applicatoinResourcesAPI(reqData);
        }
      );
    } else if (ModalFor === "Comments") {
      this.setState({
        commentsModalOpen: true,
        modalOpen: this.state.modalOpen ? false : this.state.modalOpen,
        modalHeading:
          "[" +
          this.state.activityData.APPLICATIONID +
          "] " +
          this.state.activityData.mnemonic +
          " - " +
          this.state.activityData.Description,
      });
    }
  };

  renderCustomTime(item) {
    let isValidTime = moment(item.value, "YYYY-MM-DDTHH:mm:ss", true).isValid();
    if (isValidTime) {
      return (
        <label style={{ width: "126px" }}>
          {moment(item.value).format("hh:mm A")}
        </label>
      );
    }
 
  }

  renderCellWeightage(item) {
    return item.value.toString();
  }

  renderCellBuildProgress(item,weightage) {
    
 
    let roundedProgressPercentageValue = item.value ? item.value : 0;

    var customSpan = function (badge) {
      
      return (
        <h6>
          <span className={"badge badge-" + badge}>{item.value}%</span>
        </h6>
      );
      
    };
    
    if (item.value < 90) {
      return customSpan("danger");
    } else if (item.value >= 90 ) {
        return customSpan("success");
    } 
    else if (item.value == 100) {
      return customSpan("success");
  }
   else {
      return <span>{item.value}%</span>;
    }
  }

  renderCellBuildStatus(item) {
       
    var customSpan = function (badge) {
      return (
        <h6>
          <span className={"badge badge-" + badge}>{item.value}</span>
        </h6>
      );
   
    };

    if (item.value === "Not Required") {
      return customSpan("warning");
    } else if (item.value === "Completed") {
        return customSpan("success");}
        else if (item.value === "In-Progress") {
          return customSpan("success");}
          else if (item.value === "Outstanding") {
            return customSpan("danger");
          }
       else {
      return <span>{item.value}</span>;
    }
  }

  

  transposeData = () => {
    const { data, activityName } = this.state;
    const tempData = this.transposeDataFromAPI(data, activityName);
    this.setState({ tempData });
    console.log(tempData.meta);
    console.log(tempData.list);
   
  };

 

   transposeDataFromAPI = (list, activityName) => {
    let _list = JSON.parse(JSON.stringify(list));
    const meta_API = TransposingDataFunc(
      activityName,
      this.state.UserOrgType,
      this.state.loggedInUserData
    );
  
    const defaultMeta = {
      activityIdentifier: { heading: 'Activity', isVisible: true, type: "custom", isCustomRender: true,customRenderFunc: this.customRenderFuncforTable },
      description: { heading: 'Description', isVisible: true, type: "text" },
      status: { type: "text", heading: 'Status', isVisible: true, isCustomRender: true, customRenderFunc: this.renderCellBuildStatus },
      progress: { heading: 'Progress', isVisible: true, isCustomRender: true, customRenderFunc: this.renderCellBuildProgress },
      planneddate: { heading: 'Planned Date', isVisible: true },
      completiondate: { heading: 'Completion Date', isVisible: true }
    };
  
    const meta = meta_API || defaultMeta;
  debugger;
    for (let item of _list) {
      item.activityIdentifier = {
        application: item.application,
        activity: item.activity,
        subActivity: item.subActivity
      };
      let keys = Object.keys(item);
      for (let key of keys) {
        if (
          key === "BASELINEDATE" ||
          key === "PLANNEDDATE" ||
          key === "ACTUALCOMPLETIONDATE" ||
          key === "DETAILSSHAREDWITHMTDT" ||
          key === "SPECSRECDFROMMTDT" ||
          key === "SPECSAPPROVEDBYAKUDT" ||
          key === "ACTDATE" ||
          key === "SCHDATE"
        ) {
          if (item[key]) {
            item["custom" + key] = {
              value: moment(item[key]).format("MMM DD, YYYY"),
              isEdit: false,
            };
          } else {
            item["custom" + key] = { value: "", isEdit: false };
          }
        }
        item[key] = { value: item[key], isEdit: false };
      }
    }
  
    let tempData = {
      actionCol: {
        isVisible: true,
        buttons: [
          {
            caption: "Edit",
            callbackFunc: this.EditRow,
          },
        ],
      },
      meta: meta,
      list: _list,
    };
   const tempDataHeadings = Object.keys(meta).map(key => meta[key].heading);

  this.setState({
    tempData,
    tempDataHeadings,
  });

  return tempData;
  };
  


  customRenderFuncforTable = (row) => {
    const { value } = row;
    const activityIdentifier = value;
  
    console.log("activityIdentifier:", activityIdentifier); // Debugging
  debugger;
    if (!activityIdentifier) return '';
  
    return (
      <div>
        <div style={{fontSize:'12px'}}>{activityIdentifier.application}</div>
        <div style={{ fontSize:'12px'}}>  
       {activityIdentifier.activity}
        </div>
        <div style={{ fontSize:'14px' ,color: '#5A9BD3'}}>
         {activityIdentifier.subActivity}
        </div>
      </div>
    );
  };
  






  getSubActivtyDicData = (e, data) => {
    e.preventDefault();
    this.state.DashboardDetails.forEach((item) => {
      item.activeClass = false;
    });
    data.activeClass = true;
    this.setState(
      {
        DashboardDetails: this.state.DashboardDetails,
      },
      () => {
        this.SubActivityGridData(this.state.activityData, data);
      }
    );
  };


  getSubActivtyDueData = (e, data) => {
    e.preventDefault();
    this.state.DashboardDetails.forEach((item) => {
      item.activeClass = false;
    });
    data.activeClass = true;
    this.setState(
      {
        DashboardDetails: this.state.DashboardDetails,
      },
      () => {
        this.DueSubActivityData(this.state.activityData, data);
        this.DueSubActivityGridData(this.state.activityData, data);
      }
    );
  };

  getSubActivtyMissedData = (e, data) => {

    e.preventDefault();
    this.state.DashboardDetails.forEach((item) => {
      item.activeClass = false;
    });
    data.activeClass = true;
    this.setState(
      {
        DashboardDetails: this.state.DashboardDetails,
      },
      () => {
        this.MissedSubActivityData(this.state.activityData, data);
        this.MissedSubActivityGridData(this.state.activityData, data);
      }
    );
  };



  getSubActivtyCompletedData = (e, data) => {

    e.preventDefault();
    this.state.DashboardDetails.forEach((item) => {
      item.activeClass = false;
    });
    data.activeClass = true;
    this.setState(
      {
        DashboardDetails: this.state.DashboardDetails,
      },
      () => {
        this.CompletedSubActivityData(this.state.activityData, data);
        this.CompletedSubActivityGridData(this.state.activityData, data);
      }
    );
  };


  handleOnChange = (e) => {
    this.setState({
      addRecorderrors: { ...Contants.intState },
    });
    if (
      this.state.UserOrgType != Contants.AppRoleEnum.Admin &&
      e.target.name === "NewWeightage" &&
      (e.target.value === "2" || e.target.value == "4")
    ) {
      ToastsStore.error("This value can only be set by admin", 5000);
    } else {
      this.state.addRecord[e.target.name] = e.target.value;
      this.setState({
        addRecord: this.state.addRecord,
      });
    }
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  setErrors = (errors) => {
    //For Validation set error msgs
    this.setState({
      addRecorderrors: errors,
    });
  };

 

  // Input Handlers

  handleSliderChange = (event, newValue) => {
    this.setState({ sliderValue: newValue }, () => {

      if (this.state.selectedSubActivityDescription === 'Completed Tasks') {
        this.CompletedSubActivityData(this.state.activityData, this.state.SubActivityDictionariesData);
        this.CompletedSubActivityGridData(this.state.activityData, this.state.SubActivityDictionariesData);
      } else if (this.state.selectedSubActivityDescription === 'Due Tasks') {
        this.DueSubActivityData(this.state.activityData, this.state.SubActivityDictionariesData);
        this.DueSubActivityGridData(this.state.activityData, this.state.SubActivityDictionariesData);
      }
     });
  };


  handleDateTimeChange = (name, DateTime) => {
    this.state.addRecord[name] = DateTime;
    this.setState({
      addRecorderrors: { ...Contants.intState },
      name: this.state.addRecord,
    });
  };
  handleDeletetags = (i) => {
    const _actParticipantstags = this.state.actParticipantstags;
    this.setState({
      actParticipantstags: _actParticipantstags.filter((index) => index !== i),
    });
  };
  handleAdditiontags = (tag) => {
    if (!this.state.actParticipantstags) {
      this.setState({
        actParticipantstags: [],
      });
    }
    this.setState((state) => ({
      actParticipantstags: [...state.actParticipantstags, tag],
    }));
  };
  handleDragtags = (tag, currPos, newPos) => {
    const tags = [...this.state.actParticipantstags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ actParticipantstags: newTags });
  };
  handleDeleteschtags = (i) => {
    const _schParticipantstags = this.state.schParticipantstags;
    this.setState({
      schParticipantstags: _schParticipantstags.filter(
        (schParticipantstags, index) => index !== i
      ),
    });
  };
  handleAdditionschtags = (tag) => {
    if (!this.state.schParticipantstags) {
      this.setState({
        schParticipantstags: [],
      });
    }
    this.setState((state) => ({
      schParticipantstags: [...state.schParticipantstags, tag],
    }));
  };
  handleDragschtags = (tag, currPos, newPos) => {
    const tags = [...this.state.schParticipantstags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ schParticipantstags: newTags });
  };
  handleOnChangeMulti = (e) => {
    const NewData = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    if (this.state.addRecord.NewTestCaseResult) {
      this.state.addRecord.NewTestCaseResult =
        this.state.addRecord.NewTestCaseResult.concat(
          "|",
          e.target.value,
          " ",
          NewData
        );
    } else {
      this.state.addRecord.NewTestCaseResult = e.target.value + " " + NewData;
    }
    this.setState({
      addRecord: this.state.addRecord,
      multidefault: e.target.value,
    });
  };
  handleChangeForEvent = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  
  copyTableData = () => {
    const { tempData, tempDataHeadings } = this.state;
  
    console.log("tempData:", tempData);
    console.log("tempDataHeadings:", tempDataHeadings);
  
    // Check if tempData and its necessary properties exist
    if (!tempData || !tempData.list || !tempDataHeadings || tempDataHeadings.length === 0) {
      alert("No data available to copy");
      return;
    }
  
    console.log("tempData.list:", tempData.list);
  
    // Convert table data to a string format
    let tableString = tempDataHeadings.join("\t") + "\n";
  
    tempData.list.forEach(row => {
      let rowString = tempDataHeadings.map(heading => {
        // Extract the value from the row based on the heading
        let cellValue = '';
        for (let key in tempData.meta) {
          if (tempData.meta[key].heading === heading) {
            cellValue = row[key]?.value || ''; // Get the value of the cell or an empty string if undefined
            break;
          }
        }
  
        // If the cell value is an object, serialize it to a string
        if (typeof cellValue === 'object' && cellValue !== null) {
          if (cellValue.application || cellValue.activity || cellValue.subActivity) {
            // Special handling for activityIdentifier objects
            cellValue = `${cellValue.application || ''} ${cellValue.activity || ''} ${cellValue.subActivity || ''}`;
          } else {
            cellValue = JSON.stringify(cellValue);
          }
        }
  
        return cellValue;
      }).join("\t");
      tableString += rowString + "\n";
    });
  
    console.log("tableString:", tableString);
  
    // Copy the table string to the clipboard
    navigator.clipboard.writeText(tableString)
      .then(() => alert("Table data copied to clipboard"))
      .catch(err => alert("Failed to copy data: " + err));
  };
  
  
  //handlers end-------

  sortTable = (item) => {
    const _tempData = TableFunctions.sortTableFunc(item, this.state.tempData);
    this.setState({
      tempData: _tempData,
    });
  };
  searchTypedKeyword = (e) => {
    let allVisibleProps = Object.entries(this.state.tempData.meta)
      .filter((a) => a[1].isVisible)
      .map((m) => m[0]);
    let newList = this.state.tempDataList_master.filter((a) => {
      for (let prop of allVisibleProps) {
        if (
          a[prop].value &&
          a[prop].value
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return a;
        }
      }
    });
    this.state.tempData.list = newList;
    this.setState({
      tempData: this.state.tempData,
    });
  };
  CloseCommentNResourecsModal = () => {
    this.setState({
      resourcesModalOpen: false,
      commentsModalOpen: false,
      resourcesData: {},
      modalHeading: "",
    });
  };
  closeAddNEditFormModal = () => {
    this.setState({
      modalOpen: false,
      addRecord: { ...Contants.intState },
      addRecorderrors: { ...Contants.intState },
      actParticipantstags: [],
      schParticipantstags: [],
      multidefault: "",
    });
  };
  GetSubActivityWeightage = () => {
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetAppSubActivityWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.setState({
            subActivityWeightageObject: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleWeightageChangeModal = (data) => {
    let singleSubActivityWeightage = this.state.subActivityWeightageObject.find(
      (a) => a.SubActivityID === data.objMain.SUBACTIVITYID
    );
    this.setState({
      WeightageModalHeading: data.objMain.SubActivityDescription,
      showWeightageModal: true,
      SubActivityID: data.objMain.SUBACTIVITYID,
      SubActivityWeightage: singleSubActivityWeightage
        ? singleSubActivityWeightage.WHEIGHTAGE
        : 0,
    });
  };
  saveSubactivityWeightage = () => {
    let reqData = {
      FacilityID: this.state.activityData.FACILITYID,
      ActivityID: this.state.activityData.ACTIVITYID,
      ApplicationID: this.state.activityData.APPLICATIONID,
      Weightage: parseInt(this.state.SubActivityWeightage),
      SUBACTIVITYID: this.state.SubActivityID,
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveAppSubActivityWeightage",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          this.setState(
            {
              showWeightageModal: false,
              WeightageModalHeading: "",
              SubActivityWeightage: "",
              SubActivityID: "",
            },
            this.getMainActivityTargetDate(),
            this.GetSubActivityWeightage(),
            this.getAllSubActivity(),
            () => {
              ToastsStore.success("Saved Successfully");
            }
          );
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  applicatoinResourcesAPI = (reqData) => {
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetApplicationResources",
        reqData
      )
      .then((apiRes) => {
        console.log("RES", apiRes);
        if (apiRes.ResponseCode == 200) {
          this.setState(
            {
              resourcesData: apiRes.ResponseResult,
            },
            () => {
              console.log("resourcesData", this.state.resourcesData);
            }
          );
        } else {
          this.setState({
            resourcesData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add New Resource -------
  addNewResource = () => {

    const cModalState = this.CommentModalElement.current;
    let reqResourceData = {
      ApplicationID: this.state.activityData.APPLICATIONID,
      ActivityID: this.state.activityData.ACTIVITYID,
      FACILITYID: this.state.activityData.FACILITYID,
    };
    let reqData = {
      FACILITYID: this.state.activityData.FACILITYID,
      APPLICATIONID: this.state.activityData.APPLICATIONID,
      ACTIVITYID: this.state.activityData.ACTIVITYID,
      RESOURCENAME: this.state.resourceName,
      RESOURCEREGION: this.state.resourceLocation,
      _ACTIVE: "Y",
      RESOURCEUSERID: this.state.resourceID,
      RESOURCETYPE: this.state.resourceRole,
      LASTFILEUSERID: this.state.UserID,
    };
    console.log("reqData", reqData);
    window
      .APIRequest(appCred, "", "EHRDashboardManager.SaveResources", reqData)
      .then((apiRes) => {
        if (apiRes.ResponseResult === true) {
          console.log("RES", apiRes);
          this.setState(
            {
              resourceName: "",
              resourceLocation: "",
              resourceID: "",
              resourceRole: "",
            },
            () => this.applicatoinResourcesAPI(reqResourceData),
            cModalState.changeAddResource(false)
          );
          ToastsStore.success("Saved Successfully");
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End ------
  clearResourceInput = () => {
    this.setState({
      resourceName: "",
      resourceLocation: "",
      resourceID: "",
      resourceRole: "",
    });
  };
  copyFromSchToAct = (e) => {
    e.preventDefault();
    const GetDataFromFunction = CustomFunctions.copyFromSchToAct(
      this.state.addRecord
    );
    this.setState({
      addRecord: GetDataFromFunction,
      actParticipantstags: this.state.schParticipantstags,
    });
  };
  openRecordHistory = (x) => {
    let reqData = CustomFunctions.recordHistoryObj(x);
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetSubActivityDictionariesDataLog",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode == 200) {
          this.setState({
            historyApiRes: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      recordInfo: x,
      recordHistoryModalOpen: true,
    });
  };
  recordHistoryModalClose = () => {
    this.setState({
      recordInfo: {},
      recordHistoryModalOpen: false,
      historyApiRes: {},
    });
  };
  DoNothing = () => { };
  
  


  handleClick = (e, item) => {
    const description = item.objMain.SubActivityDescription;
let data = null;
let isSliderDisabled = false;

if (description === 'Completed Tasks') {
  this.getSubActivtyCompletedData(e, item);
  this.setState({ isSliderDisabled: false });
} else if (description === 'Missed Tasks') {
  this.getSubActivtyMissedData(e, item);
  this.setState({ isSliderDisabled: true });
} else if (description === 'Due Tasks') {
  this.getSubActivtyDueData(e, item);
  this.setState({ isSliderDisabled: false });
}
    if (!data || data.length === 0) {
      this.setState({ 
        selectedSubActivityDescription: description,
        tempData: null, // Set tempData to null if no data is returned
      });
    } else {
      this.setState({ 
        selectedSubActivityDescription: description,
        tempData: data,
      });
    }
    this.setState({ selectedSubActivityDescription: description });
  };

  
  
  render() {
    const getSliderLabel = (value) => {
      if (value === 0) return 'Till Today';
      if (value <= 30) return 'During Last 1 month';
      if (value <= 60) return 'During Last 2 months';
      if (value <= 90) return 'During Last 3 months';
      if (value <= 120) return 'During Last 4 months';
      if (value <= 150) return 'During Last 5 months';
      if (value <= 182) return 'During Last 6 months';
      if (value <= 212) return 'During Last 7 months';
      if (value <= 243) return 'During Last 8 months';
      if (value <= 273) return 'During Last 9 months';
      if (value <= 304) return 'During Last 10 months';
      if (value <= 334) return 'During Last 11 months';
      if (value <= 365) return 'During Last 1 year';
     
    };


    const getSliderLabelForSlider = (value) => {
      if (value === 0) return 'Today';
      if (value <= 30) return '1';
      if (value <= 60) return '2';
      if (value <= 90) return '3';
      if (value <= 120) return '4';
      if (value <= 150) return '5';
      if (value <= 182) return '6';
      if (value <= 212) return '7';
      if (value <= 243) return '8';
      if (value <= 273) return '9';
      if (value <= 304) return '10';
      if (value <= 334) return '11';
      if (value <= 365) return '1 year';
     
    };

   
    const { selectedSubActivityDescription, activityData, subActivityDescription } = this.state;
    let summaryText = subActivityDescription;
    const { sliderValue } = this.state;

    if (selectedSubActivityDescription === 'Completed Tasks') {
      summaryText = 'Completed Tasks Summary';
    } else if (selectedSubActivityDescription === 'Missed Tasks') {
      summaryText = 'Missed Tasks Summary';
    } else if (selectedSubActivityDescription === 'Due Tasks') {
      summaryText = 'Due Tasks Summary';
    }

    const { DashboardDetails, Missed } = this.state;
  
    console.log("Render Missed state:", Missed);
    
    let tempData = this.state.tempData;
    console.log("this.state?.DashboardDetails", tempData);
    let SubActivtyData = this.state?.DashboardDetails;
    let tempDataKeys = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[0])
      : [];
    let tempDataHeadings = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => m[1].heading)
      : [];
    let tempDataisEditable = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].allowEdit)
        .map((m) => m[1].heading)
      : [];
    let tempDataSortType = tempData
      ? Object.entries(tempData.meta)
        .filter((a) => a[1].isVisible)
        .map((m) => {
          return { key: m[0], value: m[1].sortType };
        })
      : [];
    tempDataSortType = tempDataSortType.map((a) => a.value);

    // console.log("tempData.list", tempData.list);
    // console.log("tempData.ey", tempDataKeys);
    return (
      <div style={{ overflowX: "hidden" }}>
       
        <div className={SubActivtyData.length > 3 ? "row-style" : ""}>
          {/* <div className={(SubActivtyData.length>2 ? "d-flex justify-content-center " : "") + ("row ml-4 mt-3 mb-3 ")}> */}
          <div className="row ml-4 mt-3 mb-3 ">
            {SubActivtyData ? (
              SubActivtyData.map((item, index) => {
                let roundedProgressPercentageValue = item.objMain
                  .progressPercentage
                  ? Math.round(item.objMain.progressPercentage)
                  : 0;
                let formattedItemDate = moment(item.objMain.Targetdate).format(
                  "MM/DD/YYYY"
                );
                let dateDiff = moment()
                  .startOf("day")
                  .diff(formattedItemDate, "days");
                CustomFunctions.setTileStyle(
                  dateDiff,
                  roundedProgressPercentageValue
                );

                //On Click box style
                let defaultTileActive = SubActivtyData.find(
                  (a) => a.activeClass === true
                );
                CustomFunctions.selectedTileStyle(
                  defaultTileActive,
                  index,
                  item?.activeClass,
                  SubActivtyData.length
                );
                const hexagonStyle =(hex_color)=>{
                  return hex_color+" text-center"
                }
                let hexagonColorClass = "";
              if (item.objMain.SubActivityDescription === "Completed Tasks") {
                hexagonColorClass = hexagonStyle("individual-greenhexagon")
              } else if (item.objMain.SubActivityDescription === "Missed Tasks") {
                hexagonColorClass = hexagonStyle("individual-redhexagon")
              } else if (item.objMain.SubActivityDescription === "Due Tasks") {
                hexagonColorClass = hexagonStyle("individual-bluehexagon")
              } else {
                hexagonColorClass = CustomFunctions.TileProperties.individualHexagon; // Fallback to default style
              }


                return (
                  <div
                    className={CustomFunctions.TileProperties.cardBoxClass}
                    style={CustomFunctions.TileBackgroundStyle}
                    key={index}
                    onClick={(e) => this.handleClick(e, item)}
                  >
                    {/* <div className="col-12 ml-1 pr-0 pl-1" style={{ paddingRight: "0px" }}> */}
                    <div className="row">
                      <div className="col-9 pr-0">
                        <div className="row ml-2">
                          <h3
                            className="text-dark"
                            style={{
                              margin: "10px auto 5px",
                              marginLeft: "30px",
                            
                              textAlign: "center",
                              fontSize: "18px",
                              //paddingLeft: 10,
                              paddingTop: 15,
                              
                            }}

                          >
                            {item.objMain.SubActivityDescription}
                            {/* [MT-01] HIM */}
                          </h3>
                        </div>


                      </div>
                      <div className="col-3 pt-2 pr-0 pl-3">
                        <div
                          className={
                            hexagonColorClass
                          }
                        >

                          <span
                            className="text-dark"
                            style={{ fontSize: "16px", fontWeight: 500 }}
                          >
                             {item.objMain.SubActivityDescription === 'Missed Tasks' && this.state.Missed}
                            {item.objMain.SubActivityDescription === 'Completed Tasks' && this.state.Completed}
                           
                            {item.objMain.SubActivityDescription === 'Due Tasks' && this.state.Due}
                          </span>

                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: 0,
                        height: 1,
                        marginBottom: "10px",
                        marginTop: "6px",
                        backgroundImage:
                          "linear-gradient(to right, rgba(0, 0, 0, 0), rgb(17 16 16), rgba(0, 0, 0, 0))",
                      }}
                    />
                    <div
                      className="row mb-2"
                      style={{ lineHeight: "1", height: "30px" }}
                    >
                      <div className="col-11 pl-0 pr-0">
                        <div className="text-left">
                          <span
                            className="label-color-individual mb-1"
                            style={{
                              fontSize: "12px",
                              paddingLeft: "50px"
                            }}
                          >
                            <i
                              className="text-secondary text-sm"
                              style={{ paddingRight: "1px" }}
                            >
                              {" "}
                            </i>
                            {item.objMain.SubActivityDescription === 'Completed Tasks' && 'Completed Tasks'}
          {item.objMain.SubActivityDescription === 'Missed Tasks' && 'Total Missed Activities Till Now'}
          {item.objMain.SubActivityDescription === 'Due Tasks' && 'Due Tasks'}
          {item.objMain.SubActivityDescription === 'Completed Tasks' && (
           <label className="text-center ml-1" style={{ color: 'green', background: 'none', border: 'none', padding: '0' }}>
            {getSliderLabel(sliderValue)}
         </label>
          )}


         
          {item.objMain.SubActivityDescription === 'Due Tasks' && (
           <label className="text-center ml-1" style={{ color: 'Blue', background: 'none', border: 'none', padding: '0' }}>
                 {sliderValue === 0 ? 'Today' : `In Next ${getSliderLabel(sliderValue).replace('During Last ', '')}`}
            </label>
          )}
                          </span>
                       
                          
                        </div>
                      </div>
                     
                    </div>
                    {/* </div> */}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className="col-12 mt-1"
          style={{ backgroundColor: "white", borderRadius: "15px" }}
        >

<div className="row align-items-center">
  <div className="col-6">
    <h6 className="ml-3">{summaryText}</h6>
  </div>

 
  <div className="col-6" style={{ paddingLeft: '2px' }} >
    <div className="d-flex justify-content-between align-items-center">
    <div className={this.props.classes.slider}>
        <div className={`${this.props.classes.sliderContainer} p-2 m-2`}>
          <div className={this.props.classes.flexContainer}>
            <Typography id="discrete-slider" gutterBottom>
              Date Range
            </Typography>
            <PrettoSlider
              defaultValue={0}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 0, label: 'Today' },
                { value: 182, label: '6 months' },
                { value: 365, label: '1 year' },
              ]}
              min={0}
              max={365}
              valueLabelFormat={getSliderLabelForSlider}
              onChange={this.handleSliderChange}
              disabled={this.state.isSliderDisabled}
            />
          </div>
        </div>
        
      </div>
      <button
          className="btn btn-info btn-sm mb-2"
          style={{ color: "white", width: "80px" , marginRight: "5px" }}
          onClick={this.copyTableData}
        >
          Copy 
        </button>
     
      <Link
        to={{
          pathname: "/ApplicationsMain",
          state: {
            facilityID: this.state.activityData.FACILITYID,
            activityID: this.state.activityData.ACTIVITYID,
            thruPSB: true,
          },
        }}
      >
        <button
          className="btn btn-info btn-sm mb-2"
          style={{ color: "white", width: "80px" }}
        >
          Return
        </button>
      </Link>

     
      
    </div>
    
  </div>
</div>



          {tempData && tempData.meta ? (
            <div
              className="col-12"
              style={{ height: "308px", overflowY: "scroll", marginTop: "4px" }}
            >
              <table className="table table-bordered table-striped">
                {tempData.meta != null && tempDataHeadings.length > 0 ? (
                  <thead>
                    {this.state.activityData.ACTIVITYID === "UT" &&
                      this.state.activityData.Description !=
                      "Training Planning" && this.state.activityData.Description !=
                      "Expanse System Demonstration" ? (
                      <tr>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                        ></th>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                          className="text-center"
                        >
                          Scheduled
                        </th>
                        <th
                          colSpan="5"
                          style={Contants.Trainingtheadstickyclass}
                          className="text-center"
                        >
                          Actual
                        </th>
                        <th
                          colSpan="3"
                          style={Contants.Trainingtheadstickyclass}
                        ></th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th
                        className="hideextra"
                        style={Contants.theadstickyclass}
                      >
                        S. No
                      </th>
                      {tempDataHeadings.map((item, i) => (
                        <th
                          scope="col"
                          style={Contants.theadstickyclass}
                          className={TableFunctions.tableCellSize(item)}
                          key={i}
                          onClick={(e) => {
                            e.preventDefault();
                            this.sortTable(item);
                          }}
                          id={
                            item === "Planned Date"
                              ? "Planned_date"
                              : "column" + i
                          }
                        >
                          {tempDataSortType[i] ? (
                            tempDataSortType[i] === "asc" ? (
                              <i
                                className="fa fa-long-arrow-down"
                                aria-hidden="true"
                              >
                                &nbsp;
                              </i>
                            ) : (
                              <i
                                className="fa fa-long-arrow-up"
                                aria-hidden="true"
                              >
                                &nbsp;
                            </i>
                            )
                          ) : (
                            <></>
                          )}
                          {item}{" "}
                          {tempDataisEditable.indexOf(item) > -1 ? (
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          ) : (
                            <></>
                          )}
                        </th>
                      ))}
                      
                    </tr>
                  </thead>
                ) : (
                  <div>No Data Found</div>
                )}
                {tempData.list && tempData.list.length > 0 ? (
                  <tbody>
                    {tempData.list.map((x, rowIdx) => (
                      <tr key={rowIdx}>
                        <td>{rowIdx + 1}</td>
                        {tempDataKeys.map(
                          (y, propIdx) =>
                            x[y].isEdit ? (
                              <td
                                key={propIdx}
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              >
                                {(() => {
                                  switch (tempData.meta[y].type) {
                                    case "text":
                                      return (
                                        <input
                                          autoFocus
                                          type="text"
                                          value={x[y].value}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        />
                                      );
                                    case "comment":
                                      return (
                                        <textarea
                                          autoFocus
                                          type="text"
                                          value={x[y].value}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                          cols={33}
                                          rows={2}
                                        />
                                      );
                                    case "radio":
                                      return tempData.meta[y].options.map(
                                        (ropt, z) => (
                                          <>
                                            <input
                                              autoFocus
                                              name={y + rowIdx}
                                              type="radio"
                                              key={z}
                                              onKeyDown={(e) => {
                                                this._handleKeyDown(
                                                  e,
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }}
                                              checked={x[y].value == ropt}
                                              value={ropt}
                                              onChange={(e) => {
                                                x[y].value = ropt;
                                                this.setState({
                                                  tempData: this.state.tempData,
                                                });
                                              }}
                                            />
                                            <label htmlFor={ropt}>{ropt}</label>
                                          </>
                                        )
                                      );
                                    case "checkbox":
                                      return tempData.meta[y].options.map(
                                        (ropt, z) => (
                                          <>
                                            <input
                                              autoFocus
                                              name={y + rowIdx}
                                              key={z}
                                              onKeyDown={(e) => {
                                                this._handleKeyDown(
                                                  e,
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }}
                                              checked={
                                                x[y].value
                                                  .split(",")
                                                  .includes(ropt)
                                                  ? true
                                                  : false
                                              }
                                              type="checkbox"
                                              value={ropt}
                                              onChange={(e) => {
                                                let tmpArr =
                                                  x[y].value.split(",");
                                                if (e.target.checked == true) {
                                                  tmpArr.push(ropt);
                                                } else {
                                                  tmpArr = tmpArr.filter(
                                                    (a) => !(a == ropt)
                                                  );
                                                }
                                                console.log(
                                                  "checked",
                                                  e.target.checked
                                                );
                                                x[y].value = tmpArr.toString();
                                                this.setState({
                                                  tempData: this.state.tempData,
                                                });
                                              }}
                                            />
                                            <label htmlFor={ropt}>{ropt}</label>
                                          </>
                                        )
                                      );
                                    case "multiselect":
                                      return (
                                        <select
                                          autoFocus
                                          defaultValue=""
                                          value={this.state.multidefault} //{x[y].value.substring(x[y].value.lastIndexOf('|')+1)}
                                          style={{ maxWidth: "215px" }}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            console.log(x[y].value);
                                            if (x[y].value) {
                                              x[y].value = x[y].value.concat(
                                                "|",
                                                e.target.value
                                              );
                                            } else {
                                              x[y].value = e.target.value;
                                            }

                                            console.log("change", x[y].value);
                                            this.setState({
                                              multidefault: x[
                                                y
                                              ].value.substring(
                                                x[y].value.lastIndexOf("|") + 1
                                              ),
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        >
                                          {tempData.meta[y].options.map(
                                            (ropt, z) => (
                                              <option key={z} value={ropt}>
                                                {tempData.meta[y].displays[z]}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      );
                                    case "select":
                                      return (
                                        <select
                                          autoFocus
                                          value={x[y].value}
                                          style={{ maxWidth: "215px" }}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              x,
                                              y,
                                              propIdx,
                                              rowIdx
                                            );
                                          }}
                                          onChange={(e) => {
                                            x[y].value = e.target.value;
                                            this.setState({
                                              tempData: this.state.tempData,
                                            });
                                          }}
                                        >
                                          {tempData.meta[y].options.map(
                                            (ropt, z) => (
                                              <option key={z} value={ropt}>
                                                {tempData.meta[y].displays[z]}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      );
                                    case "date":
                                      return (
                                        <DatePicker
                                          style={{ zIndex: "100" }}
                                          selected={
                                            x[y].value
                                              ? moment(
                                                x[y].value,
                                                "MMM DD, YYYY"
                                              ).toDate()
                                              : new Date()
                                          }
                                          value={x[y].value}
                                          popperPlacement="bottom-end"
                                          popperProps={{
                                            positionFixed: true,
                                          }}
                                          onChange={(e) => {
                                            x[y].value =
                                              moment(e).format("MMM DD, YYYY");
                                            x[y].isEdit = false;
                                            x[
                                              y.toString().replace("custom", "")
                                            ].value = moment(e).format(
                                              "YYYY-MM-DDTHH:mm:ss"
                                            );
                                            this.setState(
                                              {
                                                tempData: this.state.tempData,
                                              },
                                              () => {
                                                this._handleKeyDown(
                                                  { key: "Enter" },
                                                  x,
                                                  y,
                                                  propIdx,
                                                  rowIdx
                                                );
                                              }
                                            );
                                          }}
                                        />
                                      );
                                    case "time":
                                      return (
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardTimePicker
                                            value={x[y].value}
                                            onChange={(value) => {
                                              x[y].value = value;
                                              this.setState({
                                                tempData: this.state.tempData,
                                              });
                                            }}
                                            onKeyDown={(e) => {
                                              this._handleKeyDown(
                                                e,
                                                x,
                                                y,
                                                propIdx,
                                                rowIdx
                                              );
                                            }}
                                            style={{ width: 126 }}
                                            autoComplete="off"
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      );
                                    default:
                                      return <></>;
                                  }
                                })()}
                              </td>
                            ) : tempData.meta[y].isCustomRender ? ( //1st con start
                              <td
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              >
                                {tempData.meta[y].customRenderFunc(
                                  x[y],
                                  x.PLANNEDDATE ? x.PLANNEDDATE.value : "",
                                  x.SCHDATE ? x.SCHDATE.value : "",
                                  x.WEIGHTAGE ? x.WEIGHTAGE.value : "",
                                  x.BUILDPROGRESS ? x.BUILDPROGRESS.value : "",
                                  x.PROGRESS ? x.PROGRESS.value : ""
                                )}
                              </td>
                            ) : x[y].value ? ( //2nd in 1st start
                              x[y].value.toString().length < 35 ? ( //3rd in 2nd start
                                <td
                                  onDoubleClick={(e) => {
                                    this.ToggleTableDiv(
                                      e,
                                      x,
                                      y,
                                      propIdx,
                                      rowIdx
                                    );
                                  }}
                                >
                                  <label
                                    className={
                                      tempData.meta[y].style === "blink"
                                        ? x.CalculatedPlannedDateDiff
                                          ? x.CalculatedPlannedDateDiff.value
                                            ? x.CalculatedPlannedDateDiff
                                              .value === "Y"
                                              ? "customPlannedDatedStyle"
                                              : "demo"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    {x[y].value}
                                  </label>
                                </td>
                              ) : (
                                <td
                                  ref={
                                    this.elRefs[(rowIdx + 1) * (propIdx + 1)]
                                  }
                                  onDoubleClick={(e) => {
                                    window.deactivatePopover(e.target);
                                    this.ToggleTableDiv(
                                      e,
                                      x,
                                      y,
                                      propIdx,
                                      rowIdx
                                    );
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  data-html="true"
                                  title=""
                                  data-original-title={CustomFunctions.fullInformation(
                                    x[y].value
                                  )}
                                >
                                  {x[y].value.toString().substring(0, 34) +
                                    "..."}
                                </td>
                              ) //3rd end
                            ) : (
                              <td
                                onDoubleClick={(e) => {
                                  this.ToggleTableDiv(e, x, y, propIdx, rowIdx);
                                }}
                              ></td>
                            ) //1st and 2nd end
                        )}
                        
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
            </div>
          ) : (
            "No Data Found"
          )}






        </div>

        <div className="text-right col-6 p-1 m-0 "></div>
        <Modal isOpen={this.state.showWeightageModal} size="md" centered={true}>
          <div className="maindashboard-custom-modal-header">
            {this.state.WeightageModalHeading}
            <br />
            <i
              className="fa fa-times text-warning fa-2x"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
              onClick={() => {
                this.setState({
                  showWeightageModal: false,
                  WeightageModalHeading: "",
                  SubActivityWeightage: "",
                  SubActivityID: "",
                });
              }}
            ></i>{" "}
          </div>
          <ModalBody>
            <div>
              <div className="col-12">
                <div className="form-group row">
                  <label
                    className="col-sm-5 text-right pt-0 col-form-label  custom-leftAlign-Label"
                    style={{ marginTop: "3px", fontSize: "14px" }}
                  >
                    Weightage:{" "}
                  </label>
                  <div className="col-sm-7">
                    <select
                      name="SubActivityWeightage"
                      value={this.state.SubActivityWeightage}
                      className="form-control custom-individual-dropdown fontsize"
                      onChange={(e) => {
                        this.handleChangeForEvent(e);
                      }}
                    >
                      <option value=""> Select </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "72px" }}
              color="success"
              onClick={() => {
                this.saveSubactivityWeightage();
              }}
            >
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showWeightageModal: false,
                  WeightageModalHeading: "",
                  SubActivityWeightage: "",
                  SubActivityID: "",
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <AddnEditFormModal
          ParentData={this.state}
          saveNewRecord={this.saveNewRecord}
          handleDateTimeChange={this.handleDateTimeChange}
          handleOnChange={this.handleOnChange}
          handleEnter={this.handleEnter}
          handleDeletetags={this.handleDeletetags}
          handleDeleteschtags={this.handleDeleteschtags}
          handleAdditiontags={this.handleAdditiontags}
          handleAdditionschtags={this.handleAdditionschtags}
          handleDragtags={this.handleDragtags}
          handleDragschtags={this.handleDragschtags}
          closeAddNEditFormModal={this.closeAddNEditFormModal}
          handleOnChangeMulti={this.handleOnChangeMulti}
          copyFromSchToAct={this.copyFromSchToAct}
        />

        <CommentsNResourcesModal
          ref={this.CommentModalElement}
          ParentData={this.state}
          handleOnChange={this.handleChangeForEvent}
          addNewResource={this.addNewResource}
          clearResourceInput={this.clearResourceInput}
          CloseCommentNResourecsModal={this.CloseCommentNResourecsModal}
        />

        <RecordHistoryModal
          ParentData={this.state.recordInfo}
          showModal={this.state.recordHistoryModalOpen}
          recordHistoryModalClose={this.recordHistoryModalClose}
          historyApiRes={this.state.historyApiRes}
          tableHeading={this.state.tableHeading}
        />
      </div>
    );
  }
}
export default withStyles(styles)(OverallProgressDetailView);
//export default OverallProgressDetailView;
