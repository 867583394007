import { PROPERTY_TYPES } from "@babel/types";
import React, { createContext, useEffect, useState } from "react";

export const UserAccessContext = createContext();

const UserAccessProvider = (props) => {
  const _user = window.mylsataku.get("cache2");
  const user = _user?.User;
  const refreshData = props.getfunc;
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(props.data);
  }, [props.data]);
  return (
    <UserAccessContext.Provider value={{ userData, user, refreshData }}>
      {props.children}
    </UserAccessContext.Provider>
  );
};

export default UserAccessProvider;
