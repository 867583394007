import bluesimple from "../Components/assests/Dashboard/bigboxLightblue-Artboard_1.png";
import greensimple from "../Components/assests/Dashboard/bigboxLightGreen-Artboard_1.png";
import redsimple from "../Components/assests/Dashboard/bigbox-Artboard_1.png";
import infoicon from "../Components/assests/Dashboard/Info.png";
import redicon from "../Components/assests/Dashboard/red-flag.png";
import weightageicon from "../Components/assests/Dashboard/grayweighticon-removebg.jpg";

export const KeyCodes = {
  comma: 188,
  enter: 13,
};

export const _activityName = {
  Dictionary: "DICTIONARY",
  Instrument: "INSTRUMENTS",
  Interface: "INTERFACES",
  Documents: "DOCUMENTS",
  Careplans: "CAREPLANS",
  Ordersets: "ORDERSETS",
  Gaprequirement: "GAPREQUIREMENT",
  Datamigration: "DATAMIGRATION",

  //Training ---
  Euttraining: "EUTTRAINING",
  Sutraining: "SUTRAINING",
  DictTrain: "DICTTRAIN",
  AppTr: "APPTRAIN",
  PhaApp: "PHAAPP",
  LabApp: "LABAPP",
  // ----User Training
  TP: "TP",
  SD: "SD",
  TM: "TM",
  //End

  //Testing ---
  Testing: "UTTESTING",
  IntTesting: "INTTESTING",
  IntTestingII: "INTTESTINGII",
  ParaTesting: "PARATESTING",
  MGL: "MGL",
  MGLII: "MGLII",
  //End

  Reportwriting: "REPORTWRITING",
  Projectmgmnt: "PROJECTMGMNT",

  //12/23/2021 Project Status Board Changes
  //Third Party Software
  Interbit: "INTERBIT",
  SIS: "SIS",
  VR: "VR",
  Elsevier: "ELSEVIER",
  WK: "WK",
  IMO: "IMO",
  SPG: "SPG",
  VTH: "VTH",
  CPT: "CPT",

  //Readiness Assessment
  RA: "RA",
  RAII: "RAII",
  RAIII: "RAIII",
  GLC: "GLC",

  //Future State Process
  FSP: "FSP",
  TMV: "TMV",
  CSP: "CSP",

  //Infrastructure Readiness
  EndUserDevices: "ESD",
  CentralHardware: "CH",
  EIC: "EIC",
  NetworkRead: "NR",


  //Plans and Strategies
  Plans: "PLANS",
  Strategies: "STRATEGIES",

  //Go-Live Activities
  Gla: "GLA",

  //Pre Activies ,
  PreAct: "PREACT",
  AW:"AW",
  GI:"GI",
  NbiVisit:"NBIVISIT",
  PP:"PP",
  TB:"TB",

  //Clinical Activities
  Ndrb: "NDRB",
  Pdrb: "PDRB",
  Pdp: "PDP",
  Kpi: "KPI",
  RBT: "RBT",
  US: "US",
  Eup: "EUP",

  //Medical Devices
  MD: "MD",

  //Iterations
  ITI: "ITI",
  ITII: "ITII",
  ITIII: "ITIII",
  ITIV: "ITIV",

  //Security Assessment,
  SA: "SA",

  //Post Go-Live Optimization
  PGLO: "PGLO",
  PGLS: "PGLS",

  //UAM
  UAM: "UAM",

  //
  CM: "CM",
};

export const largetablecellnames = {
  CarePlanName: "Care Plan Name",
  Comments: "Comments",
  DidctionaryName: "Dictionary Name",
  InstrumentName: "Instrument Name",
  InterfaceName: "Interface Name",
  DocumentName: "Document Name",
  RequirementDescription: "Requirement Description",
  WalkThruOutcome: "Walk Thru Outcome",
  TestCaseName: "Test Case Name",
  TrainingName: "Training Session",
  Description: "Description",
  ReportName: "Report Name",
  ProjectName: "Project Name",
  Activity: "Activity",
  Action: "Action",
  DataDescription: "Data Description",
  ID: "ID",
  DocDescription: "Document Description",
};
export const dateCellHeading = {
  PlannedDate: "Planned Date",
  BaselineDate: "Baseline Date",
  CompletionDate: "Completion Date",
};

export const AppRoleEnum = {
  Admin: "ADMIN",
  Viewer: "VIEWER",
  Collaborator: "COLLABORATOR",
  EViewer: "E-VIEWER",
  PMO: "PMO",
};
//constants for main dashboard component
let weightageIcon = `url('${weightageicon}')`;
let bluebackground = `url('${bluesimple}')`;
let redbackground = `url('${redsimple}')`;
let greenbackground = `url('${greensimple}')`;
export const WeightageIcon = {
  backgroundImage: weightageIcon,
  backgroundSize: "15px 15px",
  backgroundRepeat: "no-repeat",
  height: "15px",
  width: "15px",
  position: "absolute",
  bottom: 0,
};
export const MSWeightageIcon = {
  backgroundImage: weightageIcon,
  backgroundSize: "15px 15px",
  backgroundRepeat: "no-repeat",
  height: "15px",
  width: "15px",
  position: "absolute",
  bottom: 0,
  left: "-22px",
  bottom: "-10px",
};
export const BlueTileBackgroundStyle = {
  backgroundImage: bluebackground,
  backgroundSize: "239px 193px",
  backgroundRepeat: "no-repeat",
  height: "182px",
  minWidth: "15rem",
  borderRadius: 15,
};
export const GreenTileBackgroundStyle = {
  backgroundImage: greenbackground,
  backgroundSize: "239px 193px",
  backgroundRepeat: "no-repeat",
  minWidth: "15rem",
  height: "182px",
  borderRadius: 15,
};
export const RedTileBackgroundStyle = {
  backgroundImage: redbackground,
  backgroundSize: "239px 207px",
  backgroundRepeat: "no-repeat",
  height: "182px",
  minWidth: "15rem",
  borderRadius: 15,
};
export const BigInfoIcon = {
  backgroundImage: `url('${infoicon}')`,
  backgroundSize: "89px 97px",
  backgroundRepeat: "no-repeat",
};
export const BigRedIcon = {
  backgroundImage: `url('${redicon}')`,
  backgroundSize: "114px 97px",
  backgroundRepeat: "no-repeat",
  top: "2px",
};
export const infoIcon = {
  backgroundImage: `url('${infoicon}')`,
  backgroundSize: "39px 35px",
  backgroundRepeat: "no-repeat",
};
export const redIcon = {
  backgroundImage: `url('${redicon}')`,
  backgroundSize: "39px 32px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "1px",
};

//constants for individual app

export const IndividualWeightageIcon = {
  backgroundImage: weightageIcon,
  backgroundSize: "15px 15px",
  backgroundRepeat: "no-repeat",
  height: "15px",
  width: "15px",
  top: "11px",
};

export const intState = {
  NewDictionaryName: "",
  NewWeightage: "",
  NewBuildProgress: "",
  NewBuildStatus: "",
  NewComments: "",
  NewBaselineDate: "",
  NewPlannedDate: "",
  NewCompletionDate: "",
  NewActive: "",
  NewStandardContentAvailable: "",
  NewDataGatheringOnExcel: "",
  NewCMSControl: "",
  NewTargetHCIS: "",
  NewDictionaryID: "",
  NewResponsibleTeam: "",
  NewDictionaryNature: "",
  NewDisplaySequence: "",
  //INSTRUMENTS
  //NewInstrumentLocation: "",
  NewLabSection: "",
  NewLantronixConfigured: "",
  NewInstrumentConfigured: "",
  NewLISConnectionEstablished: "",
  NewCommType: "", // For Instrument and Interface
  NewInsLocation: "",
  //INTERFACES
  NewInterfaceBelongsto: "",
  NewCommFromMT: "",
  NewCommFromOV: "",
  NewConfigintoSandbox: "",
  NewConfigintoTest: "",
  //DOCUMENTS
  NewDOCUMENTID: "",
  NewRevByChampions: "",
  NewRevByClinicalUsers: "",
  NewBuildintoSandbox: "",
  NewSignedOffRecd: "",
  //CarePlan
  NewCarePlanDecision: "",
  //Gap Documents
  NewGapID: "",
  NewRequirementSource: "",
  NewRequirementRefNo: "",
  NewMTResponse: "",
  NewRequirementNature: "",
  NewRequirementCatoegory: "",
  NewGapWalkThruDone: "",
  NewGapWalkThruOutcome: "",
  NewDetailsSharedWithMT: "",
  NewDetailsSharedWithMTDT: "",
  NewSpecsRecdFromMT: "",
  NewSpecsRecdFromMTDT: "",
  NewSpecsApprovedByAKU: "",
  NewSpecsApprovedByAKUDT: "",
  NewGapProgress: "", //also for Progress (Application Testing)
  NewGapStatus: "",
  NewRequirementFulfilled: "",
  //APPLICATION TRAINING
  NewschDate: "",
  NewactDate: "",
  NewSchStartTime: null,
  NewSchEndTime: null,
  NewSchVenue: "",
  NewSchParticipants: "",
  NewSchParticipantNames: "",
  NewActStartTime: null,
  NewActEndTime: null,
  NewActVenue: "",
  NewActParticipants: "",
  NewActParticipantNames: "",
  NewTrainingInstructors: "",
  NewTrainingSessionID: "",
  //APPLICATION TESTING
  NewTestCaseID: "",
  NewTestCaseResult: "",
  //Report Writing
  NewReportID: "",
  NewReportType: "",
  // PROJECT MANAGEMENT
  NewProjectID: "",
  //Third Party Software
  NewTPSID: "",
  //Future State Process
  NewFSPID: "",
  //Infrastructure Readiness
  NewINFREADINESSID: "",
  //Plans and strategies
  NewSAPID: "",
  //Go-Live Execution
  NewGLAID: "",
  //Pre Activities
  NewPREACTIVITYID: "",
};

export const theadstickyclass = {
  position: "sticky",
  top: "0px",
  background: "#dce8ff",
  zIndex: 1,
};

export const theadstickyclassAction = {
  position: "sticky",
  top: "0px",
  background: "#dce8ff",
  zIndex: 1,
  minWidth: "100px",
};

export const Trainingtheadstickyclass = {
  position: "sticky",
  top: "0px",
  background: "rgb(247 226 230)",
  zIndex: 1,
};
