import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { UserAccessContext } from "../UserAccessContext";
import { appCred } from "../../AppConfig";
import { ToastsStore } from "react-toasts";

const BannerInterventionModal = ({ modalOpen, data, closeModal, modalFor }) => {
  const userData = useContext(UserAccessContext);

  const setIntervention = (color) => {
    let reqData = {
      ACTIVITYFLAG: color,
      FACILITYID: data.FACILITYID, //Hardcoded for now because of dev value against facility id
      ACTIVITYID: data.ACTIVITYID,
      LASTFILEUSERID: userData.user?.UserID,
      optionalString: "PSB",
    };
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.SaveInterventionColor",
        reqData
      )
      .then((apiRes) => {
        if (apiRes.ResponseResult == true) {
          closeModal();
          ToastsStore.success("Intervention Saved Successfully");
          userData.refreshData();
        } else {
          ToastsStore.error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      size="md"
      isOpen={modalFor === "intervention" ? modalOpen : false}
      centered={true}
    >
      <div className="maindashboard-custom-modal-header">
        {data?.DESCRIPTION}
        <br /> Intervention
        <i
          className="fa fa-times text-warning fa-2x"
          style={{ cursor: "pointer" }}
          aria-hidden="true"
          onClick={() => {
            closeModal();
          }}
        ></i>{" "}
      </div>
      <ModalBody>
        <div style={{ width: "500px", height: "100px" }} className="pt-2">
          <div>
            <div className="row text-center custom-modal-marginright ">
              <div
                className=" col-2 text-center"
                style={{ paddingLeft: "17px" }}
                onClick={(e) => {
                  setIntervention("B");
                }}
              >
                <div
                  className={
                    data?.ActivityFlag == "B"
                      ? "active_simple_square_ColorSelection-Blue "
                      : "simple_square_ColorSelection-Blue"
                  }
                >
                  <i className="fa fa-square Simple-Blue-Color"></i>
                  <div className="mt-2">
                    <span style={{ marginLeft: "-3px" }}>Blue</span>
                  </div>
                </div>
              </div>
              <div
                className=" col-2"
                style={{ paddingLeft: "20px" }}
                onClick={(e) => {
                  setIntervention("G");
                }}
              >
                <div
                  className={
                    data?.ActivityFlag == "G"
                      ? "active_simple_square_ColorSelection-Green"
                      : "simple_square_ColorSelection-Green"
                  }
                >
                  <i className="fa fa-square Simple-Green-Color"></i>
                  <div className="mt-2">
                    <span style={{ marginLeft: "-9px" }}>Green</span>
                  </div>
                </div>
              </div>
              <div
                className=" col-lg-2"
                style={{ paddingLeft: "25px" }}
                onClick={(e) => {
                  setIntervention("R");
                }}
              >
                <div
                  className={
                    data?.ActivityFlag == "R"
                      ? "active_simple_square_ColorSelection-Red"
                      : "simple_square_ColorSelection-Red"
                  }
                >
                  <i className="fa fa-square Simple-Red-Color"></i>
                  <div className="mt-2">
                    <span>Red</span>
                  </div>
                </div>
              </div>
              <div
                className=" col-lg-2"
                style={{ paddingLeft: "30px" }}
                onClick={(e) => {
                  setIntervention("A");
                }}
              >
                <div
                  className={
                    data?.ActivityFlag == "A"
                      ? "active_simple_square_ColorSelection-Amber"
                      : "simple_square_ColorSelection-Amber"
                  }
                >
                  <i className="fa fa-square Simple-Green-Amber"></i>
                  <div className="mt-2">
                    <span style={{ marginLeft: "-9px" }}>Amber</span>
                  </div>
                </div>
              </div>
              <div
                className=" col-lg-2 pr-2"
                onClick={(e) => {
                  setIntervention("N");
                }}
              >
                <div
                  className="simple_square_ColorSelection-Neutral"
                  style={{ marginLeft: "13px" }}
                >
                  <i className="fa fa-square text-secondary"></i>
                </div>
                <div className="mt-2" style={{ marginRight: "45px" }}>
                  <span>Neutral</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BannerInterventionModal;
