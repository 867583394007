import React from "react";
import { _activityName, AppRoleEnum } from "../../utils/Constants";
import moment from "moment";

export function TransposingDataFunc(activityName, UserOrgType) {
  let tempMeta_API;
  if (activityName === _activityName.Dictionary) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Dictionary Name",
      },
      DICTIONARYNATURE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Global/Local",
      },
      TARGETHCIS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Target HCIS",
      },
      STANDARDCONTENTAVAILABLE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Standard Content",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      _DATAGATHERINGONEXCEL: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Data on Excel",
      },
      CMSCONTROL: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "CMS Controlled",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
     
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Dictionary ID",
      },
      RESPONSIBLEAPPTEAM: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Responsible Team",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      dictWeightage: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Instrument) {
    tempMeta_API = {
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: false,
      },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Instrument Name",
      },
      INSLOCATION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Instrument Location",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      COMMTYPE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Communication Type",
      },
      LANTRONIXCONFIGURED: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Lantronix Configured",
      },
      INSTRUMENTCONFIGURED: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Instrument Configured",
      },
      LISCONNESTABLISHED: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "LIS Connectivity Established",
      },
      LABSECTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Lab Section",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Instrument ID",
      },
      Outstanding: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Outstanding",
      },
      Completed: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Completed",
      },
      dictWeightage: { type: "text", allowEdit: false, isVisible: false },
      TotalCount: { type: "text", allowEdit: false, isVisible: false },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      // RESPONSIBLEAPPTEAM: {
      //   type: "text",
      //   allowEdit: false,
      //   isVisible: false,
      //   heading: "Responsible Team",
      // },
    };
  }
  if (
    activityName === _activityName.Interface ||
    activityName === _activityName.MD
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Interface Name",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      COMMFROMMT: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Communication From MT",
      },
      COMMFROMOV: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Communication From OV",
      },
      COMMTYPE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Communication Type",
      },
      CONFIGINTOSANDBOX: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Configured In Sandbox",
      },
      CONFIGINTOTEST: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Configured In Test",
      },
      INTERFACEBELONGSTO: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Interface Belongs To",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Interface ID",
      },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      dictWeightage: { type: "text", allowEdit: false, isVisible: false },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (
    activityName === _activityName.Documents ||
    activityName === _activityName.Ndrb ||
    activityName === _activityName.Pdrb
  ) {
    console.log("IN CONDITION");
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Document Name",
      },
      // STANDARDCONTENTAVAILABLE: {
      //   type: "text",
      //   allowEdit: false,
      //   isVisible: true,
      //   heading: "Standard Content",
      // },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REVBYCHAMPIONS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Reviewed By Champions",
      },
      REVBYCLINICALUSERS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Reviewed By Clinical Users",
      },
      BUILDINTOSANDBOX: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Into Standards",
      },
      SIGNEDOFFRECD: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Signed Off Record",
      },

      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },

      _DATAGATHERINGONEXCEL: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Data on Excel",
      },
      CMSCONTROL: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "CMS Controlled",
      },

      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Document ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      CAREPLANDECISION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Care Plan Decision",
      },
      // RESPONSIBLEAPPTEAM: {
      //   type: "text",
      //   allowEdit: false,
      //   isVisible: false,
      //   heading: "Responsible Team",
      // },
      TARGETHCIS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Target HCIS",
      },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Careplans) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading:
          activityName === _activityName.Careplans
            ? "Care Plan Name"
            : "Document Description",
      },
      TARGETHCIS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Target HCIS",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      RESPONSIBLEAPPTEAM: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Responsible Team",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REVBYCHAMPIONS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Reviewed By Champions",
      },
      CAREPLANDECISION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Care Plan Decision",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading:
          activityName === _activityName.Careplans
            ? "CarePlan ID"
            : "Document ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Ordersets) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Orderset Description",
      },
      TARGETHCIS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Target HCIS",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Build Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      RESPONSIBLEAPPTEAM: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Responsible Team",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Build Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Orderset ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Gaprequirement) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Requirement Description",
      },
      REQUIREMENTSOURCE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Requirement Source",
      },
      REQUIREMENTCATEGORY: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Category",
      },
      REQUIREMENTNATURE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Nature",
      },
      MTRESPONSE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "MT Response",
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      customBASELINEDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Baseline Date",
      },
      _GAPWALKTHRUDONE: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Walk Thru Done",
        options: ["", "Y", "N"],
        displays: ["", "Yes", "No"],
        isCustomRender: true,
        customRenderFunc: renderItemFull,
      },
      GAPWALKTHRUOUTCOME: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Walk Thru Outcome",
        isCustomRender: true,
        customRenderFunc: renderCellGapWTOutcome,
        options: ["", "A", "B", "C", "D", "E"],
        displays: [
          "",
          "Requirement is Fulfilled",
          "Requirement is Fulfilled via Available Workaround",
          "Development is Required",
          "3rd Party Solution is Required",
          "Requirement is Being Dropped",
        ],
      },
      DETAILSSHAREDWITHMT: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Dtls. shared with MT",
        options: ["", "Yes", "No"],
        displays: ["", "Yes", "No"],
      },
      customDETAILSSHAREDWITHMTDT: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Dtls. shared Date",
      },
      SPECSRECDFROMMT: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Specs Recd from MT",
        options: ["", "Yes", "No"],
        displays: ["", "Yes", "No"],
      },
      customSPECSRECDFROMMTDT: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Specs Recd Date",
      },
      SPECSAPPROVEDBYAKU: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Specs Appvd. by AKU",
        options: ["", "Yes", "No"],
        displays: ["", "Yes", "No"],
      },
      customSPECSAPPROVEDBYAKUDT: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Specs Appvd. Date",
      },
      _REQUIREMENTFULFILLED: {
        type: "select",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Req Fulfilled",
        options: ["", "Y", "N"],
        displays: ["", "Yes", "No"],
        isCustomRender: true,
        customRenderFunc: renderItemFull,
      },
      REQUIREMENTREFNO: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Requirment Ref No",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Gap ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      DETAILSSHAREDWITHMTDT: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Dets shared with MT Date",
      },
      SPECSRECDFROMMTDT: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Spec Recd From MT Date",
      },
      SPECSAPPROVEDBYAKUDT: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Specs Approv By AKU Date",
      },
      GAPWALKTHRUDONE: {
        type: "select",
        allowEdit: false,
        isVisible: false,
        heading: "Walk Thru Done",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Datamigration) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Data Description",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      BUILDPROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      BUILDSTATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      _DATAGATHERINGONEXCEL: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Data on Excel",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      TARGETHCIS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Target HCIS",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Data ID",
      },
      RESPONSIBLEAPPTEAM: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Responsible Team",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      dictWeightage: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (
    activityName === _activityName.Euttraining ||
    activityName === _activityName.Sutraining ||
    activityName === _activityName.DictTrain ||
    activityName === _activityName.AppTr ||
    activityName === _activityName.PhaApp ||
    activityName === _activityName.LabApp
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Training Session",
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      SCHDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Scheduled Date",
      },
      SCHVENUE: {
        type: "text",
        allowEdit: true,
        isVisible: true,
        heading: "Venue",
        isCustomRender: true,
        customRenderFunc: renderVenue,
      },
      customSCHDATE: {
        type: "date",
        allowEdit: true,
        isVisible: true,
        heading: "Date",
        isCustomRender: true,
        customRenderFunc: renderVenue,
      },
      SCHSTARTTIME: {
        type: "time",
        allowEdit: true,
        isVisible: true,
        heading: "Start Time",
        isCustomRender: true,
        customRenderFunc: renderCustomTime,
      },
      SCHENDTIME: {
        type: "time",
        allowEdit: true,
        isVisible: true,
        heading: "End Time",
        isCustomRender: true,
        customRenderFunc: renderCustomTime,
      },
      SCHPARTICIPANTS: {
        type: "text",
        allowEdit: true,
        isVisible: true,
        heading: "Participants No",
      },
      SCHPARTICIPANTNAMES: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Invited Participants Name",
        isCustomRender: true,
        customRenderFunc: renderParticipantName,
      },
      ACTDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Date",
        isCustomRender: true,
        customRenderFunc: renderDateVenue,
      },
      ACTVENUE: {
        type: "text",
        allowEdit: true,
        isVisible: true,
        heading: "Venue",
        isCustomRender: true,
        customRenderFunc: renderDateVenue,
      },
      customACTDATE: {
        type: "date",
        allowEdit: true,
        isVisible: true,
        heading: "Date",
        isCustomRender: true,
        customRenderFunc: renderDateVenue,
      },
      ACTSTARTTIME: {
        type: "time",
        allowEdit: true,
        isVisible: true,
        heading: "Start Time",
        isCustomRender: true,
        customRenderFunc: renderCustomTime,
      },
      ACTENDTIME: {
        type: "time",
        allowEdit: true,
        isVisible: true,
        heading: "End Time",
        isCustomRender: true,
        customRenderFunc: renderCustomTime,
      },
      ACTPARTICIPANTS: {
        type: "text",
        allowEdit: true,
        isVisible: true,
        heading: "Participants No",
      },
      ACTPARTICIPANTNAMES: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Actual Participant Names",
        isCustomRender: true,
        customRenderFunc: renderParticipantName,
      },
      TRAININGINSTRUCTORS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Training Instructors",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "Training ID",
      },
      TRAININGSESSIONID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Training Sess ID",
      },
    };
  }
  //Testing
  if (
    activityName === _activityName.Testing ||
    activityName === _activityName.IntTesting ||
    activityName === _activityName.ParaTesting ||
    activityName === _activityName.MGL ||
    activityName === _activityName.MGLII ||
    activityName === _activityName.IntTestingII
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: false, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: false, isVisible: false },
      ACTIVE: { type: "text", allowEdit: false, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Test Case Name",
      },
      TESTCASERESULT: {
        type: "multiselect",
        allowEdit: true,
        isVisible: true,
        options: ["", "Passed", "Failed"],
        displays: ["", "Passed", "Failed"],
        heading: "Test Case Result",
        isCustomRender: true,
        customRenderFunc: renderCellTestCase,
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      TESTCASEID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Test case ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Reportwriting) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report Name",
      },
      REPORTTYPE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report Type",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      REPORTID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Projectmgmnt) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Activity",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit:
          UserOrgType === AppRoleEnum.Admin || UserOrgType === AppRoleEnum.PMO
            ? true
            : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit:
          UserOrgType === AppRoleEnum.Admin || UserOrgType === AppRoleEnum.PMO
            ? true
            : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      //CalculatedPlannedDateDiff : { type: "text",allowEdit: false,isVisible: false},
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit:
          UserOrgType === AppRoleEnum.Admin || UserOrgType === AppRoleEnum.PMO
            ? true
            : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      DICTIONARYID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Task ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  if (activityName === _activityName.Reportwriting) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report Name",
      },
      REPORTTYPE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report Type",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      REPORTID: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Report ID",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }
  //Third Party Software
  if (
    activityName === _activityName.Interbit ||
    activityName === _activityName.SIS ||
    activityName === _activityName.VR ||
    activityName === _activityName.Elsevier ||
    activityName === _activityName.WK ||
    activityName === _activityName.IMO ||
    activityName === _activityName.SPG ||
    activityName === _activityName.VTH ||
    activityName === _activityName.CPT
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      TPSID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  //Future State Process
  if (
    activityName === _activityName.FSP ||
    activityName === _activityName.CSP ||
    activityName === _activityName.TMV 
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      FSPID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  //Infrastructure Readiness
  if (
    activityName === _activityName.EndUserDevices ||
    activityName === _activityName.CentralHardware ||
    activityName === _activityName.NetworkRead ||
    activityName === _activityName.EIC
    //last line added by -mz
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      INFREADINESSID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  //Strategies and Plans
  if (
    activityName === _activityName.Plans ||
    activityName === _activityName.Strategies
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      SAPID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  //Go-Live Activites
  if (activityName === _activityName.Gla) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      GLAID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  //Pre Activites , Clinical Activities, Training, Readiness Assessment, Iterrations, Security Assessment,
  // User Access Management
  if (
    activityName === _activityName.PreAct ||
    activityName === _activityName.Pdp ||
    activityName === _activityName.US ||
    activityName === _activityName.Kpi ||
    activityName === _activityName.RBT ||
    activityName === _activityName.Eup ||
    activityName === _activityName.TP ||
    activityName === _activityName.TM ||
    activityName === _activityName.RA ||
    activityName === _activityName.RAII ||
    activityName === _activityName.RAIII ||
    activityName === _activityName.GLC ||
    activityName === _activityName.ITI ||
    activityName === _activityName.ITII ||
    activityName === _activityName.ITIII ||
    activityName === _activityName.ITIV ||
    activityName === _activityName.SA ||
    activityName === _activityName.PGLO ||
    activityName === _activityName.UAM ||
    activityName === _activityName.CM ||
    activityName === _activityName.AW ||
    activityName === _activityName.GI ||
    activityName === _activityName.NbiVisit ||
    activityName === _activityName.PP ||
    activityName === _activityName.TB ||
    activityName === _activityName.PGLS ||
    activityName === _activityName.SD
    //last 7 lines added by -mz
  ) {
    tempMeta_API = {
      FACILITYID: { type: "text", allowEdit: true, isVisible: false },
      APPLICATIONID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      SUBACTIVITYID: { type: "text", allowEdit: true, isVisible: false },
      ACTIVE: { type: "text", allowEdit: true, isVisible: false },
      DESCRIPTION: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Description",
      },
      PREACTIVITYID: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "ID",
      },
      WEIGHTAGE: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Weightage",
        isCustomRender: true,
        customRenderFunc: renderCellWeightage,
      },
      PROGRESS: {
        type: "text",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Progress",
        isCustomRender: true,
        customRenderFunc: renderCellBuildProgress,
      },
      COMMENTS: {
        type: "comment",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Comments",
      },
      STATUS: {
        type: "text",
        allowEdit: false,
        isVisible: true,
        heading: "Status",
        isCustomRender: true,
        customRenderFunc: renderCellBuildStatus,
      },
      CalculatedPlannedDateDiff: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      customBASELINEDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Baseline Date",
      },
      customPLANNEDDATE: {
        type: "date",
        allowEdit: UserOrgType === AppRoleEnum.Admin ? true : false,
        isVisible: true,
        heading: "Planned Date",
        style: "blink",
      },
      customACTUALCOMPLETIONDATE: {
        type: "date",
        allowEdit: false,
        isVisible: true,
        heading: "Completion Date",
      },
      REFTableName: {
        type: "text",
        allowEdit: false,
        isVisible: false,
        heading: "",
      },
      BASELINEDATE: { type: "text", allowEdit: false, isVisible: false },
      PLANNEDDATE: { type: "text", allowEdit: false, isVisible: false },
      ACTUALCOMPLETIONDATE: {
        type: "text",
        allowEdit: false,
        isVisible: false,
      },
      LASTFILEUSERID: { type: "text", allowEdit: false, isVisible: false },
      DISPLAYSEQUENCE: { type: "text", allowEdit: false, isVisible: false },
    };
  }

  return tempMeta_API;
}

// --table Render functions--
function renderCellBuildStatus(
  item,
  PDate,
  SDate,
  Weightage,
  BuildProgress,
  Progress
) {
  let Date = PDate ? PDate : SDate;
  let formattedTargetDate = moment(Date).format("MM/DD/YYYY");
  let dateDiff = moment().startOf("day").diff(formattedTargetDate, "days");
  let roundedProgressPercentageValue = BuildProgress ? BuildProgress : Progress;
  var customSpan = function (badge) {
    return (
      <h6>
        <span className={"badge badge-" + badge}>{item.value}</span>
      </h6>
    );
  };

  if (item.value === "Not Required") {
    return customSpan("warning");
  } else if (dateDiff >= 0 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 90) {
      return customSpan("success");
    } else if (roundedProgressPercentageValue < 90 && parseInt(Weightage) > 0) {
      return customSpan("danger");
    }
  } else if (dateDiff <= -60 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 75) {
      return customSpan("success");
    } else if (
      roundedProgressPercentageValue >= 20 &&
      roundedProgressPercentageValue < 75
    ) {
      return customSpan("primary");
    } else if (roundedProgressPercentageValue < 20) {
      return customSpan("danger");
    }
  } else if (dateDiff > -60 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 90) {
      return customSpan("success");
    } else if (
      roundedProgressPercentageValue >= 30 &&
      roundedProgressPercentageValue < 90
    ) {
      return customSpan("primary");
    } else if (roundedProgressPercentageValue < 30) {
      return customSpan("danger");
    }
  } else {
    return <span>{item.value}</span>;
  }
}

function renderCellBuildProgress(item, PDate, SDate, Weightage) {
  let Date = PDate ? PDate : SDate;
  let formattedTargetDate = moment(Date).format("MM/DD/YYYY");
  let dateDiff = moment().startOf("day").diff(formattedTargetDate, "days");
  let roundedProgressPercentageValue = item.value ? item.value : 0;
  var customSpan = function (badge) {
    return (
      <h6>
        <span className={"badge badge-" + badge}>{item.value}%</span>
      </h6>
    );
  };
  if (parseInt(Weightage) === 0) {
    return customSpan("warning");
  } else if (dateDiff >= 0 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 90) {
      return customSpan("success");
    } else if (roundedProgressPercentageValue < 90 && parseInt(Weightage) > 0) {
      return customSpan("danger");
    }
  } else if (dateDiff <= -60 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 75) {
      return customSpan("success");
    } else if (
      roundedProgressPercentageValue >= 20 &&
      roundedProgressPercentageValue < 75
    ) {
      return customSpan("primary");
    } else if (roundedProgressPercentageValue < 20) {
      return customSpan("danger");
    }
  } else if (dateDiff > -60 && parseInt(Weightage) > 0) {
    if (roundedProgressPercentageValue >= 90) {
      return customSpan("success");
    } else if (
      roundedProgressPercentageValue >= 30 &&
      roundedProgressPercentageValue < 90
    ) {
      return customSpan("primary");
    } else if (roundedProgressPercentageValue < 30) {
      return customSpan("danger");
    }
  } else {
    return <span>{item.value}%</span>;
  }
}

function renderCellGapWTOutcome(item) {
  switch (item.value) {
    case "A":
      return <span>Requirement is Fulfilled</span>;
    case "B":
      return <span>Requirement is Fulfilled via Available Workaround</span>;
    case "C":
      return <span>Development is Required</span>;
    case "D":
      return <span>3rd Party Solution is Required</span>;
    case "E":
      return <span>Requirement is Being Dropped</span>;
    default:
      break;
  }
}

function renderItemFull(item) {
  switch (item.value) {
    case "Y":
      return <span>Yes</span>;
    case "N":
      return <span>No</span>;
    default:
      break;
  }
}

function renderCellWeightage(item) {
  return item.value.toString();
}

function renderParticipantName(item) {
  let arr = item.value;
  let returnData = <></>;
  if (arr) {
    returnData = (
      <span className="custom-participantsname">
        {JSON.parse(arr)
          .map(({ text }) => text)
          .join(", ")}
      </span>
    );
  }
  return returnData;
}

function renderDateVenue(item) {
  return <label style={{ width: "85px" }}>{item.value}</label>;
}

function renderVenue(item) {
  return <label style={{ width: "85px" }}>{item.value}</label>;
}

function renderCustomTime(item) {
  let isValidTime = moment(item.value, "YYYY-MM-DDTHH:mm:ss", true).isValid();

  if (isValidTime) {
    return (
      <label style={{ width: "126px" }}>
        {moment(item.value).format("hh:mm A")}
      </label>
    );
  }
}

function renderCellTestCase(_item) {
  let itemarr;
  if (_item.value) {
    itemarr = _item.value.split("|");
    let item = [];
    for (let i = 0; i < itemarr.length; i++) {
      //if(itemarr[i].substring(0,6) =="Passed"){
      item.push(
        <span
          onDoubleClick={(e) => {
            window.deactivatePopover(e.target);
          }}
          className={
            itemarr[i].substring(0, 6) == "Passed"
              ? "badge badge-success mr-2 mb-1 pt-1"
              : "badge badge-danger mr-2 mb-1 pt-1"
          }
          style={{ fontSize: "12px", width: "53px" }}
          data-toggle="tooltip"
          data-placement="left"
          data-html="true"
          title=""
          data-original-title={itemarr[i].slice(-20)}
        >
          {itemarr[i].substring(0, 6)}
        </span>
      );
    }
    return <div style={{ width: "260px" }}>{item}</div>;
  }
}

//  -- End--

