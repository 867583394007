import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//#region Validator Imports
import ValidateDictionaryData from './Components/Validators/ValidateDictionaryData';
import ValidateInstrumentData from './Components/Validators/ValidateInstrumentData';
import ValidateInterfaceData from './Components/Validators/ValidateInterfaceData';
import ValidateDocumentData from './Components/Validators/ValidateDocumentData';
import ValidateCarePlanData from './Components/Validators/ValidateCarePlanData';
import ValidateMigrationData from './Components/Validators/ValidateMigrationData';
import ValidateOrderSetsData from './Components/Validators/ValidateOrderSetsData';
import ValidateGapDocumentData from './Components/Validators/ValidateGapDocumentData';
import ValidateTestingData from './Components/Validators/ValidateTestingData.js';
import ValidateTrainingData from './Components/Validators/ValidateTrainingData.js';
import ValidateReportWritinData from './Components/Validators/ValidateReportWritinData.js';
import ValidateProjectMangmntData from './Components/Validators/ValidateProjectMangmntData.js';
import ValidateThirdPartySoftwareData from './Components/Validators/ValidateThirdPartySoftwareData.js';
import ValidateFutureStateProcessData from './Components/Validators/ValidateFutureStateProcessData';
import ValidateStrategiesAndPlansData from  './Components/Validators/ValidateStrategiesAndPlansData';
import ValidateInfrastructureReadinessData from  './Components/Validators/ValidateInfrastructureReadinessData';
import ValidateGoLiveExecutionData from './Components/Validators/ValidateGoLiveExecutionData';
import ValidatePreActivitiesData from './Components/Validators/ValidatePreActivitiesData.js';
//#endregion
export const Validators = {
    ValidateDictionaryData,
    ValidateInstrumentData,
    ValidateInterfaceData,
    ValidateDocumentData,
    ValidateCarePlanData,
    ValidateMigrationData,
    ValidateOrderSetsData,
    ValidateGapDocumentData,
    ValidateTestingData,
    ValidateTrainingData,
    ValidateReportWritinData,
    ValidateThirdPartySoftwareData,
    ValidateFutureStateProcessData,
    ValidateProjectMangmntData,
    ValidateStrategiesAndPlansData,
    ValidateInfrastructureReadinessData,
    ValidateGoLiveExecutionData,
    ValidatePreActivitiesData
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https:/bit.ly/CRA-PWA
serviceWorker.unregister();
