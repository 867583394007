import React, { Suspense } from "react";
import { appCred } from "../AppConfig.js";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Collapse } from "reactstrap";
//import MainDashboardHeader from "./Headers/_MainDashboardHeader";
import Select from "react-select";
import UserAccessProvider from "./UserAccessContext";

const EhrApplications = React.lazy(() => import("./EhrApplications"));
const MainStream = React.lazy(() => import("./MainStream/MainStream"));

const MainDashboardHeader = React.lazy(() =>
  import("./Headers/MainDashboardHeader")
);

class ApplicationsMain extends React.Component {
  constructor() {
    super();
    this.CommentModalElement = React.createRef();
    let user = window.mylsataku.get("cache2");
    this.state = {
      bannerInformation: [],
      UserRole: user.User.UserOrganizationType,
      UserID: window.mylsataku.get("cache3"),
      DashboardDetails: {},
      APIReqObj: {},
      userAccessData: {},
      activitiesDD: [],
      selectedActivityOption: "",
      isOpen: false,
      isLoading: false,
      errorMessage: "",
      selectedActivity: "", //For applying conditions
      PSBdata: {},
      // MM on 19012022 addedd facilitiesdd
      facilityDD: [],
      selectedFacility: "",
      selectedFacilityOption: "",
    };
  }
  selectRef = null;
  clearValue = () => {
    this.selectRef.select.clearValue();
  };


  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidMount() {
    
    // mz start
    var currentURL = window.location.pathname;
    var routeName = currentURL.substring(1);
    if (routeName == "") {
      // const homebutton = document.querySelector(".home-btn");
      document.body.style.background =
      document.body.style.background = "white";
      
           
    } 
    if (routeName == "ApplicationsMain") {
    
    }
    //mz end
    const _PSBdata = window.history.state?.state;
    this.setState({ PSBdata: _PSBdata });

    // MM on 19012023 - get user facilities and bind to dropdown
   //this.GetUserFacilityAccess(this.state.UserID);

    window
    .APIRequest(appCred, "", "EHRDashboardManager.GetUserFacilityAccess", {
      UserID: this.state.UserID,
    })
    .done((apiRes) => {
      if (apiRes.ResponseCode === 200) {
        if (apiRes.ResponseResult[0]) {
          //filling the facility dropdown
          for (let item of apiRes.ResponseResult[0].UserData) {
            let obj = {};
            obj["value"] = item.FacilityID;
            obj["label"] = item.FacilityName;
            this.state.facilityDD.push(obj);
          }

          // Set Default Facility
            if (this.state.PSBdata?.thruPSB) {
                this.state.selectedFacility = this.state.PSBdata.facilityID;
            }
            else
            {
               this.state.selectedFacility = apiRes.ResponseResult[0].UserDefaultFacility.FacilityID;
            }

            if (!this.state.selectedFacility) {
              this.setState({
                errorMessage: "User Default Facility Access is not defined. ",
              });
              return;
            }

            //Call Activity 
            this.setState({activitiesDD: []});
            this.state.selectedActivity =   "";  
            this.state.selectedActivityOption =  "";  
            this.state.APIReqObj.activityID =   "";  
              window
              .APIRequest(appCred, "", "EHRDashboardManager.GetUserAccessData", {
                UserID:this.state.UserID, FacilityID: this.state.selectedFacility }
                )
              .done((apiResAct) => {
              
                if (apiResAct.ResponseCode === 200) {
                  if (apiResAct.ResponseResult[0]) {
                        //filling the facility dropdown
                        for (let item of apiResAct.ResponseResult[0].UserData) {
                          let obj = {};
                          obj["value"] = item.ActivityID;
                          obj["label"] = item.description;
                          this.state.activitiesDD.push(obj);
                        }
                       
                        if (this.state.PSBdata?.thruPSB) {
                            this.state.selectedActivity = this.state.PSBdata.activityID;
                        }
                        else
                        {
                          this.state.selectedActivity = apiResAct.ResponseResult[0].UserData.find(
                                                          (a) => a.IsDefault == "Y")?.ActivityID
                        } 
                        // Set API Req Object
                        this.state.APIReqObj = {
                          facilityID: this.state.selectedFacility, 
                          activityID: this.state.selectedActivity,
                          LoggedInRole: this.state?.UserRole,
                        };
                        

                        // Call Main Dashboard Activities
                        this.setState(
                        {
                          userAccessData: apiResAct.ResponseResult[0],
                          selectedFacility: this.state.selectedFacility,
                          selectedActivity: this.state.selectedActivity,
                          APIReqObj: this.state.APIReqObj,
                        },
                        () => {
                        
                          this.GetMainDashboardActivities();
                          
                        }
                      );
                        
                    } else {
                      this.setState({
                        errorMessage: "User activity access is not defined",
                      });
                  }
                }
              })
              .catch((error) => console.log('ERR - GetUserActivityData'))
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });    
  }
 


// MM - Get User Activity by facilityID and UserID
  GetUserActivityData = (e) => {
   
console.log('HERE');

this.clearValue();
   // clear detail 
   this.state.isLoading = true;
   this.state.bannerInformation= [];
   this.state.DashboardDetails= {};


    //clearing previous values of activity
    this.setState({activitiesDD: []});
    this.setState({selectedActivity:""});
   // this.state.activitiesDD= [];
    this.state.selectedActivity =   null;  
    this.state.selectedActivityOption =  null;  
    this.state.APIReqObj.activityID =   null;  


      //Set New values 
      this.state.selectedFacility = e.value;
      this.state.APIReqObj.facilityID = e.value;
     
      console.log(this.state.selectedActivity );
      console.log(this.state.selectedActivityOption );
      console.log(this.state.APIReqObj.activityID );



    window
    .APIRequest(appCred, "", "EHRDashboardManager.GetUserAccessData", {
      UserID:this.state.UserID, FacilityID:  this.state.selectedFacility }
      )
    .done((apiRes) => {
      console.log(apiRes)
      if (apiRes.ResponseCode === 200) {
        if (apiRes.ResponseResult[0]) {
       
          for (let item of apiRes.ResponseResult[0].UserData) {
            let obj = {};
            obj["value"] = item.ActivityID;
            obj["label"] = item.description;
             this.state.activitiesDD.push(obj);
          }
         this.state.selectedActivity = "";
          } else {
            this.setState({
              errorMessage: "User activity access is not defined",
            });
        }
       
      }

    })
    .catch((error) => console.log('ERR - GetUserActivityData'))
  }

  GetMainDashboardActivities = () => {

    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetDashboardActivities",
        this.state.APIReqObj
      )
      .then((apiRes) => {
      debugger;
        if (apiRes.ResponseCode === 200) {
       
          if (apiRes.ResponseResult[0]) {
            apiRes.ResponseResult[0].DashboardActivities.map((item) => {
              item.resourcesModalOpen = false;
              item.commentsModalOpen = false;
            });
        
            //Filling banner Information state only for Status
            if (this.state.APIReqObj.activityID === "MTISB") {
              let obj = {};
              obj["ACTIVITYTARGETDATE"] = apiRes.ResponseResult[0].TargetDate;
              obj["ACTIVITYBASELINEDATE"] = apiRes.ResponseResult[0].BaselineDate;
              obj["ACTIVITYSTARTDATE"] = apiRes.ResponseResult[0].StartDate;
              obj["ACTIVITYENDDATE"] = apiRes.ResponseResult[0].EndDate;
              obj["ACTIVITYNINETYPROGRESSDATE"] = apiRes.ResponseResult[0].NinetyProgressDate;
              obj["Facility"] = apiRes.ResponseResult[0].Facility;
              obj["ACTIVITYID"] = apiRes.ResponseResult[0].Activity;
              obj["mainDashboardPercentage"] =
              apiRes.ResponseResult[0].Progress;
              // MM added facilityid
              obj["FACILITYID"] = apiRes.ResponseResult[0].FacilityID;
              this.state.bannerInformation.push(obj);
            } 
            else 
            {
             
              this.state.bannerInformation = [];
            }
            this.setState({
              isOpen: false,
              errorMessage: false,
              DashboardDetails: apiRes.ResponseResult,
              bannerInformation: this.state.bannerInformation,
            });

          } else {
            this.setState({
              isLoading: false,
              errorMessage: true,
            });
          }
        } else {
          this.setState({
            isLoading: false,
            errorMessage: true,
          });
        }
      })
      .catch((error) => {
        this.setState(
          {
            isLoading: false,
            errorMessage: true,
          },
          () => {
            console.log(error);
          }
        );
      });
  };

  GetGanttChartData = () => {
    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.GetGanttChartData",
        this.state.APIReqObj
      )
      .then((apiRes) => {
        if (apiRes.ResponseCode === 200) {
       
          if (apiRes.ResponseResult[0]) {
            apiRes.ResponseResult[0].DashboardActivities.map((item) => {
              item.resourcesModalOpen = false;
              item.commentsModalOpen = false;
            });
            if (this.state.APIReqObj.activityID === "MTISB") {
              let obj = {};
              obj["ACTIVITYTARGETDATE"] = apiRes.ResponseResult[0].TargetDate;
              
              obj["Facility"] = apiRes.ResponseResult[0].Facility;
              obj["ACTIVITYID"] = apiRes.ResponseResult[0].Activity;
              obj["mainDashboardPercentage"] =
                apiRes.ResponseResult[0].Progress;
                // MM added facilityid
              obj["FACILITYID"] = apiRes.ResponseResult[0].FacilityID;
              this.state.bannerInformation.push(obj);
            } else this.state.bannerInformation = [];
            this.setState({
              isOpen: false,
              errorMessage: false,
              DashboardDetails: apiRes.ResponseResult,
              bannerInformation: this.state.bannerInformation,
            });
          } else {
            this.setState({
              isLoading: false,
              errorMessage: true,
            });
          }
        } else {
          this.setState({
            isLoading: false,
            errorMessage: true,
          });
        }
      })
      .catch((error) => {
        this.setState(
          {
            isLoading: false,
            errorMessage: true,
          },
          () => {
            console.log(error);
          }
        );
      });
  };

  GetUserAccessData = () => {
    if (this._isMounted) {
    
      window
        .APIRequest(appCred, "", "EHRDashboardManager.GetUserAccessData", {
          UserID: this.state.UserID,
        })
        .done((apiRes) => {
          if (apiRes.ResponseCode === 200) {
            if (apiRes.ResponseResult[0]) {
              this.setState({
                userAccessData: apiRes.ResponseResult[0],
                defaultAcess: this.state.userAccessData.UserData.find(
                  (a) => a.IsDefault === "Y"
                ),
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: true });
    }
    //is48hrOpen
  };

 
  handleSearchChange = (e) => {
    if(e == null){
      return;
    }
    this.state.selectedActivity = e.value;
     this.state.APIReqObj = {
      facilityID: this.state.selectedFacility, 
      activityID:this.state.selectedActivity,
      LoggedInRole: this.state?.UserRole,
    };
    
    this.setState({ APIReqObj: this.state.APIReqObj });
    //this.UpdateAndFetchData();
    // clear data 

     this.state.bannerInformation= [];
    this.state.DashboardDetails= {};

    //this.UpdateAndFetchData();
    
  };

  UpdateAndFetchData = () => {
    if(this.state.APIReqObj.activityID == null || this.state.APIReqObj.activityID == ''||
      this.state.APIReqObj.facilityID == null || this.state.APIReqObj.facilityID == '')
    {
      return; 

    }
    let reqObj = {
      ActivityID: this.state.APIReqObj.activityID,
      UserID: this.state.UserID,
      FacilityID: this.state.APIReqObj.facilityID, 
    };

    window
      .APIRequest(
        appCred,
        "",
        "EHRDashboardManager.UpdateUserAccessData",
        reqObj
      )
      .done((apiRes) => {
        if (apiRes.ResponseCode === 200) {
          this.setState({
            selectedFacility: this.state.APIReqObj.facilityID,
            selectedActivity: this.state.APIReqObj.activityID,
          });
          this.GetMainDashboardActivities();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const DashboardDetails = this.state.DashboardDetails;
    return !this.state.errorMessage ? (
      <React.Fragment>
        <div
          className="row alert alert-primary ml-0 mb-1 mt-0 mr-0"
          style={{
            padding: "3px 13px 3px 0px",
            color: "rgb(56 53 53)",
            backgroundColor: "rgb(219 219 219)",
            borderColor: "rgb(187 189 192)",
          }}
        >
          <div
            className="col-11 pr-0"
            style={
              this.state.selectedActivity === "MTISB"
                ? { lineHeight: "12px" }
                : { lineHeight: "22px" }
            }
            onClick={this.toggle}
          >
            <span style={{ fontSize: "12px" }}>
              <UserAccessProvider data={this.state.userAccessData}>
                <Suspense fallback={<div>Loading...</div>}>
                  <MainDashboardHeader
                    OTHERsbanner={
                      this.state.selectedActivity !== "MTISB"
                        ? this.state.DashboardDetails[2]
                        : {}
                    }
                    PSBbanner={
                      this.state.selectedActivity === "MTISB"
                        ? this.state.bannerInformation
                        : []
                    }
                    forAct={
                      this.state.selectedActivity === "MTISB"
                        ? "MTISB"
                        : "OTHER"
                    }
                  />
                </Suspense>
              </UserAccessProvider>
            </span>
          </div>
          <div
            className="col-1"
            onClick={this.toggle}
            style={{ paddingRight: "1.8rem" }}
          >
            <i
              style={{
                float: "right",
                lineHeight: "20px",
                fontSize: "20px",
                marginTop: "5px",
              }}
              className={
                this.state.isOpen ? "fa fa-chevron-right" : "fa fa-chevron-down"
              }
            ></i>
          </div>
        </div>
        <Row>
          <Collapse
            style={{ marginTop: "" }}
            isOpen={this.state.isOpen}
            className="col-lg-12"
          >
            <div
              className="alert alert-primary ml-0 pt- pb-0 pr-2 pl-0"
              style={{
                fontSize: "12px",
                color: "rgb(56 53 53)",
                backgroundColor: "rgb(219 219 219)",
                borderColor: "rgb(187 189 192)",
              }}>
              <form>
                <div className="form-row ml-2 pb-2">
                  <div className="col-3">
                    <div className="mb-1">
                      <b>Implementation Site</b>
                    </div>
                    <Select
                     isSearchable={false}
                      // value={{
                      //   value: "AKUHN",
                      //   label: "Aga Khan University Hospital, Nairobi",
                      // }}
                      //options={[{value: 'AKUHN', label:'Aga Khan University Hospital, Nairobi'}]}
                      value={this.state.facilityDD.find(
                        (option) =>
                          option.value === this.state.APIReqObj.facilityID
                      )}
                      name="selectedFacilityOption"
                      options={this.state.facilityDD}
                      onChange={(e) => {
                        this.GetUserActivityData(e);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <div className="mb-1">
                      <b>Dashboard Activity </b>
                    </div>
                    <Select
                    ref={ref => {
                      this.selectRef = ref;
                    }}
                    placeholder="Select Activity"
                      isSearchable={false}
                      options={this.state.activitiesDD}
                      value={this.state.activitiesDD === null ? null : this.state.activitiesDD.find(
                        (option) =>
                          option.value === this.state.APIReqObj.activityID
                      )}
                      onChange={(e) => {
                        this.handleSearchChange(e);
                      }}
                     //value = {this.state.selectedActivity}
                      // value={ this.state.APIReqObj.activityID}
                      name="selectedActivity"
                    />
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      style={{ marginTop: "23px" }}
                      className="btn btn-success"
                      onClick={() => {
                        this.UpdateAndFetchData();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Collapse>
        </Row>
        <UserAccessProvider
          data={this.state.userAccessData}
          getfunc={this.GetMainDashboardActivities}
        >
      
          <Suspense fallback={<div> Loading... </div>}>
            {this.state.selectedActivity === "MTISB" ? (
              <MainStream
                MainData={DashboardDetails}
                userAccess={this.state.userAccessData}
                getfunc={this.GetMainDashboardActivities}
              />
            ) : (
              <EhrApplications
                data={DashboardDetails}
                getfunc={this.GetMainDashboardActivities}
              />
            )}
          </Suspense>
        </UserAccessProvider>
      </React.Fragment>
    ) : (
      <div className="ml-3 mt-2">
        <h5>{this.state.errorMessage}</h5>
      </div>
    );
  }
}

export default ApplicationsMain;
