import React from 'react';

const ValidateInstrumentData = (addRecorderrors, addRecord,pSetErrors) => {
    let errors = addRecorderrors;
    let addRecordData = addRecord;
    let value = true;
    if (!addRecordData.NewBuildProgress ||
        addRecordData.NewBuildProgress < 0 ||
        addRecordData.NewBuildProgress > 100
      ) {
        errors.NewBuildProgress = "Build Progress must be in between 0 to 100";
        value = false;
      }
       if (!addRecordData.NewWeightage) {
        errors.NewWeightage = "value cannot be null";
        value = false;
      }
       if (!addRecordData.NewBaselineDate) {
        errors.NewBaselineDate = "date cannot be empty";
        value = false;
      }
       if (!addRecordData.NewDictionaryID) {
        errors.NewDictionaryID = "value cannot be null";
        value = false;
      }
       if (!addRecordData.NewDictionaryName) {
        errors.NewDictionaryName = "value cannot be null";
        value = false;
      }
      if (!addRecordData.NewActive || addRecordData.NewActive==="Select") {
        errors.NewActive = "value cannot be null";
        value = false;
      }
       if(!addRecordData.NewLabSection){
        errors.NewLabSection = 'value cannot be null'
        value = false
      }
       if(!addRecordData.NewLantronixConfigured){
        errors.NewLantronixConfigured = 'value cannot be null'
        value = false
      }
       if(!addRecordData.NewInsLocation){
        errors.NewInsLocation = 'value cannot be null'
        value = false
      }
       if(!addRecordData.NewCommType){
        errors.NewCommType = 'value cannot be null'
        value = false
      }
       if(!addRecordData.NewInstrumentConfigured){
        errors.NewInstrumentConfigured = 'value cannot be null'
        value = false
      }
       if(!addRecordData.NewLISConnectionEstablished){
        errors.NewLISConnectionEstablished = 'value cannot be null'
        value = false
      }

      pSetErrors(errors)
      return value;
}
 
export default ValidateInstrumentData;