import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { WithContext as ReactTags } from "react-tag-input";
import { KeyCodes, _activityName } from "../../utils/Constants";
import Input from "../Controls/Input";
import Select from "../Controls/Select";
import {
  inputDictionaryName,
  inputDictionaryID,
  selectActive,
  selectWeightage,
  inputBuildProgress,
  inputTargetHCIS,
  datePicker,
  displaySequence,
  commentTextArea,
} from "../Controls/ReusableControlsFunction";
import MultiSelect from "../Controls/MultiSelect";

//#region Form Imports
import DictionaryData from "../AppActDetailDataForms/DictionaryData";
import InstrumentData from "../AppActDetailDataForms/InstrumentData";
import InterfaceData from "../AppActDetailDataForms/InterfaceData";
import ThirdPartySoftwareData from "../AppActDetailDataForms/ThirdPartySoftwareData";
import FutureStateProcessData from "../AppActDetailDataForms/FutureStateProcessData";
import InfrastructureReadinessData from "../AppActDetailDataForms/InfrastructureReadinessData";
import PlansAndStrategiesData from "../AppActDetailDataForms/PlansAndStrategiesData";
import GoLiveExecutionData from "../AppActDetailDataForms/GoLiveExecutionData";
import PreActivitiesData from "../AppActDetailDataForms/PreActivitiesData";

//#endregion

const delimiters = [KeyCodes.enter, KeyCodes.comma];

const renderModalBody = (props) => {
  const pData = props.ParentData;
  const pDataRecord = pData?.addRecord;
  const pDataRecorderrors = pData?.addRecorderrors;
  console.log("pData", pData);
  console.log("pDataRecord", pDataRecord);
  console.log("pDataRecorderrors", pDataRecorderrors);

  if (pData.tableHeading === _activityName.Dictionary) {
    return (
      <DictionaryData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (pData.tableHeading === _activityName.Instrument) {
    return (
      <InstrumentData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.Interface ||
    pData.tableHeading === _activityName.MD
  ) {
    return (
      <InterfaceData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.Documents ||
    pData.tableHeading === _activityName.Ndrb ||
    pData.tableHeading === _activityName.Pdrb
  ) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            {inputDictionaryID(props, "Document ID")}
            {inputDictionaryName(props, "Document Name")}
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
          </div>
          <div className="row mb-2">
            {inputTargetHCIS(props)}
            <Select
              description="Std Content Available"
              name="NewStandardContentAvailable"
              value={pDataRecord.NewStandardContentAvailable}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
          </div>
          <div className="row mb-2">
            {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
            <Select
              description="Signed off Record"
              name="NewSignedOffRecd"
              value={pDataRecord.NewSignedOffRecd}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewBaselineDate",
              "Baseline Date",
              "required-field"
            )}
            {datePicker(props, "NewPlannedDate", "Planned Date")}
          </div>

          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
            <Select
              description="Data On Excel"
              name="NewDataGatheringOnExcel"
              value={pDataRecord.NewDataGatheringOnExcel}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
          </div>
          <div className="row mb-2">
            <Select
              description="Rev By Champions"
              name="NewRevByChampions"
              value={pDataRecord.NewRevByChampions}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField="required-field custom-leftAlign-Label"
              error={pDataRecorderrors?.NewRevByChampions}
            />
            <Select
              description="Rev By Clinical Users"
              name="NewRevByClinicalUsers"
              value={pDataRecord.NewRevByClinicalUsers}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField="required-field custom-leftAlign-Label"
              error={pDataRecorderrors?.NewRevByClinicalUsers}
            />

            {/* <Select
              description='Rev By Clinical Users'
              name='NewRevByChampions'
              value={pDataRecord.NewRevByClinicalUsers}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField='required-field custom-leftAlign-Label'
              error={pDataRecorderrors?.NewRevByClinicalUsers}
            /> */}
          </div>
          <div className="row mb-2">
            <Select
              description="CMS Controlled"
              name="NewCMSControl"
              value={pDataRecord.NewCMSControl}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
            {displaySequence(props)}
          </div>
          {commentTextArea(props)}
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Careplans) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            {inputDictionaryID(props, "CarePlan ID")}
            {inputDictionaryName(props, "CarePlan Name")}
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            {inputTargetHCIS(props)}
          </div>
          <div className="row mb-2">
            {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
          </div>
          <div className="row mb-2">
            <Input
              description="Care Plan Decis."
              name="NewCarePlanDecision"
              value={pDataRecord.NewCarePlanDecision}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
              requiredField="required-field"
              error={pDataRecorderrors.NewCarePlanDecision}
            />
            <Select
              description="Rev By Champions"
              name="NewRevByChampions"
              value={pDataRecord.NewRevByChampions}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField=" required-field custom-leftAlign-Label"
              error={pDataRecorderrors?.NewRevByChampions}
            />
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewBaselineDate",
              "Baseline Date",
              "required-field"
            )}
            {datePicker(props, "NewPlannedDate", "Planned Date")}
          </div>
          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
            <Input
              description="Responsible Team"
              name="NewResponsibleTeam"
              value={pDataRecord.NewResponsibleTeam}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
            />
          </div>
          <div className="row mb-2">{displaySequence(props)}</div>
          {commentTextArea(props)}
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Ordersets) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            {inputDictionaryID(props, "Orderset ID")}
            {inputDictionaryName(props, "Orderset Name")}
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
          </div>
          <div className="row mb-2">
            {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
            {inputTargetHCIS(props)}
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewBaselineDate",
              "Baseline Date",
              "required-field"
            )}
            {datePicker(props, "NewPlannedDate", "Planned Date")}
          </div>
          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
            <Input
              description="Responsible Team"
              name="NewResponsibleTeam"
              value={pDataRecord.NewResponsibleTeam}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
            />
          </div>
          <div className="row mb-2">{displaySequence(props)}</div>
          {commentTextArea(props)}
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Gaprequirement) {
    return (
      <div>
        <form>
          <div className="form-group row col-lg-11 mt-2">
            <label className="col-sm-2 text-left pt-2 pl-0 col-form-label required-field">
              Gap Description:{" "}
            </label>
            <div className="col-sm-8 mb-3" style={{ marginLeft: "26px" }}>
              <input
                style={{ width: "566px" }}
                type="text"
                name="NewDictionaryName"
                value={pDataRecord.NewDictionaryName}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
                onKeyDown={props.handleEnter}
              />
              {pDataRecorderrors.NewDictionaryName ? (
                <label style={{ fontSize: 10, color: "RED" }}>
                  {pDataRecorderrors.NewDictionaryName}
                </label>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            <Select
              description="Requirement Source"
              name="NewRequirementSource"
              value={pDataRecord.NewRequirementSource}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField=" required-field"
              error={pDataRecorderrors?.NewRequirementSource}
            />
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Progress:{" "}
                </label>
                <div className="input-group col-sm-7">
                  <input
                    type="number"
                    name="NewGapProgress"
                    value={pDataRecord.NewGapProgress}
                    className="form-control fontsize border-right-0 rounded-left "
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text border-left-0"
                      style={{ height: "28px", border: "1px solid" }}
                    >
                      %
                    </span>
                  </div>
                  {pDataRecorderrors.NewGapProgress ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewGapProgress}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <Select
              description="Requirement Nature"
              name="NewRequirementNature"
              value={pDataRecord.NewRequirementNature}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField=" required-field"
              error={pDataRecorderrors?.NewRequirementNature}
            />
          </div>
          <div className="row mb-2">
            {datePicker(props, "NewPlannedDate", "Planned Date")}
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
          </div>
          <div className="row mb-2">
            <Select
              description="Category"
              name="NewRequirementCatoegory"
              value={pDataRecord.NewRequirementCatoegory}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField="required-field pr-0"
              error={pDataRecorderrors?.NewRequirementCatoegory}
            />
            <Input
              description="MT Response"
              name="NewMTResponse"
              value={pDataRecord.NewMTResponse}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
            />
          </div>
          <div className="row mb-2">
            <Select
              description="Gap Walk Thru Done"
              name="NewGapWalkThruDone"
              value={pDataRecord.NewGapWalkThruDone}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              error={pDataRecorderrors.NewGapWalkThruDone}
              requiredField="required-field"
            />
            <Select
              description="Gap Walk Thru Outcome"
              name="NewGapWalkThruOutcome"
              value={pDataRecord.NewGapWalkThruOutcome}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              requiredField="pr-0"
            />
          </div>
          <div className="row mb-2">
            <Select
              description="Dtls. shared with MT"
              name="NewDetailsSharedWithMT"
              value={pDataRecord.NewDetailsSharedWithMT}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
            {datePicker(
              props,
              "NewDetailsSharedWithMTDT",
              "Dtls. shared with MT on",
              "pr-0"
            )}
          </div>
          <div className="row mb-2">
            <Select
              description="Specs Recd From MT"
              name="NewSpecsRecdFromMT"
              value={pDataRecord.NewSpecsRecdFromMT}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
            {datePicker(
              props,
              "NewSpecsRecdFromMTDT",
              "Specs Recd from MT on",
              " pr-0"
            )}
          </div>
          <div className="row mb-2">
            <Select
              description="Specs Appvd. By AKU"
              name="NewSpecsApprovedByAKU"
              value={pDataRecord.NewSpecsApprovedByAKU}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
            {datePicker(
              props,
              "NewSpecsApprovedByAKUDT",
              "Specs Appvd. By AKU on",
              " pr-0"
            )}
          </div>
          <div className="row mb-2">
            <Select
              description="Requirement Fulfilled"
              name="NewRequirementFulfilled"
              value={pDataRecord.NewRequirementFulfilled}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
            <Input
              description="Gap ID"
              name="NewGapID"
              value={pDataRecord.NewGapID}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
              error={pDataRecorderrors.NewGapID}
              requiredField="required-field"
            />
          </div>
          <div className="row mb-2">
            <Input
              description="Requirement Ref No"
              name="NewRequirementRefNo"
              value={pDataRecord.NewRequirementRefNo}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
              error={pDataRecorderrors.NewRequirementRefNo}
              requiredField="required-field"
            />
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
          </div>
          <div className="row mb-2">
            <Input
              description="Display Sequence"
              name="NewDisplaySequence"
              value={pDataRecord.NewDisplaySequence}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
            />
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Comments:{" "}
                </label>
                <div className="col-sm-7">
                  <textarea
                    type="text"
                    name="NewComments"
                    value={pDataRecord.NewComments}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Datamigration) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            {inputDictionaryName(props, "Data Description")}
            {inputDictionaryID(props, "Data ID")}
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
          </div>
          <div className="row mb-2">
            {inputBuildProgress(props)} {/*Build Progress Repeated Function */}
            {inputTargetHCIS(props)}
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewBaselineDate",
              "Baseline Date",
              "required-field"
            )}
            {datePicker(props, "NewPlannedDate", "Planned Date")}
          </div>
          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
            <Select
              description="Data On Excel"
              name="NewDataGatheringOnExcel"
              value={pDataRecord.NewDataGatheringOnExcel}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
            />
          </div>
          <div className="row mb-2">
            <Input
              description="Responsible Team"
              name="NewResponsibleTeam"
              value={pDataRecord.NewResponsibleTeam}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
            />
            {displaySequence(props)}
          </div>
          {commentTextArea(props)}
        </form>
      </div>
    );
  }
  if (
    pData.tableHeading === _activityName.Euttraining ||
    pData.tableHeading === _activityName.Sutraining ||
    pData.tableHeading === _activityName.DictTrain ||
    pData.tableHeading === _activityName.AppTr ||
    pData.tableHeading === _activityName.PhaApp ||
    pData.tableHeading === _activityName.LabApp
  ) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            <Input
              description="Training Sess ID"
              name="NewTrainingSessionID"
              value={pDataRecord.NewTrainingSessionID}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="text"
              error={pDataRecorderrors.NewTrainingSessionID}
              requiredField="required-field"
            />
            {inputDictionaryName(props, "Training Session")}
          </div>
          <div className="row mb-2">
            <Input
              description="Progress"
              name="NewGapProgress"
              value={pDataRecord.NewGapProgress}
              onChange={(e) => {
                props.handleOnChange(e);
              }}
              handleEnter={props.handleEnter}
              type="number"
              error={pDataRecorderrors.NewGapProgress}
              requiredField="required-field"
            />
            {selectActive(props)} {/*Active Repeated Function */}
          </div>
          <hr />
          <div className="mr-4" style={{ marginBottom: "8%" }}>
            <button
              className="btn btn-sm btn-info"
              style={{ float: "right" }}
              onClick={(e) => {
                props.copyFromSchToAct(e);
              }}
            >
              {"Copy from Scheduled"}
              <i className="ml-2 fa fa-clipboard"></i>
            </button>
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewschDate",
              "Scheduled Date",
              "required-field"
            )}
            {datePicker(props, "NewactDate", "Actual Date")}
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label required-field">
                  Scheduled Start Time:{" "}
                </label>
                <div className="col-sm-7">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      name="NewSchStartTime"
                      value={pDataRecord.NewSchStartTime}
                      onChange={(value) => {
                        props.handleDateTimeChange("NewSchStartTime", value);
                      }}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {pDataRecorderrors.NewSchStartTime ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewSchStartTime}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label">
                  Actual Start Time:{" "}
                </label>
                <div className="col-sm-7">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      name="NewActStartTime"
                      value={pDataRecord.NewActStartTime}
                      onChange={(value) => {
                        props.handleDateTimeChange("NewActStartTime", value);
                      }}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label required-field">
                  Scheduled End Time:{" "}
                </label>
                <div className="col-sm-7">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      name="NewSchEndTime"
                      value={pDataRecord.NewSchEndTime}
                      onChange={(value) => {
                        props.handleDateTimeChange("NewSchEndTime", value);
                      }}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {pDataRecorderrors.NewSchEndTime ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewSchEndTime}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label">
                  Actual End Time:{" "}
                </label>
                <div className="col-sm-7">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      name="NewActEndTime"
                      value={pDataRecord.NewActEndTime}
                      onChange={(value) => {
                        props.handleDateTimeChange("NewActEndTime", value);
                      }}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Scheduled Venue:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewSchVenue"
                    value={pDataRecord.NewSchVenue}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewSchVenue ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewSchVenue}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Actual Venue:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewActVenue"
                    value={pDataRecord.NewActVenue}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Sch Participants No:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="number"
                    name="NewSchParticipants"
                    value={pDataRecord.NewSchParticipants}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Act Participants No:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="number"
                    name="NewActParticipants"
                    value={pDataRecord.NewActParticipants}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row col-lg-12">
            <label className="col-sm-2 text-left pt-2 pl-0 pr-0 col-form-label">
              Sch Participant Names:{" "}
            </label>
            <div
              className="col-sm-10 mb-2 ml-0 pr-0"
              style={{ paddingLeft: "32px" }}
            >
              <ReactTags
                name="schParticipantstags"
                tags={
                  pData.schParticipantstags ? pData.schParticipantstags : []
                }
                //inline={false}
                inputFieldPosition="top"
                //suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={props.handleDeleteschtags}
                handleAddition={(e) => {
                  props.handleAdditionschtags(e);
                }}
                handleDrag={props.handleDragschtags}
              />
            </div>
          </div>
          <div className="form-group row col-lg-12">
            <label className="col-sm-2 text-left pt-2 pl-0 pr-0 col-form-label">
              Act Participant Names:{" "}
            </label>
            <div
              className="col-sm-10 mb-2 ml-0 pr-0"
              style={{ paddingLeft: "32px" }}
            >
              <ReactTags
                name="actParticipantstags"
                tags={
                  pData.actParticipantstags ? pData.actParticipantstags : []
                }
                //inline={false}
                inputFieldPosition="top"
                //suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={props.handleDeletetags}
                handleAddition={(e) => {
                  props.handleAdditiontags(e);
                }}
                handleDrag={props.handleDragtags}
                handleTagClick={props.handleTagClicktags}
              />
            </div>
          </div>
          <hr />
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Display Sequence:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="number"
                    name="NewDisplaySequence"
                    value={pDataRecord.NewDisplaySequence}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row col-lg-11">
            <label className="col-sm-3 text-left pt-2 pl-0 col-form-label  mb-2">
              Training Instructor:{" "}
            </label>
            <div className="col-sm-9" style={{ marginLeft: "-26px" }}>
              <input
                style={{ width: "125%" }}
                type="text"
                name="NewTrainingInstructors"
                value={pDataRecord.NewTrainingInstructors}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
                onKeyDown={props.handleEnter}
              />
            </div>
          </div>
          <div className="form-group row col-lg-11">
            <label className="col-sm-1 text-left pt-2 pl-0 col-form-label">
              Comments:{" "}
            </label>
            <div className="col-sm-9" style={{ marginLeft: "80px" }}>
              <textarea
                style={{ width: "125%", minHeight: "50px" }}
                type="text"
                name="NewComments"
                value={pDataRecord.NewComments}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (
    pData.tableHeading === _activityName.Testing ||
    pData.tableHeading === _activityName.IntTesting ||
    pData.tableHeading === _activityName.ParaTesting ||
    pData.tableHeading === _activityName.MGL ||
    pData.tableHeading === _activityName.MGLII ||
    pData.tableHeading === _activityName.IntTestingII
  ) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Test Case ID:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewTestCaseID"
                    value={pDataRecord.NewTestCaseID}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewTestCaseID ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewTestCaseID}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row col-lg-11">
            <label className="col-sm-1 text-left pt-2 pl-0 pr-0 col-form-label">
              Test Case Description:{" "}
            </label>
            <div className="col-sm-9 mb-3" style={{ marginLeft: "80px" }}>
              <textarea
                style={{ width: "125%", minHeight: "50px" }}
                type="text"
                name="NewDictionaryName"
                value={pDataRecord.NewDictionaryName}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
                requiredField="required-field"
                error={pDataRecorderrors?.NewDictionaryName}
              />
            </div>
          </div>
          <div className="row mb-2">
            {selectActive(props)} {/*Active Repeated Function */}
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Progress:{" "}
                </label>
                <div className="input-group col-sm-7">
                  <input
                    type="number"
                    name="NewGapProgress"
                    value={pDataRecord.NewGapProgress.toString()}
                    className="form-control fontsize border-right-0 rounded-left"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text border-left-0"
                      style={{ height: "28px", border: "1px solid" }}
                    >
                      %
                    </span>
                  </div>
                  {pDataRecorderrors.NewGapProgress ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewGapProgress}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            {datePicker(
              props,
              "NewBaselineDate",
              "Baseline Date",
              "required-field"
            )}
            {datePicker(props, "NewPlannedDate", "Planned Date")}
          </div>
          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return datePicker(
                  props,
                  "NewCompletionDate",
                  "Completion Date"
                );
              }
            })()}
            <MultiSelect
              description="Test Case Result"
              name="NewTestCaseResult"
              value={pData.multidefault}
              onChange={(e) => {
                props.handleOnChangeMulti(e);
              }}
              handleEnter={props.handleEnter}
              error={pDataRecorderrors.NewTestCaseResult}
              DataRecord={pDataRecord.NewTestCaseResult}
            />
          </div>
          <div className="row mb-2">
            {selectWeightage(props, "required-field")}{" "}
            {/*Weightage Repeated Function */}
            {displaySequence(props)}
          </div>
          {commentTextArea(props)}
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Reportwriting) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Report ID:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewReportID"
                    value={pDataRecord.NewReportID}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewReportID ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewReportID}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Report Name:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewDictionaryName"
                    value={pDataRecord.NewDictionaryName}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  {pDataRecorderrors.NewDictionaryName ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewDictionaryName}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Active:{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    name="NewActive"
                    // defaultValue = {{label: pDataRecord.NewActive ? pDataRecord.NewActive: "" , value : pDataRecord.NewActive==="Yes" ? "Y":"N"}}
                    value={pDataRecord.NewActive}
                    className="form-control custom-individual-dropdown fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  >
                    <option value=""> Select </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                  {pDataRecorderrors.NewActive ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewActive}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Weightage:{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    name="NewWeightage"
                    value={pDataRecord.NewWeightage}
                    className="form-control custom-individual-dropdown fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  >
                    <option value=""> Select</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  {pDataRecorderrors.NewWeightage ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewWeightage}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Progress:{" "}
                </label>
                <div className="input-group col-sm-7">
                  <input
                    type="number"
                    name="NewBuildProgress"
                    value={pDataRecord.NewBuildProgress.toString()}
                    className="form-control fontsize border-right-0 rounded-left"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text border-left-0"
                      style={{ height: "28px", border: "1px solid" }}
                    >
                      %
                    </span>
                  </div>
                  {pDataRecorderrors.NewBuildProgress ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewBuildProgress}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Report Type:{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    name="NewReportType"
                    value={pDataRecord.NewReportType}
                    className="form-control custom-individual-dropdown fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  >
                    <option value=""> Select</option>
                    <option value="M-AT">M-AT</option>
                    <option value="NPR">NPR</option>
                  </select>
                  {pDataRecorderrors.NewReportType ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewReportType}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label required-field">
                  Baseline Date:{" "}
                </label>
                <div className="col-sm-7">
                  <DatePicker
                    className="pl-2"
                    name="NewBaselineDate"
                    selected={pDataRecord.NewBaselineDate}
                    onChange={(date) => {
                      props.handleDateTimeChange("NewBaselineDate", date);
                    }}
                    onKeyDown={props.handleEnter}
                    minDate={pData.todayDate}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewBaselineDate ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewBaselineDate}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label">
                  Planned Date:{" "}
                </label>
                <div className="col-sm-7">
                  <DatePicker
                    className="pl-2"
                    name="NewPlannedDate"
                    selected={pDataRecord.NewPlannedDate}
                    onChange={(date) => {
                      props.handleDateTimeChange("NewPlannedDate", date);
                    }}
                    onKeyDown={props.handleEnter}
                    minDate={pData.todayDate}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return (
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label className="col-sm-5 text-left pt-1 col-form-label">
                        Completion Date:{" "}
                      </label>
                      <div className="col-sm-7">
                        <DatePicker
                          className="pl-2"
                          name="NewCompletionDate"
                          selected={pDataRecord.NewCompletionDate}
                          onChange={(date) => {
                            props.handleDateTimeChange(
                              "NewCompletionDate",
                              date
                            );
                          }}
                          onKeyDown={props.handleEnter}
                          maxDate={pData.todayDate}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })()}
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Display Sequence:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="number"
                    name="NewDisplaySequence"
                    value={pDataRecord.NewDisplaySequence}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row col-lg-11">
            <label className="col-sm-1 text-left pt-2 pl-0 col-form-label">
              Comments:{" "}
            </label>
            <div className="col-sm-9" style={{ marginLeft: "80px" }}>
              <textarea
                style={{ width: "125%", minHeight: "50px" }}
                type="text"
                name="NewComments"
                value={pDataRecord.NewComments}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (pData.tableHeading === _activityName.Projectmgmnt) {
    return (
      <div>
        <form>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Task ID:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewProjectID"
                    value={pDataRecord.NewProjectID}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewProjectID ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewProjectID}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Project Name:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="NewDictionaryName"
                    value={pDataRecord.NewDictionaryName}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  {pDataRecorderrors.NewDictionaryName ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewDictionaryName}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label required-field">
                  Active:{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    name="NewActive"
                    // defaultValue = {{label: pDataRecord.NewActive ? pDataRecord.NewActive: "" , value : pDataRecord.NewActive==="Yes" ? "Y":"N"}}
                    value={pDataRecord.NewActive}
                    className="form-control custom-individual-dropdown fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  >
                    <option value=""> Select </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                  {pDataRecorderrors.NewActive ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewActive}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Weightage:{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    name="NewWeightage"
                    value={pDataRecord.NewWeightage}
                    className="form-control custom-individual-dropdown fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  >
                    <option value=""> Select</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  {pDataRecorderrors.NewWeightage ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewWeightage}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left col-form-label required-field">
                  Progress:{" "}
                </label>
                <div className="input-group col-sm-7">
                  <input
                    type="number"
                    name="NewGapProgress"
                    value={pDataRecord.NewGapProgress.toString()}
                    className="form-control fontsize border-right-0 rounded-left"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text border-left-0"
                      style={{ height: "28px", border: "1px solid" }}
                    >
                      %
                    </span>
                  </div>
                  {pDataRecorderrors.NewGapProgress ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewGapProgress}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {(() => {
              if (pData.formHeading === "Edit Record") {
                return (
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label className="col-sm-5 text-left pt-1 col-form-label">
                        Completion Date:{" "}
                      </label>
                      <div className="col-sm-7">
                        <DatePicker
                          className="pl-2"
                          name="NewCompletionDate"
                          selected={pDataRecord.NewCompletionDate}
                          onChange={(date) => {
                            props.handleDateTimeChange(
                              "NewCompletionDate",
                              date
                            );
                          }}
                          onKeyDown={props.handleEnter}
                          maxDate={pData.todayDate}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })()}
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label required-field">
                  Baseline Date:{" "}
                </label>
                <div className="col-sm-7">
                  <DatePicker
                    className="pl-2"
                    name="NewBaselineDate"
                    selected={pDataRecord.NewBaselineDate}
                    onChange={(date) => {
                      props.handleDateTimeChange("NewBaselineDate", date);
                    }}
                    onKeyDown={props.handleEnter}
                    minDate={pData.todayDate}
                    autoComplete="off"
                  />
                  {pDataRecorderrors.NewBaselineDate ? (
                    <label style={{ fontSize: 10, color: "RED" }}>
                      {pDataRecorderrors.NewBaselineDate}
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-1 col-form-label">
                  Planned Date:{" "}
                </label>
                <div className="col-sm-7">
                  <DatePicker
                    className="pl-2"
                    name="NewPlannedDate"
                    selected={pDataRecord.NewPlannedDate}
                    onChange={(date) => {
                      props.handleDateTimeChange("NewPlannedDate", date);
                    }}
                    onKeyDown={props.handleEnter}
                    minDate={pData.todayDate}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-sm-5 text-left pt-2 col-form-label">
                  Display Sequence:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="number"
                    name="NewDisplaySequence"
                    value={pDataRecord.NewDisplaySequence}
                    className="form-control fontsize"
                    onChange={(e) => {
                      props.handleOnChange(e);
                    }}
                    onKeyDown={props.handleEnter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row col-lg-11">
            <label className="col-sm-1 text-left pt-2 pl-0 col-form-label">
              Comments:{" "}
            </label>
            <div className="col-sm-9" style={{ marginLeft: "80px" }}>
              <textarea
                style={{ width: "125%", minHeight: "50px" }}
                type="text"
                name="NewComments"
                value={pDataRecord.NewComments}
                className="form-control fontsize"
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (
    pData.tableHeading === _activityName.Interbit ||
    pData.tableHeading === _activityName.SIS ||
    pData.tableHeading === _activityName.VR ||
    pData.tableHeading === _activityName.Elsevier ||
    pData.tableHeading === _activityName.SPG ||
    pData.tableHeading === _activityName.WK ||
    pData.tableHeading === _activityName.VTH ||
    pData.tableHeading === _activityName.IMO ||
    pData.tableHeading === _activityName.CPT
  ) {
    return (
      <ThirdPartySoftwareData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.FSP ||
    pData.tableHeading === _activityName.CSP ||
    pData.tableHeading === _activityName.TMV
  ) {
    return (
      <FutureStateProcessData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.EndUserDevices ||
    pData.tableHeading === _activityName.CentralHardware ||
    pData.tableHeading === _activityName.EIC ||
    pData.tableHeading === _activityName.NetworkRead
  ) {
    return (
      <InfrastructureReadinessData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.Plans ||
    pData.tableHeading === _activityName.Strategies
  ) {
    return (
      <PlansAndStrategiesData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (pData.tableHeading === _activityName.Gla) {
    return (
      <GoLiveExecutionData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
  if (
    pData.tableHeading === _activityName.PreAct ||
    pData.tableHeading === _activityName.Pdp ||
    pData.tableHeading === _activityName.US ||
    pData.tableHeading === _activityName.Kpi ||
    pData.tableHeading === _activityName.RBT ||
    pData.tableHeading === _activityName.Eup ||
    pData.tableHeading === _activityName.TP ||
    pData.tableHeading === _activityName.TM ||
    pData.tableHeading === _activityName.RA ||
    pData.tableHeading === _activityName.ITI ||
    pData.tableHeading === _activityName.ITII ||
    pData.tableHeading === _activityName.ITIII ||
    pData.tableHeading === _activityName.ITIV ||
    pData.tableHeading === _activityName.SA ||
    pData.tableHeading === _activityName.PGLO ||
    pData.tableHeading === _activityName.UAM ||
    pData.tableHeading === _activityName.RAII ||
    pData.tableHeading === _activityName.RAIII ||
    pData.tableHeading === _activityName.GLC ||
    pData.tableHeading === _activityName.CM ||
    pData.tableHeading === _activityName.AW ||
    pData.tableHeading === _activityName.GI ||
    pData.tableHeading === _activityName.NbiVisit ||
    pData.tableHeading === _activityName.PP ||
    pData.tableHeading === _activityName.TB ||
    pData.tableHeading === _activityName.PGLS ||
    pData.tableHeading === _activityName.SD
    //last 6 line added by -mz
  ) {
    return (
      <PreActivitiesData
        props={props}
        pData={pData}
        pDataRecord={pDataRecord}
        pDataRecorderrors={pDataRecorderrors}
      />
    );
  }
};
const AddnEditFormModal = (props) => {
  return (
    <Modal isOpen={props.ParentData.modalOpen} className="custom-modal-style">
      <ModalHeader>{props.ParentData.formHeading}</ModalHeader>
      <ModalBody>{renderModalBody(props)}</ModalBody>
      <ModalFooter>
        <Button
          style={{ width: "72px" }}
          color="success"
          onClick={props.saveNewRecord}
        >
          Save
        </Button>
        <Button color="danger" onClick={props.closeAddNEditFormModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddnEditFormModal;
