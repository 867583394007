import moment from "moment";
import React from "react";
import {_activityName,} from '../../utils/Constants'
import simple from "./../assests/Dashboard/boxblank2-Artboard_1.png";

export function copyFromSchToAct(data){
    data.NewactDate = data.NewschDate;
    data.NewActStartTime = data.NewSchStartTime;
    data.NewActEndTime = data.NewSchEndTime;
    data.NewActVenue = data.NewSchVenue;
    data.NewActParticipants = data.NewSchParticipants;
    data.NewActParticipantName = data.NewSchParticipantNames;
    return data
    
} 

export function recordHistoryObj (x){
    let reqData={
        FACILITYID: x.FACILITYID.value,
        APPLICATIONID: x.APPLICATIONID.value,
        ACTIVITYID: x.ACTIVITYID.value,
        SUBACTIVITYID: x.SUBACTIVITYID.value, 
        TRAININGSESSIONID: x.DICTIONARYID?.value,
        INTERFACEID: x.DICTIONARYID?.value,
        DICTIONARYID: x.DICTIONARYID?.value,
        INSTRUMENTID: x.DICTIONARYID?.value,
        DOCUMENTID: x.DICTIONARYID?.value,
        CAREPLANID: x.DICTIONARYID?.value,
        ORDERSETID: x.DICTIONARYID?.value,
        GAPID: x.DICTIONARYID?.value,
        TESTCASEID: x.DICTIONARYID?.value,
        REPORTID: x.REPORTID ? x.REPORTID.value : "",
        TASKID: x.DICTIONARYID?.value
        }
        return reqData
}

export function editRowObject (item){
    const addRecord ={
        NewDictionaryID : item.DICTIONARYID?.value ,
        NewDictionaryName : item.DESCRIPTION.value,
        NewActive : item._ACTIVE.value,
        NewStandardContentAvailable : item._STANDARDCONTENTAVAILABLE ? item._STANDARDCONTENTAVAILABLE.value : "",
        NewBuildProgress : item.BUILDPROGRESS ? item.BUILDPROGRESS.value.toString(): "0",
        NewCMSControl : item._CMSCONTROL ? item._CMSCONTROL.value : "",
        NewWeightage : item.WEIGHTAGE ? item.WEIGHTAGE.value.toString() : "",
        NewDataGatheringOnExcel : item._DATAGATHERINGONEXCEL ? item._DATAGATHERINGONEXCEL.value : "",
        NewTargetHCIS : item.TARGETHCIS ? item.TARGETHCIS.value : "",
        NewBaselineDate : item.BASELINEDATE ? item.BASELINEDATE.value  ? moment(item.BASELINEDATE.value).toDate() :"" : "",
        NewPlannedDate : item.PLANNEDDATE ? item.PLANNEDDATE.value ?  moment(item.PLANNEDDATE.value).toDate() : "" : "",
        NewCompletionDate : item.ACTUALCOMPLETIONDATE ? item.ACTUALCOMPLETIONDATE.value ? moment(item.ACTUALCOMPLETIONDATE.value).toDate() : "" : "",
        NewResponsibleTeam : item.RESPONSIBLEAPPTEAM ? item.RESPONSIBLEAPPTEAM.value : "",
        NewComments : item.COMMENTS.value ? item.COMMENTS.value : "",
        NewLabSection : item.LABSECTION ? item.LABSECTION.value : "",
        NewInsLocation : item.INSLOCATION ? item.INSLOCATION.value : "",
        NewInstrumentConfigured : item._INSTRUMENTCONFIGURED ? item._INSTRUMENTCONFIGURED.value : "",
        NewLISConnectionEstablished : item._LISCONNESTABLISHED ? item._LISCONNESTABLISHED.value : "",
        NewLantronixConfigured : item._LANTRONIXCONFIGURED ? item._LANTRONIXCONFIGURED.value: "",
        NewCommType : item.COMMTYPE ? item.COMMTYPE.value :"",
        NewInterfaceBelongsto : item.INTERFACEBELONGSTO ? item.INTERFACEBELONGSTO.value : "",
        NewCommFromMT : item._COMMFROMMT ? item._COMMFROMMT.value: "",
        NewCommFromOV : item._COMMFROMOV ? item._COMMFROMOV.value: "",
        NewDictionaryNature : item.DICTIONARYNATURE ? item.DICTIONARYNATURE.value: "",
        NewConfigintoSandbox : item._CONFIGINTOSANDBOX ? item._CONFIGINTOSANDBOX.value : "",
        NewConfigintoTest : item._CONFIGINTOTEST ? item._CONFIGINTOTEST.value : "",
        NewRevByChampions : item._REVBYCHAMPIONS ? item._REVBYCHAMPIONS.value : "",
        NewRevByClinicalUsers : item._REVBYCLINICALUSERS ? item._REVBYCLINICALUSERS.value : "",
        NewBuildintoSandbox : item._BUILDINTOSANDBOX ? item._BUILDINTOSANDBOX.value : "",
        NewSignedOffRecd : item._SIGNEDOFFRECD ? item._SIGNEDOFFRECD.value : "",
        NewDisplaySequence : item.DISPLAYSEQUENCE ? item.DISPLAYSEQUENCE.value : "",
        NewCarePlanDecision : item.CAREPLANDECISION ? item.CAREPLANDECISION.value : "",
        NewGapID : item.DICTIONARYID ? item.DICTIONARYID.value :"",
        NewRequirementSource : item.REQUIREMENTSOURCE ? item.REQUIREMENTSOURCE.value : "",
        NewRequirementRefNo : item.REQUIREMENTREFNO ? item.REQUIREMENTREFNO.value :"",
        NewMTResponse : item.MTRESPONSE ? item.MTRESPONSE.value :"",
        NewRequirementNature : item.REQUIREMENTNATURE ? item.REQUIREMENTNATURE.value :"",
        NewRequirementCatoegory : item.REQUIREMENTCATEGORY ? item.REQUIREMENTCATEGORY.value :"",
        NewGapWalkThruDone : item._GAPWALKTHRUDONE ? item._GAPWALKTHRUDONE.value : "",
        NewGapWalkThruOutcome : item.GAPWALKTHRUOUTCOME ? item.GAPWALKTHRUOUTCOME.value :"",
        NewDetailsSharedWithMT : item.DETAILSSHAREDWITHMT ? item.DETAILSSHAREDWITHMT.value : "",
        NewDetailsSharedWithMTDT : item.DETAILSSHAREDWITHMTDT ? item.DETAILSSHAREDWITHMTDT.value ? moment(item.DETAILSSHAREDWITHMTDT.value).toDate() :"" : "",
        NewSpecsRecdFromMT : item.SPECSRECDFROMMT ? item.SPECSRECDFROMMT.value ? item.SPECSRECDFROMMT.value :"" : "",
        NewSpecsRecdFromMTDT : item.SPECSRECDFROMMTDT ? item.SPECSRECDFROMMTDT.value ? moment(item.SPECSRECDFROMMTDT.value).toDate() :"" : "",
        NewSpecsApprovedByAKU : item.SPECSAPPROVEDBYAKU ? item.SPECSAPPROVEDBYAKU.value :"",
        NewSpecsApprovedByAKUDT : item.SPECSAPPROVEDBYAKUDT ? item.SPECSAPPROVEDBYAKUDT.value ? moment(item.SPECSAPPROVEDBYAKUDT.value).toDate() :"" : "",
        NewRequirementFulfilled : item._REQUIREMENTFULFILLED ? item._REQUIREMENTFULFILLED.value :"",
        NewGapProgress : item.PROGRESS ? item.PROGRESS.value.toString() : "", //also for Progress (Application Testing)
        NewschDate : item.SCHDATE ? item.SCHDATE.value  ? moment(item.SCHDATE.value).toDate() :"" : "",
        NewactDate : item.ACTDATE ? item.ACTDATE.value  ? moment(item.ACTDATE.value).toDate() :"" : "",
        NewSchStartTime : item.SCHSTARTTIME ? item.SCHSTARTTIME.value ?  moment(item.SCHSTARTTIME.value).toDate() : null : null,
        NewSchEndTime : item.SCHENDTIME ? item.SCHENDTIME.value ?  moment(item.SCHENDTIME.value).toDate() : null : null,
        NewActStartTime : item.ACTSTARTTIME ? item.ACTSTARTTIME.value ?  moment(item.ACTSTARTTIME.value).toDate() : null : null,
        NewActEndTime : item.ACTENDTIME ? item.ACTENDTIME.value ?  moment(item.ACTENDTIME.value).toDate() : null : null,
        NewSchVenue : item.SCHVENUE ? item.SCHVENUE.value : "",
        NewSchParticipants : item.SCHPARTICIPANTS ? item.SCHPARTICIPANTS.value : "",
        NewActVenue : item.ACTVENUE ? item.ACTVENUE.value : "",
        NewActParticipants : item.ACTPARTICIPANTS ? item.ACTPARTICIPANTS.value : "",
        NewTrainingInstructors : item.TRAININGINSTRUCTORS ? item.TRAININGINSTRUCTORS.value : "",
        NewTrainingSessionID: item.TRAININGSESSIONID ? item.TRAININGSESSIONID.value : "",
        NewTestCaseID : item.TESTCASEID ? item.TESTCASEID.value : "",
        NewTestCaseResult  : item.TESTCASERESULT ? item.TESTCASERESULT.value : "",
        NewReportType : item.REPORTTYPE ? item.REPORTTYPE.value : "",
        NewReportID : item.REPORTID ? item.REPORTID.value : "",
        NewProjectID : item.DICTIONARYID ? item.DICTIONARYID.value :"",
        //Project Status Board Changes
          // Third Party Software
        NewTPSID : item.TPSID ? item.TPSID.value :"",        
          // Future State Process
        NewFSPID : item.FSPID ? item.FSPID.value :"",   
          // Plans And Strategies
        NewSAPID : item.SAPID ? item.SAPID.value :"",   
          // Infrastructure Readiness
        NewINFREADINESSID : item.INFREADINESSID ? item.INFREADINESSID.value : "",
          // Go-Live Executions
        NewGLAID : item.GLAID ? item.GLAID.value : "",
          // Pre-Activites
        NewPREACTIVITYID : item.PREACTIVITYID ? item.PREACTIVITYID.value : ""

        //--End
    }
    return addRecord
    
}

export function fullInformation(value){
    let val
    if(value.includes("\n")){
      val = value.split("\n")
      for(let i = 0; i < val.length; i++){
        val[i] = val[i] + "<br/>" ;
      }
      val =val.join("")
    }
    else{
      val = value
    }
    return val 
}
// ---Tile Styling Functions
export const TileProperties ={
    individualHexagon : "",
    cardBoxClass : ""
}

export const TileBackgroundStyle = {
    cursor:"pointer",
    backgroundImage: `url('${simple}')`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "90%",
    width: "105%",
    borderRadius: "15px",
    border: "1px solid #7abcbe",
};


export function setTileStyle(dateDiff,roundedProgressPercentageValue){
    const hexagonStyle =(hex_color)=>{
      return hex_color+" text-center"
    }

    if(dateDiff >= 0){
      if(roundedProgressPercentageValue >=90 ){
         TileProperties.individualHexagon = hexagonStyle("individual-greenhexagon")
      }
      else if(roundedProgressPercentageValue < 90){
        TileProperties.individualHexagon = hexagonStyle("individual-redhexagon")
      }
    }
    else if(dateDiff <=-60){
      if(roundedProgressPercentageValue >=75){
        TileProperties.individualHexagon = hexagonStyle("individual-greenhexagon")
      }
      else if(roundedProgressPercentageValue >=20 && 
              roundedProgressPercentageValue < 75){
        TileProperties.individualHexagon = hexagonStyle("individual-bluehexagon")
      }
      else if(roundedProgressPercentageValue < 20){
        TileProperties.individualHexagon = hexagonStyle("individual-redhexagon")
        
      }
    }
    else if(dateDiff >-60){
      if(roundedProgressPercentageValue >=90){
        TileProperties.individualHexagon = hexagonStyle("individual-greenhexagon")
      }
      else if(roundedProgressPercentageValue >=30 &&
              roundedProgressPercentageValue <90){
        TileProperties.individualHexagon = hexagonStyle("individual-bluehexagon")
      }
      else if(roundedProgressPercentageValue < 30){
        TileProperties.individualHexagon = hexagonStyle("individual-redhexagon")
      }      
    }
}

export function selectedTileStyle(defaultTileActive,index,activeClass,itemsCount){
    const setClass=(isActive)=>{
      //return ((itemsCount >=2 ? "col-1 " : "col-3 ") + ("card col-3 custom-Shadow mb-3 mr-4 custom-border TileImage pl-0 "+ isActive));
      return "card col-3 custom-Shadow mb-3 mr-4 custom-border TileImage pl-0 "+ isActive
    }
    if (!defaultTileActive && index === 0) {
      TileProperties.cardBoxClass = setClass("active")
    } else if (activeClass) {
      TileProperties.cardBoxClass = setClass("active")
    } else {
      TileProperties.cardBoxClass = setClass()
    }
}

//--- End
