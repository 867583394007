import React from "react"
import * as constants from "./SelectControlConstants"


const options =function(_item){
    const item = constants[_item]
    const opts=[<option value=""> Select </option>]
        for(let i= 0; i < item.length; i++){
            opts.push(<option value={item[i]?.val}> {item[i]?.desc} </option>)
        }
    return opts    
}

const MultiSelect =({description,name,value,onChange,handleEnter,requiredField, error,DataRecord}) => {
    console.log("DATARECORD", DataRecord)
    return ( 
        <React.Fragment>
            <div className="col-lg-6">
                <div className="form-group row">
                    <label className="col-sm-5 text-left pt-2 col-form-label">
                        {description}
                    </label>
                    <div className="col-sm-7">
                        <select
                        value={value}
                        className={"form-control custom-individual-dropdown fontsize "+requiredField}  
                        onChange={onChange}
                        onKeyDown={handleEnter}>
                            {options(name)}
                        </select>
                    {error ? (
                        <label style={{ fontSize: 10, color: "RED" }}>
                        {error}
                        </label>) : <></>}
                        <div className={DataRecord ? "mt-2" : ""}>
                            {(()=>{
                            let itemarr 
                            if(DataRecord){
                                itemarr = DataRecord.split('|')
                            }
                            if(itemarr){
                                console.log("HELLO",itemarr)
                                let item =[]
                                for(let i=0 ; i<itemarr.length; i++){
                                    if(itemarr[i].substring(0,6) =="Passed"){ // hardcoded for testcase now
                                        item.push(<span className="badge badge-success mr-2" style={{fontSize:"11px"}}>
                                                {itemarr[i].substring(0,6)}</span>)
                                    }
                                    else if(itemarr[i].substring(0,6) =="Failed"){  // hardcoded for testcase now
                                        item.push(<span className="badge badge-danger mr-2" style={{fontSize:"11px"}}>
                                                {itemarr[i].substring(0,6)}</span> )
                                    }
                                }
                                return(item) 
                            }    
                        })()}
                        </div>
                    </div>
                </div>     
            </div>
        </React.Fragment>
     );
}

export default MultiSelect;