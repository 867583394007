import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Main from "./Components/Main";
import NotFound from "./Components/NotFound";
import Login from "./Components/Login";
import GanttChart from "./Components/GanttChart";
import SecureLS from "secure-ls";
import CryptoJS from "crypto-js";
//MM - Gantt Chart
import ProjectGanttChart from "./Components/ProjectGanttChart";
import OverallProgressDetailView from "./Components/ApplicationDetailedView/OverallProgressDetailView";
const NormalRoutes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    {/* <Route exact path='/home' component={Main} /> */}
    <Route exact path="/EhrApplications" component={Main} />
    <Route exact path="/ApplicationDetailedView" component={Main} />
    <Route exact path="/GanttChart" component={GanttChart} />
    <Route exact path="/ProjectGanttChart" component={ProjectGanttChart} />
    <Route exact path="/OverallProgressDetailView" component={Main} />
    {/* <Route exact path='/ChangePassword' component={Main} /> */}
    <Route exact path="/notfound" component={NotFound} />
    <Route exact path="/login" render={() => <Redirect to="/" />} />
    <Route render={() => <Redirect to="/notfound" />} />
  </Switch>
);

const ForceAuthRoutes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Route render={() => <Redirect to="/login" />} />
  </Switch>
);

class App extends React.Component {
  constructor() {
    super();
    window.mylsataku = new SecureLS({ encodingType: "aes" });
    window.cryptoJS = CryptoJS;
    //console.log("AMQ Imran mylsataku",window.mylsataku.get('cache1'));
    this.state = {
      isUserLoggedIn: window.mylsataku.get("cache1"), //localStorage.getItem('isUserLoggedIn')
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        {this.state.isUserLoggedIn ? <NormalRoutes /> : <ForceAuthRoutes />}
      </Router>
    );
  }
}

export default App;
