import moment from "moment";
import React from 'react';


const dateFormation=(Date)=>{return moment(Date).format("MM/DD/YYYY")}
const dateDifference=(Date)=>{return moment().startOf('day').diff(Date, "days")}
const PercentageStyle= (type)=>{return ("badge badge-"+type)} //For Other Activities
const StampType=(type)=>{return "stamp custom-stamp "+type} // Project Status Board

const generalizedCondition = (statement,ProgPercent,DaysDiff) =>{
        if(DaysDiff >= 0){
            if(ProgPercent >=90 ){
              return statement("success")  
            }
            else if(ProgPercent < 90){
              return statement("danger")  
            }
        }
        else if(DaysDiff <=-60){
            if(ProgPercent >=75){
              return statement("success")  
            }
            else if(ProgPercent >=20 && 
                    ProgPercent < 75){
              return statement("primary") 
            }
            else if(ProgPercent < 20){
              return statement("danger")  
            }
        }
        else if(DaysDiff >-60){
            if(ProgPercent >=90){
              return  statement("success")  
            }
            else if(ProgPercent >=30 &&
                    ProgPercent <90){
                return  statement("primary")  
                  }
            else if(ProgPercent < 30){
              return statement("danger")  
            }      
        }
    }

export const badgeStyle=(activityTargetData, dashboardPercentage)=>{
    const roundedProgressPercentageValue= Math.round(dashboardPercentage)
    const dateDiff = dateDifference(dateFormation(activityTargetData));
    return generalizedCondition(PercentageStyle,roundedProgressPercentageValue,dateDiff)
}

export const stampStyle=(activityTargetData, dashboardPercentage)=>{
    const roundedProgressPercentageValue= Math.round(dashboardPercentage)
    const dateDiff = dateDifference(dateFormation(activityTargetData));
    return generalizedCondition(StampType,roundedProgressPercentageValue,dateDiff)

}

export const legendshtml= <div className="legend-box mt-3 pt-2 pr-1">
                            <div className=" carret "></div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Completed</label>
                              <div className="color-box" style={{backgroundColor: "#85ccb1"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">On Track</label>
                              <div className="color-box" style={{backgroundColor: "#007bffb0"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Concerning</label>
                              <div className="color-box" style={{backgroundColor: "#c79500"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Issues & Risks</label>
                              <div className="color-box" style={{backgroundColor: "#d9534f"}}></div>
                            </div>
                          </div>


export const legendsganttchart= <div className="legend-box mt-3 pt-2 pr-1">
                            <div className=" carret "></div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Plan Date</label>
                              <div className="color-box" style={{backgroundColor: "#bbbbbb"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Completed On Time</label>
                              <div className="color-box" style={{backgroundColor: "#1b9d10"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">In Progress</label>
                              <div className="color-box" style={{backgroundColor: "#193bbc"}}></div>
                            </div>
                            <div className="input-color">
                              <label className="ml-4 mr-1">Completion Delayed</label>
                              <div className="color-box" style={{backgroundColor: "#de140e"}}></div>
                            </div>
                          </div>











{/* <div>
                            <ul>
                                <li>
                                    <div className="input-color">
                                        <input type="text" value="Completed" />
                                        <div className="color-box" style={{backgroundColor: "#FF850A"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="input-color">
                                        <input type="text" value="On Track" />
                                        <div className="color-box" style={{backgroundColor: "#FF850A"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="input-color">
                                        <input type="text" value="Concerning" />
                                        <div className="color-box" style={{backgroundColor: "#FF850A"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="input-color">
                                        <input type="text" value="Issues & Risks" />
                                        <div className="color-box" style={{backgroundColor: "#FF850A"}}></div>
                                    </div>
                                </li>
                            </ul>
                          </div>

   */}
