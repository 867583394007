import React from 'react';

class NotFound extends React.Component {
	componentDidMount () {
		window.location.href ='/'
	}
	
  render() {
    return (
		<div className="m-2">
        	
			<h4>Page Not Found</h4>
    	</div>
		);
	}
}

export default NotFound;
