import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { appCred } from "../AppConfig";
import "./assests/StyleSheet/MainHeader.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    let user = window.mylsataku.get("cache2");
    this.state = {
      LoggedInUserName: user.User.UserName,
      LoggedInUserRole: user.User.UserOrganizationType,
      tempMenu: "",
      showHomeButton: false,
    };

   
  }
 
  componentDidMount() {
   
 

    window
      .APIRequest(appCred, "", "EHRDashboardManager.CheckUserHasMTISBAccess", {
        UserID: this.state.LoggedInUserName,
      })
      .then((apiRes) => {
     
        if (apiRes.ResponseResult == true) {
          this.setState({
            showHomeButton: apiRes.ResponseResult,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidUpdate() {
    console.log("header updated");
  }
  rToggleNav = () => {
    window.ToggleNav();
  };
  rLogOut = () => {
    window.mylsataku.removeAll();
    //localStorage.clear();
    window.location = "/";
  };
  Capitalizing(str) {
    let string = str.replace(".", " ");
    var separateWord = string.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  }

  render() {
    return (
      <React.Fragment>
        <header className="custom-main-header header-mobile">
          <div className="col-10">
            <span className="header-user header-user-font">
              {this.Capitalizing(this.state.LoggedInUserName)} [
              {this.state.LoggedInUserRole}]
            </span>
          </div>
          <div className="col-2 float-right">
            <i className="fa fa-sign-out fa-2x " onClick={this.rLogOut}></i>
          </div>
        </header>

        <header className="custom-main-header custom-header-height">
          <div className=" row mt-1">
            <div className={this.state.showHomeButton ? "col-7" : "col-8"}>
              <span title="" className="custom-header-title">
                <Link style={{ color: "white", textDecoration: "none" }} to="/">
                  AKU One-Health - Implementation Status Board
                </Link>
              </span>
              {/* <span title="" style={{fontFamily: '"Raleway", sans-serif', fontSize: '26px', textAlign: 'left', fontWeight: 600, color: 'white', cursor: 'pointer'}} onClick={this.rToggleNav}>
            ☰ 
          </span> */}
            </div>
            <div className="col-3" style={{ right: "-40px" }}>
              <span className="header-user ">
                {this.Capitalizing(this.state.LoggedInUserName)} [
                {this.state.LoggedInUserRole}]
              </span>
            </div>
            <div
              className={
                this.state.showHomeButton ? "col-2 text-center pl-0" : "col-1"
              }
            >
              <span className={this.state.showHomeButton ? "" : "home-btn"}>
              <Link  to="/">
                <Button
                  className="btn-sm"
                  style={{
                    backgroundColor: "#2d6186",
                    borderColor: "#35698d",
                    
                  }} >
                  Home
                </Button>
                </Link>
              </span>
              <span className={this.state.showHomeButton ? "" : "logout-btn"}>
                <Button
                  className="btn-sm"
                  onClick={this.rLogOut}
                  style={
                    this.state.showHomeButton
                      ? {
                          marginBottom: "20px",
                          marginRight: "10px",
                          paddingBottom: "5px",
                          height: "33px",
                          backgroundColor: "#b02d27b8",
                          borderColor: "#b02d27b8",
                        }
                      : {
                          backgroundColor: "#b02d27b8",
                          borderColor: "#b02d27b8",
                        }
                  }
                >
                  Logout
                </Button>
              </span>
              {this.state.showHomeButton ? (
                <Link
                  to={{
                    pathname: "/ApplicationsMain",
                    state: {
                      facilityID: this.state.facilityID,// "AKUHN",
                      activityID: "MTISB",
                      thruPSB: true,
                    },
                  }}
                >
                  <Button
                    className="btn-sm"
                    style={{
                      marginBottom: "20px",
                      backgroundColor: "#32a135",
                      borderColor: "#32a135",
                      minHeight: "32px",
                      minWidth: "60px",
                    }}
                  >
                    Home
                  </Button>
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
